
import cloud from '@cloud/VJYCloudClient';
import * as THREE from 'three'
import shaderUtils from "@three-extra/util/ShaderUtils"

const blendingModes = [THREE.NoBlending, THREE.NormalBlending, THREE.AdditiveBlending, THREE.SubtractiveBlending, THREE.MultiplyBlending, THREE.CustomBlending];

const { abs, floor } = Math
 /**
  * @typedef ParticleSystemParams
  * @type { object }
  * @property {number} particleCount - number of particles 
  * @property { Shader } [ vertexShader ]
  * @property { Shader } [ fragmentShader ] 
  * @property { Color } [ color ] 
  * @property { number } [ alpha ]  - general particle alpha
  * @property { number } [ size ] - general particle sizes 
  * @property { number } [ blendingMode ] - blending mode
  * @property { boolean } [ depthWrite ] - turn depthWrite on 
  * 
  * @property { boolean } [ velocity ] - turn velocity on
  */

   /**
  * @typedef Shader
  * @type { object }
  * @property { string } code 
  * @property { string } functions 
  * @property {object} uniforms 
  */



  /**
 * A simple particle system
 * @param { ParticleSystemParams  } params 
 *
 */
export default class ParticleSystem extends THREE.Points {
    
    constructor( params ) {
        super()

        this.setParams( params )
        this.createGeometry()
        this.createAttributes()
        this.createAttributesAndVaryingsDecl()
        this.createUniforms()
        this.createUniformsDecl()
        this.createShaderCode()
        this.createMaterial()
    }
    setParams( params ){
        if ( Number.isNaN( params.particleCount ) ) throw new Error('Particle system params.particleCount is not a number')
        this.params = params 
        this.params.map = params.map !==undefined?  cloud.getDoc( this.params.map ).d.baseObj.asset[">ext"].url : 'http://vjyourself.com/webgl/tex/particle2.png'
        this.params.blendingMode = params.blendingMode !== undefined? blendingModes[ params.blendingMode ]  : blendingModes[ 0] 
        this.params.alpha = this.params.alpha !== undefined? this.params.alpha : 0.5
        this.params.size= this.params.size !== undefined? this.params.size : 10  
        this.params.depthWrite  = this.params.depthWrite !== undefined? this.params.depthWrite : false 

        this.currentIndex = 0 
    }
    setPosition(index, position) {
        this.geometry.attributes.position.array[index * 3] = position.x
        this.geometry.attributes.position.array[index * 3 + 1 ] = position.y
        this.geometry.attributes.position.array[index * 3 + 2 ] = position.z

        this.geometry.attributes.position.shouldUpdate = true 
    }
    setColor(index, color) {
        this.geometry.attributes.color.array[index * 3] = color.r
        this.geometry.attributes.color.array[index * 3 + 1 ] = color.g
        this.geometry.attributes.color.array[index * 3 + 2 ] = color.b

        this.geometry.attributes.color.shouldUpdate = true 
    }
    getColor( index ) {
        return new THREE.Color(
            this.geometry.attributes.color.array[index * 3],
            this.geometry.attributes.color.array[index * 3 + 1 ],
            this.geometry.attributes.color.array[index * 3 + 2 ] 
        )
    }
    setSize(index, size) {
        this.geometry.attributes.size.array[index ] = size 
        this.geometry.attributes.size.shouldUpdate = true 
    }
    getSize( index ) {
        return this.geometry.attributes.size.array[index ] 
    }
    setUV(index, uv) {
        this.geometry.attributes.uv.array[index * 2] = uv.x
        this.geometry.attributes.uv.array[index * 2 + 1 ] = uv.y 
        this.geometry.attributes.uv.shouldUpdate = true 
    }
    setStartTime( index ){
        this.geometry.attributes.startTime.array[index ] = this.uniforms.uTime.value  
        this.geometry.attributes.startTime.shouldUpdate = true 
    }
    setVelocity( index, velocity ){
        this.geometry.attributes.velocity.array[index * 3  ] = velocity.x  
        this.geometry.attributes.velocity.array[index * 3  + 1 ] = velocity.y 
        this.geometry.attributes.velocity.array[index * 3 + 2  ] = velocity.z  
        this.geometry.attributes.velocity.shouldUpdate = true 
    }
    setParticle( index, options = {position: null, color: null, size: null, uv: null, velocity: null  }){
        options.position && this.setPosition( index, options.position )
        options.color && this.setColor( index, options.color )
        options.size && this.setSize( index, options.size )
        options.uv && this.setUV( index, options.uv )
        if ( this.params.sizeDecay || this.params.velocity)  this.setStartTime( index )
        options.velocity && this.setVelocity( index, options.velocity)
    }
    setNextParticle( options = {position: null, color: null, size: null, uv: null }){
        if ( this.currentIndex > this.params.particleCount - 1 ) this.currentIndex = 0 
        const index = this.currentIndex
        this.setParticle( index, options )
        this.currentIndex ++ 

    }

    createGeometry() {
        this.geometry = new THREE.BufferGeometry()
    }
    createAttributes() {
        const attributes = [
            {
                name: "position",
                itemSize: 3
            },
            {
                name: "color",
                itemSize: 3
            },
            {
                name: "size",
                itemSize: 1
            },
            {
                name: "uv",
                itemSize: 2
            },
        ]
        if ( this.params.sizeDecay || this.params.velocity  ) attributes.push( {
            name: 'startTime',
            itemSize: 1
        })
        if ( this.params.velocity ) attributes.push( {
            name: 'velocity',
            itemSize: 3
        })
        for (let attr of attributes) this.addAttribute(attr.name, attr.itemSize)
      
    }

    addAttribute(name, itemSize) {
        const buffer = new Float32Array( itemSize  * this.params.particleCount).fill( 1 )
        this.geometry.setAttribute(name, new THREE.BufferAttribute( buffer, itemSize ))
        this.geometry.attributes[ name ].shouldUpdate = false 
    }

    /**
     * create GLSL declarations for attributes and corresponding varyings
     */
    createAttributesAndVaryingsDecl() {
        const attributes = {}
        this.varyingDecl = ""
        this.varyingMain = ""
        for (let i in this.geometry.attributes) {
            let type
            switch (this.geometry.attributes[i].itemSize) {
                case 1:
                    type = "float"
                    break;
                case 2:
                    type = "vec2"
                    break;
                case 3:
                    type = "vec3"
                    break;
                case 4:
                    type = "vec4"
                    break;
                default:
                    break;
            }
            
            attributes[i] = { type }
            const varyingName = "v" + i.charAt(0).toUpperCase() + i.slice(1) 
            this.varyingDecl += "varying " +type + " " + varyingName + ";\n"
            this.varyingMain += varyingName + " = " + i + ";\n"
        }
        delete attributes.position // this is added to the GLSL by ShaderMaterial
        delete attributes.uv 
      
        console.log( attributes)
        if ( this.params.sizeDecay || this.params.velocity ) {
            this.varyingDecl += 'varying float vLifeLeft;'
        }
        this.attributesDecl = shaderUtils.attributesToGLSLDecl( Object.assign( {}, attributes)   ) 
    }

    createUniforms(){

        const loader = new THREE.TextureLoader()
        const url =  this.params.map  
        const map = loader.load( url );

        map.wrapS = map.wrapT = THREE.RepeatWrapping;
        this.uniforms = {
            iTime: { // maintain compatibility with ProceduralTexture
                value: 0,
                type: "float"
            },
            uTime: {
                value: 0,
                type: "float"
            },
            uLifeTime: {
                value: 1,
                type: "float"
            },
            uSize: {
                value: 0,
                type: "float"
            },
            uAlpha: {
                value: 0,
                type: "float"
            },
            uParticleMap: {
                value: map,
                type: "sampler2D"
            },
            uDim: {
                value: map,
                type: "vec3"
            },
        }
        if ( this.params.fragmentShader ) for ( let key in this.params.fragmentShader.uniforms ) this.uniforms[ key ] = this.params.fragmentShader.uniforms[ key ]
        if ( this.params.vertexShader ) for ( let key in this.params.vertexShader.uniforms ) this.uniforms[ key ] = this.params.vertexShader.uniforms[ key ]
    }
    createUniformsDecl() {
        this.uniformsDecl = shaderUtils.uniformsToGLSLDecl(this.uniforms)
    }
    createShaderCode() {
        this.vertexShader = {
            code: "",
            functions: ""
        }
        this.vertexShader.functions += this.attributesDecl
        this.vertexShader.functions += this.varyingDecl
        this.vertexShader.functions += this.uniformsDecl
        if ( this.params.vertexShader ) {
            this.vertexShader.functions +=  this.params.vertexShader.functions
            this.vertexShader.code +=  this.params.vertexShader.code
        }

        this.vertexShader.code = vertexShader.mainA 
                               + this.varyingMain
                               + this.vertexShader.code
                               + ( ( this.params.sizeDecay || this.params.velocity) ? lifeLeftVertex : '')
                               + ( this.params.sizeDecay? sizeDecayVertex : '')
                               + ( this.params.velocity? velocityVertex : '')
                               + vertexShader.mainB

        this.fragmentShader = {
            code: "",
            functions: ""
        }
   
        this.fragmentShader.functions += this.varyingDecl
        this.fragmentShader.functions += this.uniformsDecl
        if ( this.params.fragmentShader ) {
            this.fragmentShader.functions +=  this.params.fragmentShader.functions
            this.fragmentShader.code +=  this.params.fragmentShader.code
        }
        
        this.fragmentShader.code = fragmentShader.mainA 
                               + this.fragmentShader.code
                               + ( this.params.sizeDecay? sizeDecayFragment : '' ) 
                               + fragmentShader.mainB


        console.log( this.vertexShader.functions )
        console.log( this.vertexShader.code )

        console.log( this.fragmentShader.functions )
        console.log( this.fragmentShader.code )
        
    }

    createMaterial() { 
        for ( let i in this.uniforms ) delete this.uniforms[i].type 
        this.material = new THREE.ShaderMaterial({
            vertexShader: this.vertexShader.functions + this.vertexShader.code ,
            fragmentShader: this.fragmentShader.functions + this.fragmentShader.code ,
            uniforms: this.uniforms ,

            transparent: true,
            depthWrite: this.params.depthWrite,
            blending: this.params.blendingMode
        })
        // this.material.onBeforeCompile = shader => console.log( 
        //     shader.vertexShader, "\n\n\n\n",
        //     shader.fragmentShader, "\n\n\n\n",
        // )
      // this.material = new THREE.PointsMaterial({ color: new THREE.Color(1,0,0), size: 1 })
    }

 

    updateAttributes(){
        for ( let i in this.geometry.attributes ){
            this.geometry.attributes[ i ].needsUpdate = this.geometry.attributes[ i ].shouldUpdate
            this.geometry.attributes[ i ].shouldUpdate = false 
        }
    }
    updateDimensions(){
        this.geometry.computeBoundingBox()
        const box = this.geometry.boundingBox
        const dim = this.uniforms.uDim.value
        dim.x = abs( box.max.x - box.min.x )
        dim.y = abs( box.max.y - box.min.z )
        dim.z = abs( box.max.z - box.min.z )
    }

    update(dt) {
        dt = Math.min( dt, 0.5)
        this.uniforms.iTime.value += dt 
        this.uniforms.uTime.value += dt 
        this.uniforms.uSize.value = this.params.size
        this.uniforms.uAlpha.value = this.params.alpha
        this.material.blendingMode = this.params.blendingMode

        this.updateAttributes()
    }

}


const vertexShader = { mainA :`
                            void main() {
                                vec3 transformed = position;
                                gl_PointSize = uSize * size;`
                            ,
                        mainB: `
                                 gl_Position = projectionMatrix * modelViewMatrix * vec4( transformed, 1.0 );
                            }`


}
const fragmentShader = { mainA: `
void main() {
    vec4 tex = texture2D( uParticleMap, gl_PointCoord );
    vec4 diffuseColor = vec4( vColor.rgb , uAlpha );` ,
    mainB: `    
             gl_FragColor = vec4( diffuseColor.rgb * tex.a , diffuseColor.a * tex.a ) ; 
     }  `     

}

const lifeLeftVertex = `vLifeLeft = (uTime - startTime) / uLifeTime; 
if ( vLifeLeft < 0. ){
    vLifeLeft = 0. ;
}`
const sizeDecayVertex = ` 
gl_PointSize *= vLifeLeft;
`
const sizeDecayFragment = ` diffuseColor.a *= vLifeLeft;`


const velocityVertex = ` transformed += ( 1. - vLifeLeft ) * velocity ;
`





// THREE.PointsMaterial VERTEX DECL 

// uniform float size;
// uniform float scale;
// #include <common>
// #include <color_pars_vertex>
// #include <fog_pars_vertex>
// #include <morphtarget_pars_vertex>
// #include <logdepthbuf_pars_vertex>
// #include <clipping_planes_pars_vertex>
// void main() {
// 	#include <color_vertex>
// 	#include <begin_vertex>
// 	#include <morphtarget_vertex>
// 	#include <project_vertex>
// 	gl_PointSize = size;
// 	#ifdef USE_SIZEATTENUATION
// 		bool isPerspective = isPerspectiveMatrix( projectionMatrix );
// 		if ( isPerspective ) gl_PointSize *= ( scale / - mvPosition.z );
// 	#endif
// 	#include <logdepthbuf_vertex>
// 	#include <clipping_planes_vertex>
// 	#include <worldpos_vertex>
// 	#include <fog_vertex>
// } 


// THREE.PointsMaterial fragmnet decl

// uniform vec3 diffuse;
// uniform float opacity;
// #include <common>
// #include <color_pars_fragment>
// #include <map_particle_pars_fragment>
// #include <fog_pars_fragment>
// #include <logdepthbuf_pars_fragment>
// #include <clipping_planes_pars_fragment>
// void main() {
// 	#include <clipping_planes_fragment>
// 	vec3 outgoingLight = vec3( 0.0 );
// 	vec4 diffuseColor = vec4( diffuse, opacity );
// 	#include <logdepthbuf_fragment>
// 	#include <map_particle_fragment>
// 	#include <color_fragment>
// 	#include <alphatest_fragment>
// 	outgoingLight = diffuseColor.rgb;
// 	gl_FragColor = vec4( outgoingLight, diffuseColor.a );
// 	#include <premultiplied_alpha_fragment>
// 	#include <tonemapping_fragment>
// 	#include <encodings_fragment>
// 	#include <fog_fragment>
// }