import Listeners from "@rt/Listeners";

function findPos(obj) {
  var curleft = 0;
  let curtop = 0;
  if (obj.offsetParent) {
    do {
      curleft += obj.offsetLeft;
      curtop += obj.offsetTop;
    } while ((obj = obj.offsetParent));
  }
  return { left: curleft, top: curtop };
}

class DomManager {
  constructor(options) {
    this.listeners = new Listeners();
    this.setContainer(options.dom);
    this.setCanvas(options.canvas);
    window.addEventListener("resize", this.onResize);

    this.elems = {};
    this.styles = {};
    this.attributesNS = {};
    this.boundingRectsByQuerySelector = {};
    this.posBySelector = {};
    window.dm = this;
  }
  start() {
    this.onResize();
  }
  setContainer(querySelector) {
    this.container = document.querySelector(querySelector);
  }
  setCanvas(canvas) {
    this.canvas = canvas;
    this.canvasContainer = canvas.parentElement;
  }
  getCanvasDimensions() {
    return {
      height: this.canvas.offsetHeight,
      width: this.canvas.offsetWidth,
    };
  }
  onResize = (_) => {
    this.listeners.fire("resize", {
      height: this.canvas.offsetHeight,
      width: this.canvas.offsetWidth,
    });

    // update the base height unit
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    document.documentElement.style.setProperty(
      "--vh",
      window.innerHeight / 100 + "px"
    );
    this.boundingRectsByQuerySelector = {};
    this.posBySelector = {};
  };
  scrollTo(currentScroll) {
    this.currentScroll = currentScroll;
    this.container.style.transform = `translateY(${currentScroll}px)`;
  }
  getCurrentScroll() {
    return this.currentScroll;
  }
  setStyle = (querySelector, property, value) => {
    let style = this.styles[querySelector];
    if (!style) {
      this.styles[querySelector] = {};
      style = this.styles[querySelector];
    }
    const currentValue = style[property];
    if (currentValue === value) return;

    const el = this.getEl(querySelector);
    if (!el) return;
    el.style[property] = value;
    style[property] = value;
  };
  setAttributeNS = (querySelector, property, value) => {
    let style = this.attributesNS[querySelector];
    if (!style) {
      this.attributesNS[querySelector] = {};
      style = this.attributesNS[querySelector];
    }
    const currentValue = style[property];
    if (currentValue === value) return;

    this.getEl(querySelector).setAttributeNS(null, property, value);
    style[property] = value;
  };

  // get a DOM element
  getEl(querySelector) {
    let el = this.elems[querySelector];
    if (!el) {
      el = document.querySelector(querySelector);
      if (!el) console.warn(querySelector + " is not def");
      this.elems[querySelector] = el;
    }
    return el;
  }
  getElems(querySelectorAll) {
    let el = this.elems[querySelectorAll];
    if (!el) {
      el = document.querySelectorAll(querySelectorAll);

      this.elems[querySelectorAll] = el;
    }
    return el;
  }

  // get a DOM element's bounding rectangle
  getBoundingRect(querySelector) {
    let rect = this.boundingRectsByQuerySelector[querySelector];
    if (!rect) {
      rect = this.getEl(querySelector).getBoundingClientRect();
      this.boundingRectsByQuerySelector[querySelector] = rect;
    }
    return rect;
  }
  getPos(querySelector, refresh) {
    // console.log( querySelector )
    let rect = this.posBySelector[querySelector];
    if (!rect || refresh) {
      rect = findPos(this.getEl(querySelector));
      this.posBySelector[querySelector] = rect;
    }
    return rect;
  }
  getOffsetTop(querySelector, refresh = false) {
    return this.getPos(querySelector, refresh).top;
  }
  getHeight(querySelector) {
    return this.getBoundingRect(querySelector).height;
  }
  getTop(querySelector) {
    return this.getBoundingRect(querySelector).top;
  }

  // get the vertical offset of the element relative to the container
  getElScrollCenter(querySelector) {}
}

export default DomManager;
