import * as THREE from "three";
import VisComp from "@three-extra/VisComp";
import typeManager from "@cloud/TypeManager";

import gm from "@three-extra/asset/GeometryManager";
import typeMan from "@cloud/TypeManager";
import materialManager from "@three-extra/asset/MaterialManager";
import shaderUtils from "@three-extra/util/ShaderUtils";
import cloud from "@cloud/VJYCloudClient";
import objMan from "@cloud/ObjectManager";
import { cloneDeep } from "lodash";
const { typeNameToId, typeIdToName } = typeMan;
class ProceduralTexturePreview extends VisComp {
  constructor() {
    super();
    this.update = this.update.bind(this);
    this.stepGeom = this.stepGeom.bind(this);
    this.isPreviewComp = true;
    window.pt = this;
    window.matMan = materialManager;

    window.comp = this;

    //console.log('MatMan', materialManager)
  }

  // Doc is passed to the start function if the comp is created by PreviewCanvas
  start(doc, canvas) {
    super.start();

    console.log("Start ProcTex preview", this);

    let asset;

    //  View App
    if (!doc) {
      //

      asset = this.inputs.get("_asset"); // { '>link': { id: this.doc._id } };
      this[">link"] = { id: asset._id };
      this.doc = cloud.getDoc(asset);

      document.addEventListener(
        "keyup",
        (e) => e.code === "Space" && this.stepGeom()
      );
    } else {
      this.viewer = "preview";
      // this.renderer.renderer.domElement.addEventListener(
      //   "click",
      //   this.stepGeom
      // );
      this.previewDoc = doc;
      this[">link"] = doc[">link"] || { id: doc._id };
      this.dummyLink = this[">link"];
      asset = { ">link": { id: doc._id } };
      this.doc = doc;
      this.me.camera.position.z = 10;
    }

    this.stepType = "geometry";

    this.light = new THREE.DirectionalLight(0xffffff, 0.9);
    this.light.position.set(10, 10, 10);
    this.cont3D.add(this.light);
    this.rotSpeed = 0.002;

    this.material = materialManager.build({
      base: new THREE.MeshBasicMaterial(),
      asset,
    });
    this.geometry = new THREE.PlaneGeometry();
    this.mesh = new THREE.Mesh(this.geometry, this.material);
    this.cont3D.add(this.mesh);

    const dimZ = this.renderer.screenDimZ(this.me.camera.position.z);
    this.z = this.me.camera.position.z;
    this.mesh.scale.set(dimZ.x, dimZ.x, dimZ.x);

    if (!doc) {
      this.me.ctrl.setActive(false);
    }

    const typeDef = typeMan.getTypeDef(this.doc.t);
    this.inputs.typeDef = typeDef;
    this.material.side = THREE.DoubleSide;

    // Input listeners
    this.material.onBeforeCompile = (shader) => {
      this.shaderUniforms = shader.uniforms;

      // for ( let key in shader.uniforms ){
      // 	console.log( key,  typeof shader.uniforms[key].value, shader.uniforms[key].value )
      // 	if ( shader.uniforms[key].type === 'vec3' && typeof shader.uniforms[key].value === 'number' ){
      // 		console.log('color is a number ')
      // 		const col = new THREE.Color( shader.uniforms[key].value  )
      // 		shader.uniforms[key].value = col
      // 	}
      // }

      //console.log('UNIFORMS', cloneDeep(shader.uniforms))
      for (let propName in typeDef.properties) {
        const t = typeDef.properties[propName];

        if (
          t.type.type === typeNameToId("float") ||
          t.type.type === typeNameToId("int") ||
          t.type.type === typeNameToId("boolean")
        ) {
          this.inputs.listeners.add(propName, (_) => {
            shader.uniforms[propName].value = this.inputs.get(propName);
          });
        }
        if (
          t.type.type === typeNameToId("Vector2") ||
          t.type.type === typeNameToId("Vector3")
        ) {
          this.inputs.listeners.add(propName, (_) => {
            const v = this.inputs.get(propName);
            if (shader.uniforms[propName].value.x !== undefined) {
            } else
              shader.uniforms[propName].value.forEach(
                (_, i, arr) => (arr[i] = Object.values(v)[i])
              ); // inputs come as a Vector3 but value created by shaderutils is an array. do the conversion
          });
        }
        if (t.type.type === typeNameToId("Color")) {
          console.log(
            "Add Color Listener >>",
            propName,
            "uniform value",
            shader.uniforms[propName].value,
            "uniform ty[e",
            typeof shader.uniforms[propName].value
          );

          //	if ( typeof shader.uniforms[propName ].value === 'number') shader.uniforms[propName].value = [0,0,0]
          this.inputs.listeners.add(propName, (_) => {
            let v = this.inputs.get(propName);
            console.log("UPDATE", v);

            if (typeof shader.uniforms[propName].value === "number") {
              shader.uniforms[propName].value = [0, 0, 0];
            }

            if (typeof v === "number" || typeof v === "string") {
              v = new THREE.Color(v);
              // shader.uniforms[propName].value = new THREE.Color()
              // shader.uniforms[propName].value[0] = v.r
              // shader.uniforms[propName].value[1] = v.g
              // shader.uniforms[propName].value[2] = v.b
            }

            // if ( Array.isArray( shader.uniforms[propName].value) ){
            // 	shader.uniforms[propName].value = new THREE.Color()
            // 	shader.uniforms[propName].value.r = v[0]
            // 	shader.uniforms[propName].value.g = v[1]
            // 	shader.uniforms[propName].value.b = v[2]
            // }

            if (v.r !== undefined) {
              shader.uniforms[propName].value[0] = v.r;
              shader.uniforms[propName].value[1] = v.g;
              shader.uniforms[propName].value[2] = v.b;
            }
            //console.log( shader.uniforms[propName].value)

            //if ( shader.uniforms[ propName ].value.x !==undefined  ) {

            //	} else shader.uniforms[ propName ].value.forEach( (_,i,arr)=> arr[i] = Object.values(v)[i]) // inputs come as a Vector3 but value created by shaderutils is an array. do the conversion
          });
        }
        if (t.type.type === typeNameToId("Sequence<Color>")) {
          console.log(
            "Add SEQUNCE Color Listener >>",
            propName,
            "uniform value",
            shader.uniforms[propName].value,
            "uniform ty[e",
            typeof shader.uniforms[propName].value
          );

          //	if ( typeof shader.uniforms[propName ].value === 'number') shader.uniforms[propName].value = [0,0,0]
          this.inputs.listeners.add(propName, (_) => {
            console.log();

            let v = this.inputs.getObject(propName);

            if (shader.uniforms[propName].value.length !== v.length) {
              this.dispose();
              const i = this.inputs.get(propName);
              const id = i[">link"].id;
              this.doc.d[propName] = {
                ">link": {
                  id,
                },
              };
              // this.doc
              this.start();
              return;
            }
            shader.uniforms[propName].value = v;
          });
        }
        if (t.type.type === typeNameToId("Texture2D")) {
          this.inputs.listeners.add(propName, (_) => {
            if (this.inputs.get(propName) === null)
              return (shader.uniforms[propName].value = null);

            const doc = cloud.getDoc(this.inputs.get(propName));
            shader.uniforms[propName].value =
              materialManager.assetToTexture(doc);
          });
        }
      }
      //	console.log('UNIFORMS POST', cloneDeep(shader.uniforms))
    };

    // Preview options - step through geometries
    this.steps = [
      {
        geom: new THREE.PlaneGeometry(1, 1, 10, 10),
        matOptions: {
          wireframe: false,
        },
      },
      {
        geom: new THREE.BoxGeometry(1, 1, 1, 10, 10, 10),
        matOptions: {
          wireframe: false,
        },
      },
      {
        geom: new THREE.SphereGeometry(0.6, 16, 16),
        matOptions: {
          wireframe: false,
        },
      },
      {
        geom: new THREE.PlaneGeometry(2.3, 2.3, 10, 10),
        matOptions: {
          wireframe: true,
        },
      },
      {
        geom: new THREE.BoxGeometry(1, 1, 1, 10, 10, 10),
        matOptions: {
          wireframe: true,
        },
      },
      {
        geom: new THREE.SphereGeometry(0.6, 16, 16),
        matOptions: {
          wireframe: true,
        },
      },
    ];

    this.stepInd = 0;

    this.update(0);

    console.log(this.me.camera.position.clone(), this.mesh.scale.clone());
    if (!this?.scene?.app?.isIframePreview) return;
  }

  // changes geometry when preview canvas is clicked
  stepGeom() {
    this.stepInd++;
    this.stepInd = this.stepInd % this.steps.length;

    if (this.stepInd > 0) {
      if (this.me.ctrl) this.me.ctrl.setActive(true);
      this.mesh.scale.setScalar(1);
    } else {
      this.me.ctrl.setActive(false);

      this.me.camera.rotation.set(0, 0, 0);

      this.me.camera.position.set(0, 0, this.z);
      const dimZ = this.renderer.screenDimZ(this.me.camera.position.z);

      this.mesh.scale.set(dimZ.x, dimZ.x, 1);
    }

    this.mesh.geometry = this.steps[this.stepInd].geom;
    for (let key in this.steps[this.stepInd].matOptions)
      this.mesh.material[key] = this.steps[this.stepInd].matOptions[key];
    this.mesh.material.needsUpdate = true;
    this.mesh.rotation.set(0, 0, 0);
  }

  //called every frame, dt: time passed between frames
  update(dt) {
    super.update(dt);
    if (!dt) return;

    if (this.stepInd !== 0 && this.stepInd !== 3)
      this.mesh.rotation.y += this.rotSpeed;
  }

  dispose() {
    this.material.dispose();
    if (this.geometry) this.geometry.dispose();
    for (let s in this.steps) if (s.geom) s.geom.dispose();
    this.cont3D.remove(this.mesh);
    this.light.dispose();
    this.cont3D.remove(this.light);
  }
}

// DO NOT DELETE THIS SHIT

typeManager.registerClass(
  typeNameToId("Preview.ProceduralTexture"),
  ProceduralTexturePreview
);

export default ProceduralTexturePreview;
