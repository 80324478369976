import objMan from "@cloud/ObjectManager";

import "@three-extra/ctrl/CompCurveFollow";
import typeMan from "@cloud/TypeManager";
const { typeNameToId } = typeMan;

class Me {
  constructor() {
    this.props = {
      ctrl: {
        t: "",
        d: {},
      },
    };
  }
  start() {
    this.cont3D = this.renderer.camera;
    this.camera = this.cont3D;
  }
  setProps(p) {
    if (p.ctrl) {
      //console.log("Me Set Ctrl",p.ctrl);
      this.props.ctrl = p.ctrl;
      this.updateCtrl();
    }
  }

  updateCtrl() {
    this.compCont3D.position.set(0, 0, 0);
    this.compCont3D.rotation.set(0, 0, 0);
    //this.renderer.camera.position.set(0, 0, 5);
    this.renderer.camera.rotation.set(0, 0, 0);

    if (this.ctrl) this.ctrl.destroy();

    /*
		this.ctrl = typeMan.instantiate({
			type: this.props.ctrl[">link"].t,
			params: this.props.ctrl,
			arguments: [, ],
		});*/
    this.ctrl = objMan.deserialize(this.props.ctrl, {
      type: typeNameToId("Ctrl"),
      level: 1,
    });
    //console.log('CTRL Obj: ', this.ctrl);
    this.ctrlType = this.ctrl[">link"].t;
    this.ctrl._camera = this.renderer.camera;
    this.ctrl._targetObj = this.compCont3D;

    //console.log('CTRL!!!', this.ctrl);
    //this.ctrl.context = this.sceneContext;

    if (this.ctrlType === typeNameToId("Ctrl.Rails") && this.path) {
      this.ctrl.setPath(this.path, this.pathX);
      //this.ctrl.path = this.composition.path;
      //if(this.state.composition === 'VJY5.Scene.EndlessPath') this.composition.setTrackCtrl(this.ctrl);
      //if(this.state.composition === 'VJY5.Scene.SpaceVJY4') this.composition.setTrackCtrl(this.ctrl);
    }

    if (this.ctrl) {
      this.ctrl.start();
      this.ctrl.setActive(true);
    }
  }
  setPath(path, x = 0) {
    //console.log("!!!!!!!!!!!!!!!!!!!!!!!!!",path,this.props.ctrl);
    this.path = path;
    this.pathX = x;
    if (this.ctrlType === typeNameToId("Ctrl.Rails") && this.path)
      this.ctrl.setPath(this.path, this.pathX);
  }
  update(dt) {
    if (this.ctrl) this.ctrl.update(dt);
  }
}

export default Me;
