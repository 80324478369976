
class HTMLUtils {
	addDiv(parent, className,content) {
		const div = document.createElement('div');
		div.className = className;
		if(parent!=null) parent.appendChild(div);
		if(content!=null) div.innerHTML=content;
		return div;
	}
	addImage(parent, className,src) {
		const div = document.createElement('img');
		div.className = className;
		if(parent!=null) parent.appendChild(div);
		if(src!=null) div.src=src;
		return div;
	}
	addSpan(parent, className,content) {
		const div = document.createElement('span');
		div.className = className;
		if(parent!=null) parent.appendChild(div);
		if(content!=null) div.innerHTML=content;
		return div;
	}
	addLink(parent, className,content) {
		const div = document.createElement('a');
		div.className = className;
		if(parent!=null) parent.appendChild(div);
		if(content!=null) div.innerHTML=content;
		return div;
	}
	addButton(parent, className,content) {
		const div = document.createElement('button');
		div.className = className;
		if(parent!=null) parent.appendChild(div);
		if(content!=null) div.innerHTML=content;
		return div;
	}
	addInput(type,parent, className,content) {
		const div = document.createElement('input');
		div.className = className;
		div.type=type;
		if(parent!=null) parent.appendChild(div);
		if(content!=null) div.innerHTML=content;
		return div;
	}
	addLabel(parent, className,content) {
		const div = document.createElement('label');
		div.className = className;
		if(parent!=null) parent.appendChild(div);
		if(content!=null) div.innerHTML=content;
		return div;
	}
	addBr(parent) {
		const br = document.createElement('br');
		if(parent!=null) parent.appendChild(br);
		return br;
	}
	addCheckbox(parent, className, val) {
		const div = document.createElement('input');
		div.type="checkbox";
		div.className = className;
		if(parent!=null) parent.appendChild(div);
		if(val!=null) div.checked=val;
		return div;
	}
	arrayToList(a){
		const list=[];
		for(let i=0;i<a.length;i++) list.push({value:i,name:a[i]});
		return list;
	}
	addDropDown(parent, className, list) {
		//const div = document.createElement('div');
		//div.className = className;
		const dd = document.createElement('select');
		//dd.className=className;
		parent.appendChild(dd);
		//div.appendChild(dd);
		for (let i = 0; i < list.length; i++) {
			const opt = document.createElement('option');
			if (typeof list[i] === 'string') {
				opt.value = list[i];
				opt.innerHTML = list[i];
			} else {
				opt.value = list[i].value;
				opt.innerHTML = list[i].name;
				if(list[i].color!=null)opt.style.backgroundColor=list[i].color;
			}
			dd.appendChild(opt);
		}
		return dd;
	}
	updateDropDown(dd, list) {
		dd.innerHTML="";
		for (let i = 0; i < list.length; i++) {
			const opt = document.createElement('option');
			if (typeof list[i] === 'string') {
				opt.value = list[i];
				opt.innerHTML = list[i];
			} else {
				opt.value = list[i].value;
				opt.innerHTML = list[i].name;
				if(list[i].color!=null)opt.style.backgroundColor=list[i].color;
			}
			dd.appendChild(opt);
		}
		return dd;
	}
	encodeEntities(value) {
        const SURROGATE_PAIR_REGEXP = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
        const NON_ALPHANUMERIC_REGEXP = /([^\#-~| |!])/g;
        return value.
          replace(/&/g, '&amp;').
          replace(SURROGATE_PAIR_REGEXP, function(value) {
            var hi = value.charCodeAt(0);
            var low = value.charCodeAt(1);
            return '&#' + (((hi - 0xD800) * 0x400) + (low - 0xDC00) + 0x10000) + ';';
          }).
          replace(NON_ALPHANUMERIC_REGEXP, function(value) {
            return '&#' + value.charCodeAt(0) + ';';
          }).
          replace(/</g, '&lt;').
          replace(/>/g, '&gt;');
      }
	
}

export default new HTMLUtils();
