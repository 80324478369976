import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import cloud from "./VJYCloudClient";
import monitor from "@rt/Monitor";
import monitorNew from "@rt/monitoring/Monitor";
import {
  typeNameToId,
  getTypeMeta,
  getTypeString,
  parseMetaLine,
  parseLine,
} from "./helpers";
import globals from "@rt/globals";

const processed = Symbol("is processed");
const ownProperties = Symbol("own properties");
const inheritedProperties = Symbol("inherited properties");
const ownConstruct = Symbol("own construct");

const { typeIdsByName } = globals;
const typeNamesById = {};
for (let key in typeIdsByName) {
  typeNamesById[typeIdsByName[key]] = key;
}

class TypeManager {
  constructor() {
    this.defs = {};
    this.defsByDisplayName = {};
    this.typesById = {};
    // store the blob object URLs of cloud JSClasses
    this.classUrlsByType = {};
    this.classes = {};
    this.customEditors = {};
    this.promises = {}; // find queries in progress
    this.addTypeDef({
      name: "Object",
      category: 2,
    });
  }

  jsonToTs = async (json, name) => {
    let props;
    if (json) props = JSON.parse(json);
    name = await this.getTypeDisplayNameAsync(name);
    let tsString = `type ${name} = { \n`;
    for (let key in props) {
      let { type } = cloneDeep(props[key]);
      const { args } = type;
      // console.log("props key", key, props);
      type.type = await this.getTypeDisplayNameAsync(type.type, args);
      const metaString = `${getTypeMeta(type, props[key])}`;
      tsString += metaString;

      let otherInfoString = "";
      for (let key2 in props[key]) {
        if (key2 === "type") continue;
        let val = props[key][key2];
        if (typeof val === "string") {
          val = '"' + val + '"';
        }
        otherInfoString += `  * @${key2} ${val}\n`;
      }
      if (metaString.length && otherInfoString.length) {
        otherInfoString += "  */\n";
      } else if (otherInfoString.length) {
        otherInfoString = "  /**\n" + otherInfoString + "*/\n";
      }
      tsString += otherInfoString;

      tsString += `  ${key} : ${type.type} ;\n`;
    }
    tsString += "\n}";

    return tsString;
  };
  updateTypeDefDisplayName(name, displayName) {
    let td = this.getTypeDef(name);
    td.displayName = displayName;
    let doc = cloud.getDoc(name);
    doc.m.n = displayName;
  }
  getTypeDisplayNameAsync = async (type, args) => {
    if (typeNamesById[type]) {
      // console.log("TD for type is present", type, typeNamesById[type], args);
      if (!args || !args.length) {
        // console.log("td no args", typeNamesById[type]);
        return typeNamesById[type];
      }
      const genericTypeName = typeNamesById[type];
      // console.log("generic type", genericTypeName);
      const argsName = await this.getTypeDisplayNameAsync(
        args[0].type,
        args[0]?.type?.args
      );
      // console.log("type args", type, args, argsName);
      if (genericTypeName === "Array") {
        // console.log("array type", `${argsName}[]`);
        return `${argsName}[]`;
      }
      // console.log("pattern", `${genericTypeName}<${argsName}>`);
      return `${genericTypeName}<${argsName}>`;
    }

    if (type.match("<") && type.match(">")) {
      const genericType = type.replace(/<.*>/, "");
      const argType = type
        .replace(genericType, "")
        .replace("<", "")
        .replace(">", "");
      const genericDisplayName = await this.getTypeDisplayNameAsync(
        genericType
      );
      const argDisplayName = await this.getTypeDisplayNameAsync(argType);
      return `${genericDisplayName}<${argDisplayName}>`;
    }
    if (type.match("[]")) {
      const argType = type.replace("[]", "");
      const argDisplayName = await this.getTypeDisplayNameAsync(argType);
      return `${argDisplayName}[]`;
    }

    let td = this.getTypeDef(type);
    if (!td) {
      td = await this.findTypeDef(type);
    }
    // console.log("TD for type", type, td?.displayName, td);
    let doc = cloud.getDoc(type);
    if (!doc) {
      const res = await cloud.find(type);
      doc = res.docs[0];
    }

    if (!doc) {
      console.log("no deo for tye", type);
    }

    const userName = await cloud.userIdToName(doc?.m?.owner);
    if (userName === "root") return td?.displayName;
    const name = userName + "::" + td?.displayName;

    // console.log(doc.m.owner + "::" + td?.displayName, doc.m, name, userDoc);
    return name;
  };
  getTypeIdByNameAsync = async (name) => {
    if (name === "Dynamic" || name === "Object") return name;
    let td;

    for (let def of Object.values(this.defs)) {
      if (def?.displayName === name) {
        // console.log("got td by name async, already present ", name, def);
        return def.name;
      }
    }
    let owner = null;
    // username is present
    if (name.match("::")) {
      const split = name.split("::");
      const userName = split[0];
      const typeName = split[1];
      name = typeName;

      owner = await cloud.userNameToId(userName);
    }
    // array
    if (name.match("[]")) {
      name = name.replace("[]", "");
      name = await this.getTypeIdByNameAsync(name);

      return `${name}[]`;
    }
    if (name.match("<") && name.match(">")) {
      let genericType = name.replace(/<.*>/, "");
      let typeName = name
        .replace(genericType, "")
        .replace("<", "")
        .replace(">", "");

      genericType = this.typeNameToId(genericType);
      typeName = await this.getTypeIdByNameAsync(typeName);
      return `${genericType}<${typeName}>`;
    }

    const res = await cloud.find(
      {
        n: { $regex: name, $options: "i" },
        t: this.typeNameToId("TypeDefinition"),
        owner,
      },
      undefined,
      { cacheTypeDefs: true }
    );

    for (let def of Object.values(this.defs)) {
      if (def?.displayName === name) {
        return def.name;
      }
    }
    return name;
    alert(`Type ${name} does not exist`);
    throw new Error(`Type ${name} does not exist`);
  };
  tsToJson = async (ts, name) => {
    const jsonObj = {};

    //   reove whitespace lines
    const lines = ts
      .split("\n")
      .filter((l) => l !== "\\n" && l.trim().length > 0);

    let currentMeta;
    let metaToAdd;

    // label, desc, group etc
    let typePropsToAdd;

    name = await this.getTypeDisplayNameAsync(name);

    const firstLineRegExp = new RegExp(`type ${name} = {`);

    if (!lines[0].match(firstLineRegExp))
      throw new Error("First line of TS is not a type defintiion");
    if (!lines[lines.length - 1].match(/}/))
      throw new Error("Missing closing bracket in TS typedef");

    for (let i in lines) {
      const line = lines[i];

      // use == as 'for in' values are strings, so we need to cast them to numbers in order for the comparison to work
      if (i == 0 || i == lines.length - 1) {
        continue;
      }
      const data = parseLine(line, currentMeta);

      if (!data) continue;

      if (data.beginMeta) {
        currentMeta = {};

        continue;
      } else if (data.endMeta) {
        metaToAdd = currentMeta;

        currentMeta = null;
        continue;
      } else if (data.prop) {
        typePropsToAdd = typePropsToAdd || {};
        typePropsToAdd[data.prop] = data.value;

        continue;
      } else if (currentMeta) {
        continue;
      }

      //query for  single doc { n  : { $regex: name , $options: "i" } }

      let typeName = data.propType;

      typeName = await this.getTypeIdByNameAsync(typeName);

      jsonObj[data.propName] = {
        type: {
          type: typeName,
        },
      };
      if (typePropsToAdd) {
        for (let key in typePropsToAdd) {
          jsonObj[data.propName][key] = typePropsToAdd[key];
        }
      }
      if (data.args) {
        // console.log("ARGS", data, data.args, jsonObj[data.propName]);
        const args = cloneDeep(data.args);
        for (let arg of args) {
          arg.type = await this.getTypeIdByNameAsync(arg.type);
        }
        jsonObj[data.propName].type.args = args;
      }

      if (metaToAdd) {
        for (let key in metaToAdd) {
          if (metaToAdd[key] && typeof metaToAdd[key] === "string") {
            metaToAdd[key] = metaToAdd[key].trim();
            // usually this is level or subclass
            if (!Number.isNaN(parseInt(metaToAdd[key])))
              metaToAdd[key] = parseInt(metaToAdd[key]);
          }
          jsonObj[data.propName].type[key] = metaToAdd[key];
        }

        metaToAdd = null;
        typePropsToAdd = null;
      }
    }

    return JSON.stringify(jsonObj, null, 2);
  };

  typeIdToName(id) {
    return typeNamesById[id];
  }
  typeNameToId(name) {
    if (!typeIdsByName[name]) {
      // @td-refactor - remove menu item with 'Path' type as this type doesn;t exist (user curve.path instead)
      if (name === "Path") return null;
      console.log("typeNameToId: name not found: " + name, typeIdsByName);
    }
    return typeIdsByName[name];
  }

  getTypeDisplayName(name) {
    if (typeNamesById[name]) return typeNamesById[name];
    if (!name) return name;
    if (!this.getTypeDef(name)) {
      console.log("TM get display name TD not found", name);
      return name;
    }
    const def = this.getTypeDef(name);
    if (typeNamesById[name]) return this.getTypeDef(name)?.displayName;
    const doc = cloud.getDoc(def.name);
    const { owner } = cloud.userIdToName(doc.m.owner);
    if (!owner) console.log("no owner ", def, def.name);
    return owner + "::" + def.displayName;
  }

  getTypeDef(name) {
    //If generic like Sequence<Color> we give back Sequence
    const decl = this.stringToDecl(name);
    return this._processTypeDef(decl?.type);
  }

  getClass(name) {
    const decl = this.stringToDecl(name);
    return this.classes[decl.type];
  }
  getCustomEditor(name) {
    const decl = this.stringToDecl(name);

    return this.customEditors[decl.type];
  }
  getId(name) {
    for (const id of Object.keys(this.typesById)) {
      if (this.typesById[id] === name) return id;
    }
  }

  getParentTypeDef(name) {
    try {
      name = this.stringToDecl(name).type;
      return this.defs[this.typesById[this.defs[name].parent[">link"].id]];
    } catch (err) {}
  }

  /**
   * Find (and cache) a TypeDefinition.
   * @param {string} name
   * @param {boolean} [cacheDeps] - Cache the TypeDefinition's dependencies
   * too. The dependencies of a TypeDefinition are its ClassDeclaration and
   * the TypeDefinitions (and dependencies) of its ancestors and its properties.
   * @returns {Promise<?TypeDefinition, ApiError>}
   */
  async findTypeDef(name, cacheDeps = true, trackId) {
    trackId = monitor.startMethod(trackId, "typeMan.findTypeDef", { name });
    monitorNew.log("TypeManager", "findTypeDef", name);
    // console.log("TypeManager", "findTypeDef", name);
    if (name === "Dynamic") return;
    const decl = this.stringToDecl(name);
    name = decl.type;
    await this._findByName(name);
    if (cacheDeps) {
      await this._cacheDeps(this.defs[name], [], trackId);
      monitor.endMethod(trackId);
      return this.getTypeDef(name);
    } else {
      monitor.endMethod(trackId);
      // return unprocessed typeDef, can't process if deps are not cached
      return this.defs[name];
    }
  }

  /**
   * This function processes a type definition by adding parent properties and fixing the construct.
   * @param name - The name of a type definition to be processed.
   * @returns the processed definition object for the given name.
   */
  _processTypeDef(name) {
    const def = this.defs[name];
    if (def && !def[processed]) {
      def[processed] = true;
      if (def.category > 0) {
        // first process parent (recursively)
        let parentProps, inheritedProps, parentConstruct;

        if (def.parent) {
          const parentDef = this._processTypeDef(
            this.typesById[def.parent[">link"].id]
          );
          parentProps = parentDef.properties;
          inheritedProps = [
            ...(parentDef[inheritedProperties] || []),
            {
              type: parentDef.name,
              properties: parentDef[ownProperties] || {},
            },
          ];
          parentConstruct = parentDef.construct;
        }

        // add parent properties
        def[ownProperties] = def.properties;
        def[inheritedProperties] = inheritedProps || [];
        def.properties = {
          ...(def.properties || {}),
          ...(parentProps || {}),
        };

        // fix construct
        def[ownConstruct] = def.construct;
        if (!def.construct) {
          def.construct = parentConstruct;
        }
      }
    }
    return def;
  }

  /**
   * This function adds a type definition to a TypeManager object and registers a JavaScript class if
   * specified.
   * @param typeDef - an object representing the definition of a data type, including its name,
   * properties, and methods
   * @param jsClass - The jsClass parameter is an optional parameter that represents the JavaScript class
   * associated with the type definition being added. If provided, it will be used to register the class
   * with the TypeManager. If not provided, the jsClass property of the typeDef object will be used
   * instead.
   * @param id - The `id` parameter is an optional identifier that can be used to associate a type
   * definition with a specific ID. This can be useful for quickly retrieving a type definition by its ID
   * later on.
   */
  async addTypeDef(typeDef, jsClass, id) {
    typeDef = cloneDeep(typeDef, jsClass, id);
    // console.log("add td", typeDef);
    monitorNew.log("TypeManager", "addTypeDef", typeDef.name);
    // console.log("TypeManager", "addTypeDef", typeDef);
    this.defs[typeDef.name] = typeDef;

    if (id) this.typesById[id] = typeDef.name;
    jsClass = jsClass || typeDef.jsClass;
    if (jsClass) typeDef.classType = 1;
    switch (typeDef.classType) {
      case 0:
        break;
      case 1:
        await this.registerClass(typeDef.name, jsClass, typeDef);
        break;
      case 2:
        //console.log("CLASS DECL!",typeDef);
        this.classes[typeDef.name] = cloud.getDoc(typeDef.classDecl);
        break;
      default:
        break;
    }
  }

  registerCustomEditor(typeName, jsClass) {
    if (typeof jsClass === "function") {
      this.customEditors[typeName] = jsClass;
      this.customEditors[typeName].type = typeName;
    }
  }

  async registerClass(typeName, jsClass, typeDef) {
    if (this.classes[typeName]) {
      // only stop the registration process if the class is a proper class
      // sometimes it's a cloud doc, why?
      const currentIsFunc = typeof this.classes[typeName] === "function";
      const newIsFunc =
        typeof jsClass === "function" || typeof jsClass === "string";

      if (currentIsFunc || !newIsFunc) return;
      if (newIsFunc) this.classes[typeName] = null;
    }

    if (typeof jsClass === "function") {
      this.classes[typeName] = jsClass;
      this.classes[typeName].type = typeName;

      return;
    }
    if (typeof jsClass !== "string") {
      return;
    }
    // if (typeName)
    //   console.log(
    //     "TM register class",
    //     typeName,
    //     jsClass,
    //     typeDef?.displayName
    //     // this.getTypeDisplayName(typeName)
    //   );

    let code = jsClass
      .split("\n")
      .filter((line) => !line.endsWith("// typemanager-ignore"))
      .join("\n");

    if (this.classes[typeName]) {
      return;
    }

    // TEMP - in standalone mode, ignore none JS assets
    if (!code.match(/class/i)) return;

    // TODO: find better way of injecting dependencies
    const VisComp = this.getClass(this.typeNameToId("VisComp"));
    window.VisComp = VisComp;

    const Geometry = this.getClass(this.typeNameToId("Geometry"));
    window.Geometry = Geometry;

    const extendMatch = code.match(/class (.*) extends/);
    // Make sure we're exporting the class
    if (!code.match("export default") && extendMatch) {
      let className = extendMatch[0].replace(/(class|extends|\s)/g, "");

      code += `
						export default ${className}
					`;
    }

    // Convert code to Blob and get its URL
    var blob = new Blob([code], { type: "text/javascript" });
    const blobSrc = URL.createObjectURL(blob);

    // Import Blob as module
    try {
      this.classes[typeName] = await import(/* webpackIgnore: true */ blobSrc);
      this.classes[typeName] = this.classes[typeName].default;
    } catch (err) {
      this.classes[typeName] = {};

      throw err;
    }
    this.classUrlsByType[typeName] = blobSrc;
  }
  updateOwnProperties(name, updatedProps) {
    const def = this.getTypeDef(name);

    const props = def[ownProperties];
    if (!props) return console.log("no own properties to update");
    const newProps = {};
    for (let parentDef of def[inheritedProperties]) {
      for (let key in parentDef.properties) {
        newProps[key] = parentDef.properties[key];
        // console.log( 'inherited prop key', key )
      }
    }
    for (let key in updatedProps) {
      newProps[key] = updatedProps[key];
    }
    // console.log( 'old props', def.properties)
    def.properties = cloneDeep(newProps);
    // console.log( 'new props', def.properties)

    // console.log( 'old own props', def[ownProperties])
    def[ownProperties] = cloneDeep(updatedProps);
    // console.log( 'new own props', def[ownProperties])
  }

  getOwnProperties(def) {
    return def && def[ownProperties];
  }

  getInheritedProperties(def) {
    return def && def[inheritedProperties];
  }

  async createDefaultData(type, path = []) {
    const def = await this.findTypeDef(type);

    // get parent default data and merge it
    if (def && def.parent) {
      const parentDoc = cloud.getDoc(def.parent);
      let parentDefault = await this.createDefaultData(parentDoc.d.name);

      if (parentDefault) {
        def.defaultValue = cloneDeep(def.defaultValue);
        parentDefault = cloneDeep(parentDefault);
        // override any parent default valus with child's
        def.defaultValue = Object.assign(parentDefault, def.defaultValue);
      }
    }
    if (!def) return;
    if (typeof def.defaultValue !== "undefined") {
      return cloneDeep(def.defaultValue);
    } else if (
      def.category === 1 ||
      (def.category === 2 && path.length === 0)
    ) {
      const props = Object.keys(def.properties || {});
      if (def.category === 1 && props.length === 0) {
        // typedef has no properties, it may be a custom basic type
        // so do not return an object
        return;
      }
      const promises = props.map((p) => {
        const { type: ptype } = def.properties[p].type;
        if (path.indexOf(ptype) < 0)
          return this.createDefaultData(ptype, [...path, type]);
        else return Promise.resolve(); // prevent infinite recursion
      });
      const values = await Promise.all(promises);
      const data = {};
      for (let i = 0; i < props.length; i++) {
        data[props[i]] = values[i];
      }
      return data;
    }
  }

  /**
   * Get `typeDecl` as a type name string.
   * @param {TypeDeclaration} typeDecl
   * @returns {string}
   */
  async declToString(typeDecl) {
    const def = await this.findTypeDef(typeDecl.type);
    if (def.isGeneric) {
      const promises = typeDecl.args.map((decl) => this.declToString(decl));
      const args = await Promise.all(promises);
      if (typeDecl.type === this.typeNameToId("Array")) return args[0] + "[]";
      else return typeDecl.type + "<" + args.join(", ") + ">";
    } else {
      return typeDecl.type;
    }
  }

  /**
   * Get type name string as a TypeDeclaration.
   * @param {string} type
   * @returns {TypeDeclaration}
   */
  stringToDecl(type) {
    if (type.endsWith("[]")) {
      return {
        type: this.typeNameToId("Array"),
        args: [this.stringToDecl(type.substr(0, type.length - 2))],
      };
    }
    const ltPos = type.indexOf("<");
    if (ltPos < 0) {
      return { type: type };
    }

    const gtPos = type.lastIndexOf(">");
    const strArgs = type.substring(ltPos + 1, gtPos).split(",");
    const args = strArgs.map((arg) => this.stringToDecl(arg.trim()));
    return {
      type: type.substr(0, ltPos),
      args: args,
    };
  }

  /**
   * Load and cache the dependencies of `def`:
   * - its ClassDeclaration
   * - the TypeDefinitions of its ancestors and properties (recursive)
   * @param {TypeDefinition} def
   * @throws {ApiError}
   */
  async _cacheDeps(def, traversedTypes = [], trackId) {
    if (!def) return;

    const { name, parent, properties, classType, classDecl } = def;

    // prevent infinite recursion
    if (traversedTypes.indexOf(def.name) >= 0) return;
    traversedTypes.push(def.name);

    trackId = monitor.startMethod(trackId, "typeMan._cacheDeps", { name }, def);
    // typeDefs of properties
    if (properties) {
      monitor.log(trackId, "START> Check Properties");
      try {
        for (const key of Object.keys(properties)) {
          const prop = properties[key];
          // console.log("key / props", key, prop);
          const propTypeDef = await this.findTypeDef(
            prop.type.type,
            false,
            trackId
          );
          // console.log("prop TD", propTypeDef);
          await this._cacheDeps(propTypeDef, traversedTypes, trackId);
        }
      } catch (err) {
        console.error("TypeManager._cacheDeps error: ", err, def);
        console.error("TypeManager._cacheDeps error details: def >", def);
      }

      monitor.log(trackId, "END< Check Properties");
    }

    // typeDefs of ancestor types
    if (parent) {
      monitor.log(trackId, "START> Check Parent");
      const { id } = parent[">link"];
      if (id in this.typesById) {
        def = this.defs[this.typesById[id]];
      } else {
        def = await this._findById(id);
      }
      await this._cacheDeps(def, traversedTypes, trackId);
      monitor.log(trackId, "END< Check Parent");
    }

    // ClassDeclaration (classType: Cloud)
    if (classType === 2 && classDecl) {
      const { id } = classDecl[">link"];
      const res = await cloud.find({ id });
      const { t, d } = res.docs[0] || {};
      if (t === this.typeNameToId("JsClass")) {
        await this.registerClass(name, d.code, def);
      }
    }

    monitor.endMethod(trackId);
  }

  async _findById(id) {
    if (!id) return;

    if (!(id in this.typesById)) {
      // check if the same request is already in progress
      const pid = "id:" + id;
      if (!this.promises[pid]) {
        this.promises[pid] = (async () => {
          const res = await cloud.find({ id });
          if (res.docs.length > 0) {
            const [doc] = res.docs;
            this.addTypeDef(doc.d, null, doc._id);
          }
          delete this.promises[pid];
        })();
      }
      await this.promises[pid];
    }

    return this.typesById[id] && this.defs[this.typesById[id]];
  }

  async _findByName(name) {
    if (!name) return;

    // console.log("TM find by name", name);

    if (!(name in this.defs)) {
      // check if the same request is already in progress
      const pid = "name:" + name;
      if (!this.promises[pid]) {
        this.promises[pid] = (async () => {
          const res = await cloud.find(
            { id: name },
            {
              include: {
                m: cloud._settings.includeMeta,
              },
            }
          );
          // console.log("TM found docs for name", res);
          for (const doc of res.docs) this.addTypeDef(doc.d, null, doc._id);
          if (!this.defs[name]) this.defs[name] = null;
          delete this.promises[pid];
        })();
      }
      await this.promises[pid];
    }

    return this.defs[name];
  }

  /**************************************
		Type Conversion HELPERS
	****************************************/
  isCompatible(typeA, typeB) {
    if (typeA === typeB) return true;
    const defA = this.defs[typeA];
    const defB = this.defs[typeB];
    if (!defA || !defB) return false;

    if (defB.compatible) {
      const declA = this.stringToDecl(typeA);
      for (const decl of defB.compatible) {
        if (this.declEquals(decl, declA)) return true;
      }
    }

    let { parent } = defA;
    while (parent) {
      const parentDef = this.defs[this.typesById[parent[">link"].id]];
      if (parentDef && parentDef.name === typeB) return true;
      parent = parentDef && parentDef.parent;
    }

    return false;
  }

  isPatternOrSequence(type) {
    return (
      type === this.typeNameToId("Pattern") ||
      type === this.typeNameToId("Sequence") ||
      type.match(`${this.typeNameToId("Pattern")}<`) ||
      type.match(`${this.typeNameToId("Sequence")}<`)
    );
  }

  declEquals(declA, declB) {
    const argsA = declA.args || [];
    const argsB = declB.args || [];
    return declA.type === declB.type && isEqual(argsA, argsB);
  }

  seqToArray(seq) {
    if (Array.isArray(seq)) return seq;
    if (seq.elems) return seq.elems;
    return [];
  }

  safeColor(col) {
    let ret;
    if (Array.isArray(col)) {
      ret = [];
      for (let i = 0; i < col.length; i++) ret.push(this.safeColor(col[i]));
      return ret;
    }
    if (typeof col === "string") return parseInt(col.substr(1, 6), 16);
    return col;
  }
}

// export as a singleton
let tm = new TypeManager();
window.tm = tm;

// function interceptMethodCalls(obj, fn) {
//   return new Proxy(obj, {
//     get(target, prop) {
//       if (typeof target[prop] === "function") {
//         return new Proxy(target[prop], {
//           apply: (target, thisArg, argumentsList) => {
//             fn(prop, argumentsList);
//             return Reflect.apply(target, thisArg, argumentsList);
//           },
//         });
//       } else {
//         return Reflect.get(target, prop);
//       }
//     },
//   });
// }
// const handleMethodCall = (fnName, fnArgs) =>
//   console.log(`${fnName} called with `, fnArgs);

// const interceptedObj = interceptMethodCalls(tm, handleMethodCall);
// export default interceptedObj;
export default tm;

/**
 * @typedef {Object} TypeDefinition
 * @property {string} name
 * @property {number} category
 * @property {VJYDocLink} [parent]
 * @property {boolean} [isGeneric]
 * @property {boolean} [areGenericArgsCompatible]
 * @property {boolean} [isEnum]
 * @property {Array<string>} [enumValues]
 * @property {*} [defaultValue]
 * @property {Array<TypeDeclaration>} [compatible]
 * @property {string} [construct]
 * @property {number} [classType]
 * @property {VJYDocLink} [classDecl]
 * @property {string} [csClass]
 * @property {TypeDefinitionProperties} [properties]
 */

/**
 * @typedef {Object} TypeDefinitionProperties
 * @property {PropertyDeclaration} *
 */

/**
 * @typedef {Object} PropertyDeclaration
 * @property {TypeDeclaration} type
 */

/**
 * @typedef {Object} TypeDeclaration
 * @property {string} type - The name of the type.
 * @property {Array<TypeDeclaration>} [args] - TypeDeclarations of the type's generic arguments.
 * @property {object} [meta] - Metadata.
 */

// {
//   "FOO": {
//     "type": {
//       "type": "594d48f2599a9f1000757bde"
//     },
//     "label": "derp",
//     "description" :"fasz",
//     "group": "derp",
//     "order":3
//   },
//   "FOO2": {
//     "type": {
//       "type": "594d48f2599a9f1000757bde"
//     }
//   }
// }
