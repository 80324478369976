import merge from "lodash/merge";
import cloud from "./VJYCloudClient";
import typeMan from "./TypeManager";
import Sequence from "@data-trans/pattern/Pattern";
import cloneDeep from "lodash/cloneDeep";
import factoryMat from "@three-extra/asset/MaterialManager";
import factoryGeom from "@three-extra/asset/GeometryManager";
import { Vector2, Vector3, Vector4, Quaternion, Color } from "three";
const { typeNameToId, typeIdToName } = typeMan;

// const factoryMat = {};
// const factoryGeom = {};

const solved = Symbol("link resolved");

const alreadyInstantiatedTypes = [
  "string",
  "int",
  "float",
  "Vector2",
  "Vector3",
  "Vector4",
  "Quaternion",
  "Color",
].map(typeNameToId);

class ObjectManager {
  constructor() {}

  /*
	// USED BY  Component.set( )
	linkToObj(link){
		if(link.level==1){
			let doc = cloud.getDoc(link);
			if (doc) return this.docToObj(doc);
			else return link;
		}else{
			let ret={}
			merge(ret,link);
			ret.level--;
			return ret;
		}
	}
	

	docToObj(doc) {
		console.log("docToObj",doc);
		let ret;
		ret._id = doc._id;
		ret['>link'] = {by:"id",type: doc.t, t: doc.t, id: doc._id, solved: true};
		return ret;
	}
	*/

  /// DESERIALIZATION ///////////////////////////////////////////////////////////////////////////
  /*
		typeDecl       val 

		dynamic
		t:Dynamic    : anything

		non-gen
		t:{cat:2}    : >link
		t:{cat:1}    : "#color", { }
		
		generic
		t:{Array}    : [ ]
		t:{Pattern}  : >link
		t:{Sequence} : >link
	*/

  /**
   * This function deserializes a value based on its type declaration and returns the deserialized value.
   * @param val - The value to be deserialized.
   * @param typeDecl - typeDecl is an object that describes the type of the value being deserialized. It
   * contains two properties: "type" which specifies the type of the value (e.g. "String", "Number",
   * "Array", etc.), and "level" which specifies the level of nesting for the value (
   * @param [path] - The path parameter is a string that represents the current path or location of the
   * value being deserialized within the overall object structure. It is used for debugging and error
   * reporting purposes.
   * @returns The function `deserialize` returns the deserialized value of the input `val` based on the
   * provided `typeDecl`. If `val` is falsy, it returns `val` as is. If `typeDecl` is not provided or its
   * `type` property is "Dynamic", it deserializes `val` as an object or array based on its type and
   * returns the deserialized value
   */
  deserialize(val, typeDecl, path = "") {
    if (!val) return val;

    // No typeDecl ... eg. Dynamic - Old Logic
    if (!typeDecl || typeDecl.type === "Dynamic") {
      //console.log("Deser No T",val,typeDecl);
      // None Object > return
      if (!val || typeof val !== "object") return val;
      // Higher Class but not Array
      if (val.constructor !== Object && !Array.isArray(val)) return val;
      // {>obj} {>link}
      if (!Array.isArray(val)) {
        if (val[">link"]) {
          if (!val[">link"][solved]) return this.solveLink(val, typeDecl);
          return val;
        }
      }
      //Typeless container: Object or Array
      let keys, ret;
      if (Array.isArray(val)) {
        ret = [];
        keys = val.keys();
      } else {
        ret = {};
        keys = Object.keys(val);
      }

      for (const i of keys) {
        ret[i] = this.deserialize(val[i], null, path + "." + i);
      }
      return ret;
    }

    let typeDef = typeMan.getTypeDef(typeDecl.type);

    if (!typeDef) typeDef = { category: 1 };
    switch (typeDef.category) {
      // 0 primitive ////////////////////////
      case 0:
        return val;

      // 1 basic ///////////////////////////
      case 1:
        //Go and solve Array elements
        if (typeDecl.type == typeNameToId("Array") && typeDecl.level == 1) {
          let ret = [];
          for (var i = 0; i < val.length; i++) ret.push(this.solveLink(val[i]));
          return ret;
        } else {
          const factory = typeMan.getClass(typeDecl.type);
          return factory ? factory(val) : val;
        }

      // 2 custom ////////////////////////
      case 2:
        const link = val[">link"];
        if (link.solved) {
          return val;
        }
        // If Type is generic
        if (typeDef.isGeneric) {
          //If linked doc is not generic > convert to generic

          const valDoc = cloud.getDoc(val);
          if (!valDoc) {
            console.warn("val", val, typeDecl, path);
            throw new Error(`Object Manager > cannot find doc for value`);
          }
          const def = typeMan.getTypeDef(valDoc.t);

          if (def === undefined || !def.isGeneric) {
            switch (typeDecl.type) {
              case typeNameToId("Pattern"):
              case typeNameToId("Sequence"):
                let ret = new Sequence({
                  elems: [
                    this.solveLink(val, {
                      level: typeDecl.level,
                      type: def ? def.type : typeDecl.type,
                    }),
                  ],
                });
                ret._id = ret.elems[0]._id;
                ret[">link"] = ret.elems[0][">link"];
                return ret;
              case typeNameToId("Array"):
                return [
                  this.solveLink(val, {
                    level: typeDecl.level,
                    type: def.type,
                  }),
                ];
              default:
                break;
            }
          } else {
            return this.solveLink(val, typeDecl);
          }
        } else {
          return this.solveLink(val, typeDecl);
        }
        break;
    }
  }
  instantiatePattern(pattern) {}

  solveLink(obj, typeDecl) {
    //let force=false;
    //if (typeof obj['>link'].level !== 'number') obj['>link'].level = 1;

    //let { level = 1 } = typeDecl || {};

    let level = 1;
    if (typeDecl && typeDecl.level) level = typeDecl.level;

    if (level !== 1) return merge({}, obj);
    //if (!typeDecl && obj['>link'].level) level = obj['>link'].level;

    //console.log("SOLVE LINK > deserialize");
    let doc = cloud.getDoc(obj);
    if (doc == null) {
      //console.warn("SOLVE LINK > NO DOC on client for",obj);
      return null;
    }
    let def = typeMan.getTypeDef(doc.t);

    if (doc) {
      let ret;
      // Do not instantiate - pure data
      if (!def || !def.construct || def.construct === "factory") {
        ret = cloneDeep(doc.d);
      } else {
        const d = {};
        const keys = Object.keys(def.properties || {});

        if (keys.length === 0) {
          ret = this.instantiateObj({ d: doc.d, t: doc.t });
        }
        //console.log("KEYS",keys);
        for (const prop of keys) {
          if (doc.d[prop]) {
            // console.log(prop);
            d[prop] = this.deserialize(doc.d[prop], def.properties[prop].type);
          }
        }

        ret = this.instantiateObj({ d, t: doc.t });
      }

      // Add identity
      if (ret === undefined) ret = {};
      ret._id = doc._id;
      ret[">link"] = {
        by: "id",
        type: doc.t,
        t: doc.t,
        id: doc._id,
        [solved]: true,
      };
      return ret;
    }

    return merge({}, obj);
  }

  //
  instantiateObj(decl, doProps = true) {
    if (decl.t === "Object") return decl.d;
    const classFn = typeMan.getClass(decl.t);
    const def = typeMan.getTypeDef(decl.t);

    //console.log(typeMan.classes);
    if (classFn) {
      let { construct } = def;
      if (classFn.isFactory) construct = "factory";
      let instance;
      //console.log("Inst",construct);
      switch (construct) {
        case "factory":
          return decl.d;
        case "constructor(...args)":
          return new classFn(...decl.d.args);
        case "constructor(props)":
          return new classFn(decl.d);
        case "set(name,val)":
          instance = new classFn();
          if (doProps) for (const i in decl.d) instance.set(i, decl.d[i]);
          return instance;
        case "[name]=val":
          instance = new classFn();
          if (doProps) for (const i in decl.d) instance[i] = decl.d[i];
          return instance;
        case "3_[name]=val":
          //console.log("Instantioate THREE");
          const Vector3 = typeMan.getClass(typeNameToId("Vector3"));
          instance = new classFn();
          if (doProps)
            for (const i in decl.d) {
              switch (i) {
                case "points":
                  let points = decl.d[i];
                  let points3 = [];
                  for (let ii = 0; ii < points.length; ii++) {
                    let v3 = Vector3({ x: 0, y: 0, z: 0 });
                    v3.copy(points[ii]);
                    points3.push(v3);
                  }
                  instance[i] = points3;
                  break;
                case "v0":
                case "v1":
                case "v2":
                case "v3":
                  instance[i] = Vector3({ x: 0, y: 0, z: 0 });
                  instance[i].copy(decl.d[i]);
                  break;
                default:
                  instance[i] = decl.d[i];
              }
            }
          return instance;
        case "props":
          instance = new classFn();
          if (doProps) instance.props = decl.d;
          return instance;
        case "params":
          instance = new classFn();
          instance.params = decl.d;
          return instance;
        default:
          break;
      }
    } else {
      return decl.d;
    }
  }

  instantiateComp(_decl, globals = {}, setInputs = true) {
    //console.log("instantiateComp 1",_decl);
    let decl;
    if (_decl[">link"]) decl = cloud.getDoc(_decl);
    if (_decl[">obj"]) decl = _decl[">obj"];
    if (!decl) decl = _decl;

    const def = typeMan.getTypeDef(decl.t);
    // console.log("InstantiateComp", typeMan.getTypeDisplayName(decl.t));
    // console.log("  Decl:", decl);
    // console.log("  TypeDef:", def);
    let obj;
    if (def) {
      //console.log("instantiateComp classType:", def.classType);
      switch (def.classType) {
        case 1: // Code
        case 2: // Cloud
          const classFn = typeMan.getClass(decl.t);

          try {
            obj = new classFn();
          } catch (err) {
            console.log(
              `Object Manager >>> ${decl.t} is not a constructor`,
              decl
            );

            throw err;
          }

          if (obj.isContainer) {
            if (decl.d.isVisual) obj.isVisual = decl.d.isVisual;
            if (decl.d.components) obj.components = decl.d.components;
          }
          if (obj.isVisual) {
            if (globals.cont3D) {
              obj.container = globals.cont3D;
              obj.cont3D = globals.cont3D;
            }
            if (globals.container) {
              obj.container = globals.container;
              obj.cont3D = globals.container;
            }
            if (globals.scene) obj.scene = globals.scene;
            if (globals.renderer) obj.renderer = globals.renderer;
            if (globals.me) obj.me = globals.me;
          }
          break;
        default:
          break;
      }
      obj[">link"] = { by: "id", type: decl.t, id: decl._id };

      obj[">link"].t = decl.t; // FIXME remove this!
      obj.inputs.typeDef = def;
      if (setInputs) obj.inputs.setObj(decl.d);
      return obj;
    }
  }

  /**
   * Used in Inputs.getObject - get instantiated values for any type
   * @param {string} type -
   * @param {*} obj
   * @param {*} param2
   * @returns
   */
  instantiateObjNew(type, obj, { baseMat, defaultMat, defGeom }, typeObj) {
    if (type === typeNameToId("Sequence<Color>")) {
      // console.log("SEQQQ", ...arguments);
      return obj.elems.map((s) => new Color(s));
    }
    if (alreadyInstantiatedTypes.includes(type)) {
      const inputType = type;
      const el = obj;

      if (inputType === typeNameToId("Vector2")) {
        return new Vector2(el.x, el.y);
      }
      if (inputType === typeNameToId("Vector3")) {
        return new Vector3(el.x, el.y, el.z);
      }
      if (inputType === typeNameToId("Vector4")) {
        return new Vector4(el.x, el.y, el.z, el.w);
      }
      if (inputType === typeNameToId("Quaternion")) {
        return new Quaternion(el.x, el.y, el.z, el.w);
      }
      if (inputType === typeNameToId("Color")) {
        return new Color(el);
      }

      return obj;
    }

    if (typeMan.isCompatible(type, typeNameToId("Geometry"))) {
      return factoryGeom.build(obj, { geom: defGeom });
    }
    if (typeMan.isCompatible(type, typeNameToId("Texture2D"))) {
      return factoryMat.assetToTexture(obj);
    }
    if (typeMan.isCompatible(type, typeNameToId("CanvasTexture"))) {
      return factoryMat.assetToTexture(obj);
    }
    if (typeMan.isCompatible(type, typeNameToId("Material"))) {
      return factoryMat.build({
        base: baseMat,
        def: defaultMat,
        asset: obj,
      });
    }
    if (typeMan.isCompatible(type, typeNameToId("Shader.ProceduralTexture"))) {
      return factoryMat.build({
        base: baseMat,
        def: defaultMat,
        asset: obj,
      });
    }
    if (type === typeNameToId("Model")) {
      const model = cloud._extAssetCache.get(obj);

      return model;
    }
    if (typeMan.isPatternOrSequence(type) || type === typeNameToId("Array")) {
      // console.log("ARRAY LIKE", type, typeIdToName(type));
      const res = [];

      // Type we get from the inputs, could be eg Sequence<Material>
      let inputType = type
        .replace(typeNameToId("Pattern"), "")
        .replace(typeNameToId("Sequence"), "")
        .replace(">", "")
        .replace("<", "");

      if (typeObj?.args && typeObj.args[0]) inputType = typeObj.args[0];

      if (typeObj.args) inputType = typeObj.args[0].type;

      // console.log("INPUT TYPE", inputType, typeIdToName(inputType));

      let count = obj.count;
      if (type === typeNameToId("Array")) count = obj.length;

      // console.log( inputType , typeMan.isCompatible( inputType, 'Material' ))
      for (let i = 0; i < count; i++) {
        let el;
        if (type === typeNameToId("Array")) {
          el = obj[i];
        } else el = obj.getNext();

        const inputTypeDef = typeMan.getTypeDef(inputType);

        if (!inputTypeDef) {
          // console.log("NO ", inputType);
        }
        if (inputType === typeNameToId("Sequence<Color>")) {
          const doc = cloud.getDoc(el);
          const inst = this.instantiateObjNew(inputType, doc.d, {}, {});
          // console.log("inst seq", inst);
          res.push(inst);
        } else if (typeMan.isCompatible(inputType, typeNameToId("Geometry"))) {
          res.push(
            this.instantiateObjNew(typeNameToId("Geometry"), el, { defGeom })
          );
        } else if (inputType === typeNameToId("Model")) {
          const model = cloud._extAssetCache.get(el);
          res.push(model);
        } else if (inputType === typeNameToId("Color")) {
          res.push(new Color(el));
        } else if (inputType === typeNameToId("Texture2D")) {
          res.push(
            this.instantiateObjNew(typeNameToId("Texture2D"), el, {
              baseMat,
              defaultMat,
            })
          );
        } else if (inputType === typeNameToId("CanvasTexture")) {
          res.push(
            this.instantiateObjNew(inputType, el, {
              baseMat,
              defaultMat,
            })
          );
        } else if (typeMan.isCompatible(inputType, typeNameToId("Material"))) {
          // console.log("INST MAT");
          const m = this.instantiateObjNew(inputType, el, {
            baseMat,
            defaultMat,
          });

          res.push(m);
        } else if (
          typeMan.isCompatible(
            inputType,
            typeNameToId("Shader.ProceduralTexture")
          )
        ) {
          const m = this.instantiateObjNew(inputType, el, {
            baseMat,
            defaultMat,
          });

          res.push(m);
        } else if (alreadyInstantiatedTypes.includes(inputType)) {
          if (inputType === typeNameToId("Vector2")) {
            res.push(new Vector2(el.x, el.y));
          } else if (inputType === typeNameToId("Vector3")) {
            res.push(new Vector3(el.x, el.y, el.z));
          } else if (inputType === typeNameToId("Vector4")) {
            res.push(new Vector4(el.x, el.y, el.z, el.w));
          } else if (inputType === typeNameToId("Quaternion")) {
            res.push(new Quaternion(el.x, el.y, el.z, el.w));
          } else {
            res.push(el);
          }

          // ENUM
        } else if (inputTypeDef?.isEnum) {
          res.push(inputTypeDef.enumValues[el]);

          // EMBEDDED
        } else if (inputTypeDef?.category === 1) {
          // console.log("CAT !", ...arguments);
          const td = inputTypeDef;
          const ret = {};

          for (let key in el) {
            const t = td.properties[key]?.type;
            if (!t) continue;
            ret[key] = this.instantiateObjNew(t.type, el[key], {});
          }
          res.push(ret);
        } else res.push(el);
      }

      return res;
    }

    // enum
    const td = typeMan.getTypeDef(type);
    if (td?.isEnum) {
      return td.enumValues[obj];
    }
    // embedded
    if (td?.category === 1) {
      const ret = {};
      for (let key in obj) {
        const t = td?.properties[key]?.type;
        if (!t) continue;
        ret[key] = this.instantiateObjNew(t.type, obj[key], {});
      }
      return ret;
    }

    return obj;
  }

  /// SERIALIZATION ///////////////////////////////////////////////////////////////////////////
  serialize(obj) {
    let ret;
    if (Array.isArray(obj)) ret = [];
    else ret = {};
    const keys = Object.keys(obj);
    //Go through the properties
    for (const prop of keys) {
      //Object
      if (typeof obj[prop] === "object" && obj[prop]) {
        //Array
        if (Array.isArray(obj[prop])) {
          ret[prop] = this.serialize(obj[prop]);
          //Std Object
        } else {
          if (obj[prop][">link"]) {
            ret[prop] = {};
            ret[prop][">link"] = { by: "id", id: obj[prop][">link"].id };
            if (obj[prop][">link"].type)
              ret[prop][">link"].type = obj[prop][">link"].type;
            //if(obj[prop]['>link'].linkId) ret[prop]['>link'].linkId=obj[prop]['>link'].linkId;
            if (obj[prop][">link"].level)
              ret[prop][">link"].level = obj[prop][">link"].level;
            if (obj[prop][">link"].srcLevel)
              ret[prop][">link"].level = obj[prop][">link"].srcLevel;
          } else {
            ret[prop] = this.serialize(obj[prop]);
          }
        }
      } else {
        ret[prop] = obj[prop];
      }
    }
    return ret;
  }

  // Helpers /////////////////////////////////////////////////////////////////////////////////
  cloneLink(link, level) {
    if (!link || typeof link !== "object" || !link[">link"]) {
      return link;
    }
    const lnk = link[">link"];
    if (typeof level !== "number") level = lnk.level;
    const nlnk = { level };
    nlnk.by = lnk.by;
    nlnk.id = lnk.id;
    nlnk.type = lnk.type;
    return { ">link": nlnk };
  }
}

// export as a singleton
const objMan = new ObjectManager();
export default objMan;
