import typeManager from "@cloud/TypeManager";
import MIDIDevice from "./MIDIDevice";
const { typeNameToId } = typeManager;

class VirusTi extends MIDIDevice {
  constructor(params) {
    super(params);
    console.log("Virus Ti", params);
    this.hasPrograms = true;

    //Bank Names /////////////
    this.bankNames = [
      "ROM-A",
      "ROM-B",
      "ROM-C",
      "ROM-D",
      "ROM-E",
      "ROM-F",
      "ROM-G",
    ];
    this.categoryNames = [
      "Acid",
      "Arp",
      "Atom",
      "Bass",
      "Classic",
      "Decay",
      "Digital",
      "Drums",
      "EFX",
      "FM",
      "Input",
      "Lead",
      "Organ",
      "Pad",
      "Perc",
      "Piano",
      "Pluck",
      "String",
      "Vocoder",
    ];
    for (let i = 0; i < this.categoryNames.length; i++)
      this.categories.push([]);

    //Process Programs //////////////////////////////////////////////////////
    for (let i = 0; i < this.bankNames.length; i++) {
      const bank = [];
      for (let ii = 0; ii < 128; ii++)
        bank.push({
          name: "-------",
          type: "",
          code: this.indToCode({ bank: i, prg: ii }),
        });
      this.banks.push(bank);
    }

    for (let i = 0; i < params.bank.length; i++) {
      let patch = params.bank[i];
      let ind = this.codeToInd(patch.code);
      console.log(patch.code, ind);
      this.banks[ind.bank][ind.prg] = patch;
      if (patch.rating != null && patch.rating > 0)
        this.favorits.push({ ind: ind, rating: patch.rating });
      if (patch.type != null && patch.type != "") {
        let catInd = this.catNameToInd(patch.type);
        if (catInd < 0) {
          this.categoryNames.push(patch.type);
          this.categories.push([]);
          catInd = this.categories.length - 1;
        }
        this.categories[catInd].push({ ind: ind, rating: patch.rating });
      }
    }

    super.orderListsByRating();
  }

  indToCode(ind) {
    return (
      (ind.bank >= 4 ? "RAM" : "ROM") +
      "-" +
      String.fromCharCode(65 + (ind.bank % 4)) +
      " " +
      ind.prg
    );
  }
  codeToInd(code) {
    let bank = code.charCodeAt(4) - 65 + (code.indexOf("ROM") >= 4 ? 4 : 0);
    let prg = parseInt(code.substr(6));
    return { prg: prg, bank: bank };
  }

  codeToMIDI(code) {
    let ret = { type: "PrgBankChg", prg: -1, bank: -1 };
    let v1 = code.charCodeAt(4) - 65;
    let v2 = parseInt(code.substr(6));
    ret.bank = v1 + (code.indexOf("ROM") >= 0 ? 4 : 0);
    ret.prg = v2;
    return ret;
  }
  getBanks() {
    return this.bankNames;
  }
}
typeManager.registerClass(typeNameToId("Synth.VirusTi"), VirusTi);

export default VirusTi;
