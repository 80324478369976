import * as THREE from "three";
import cloud from "@cloud/VJYCloudClient";

import shaderUtils from "@three-extra/util/ShaderUtils";
import objMan from "@cloud/ObjectManager";
import typeMan from "@cloud/TypeManager";
import extAssetCache from "./ExtAssetCache";
import shader from "@three-extra/fbo/fboFrag";
import MaterialUpdater from "@rt/nodes/ProxyUpdaters/MaterialUpdater";
import { cloneDeep } from "lodash";
import textureConstants from "@three-extra/constants/textures";
const { typeNameToId, typeIdToName } = typeMan;
const texturePropMap = {
  mapCenter: "center",
  mapOffset: "offset",
  mapRotation: "rotation",
  mapRepeat: "repeat",
};

class MaterialManager {
  constructor() {
    this.isFactory = true;
    this.animUniform = [];
    this.animTexMulti = [];
    this.animTexSprites = [];
    this.animGraph = [];
    this.animGraphNew = [];
    this.animCanvasTextures = [];
    this.uuids = {};
    this.updateInd = 0;
  }

  build(decl) {
    //return decl.def;

    let asset = decl.asset;

    if (!asset) {
      console.warn("MaterialManager > no asset", decl);
      return new THREE.MeshNormalMaterial();
    }
    if (typeof asset === "string")
      asset = { ">link": { type: typeNameToId("Color") }, value: asset };
    else asset = objMan.deserialize(asset);

    if (!asset) {
      if (!asset) {
        console.warn("MaterialManager > no asset", decl, asset);
        return new THREE.MeshNormalMaterial();
      }
    }
    //  console.log(">>> Material Manager",asset,typeMan.isCompatible(asset['>link'].type,"Shader.ProceduralTexture"));
    let mat;
    let doc;
    const entry = {};
    const nativeTypes = [
      "MeshBasicMaterial",
      "MeshLambertMaterial",
      "MeshPhongMaterial",
      "MeshStandardMaterial",
      "MeshPhysicalMaterial",
      "MeshNormalMaterial",
      "MeshToonMaterial",
      "LineBasicMaterial",
      "LineDashedMaterial",
      "PointsMaterial",
    ].map(typeNameToId);
    if (nativeTypes.indexOf(asset[">link"].type) >= 0) {
      const params = cloneDeep(asset);
      const typeDef = typeMan.getTypeDef(asset[">link"].type);

      // delete parameters that aren't properties of the material
      for (let key of [">link", "_id", ">animation"]) delete params[key];

      // create textures
      for (let key in params) {
        if (!params[key] || !params[key][">link"]) continue;
        const doc = cloud.getDoc(params[key][">link"]);
        params[key] = this.assetToTexture(doc);
      }

      // make sure any THREE.js types are created
      for (let key in params) {
        if (texturePropMap[key]) continue;
        const type = typeDef.properties[key].type.type;

        if (
          type === typeNameToId("Vector2") ||
          type === typeNameToId("Vector3")
        ) {
          const obj = params[key];

          params[key] = new THREE[typeIdToName(type)](obj.x, obj.y, obj.z);
        }
        if (type === typeNameToId("Color")) {
          params[key] = new THREE.Color(params[key]);
        }
      }

      // convert rotation from degrees to radians
      if (asset[">link"].type === typeNameToId("SpriteMaterial")) {
        params.rotation = (params.rotation * Math.PI) / 180;
      }

      // set texture parameters

      const texParams = params.textureParameters || {
        mapping: 0,
        magFilter: 0,
        minFilter: 0,
        wrapS: 0,
        wrapT: 0,
        rotation: 0,
      };
      params.mapOffset = params.mapOffset || { x: 0, y: 0 };
      params.mapRepeat = params.mapRepeat || { x: 1, y: 1 };
      params.mapCenter = params.mapCenter || { x: 0, y: 0 };
      params.mapRotation = params.mapRotation || 0;

      texParams.mapping = textureConstants.MappingModes[texParams.mapping];
      texParams.magFilter =
        textureConstants.MagnificationFilters[texParams.magFilter];
      texParams.minFilter =
        textureConstants.MinificationFilters[texParams.minFilter];
      texParams.wrapS = textureConstants.WrappingModes[texParams.wrapS];
      texParams.wrapT = textureConstants.WrappingModes[texParams.wrapT];

      texParams.rotation = (texParams.rotation * Math.PI) / 180;

      texParams.offset = new THREE.Vector2(
        params.mapOffset.x,
        params.mapOffset.y
      );
      texParams.repeat = new THREE.Vector2(
        params.mapRepeat.x,
        params.mapRepeat.y
      );
      texParams.center = new THREE.Vector2(
        params.mapCenter.x,
        params.mapCenter.y
      );
      texParams.rotation = (params.mapRotation * Math.PI) / 180;

      for (let key in texturePropMap) {
        delete params[key];
      }

      // global parameters
      for (let key2 in params) {
        // check if param is a texture (can't use the image property as it is created later on)
        if (!params[key2] || !params[key2].matrix) continue;
        const texture = params[key2];
        texture.needsUpdate = true;
        // apply parameters to texture
        for (let key3 in texParams) texture[key3] = texParams[key3];
      }

      delete params.textureParameters;

      //			console.log("PARAMS", params)

      mat = new THREE[typeIdToName(asset[">link"].type)](params);
    }
    //Procedural Texture
    else if (
      typeMan.isCompatible(
        asset[">link"].type,
        typeNameToId("Shader.ProceduralTexture")
      )
    ) {
      //basemat:
      let baseShader = "Basic";
      //console.log("BASE MAT",decl.base);
      if (decl.base !== undefined && decl.base.type == "MeshPhongMaterial")
        baseShader = "Phong";
      if (decl.base !== undefined && decl.base.type == "LineBasicMaterial")
        baseShader = "Lines";
      if (decl.base !== undefined && decl.base.type == "SpriteMaterial")
        baseShader = "Sprite";
      //Get Code
      let shaderCode = { code: "", functions: "" };
      doc = cloud.getDoc(asset[">link"]);

      let router = null;
      if (asset[">link"].type == typeNameToId("Shader.ProceduralTexture")) {
        shaderCode = doc.d;
      } else {
        let tt = typeMan.getTypeDef(asset[">link"].type);
        //console.log("TT",tt);
        let docCode = cloud.getDoc(tt.classDecl[">link"]);

        shaderCode = {
          code: docCode.d.code,
          functions: docCode.d.functions,
          uniforms: shaderUtils.typeDefToUniforms(tt, doc.d),
        };
      }

      mat = shaderUtils.buildMaterial2({
        base: baseShader,
        baseParams: {
          diffuse: new THREE.Color(0xffffff),
        },
        params: {
          vertexColors: THREE.NoColors,
          side: THREE.FrontSide,
          wireframe: false,
          transparent: true,
        },
        proceduralTexture: shaderCode,
      });
      this.uuids[mat.uuid] = entry;
      this.animUniform.push({ mat });
    } else if (
      typeMan.isCompatible(asset[">link"].type, typeNameToId("CanvasTexture"))
    ) {
      const tex = this.assetToTexture(asset);
      mat = decl.base.clone();
      mat.map = tex;
      mat.transparent = true;

      this.uuids[mat.uuid] = entry;
      // console.log('CanvasTexture >>> ', mat , tex )
    } else {
      switch (asset[">link"].type) {
        case typeNameToId("Color"):
          mat = decl.base.clone();
          mat.color.set(asset.value);

          break;

        case typeNameToId("Sequence<Color>"):
          mat = decl.base.clone();
          mat.color.set(asset.elems[0]);

          break;

        case typeNameToId("Sequence<Texture2D>"):
          doc = cloud.getDoc(asset.elems[0]);
          //console.log("Tex Doc:",asset.elems[0],doc);
          mat = decl.base.clone();
          mat = decl.base.clone();
          // TODO - use assetToTexture
          mat.map = new THREE.TextureLoader().load(
            doc.d.baseObj.asset[">ext"].url
          );

          break;
        case typeNameToId("VideoTexture"):
          mat = decl.base.clone();
          mat.map = this.assetToTexture(asset);
          break;
        case typeNameToId("WebcamTexture"):
          // TODO - use assetToTexture
          const video = document.createElement("video");
          document.body.appendChild(video);
          video.autoplay = true;
          video.style.display = "none";
          const texture = new THREE.VideoTexture(video);

          const constraints = { video: { width: 1280, height: 720 } };
          mat = decl.base.clone();
          mat.map = texture;

          navigator.mediaDevices
            .getUserMedia(constraints)
            .then(function (stream) {
              // apply the stream to the video element used in the texture
              video.srcObject = stream;
              video.play();
              video.style.display = "none";
            })
            .catch(function (error) {
              console.error("Unable to access the camera/webcam.", error, decl);
            });

          break;

        case typeNameToId("Texture2D"):
          // TODO - use assetToTexture
          mat = decl.base.clone();
          this.uuids[mat.uuid] = entry;
          const res = extAssetCache.get(asset);

          if (res.type === "still") {
            mat.map = res.texture;
            //check for transparency
            const doc = cloud.getDoc(asset[">link"]);
            mat.userData = { doc };
            if (doc.m.fields.transparent && doc.m.fields.transparent === "true")
              mat.transparent = true;
          } else if (res.type === "animated:multi") {
            mat.map = res.textures[0];
            this.animTexMulti.push({
              material: mat,
              textures: res.textures,
              delays: res.delays,
              index: 0,
              lastUpdated: Date.now(),
            });
          } else if (res.type === "animated:sprites") {
            const doc = cloud.getDoc(asset[">link"]);
            if (doc.m.fields.transparent && doc.m.fields.transparent === "true")
              mat.transparent = true;
            const frameWidth = res.frameWidth / res.texture.image.width;
            const frameHeight = res.frameHeight / res.texture.image.width;
            const frameCountX = Math.min(
              Math.floor(1 / frameWidth),
              res.frameCount
            );
            res.texture.repeat = new THREE.Vector2(frameWidth, frameHeight);
            mat.map = res.texture;
            this.animTexSprites.push({
              material: mat,
              texture: res.texture,
              frameCount: res.frameCount,
              frameWidth,
              frameHeight,
              frameCountX,
              delay: 1000 / res.fps,
              x: 0,
              y: 0,
              lastUpdated: Date.now(),
            });
          }
          break;

        default:
          mat = decl.def;
      }
    }

    if (
      !asset[">animation"] ||
      asset[">animation"] === undefined ||
      window.isIframePreview
    )
      return mat;

    const graphDoc = cloneDeep(cloud.getDoc(asset[">animation"]));
    const updater = new MaterialUpdater({
      graphDoc: graphDoc,
      target: mat,
      type: asset[">link"].type,
    });
    this.animGraph.push({
      mat,
      graph: updater,
    });

    //	console.warn("Mat Man >>>> Graph ", asset[">animation"])

    return mat;
  }

  /**
   * Dispose materials created by build()
   * @param {object|object[]} materials - material or array of materials
   */
  dispose(materials) {
    materials = Array.isArray(materials) ? materials : [materials];
    for (const material of materials) {
      if (
        typeof material !== "object" ||
        !material.uuid ||
        !this.uuids[material.uuid]
      ) {
        continue;
      }

      // remove from animation arrays
      for (let i = this.animUniform.length - 1; i >= 0; i--) {
        if (this.animUniform[i].mat.uuid === material.uuid) {
          this.animUniform.splice(i, 1);
        }
      }
      for (let i = this.animTexMulti.length - 1; i >= 0; i--) {
        if (this.animTexMulti[i].material.uuid === material.uuid) {
          this.animTexMulti.splice(i, 1);
        }
      }
      for (let i = this.animTexSprites.length - 1; i >= 0; i--) {
        if (this.animTexSprites[i].material.uuid === material.uuid) {
          this.animTexSprites.splice(i, 1);
        }
      }

      delete this.uuids[material.uuid];

      // TODO dispose lightMap, envMap, MultiMaterial, etc..?
      material.dispose();
    }
  }
  assetToTexture(asset) {
    let doc = asset.d ? asset : cloud.getDoc(asset);

    if (typeMan.isCompatible(doc.t, typeNameToId("CanvasTexture"))) {
      const tex = objMan.instantiateComp(asset, {}, true);
      tex.start();

      if (tex.update && typeof tex.update === "function")
        this.animCanvasTextures.push(tex);

      return tex;
    }
    if (doc.t === typeNameToId("HDRTexture")) {
      return extAssetCache.get(doc).texture;
    }

    if (doc.t === typeNameToId("Texture2D")) {
      const loader = new THREE.TextureLoader();
      let url = doc.d.baseObj.asset[">ext"].url;
      if (extAssetCache.baseUrl) url = extAssetCache.baseUrl + url;
      const tex = loader.load(url);
      tex.wrapS = THREE.RepeatWrapping;
      tex.wrapT = THREE.RepeatWrapping;

      extAssetCache.textures[url] = tex;
      tex.userData = tex.userData || {};
      tex.userData.doc = doc;
      return tex;
    }
    if (doc.t === typeNameToId("VideoTexture")) {
      let url = doc.d.baseObj.asset[">ext"].url;
      if (extAssetCache.baseUrl) url = extAssetCache.baseUrl + url;
      const video = document.createElement("video");
      video.loop = true;
      video.muted = true;
      video.autoplay = true;
      video.playsinline = true;
      video.setAttribute("crossorigin", "anonymous");
      video.src = url;
      // console.log('VIDEO', video )
      // video.load()
      video.play();
      video.width = 10;
      video.height = 100;
      const tex = new THREE.VideoTexture(video);
      // console.log('TEX', tex )
      extAssetCache.textures[url] = tex;

      return tex;
    }
    if (doc.t !== typeNameToId("WebcamTexture")) return null;

    //  webcam

    const { width, height } = { width: 1280, height: 720 };
    // Webcam texture
    const video = document.createElement("video");
    document.body.appendChild(video);
    video.autoplay = true;
    video.style.display = "none";

    const texture = new THREE.VideoTexture(video);

    texture.wrapS = THREE.ClampToEdgeWrapping;
    texture.wrapT = THREE.ClampToEdgeWrapping;

    const constraints = { video: { width, height } };
    console.trace("CAM DOC", doc);
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(function (stream) {
        // apply the stream to the video element used in the texture

        video.srcObject = stream;
        video.play();
        video.style.display = "none";
      })
      .catch(function (error) {
        console.error("Unable to access the camera/webcam.", error);
      });

    return texture;
  }

  update(dt) {
    // shader uniforms
    for (const item of this.animUniform) {
      item.mat.uniforms.iTime.value += dt;

      if (item.mat.uniforms.uScreenResolution) {
        item.mat.uniforms.uScreenResolution.value[0] = window.innerWidth;
        item.mat.uniforms.uScreenResolution.value[1] = window.innerHeight;
      }
    }

    for (let tex of this.animCanvasTextures) {
      tex.update(dt);
      tex.needsUpdate = true;
    }

    for (const el of this.animGraph) {
      el.graph.update(dt);
    }

    // multiple textures (gif)
    for (const item of this.animTexMulti) {
      const now = Date.now();
      const delay = item.delays[item.index];
      if (now - item.lastUpdated >= delay) {
        item.index = (item.index + 1) % item.textures.length;
        item.material.setValues({ map: item.textures[item.index] });
        item.material.needsUpdate = true;
        item.lastUpdated = now;
      }
    }

    // sprites
    for (const item of this.animTexSprites) {
      const now = Date.now();
      if (now - item.lastUpdated >= item.delay) {
        const { frameCountX, frameCount, frameWidth, frameHeight } = item;
        item.x += 1;
        if (item.x >= frameCountX) {
          item.x = 0;
          item.y += 1;
        }
        if (item.y * frameCountX + item.x >= frameCount) {
          item.x = 0;
          item.y = 0;
        }
        item.texture.offset.set(
          item.x * frameWidth,
          1 - (item.y + 1) * frameHeight
        );
        item.lastUpdated = now;
      }
    }
  }
}

const materialManager = new MaterialManager();
export default materialManager;
