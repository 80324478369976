/*
 * GPU Particle System
 * @author flimshaw - Charlie Hoey - http://charliehoey.com
 *
 * A simple to use, general purpose GPU system.  Particles are spawn-and-forget with
 * several options available, and do not require monitoring or cleanup after spawning.
 * Because the paths of all particles are completely deterministic once spawned, the scale
 * and direction of time is also variable.
 *
 * Currently uses a static wrapping perlin noise texture for turbulence, and a small png texture for
 * particles, but adding support for a particle texture atlas or changing to a different type of turbulence
 * would be a fairly light day's work.
 *
 * Shader and javascript packing code derrived from several Stack Overflow examples.
 *
 * ported to ES6 by AngelLove69000
 *
 */

import * as THREE from "three";
import { fragShader } from "./shaders/fragShaders";
import { vertShader } from "./shaders/vertShaders";
const blendingModes = [
  THREE.NoBlending,
  THREE.NormalBlending,
  THREE.AdditiveBlending,
  THREE.SubtractiveBlending,
  THREE.MultiplyBlending,
  THREE.CustomBlending,
];
export default class ParticleSystem extends THREE.Object3D {
  constructor(options) {
    super();
    window.ps = this;

    var options = options || {};

    // parse options and use defaults
    this.PARTICLE_COUNT = options.maxParticles || 1000000;
    this.PARTICLE_CONTAINERS = options.containerCount || 1;
    this.PARTICLES_PER_CONTAINER = Math.ceil(
      this.PARTICLE_COUNT / this.PARTICLE_CONTAINERS
    );
    this.PARTICLE_CURSOR = 0;
    this.time = 0;

    const vertexShader = options.vertShader
      ? options.vertShader
      : { code: null, functions: null };
    const fragmentShader = options.fragShader
      ? options.fragShader
      : { code: null, functions: null };
    console.log("OPTIONS", options);

    if (!options.map) {
      this.particleSpriteTexUrl =
        "https://res.cloudinary.com/vjy/image/upload/v1596734802/use9lrlc3zwmsmlkay6v.png";

      // "https://res.cloudinary.com/vjy/image/upload/v1596734802/use9lrlc3zwmsmlkay6v.png"
    }

    this.blendingMode = options.blendingMode
      ? blendingModes[options.blendingMode]
      : THREE.AdditiveBlending;
    if (options.map) this.particleSpriteTex = options.map;
    this.alpha = options.alpha || 0.5;
    this.size = options.size || 10;

    this.GPUParticleShader = {
      vertexShader: vertShader(vertexShader.code, vertexShader.functions),
      fragmentShader: fragShader(fragmentShader.code, fragmentShader.functions),
    };

    // preload a million random numbers
    this.rand = [];

    for (var i = 1e5; i > 0; i--) {
      this.rand.push(Math.random() - 0.5);
    }

    this.options = options;

    this.createShaderMat();
    this.particleContainers = [];
    this.init();
  }

  random() {
    return ++this.PARTICLE_CURSOR >= this.rand.length
      ? this.rand[(this.PARTICLE_CURSOR = 1)]
      : this.rand[this.PARTICLE_CURSOR];
  }
  init() {
    for (var i = 0; i < this.PARTICLE_CONTAINERS; i++) {
      var c = new GPUParticleContainer(this.PARTICLES_PER_CONTAINER, this);
      this.particleContainers.push(c);
      this.add(c);
    }
  }
  createShaderMat() {
    var textureLoader = new THREE.TextureLoader();

    // this.particleNoiseTex = textureLoader.load("http://vjyourself.com/webgl/tex/perlin-512.png");
    // this.particleNoiseTex.wrapS = this.particleNoiseTex.wrapT = THREE.RepeatWrapping;

    this.particleSpriteTex =
      this.particleSpriteTex || textureLoader.load(this.particleSpriteTexUrl);
    this.particleSpriteTex.wrapS = this.particleSpriteTex.wrapT =
      THREE.RepeatWrapping;

    this.particleBlurTex =
      this.particleSpriteTex || textureLoader.load(this.particleSpriteTexUrl);
    this.particleBlurTex.wrapS = this.particleBlurTex.wrapT =
      THREE.RepeatWrapping;

    const uniforms = {
      uTime: {
        type: "f",
        value: 0.0,
      },
      uScale: {
        type: "f",
        value: this.options.size || 10,
      },
      tNoise: {
        type: "t",
        value: this.particleNoiseTex,
      },
      tSprite: {
        type: "t",
        value: this.particleSpriteTex,
      },
      tBlur: {
        type: "t",
        value: this.particleBlurTex,
      },
      uMouse: {
        value: new THREE.Vector2(),
      },

      map: {
        value: null,
      },
      alpha: { value: this.alpha },
    };

    for (let key in this.options.uniforms) {
      uniforms[key] = this.options.uniforms[key];
    }
    this.particleShaderMat = new THREE.ShaderMaterial({
      transparent: true,
      depthWrite:
        this.options.depthWrite !== undefined ? this.options.depthWrite : false,
      uniforms: uniforms,
      blending: this.blendingMode,
      vertexShader: this.GPUParticleShader.vertexShader,
      fragmentShader: this.GPUParticleShader.fragmentShader,
    });

    this.uniforms = uniforms;

    // define defaults for all values
    this.particleShaderMat.defaultAttributeValues.particlePositionsStartTime = [
      0, 0, 0, 0,
    ];
    this.particleShaderMat.defaultAttributeValues.particleVelColSizeLife = [
      0, 0, 0, 0,
    ];
  }
  spawnParticle(options) {
    this.PARTICLE_CURSOR++;
    if (this.PARTICLE_CURSOR >= this.PARTICLE_COUNT) {
      this.PARTICLE_CURSOR = 1;
    }
    var currentContainer =
      this.particleContainers[
        Math.floor(this.PARTICLE_CURSOR / this.PARTICLES_PER_CONTAINER)
      ];
    currentContainer.spawnParticle(options);
  }

  update(time) {
    for (var i = 0; i < this.PARTICLE_CONTAINERS; i++) {
      this.particleContainers[i].update(time);
    }
  }
}

// Subclass for particle containers, allows for very large arrays to be spread out
export class GPUParticleContainer extends THREE.Object3D {
  constructor(maxParticles, particleSystem) {
    super();
    this.PARTICLE_COUNT = maxParticles || 100000;
    this.PARTICLE_CURSOR = 0;
    this.time = 0;
    this.DPR = window.devicePixelRatio;
    this.particleSystem = particleSystem;
    this.maxVel = 2;
    this.maxSource = 250;

    // construct a couple small arrays used for packing variables into floats etc
    this.UINT8_VIEW = new Uint8Array(4);
    this.FLOAT_VIEW = new Float32Array(this.UINT8_VIEW.buffer);

    this.particles = [];
    this.deadParticles = [];
    this.particlesAvailableSlot = [];

    // create a container for particles
    this.particleUpdate = false;

    // Shader Based Particle System
    this.particleShaderGeo = new THREE.BufferGeometry();

    this.addAttributes();

    this.particleShaderMat = this.particleSystem.particleShaderMat;

    this.offset = 0;
    this.count = 0;

    this.init();
  }
  addAttributes() {
    // new hyper compressed attributes
    this.particleVertices = new Float32Array(this.PARTICLE_COUNT * 3); // position
    this.particlePositionsStartTime = new Float32Array(this.PARTICLE_COUNT * 4); // position
    this.particleVelColSizeLife = new Float32Array(this.PARTICLE_COUNT * 4);

    this.particleIndexSizeBlur = new Float32Array(this.PARTICLE_COUNT * 3);

    for (var i = 0; i < this.PARTICLE_COUNT; i++) {
      this.particlePositionsStartTime[i * 4 + 0] = 0; //x
      this.particlePositionsStartTime[i * 4 + 1] = 0; //y
      this.particlePositionsStartTime[i * 4 + 2] = 0.0; //z
      this.particlePositionsStartTime[i * 4 + 3] = 0.0; //startTime

      this.particleVertices[i * 3 + 0] = 0; //x
      this.particleVertices[i * 3 + 1] = 0; //y
      this.particleVertices[i * 3 + 2] = 0.0; //z

      this.particleVelColSizeLife[i * 4 + 0] = this.decodeFloat(128, 128, 0, 0); //vel
      this.particleVelColSizeLife[i * 4 + 1] = this.decodeFloat(0, 254, 0, 254); //color
      this.particleVelColSizeLife[i * 4 + 2] = 1.0; //size
      this.particleVelColSizeLife[i * 4 + 3] = 0.0; //lifespan

      this.particleIndexSizeBlur[i * 3] = i;
      this.particleIndexSizeBlur[i * 3 + 1] = 1;
      this.particleIndexSizeBlur[i * 3 + 2] = 1;
    }

    this.particleShaderGeo.setAttribute(
      "position",
      new THREE.BufferAttribute(this.particleVertices, 3)
    );

    this.particleShaderGeo.setAttribute(
      "particleIndexSizeBlur",
      new THREE.BufferAttribute(this.particleIndexSizeBlur, 3)
    );
    this.particleShaderGeo.setAttribute(
      "particlePositionsStartTime",
      new THREE.BufferAttribute(this.particlePositionsStartTime, 4)
    );
    this.particleShaderGeo.setAttribute(
      "particleVelColSizeLife",
      new THREE.BufferAttribute(this.particleVelColSizeLife, 4)
    );

    this.positions = this.particleShaderGeo.getAttribute("position");
    this.posStart = this.particleShaderGeo.getAttribute(
      "particlePositionsStartTime"
    );
    this.velCol = this.particleShaderGeo.getAttribute("particleVelColSizeLife");
    this.indexSizeBlur = this.particleShaderGeo.getAttribute(
      "particleIndexSizeBlur"
    );
  }

  init() {
    this.points = new THREE.Points(
      this.particleShaderGeo,
      this.particleShaderMat
    );
    this.points.frustumCulled = false;
    this.add(this.points);
  }
  spawnParticle(options) {
    options = options || {};

    // setup reasonable default values for all arguments
    const position = new THREE.Vector3();
    const velocity = new THREE.Vector3();
    if (options.position !== undefined) position.copy(options.position);
    else position.set(0, 0, 0);
    if (options.velocity !== undefined) velocity.copy(options.velocity);
    else velocity.set(0, 0, 0);
    const positionRandomness =
      options.positionRandomness !== undefined
        ? options.positionRandomness
        : 0.0;
    const velocityRandomness =
      options.velocityRandomness !== undefined
        ? options.velocityRandomness
        : 0.0;
    const color = options.color !== undefined ? options.color : 0xffffff;
    const colorRandomness =
      options.colorRandomness !== undefined ? options.colorRandomness : 1.0;
    let turbulence =
      options.turbulence !== undefined ? options.turbulence : 1.0;
    const lifetime = options.lifetime !== undefined ? options.lifetime : 5.0;
    let size = options.size !== undefined ? options.size : 10;
    const sizeRandomness =
      options.sizeRandomness !== undefined ? options.sizeRandomness : 0.0;
    const smoothPosition =
      options.smoothPosition !== undefined ? options.smoothPosition : false;
    const blur = options.blur !== undefined ? options.blur : 0;

    const i = this.PARTICLE_CURSOR;
    const { particleSystem } = this;

    if (this.DPR !== undefined) size *= this.DPR;

    this.posStart.array[i * 4 + 0] =
      position.x + particleSystem.random() * positionRandomness; // - ( velocity.x * particleSystem.random() ); //x
    this.posStart.array[i * 4 + 1] =
      position.y + particleSystem.random() * positionRandomness; // - ( velocity.y * particleSystem.random() ); //y
    this.posStart.array[i * 4 + 2] =
      position.z + particleSystem.random() * positionRandomness; // - ( velocity.z * particleSystem.random() ); //z
    this.posStart.array[i * 4 + 3] = this.time + particleSystem.random() * 2e-2; //startTime

    this.positions.array[i * 3] = position.x;
    this.positions.array[i * 3 + 1] = position.y;
    this.positions.array[i * 3 + 2] = position.z;

    if (smoothPosition === true) {
      this.posStart.array[i * 4 + 0] += -(velocity.x * particleSystem.random()); //x
      this.posStart.array[i * 4 + 1] += -(velocity.y * particleSystem.random()); //y
      this.posStart.array[i * 4 + 2] += -(velocity.z * particleSystem.random()); //z
    }

    var velX = velocity.x + particleSystem.random() * velocityRandomness;
    var velY = velocity.y + particleSystem.random() * velocityRandomness;
    var velZ = velocity.z + particleSystem.random() * velocityRandomness;

    velX = 0;
    velY = velocity.y;
    velX = 0;

    // convert turbulence rating to something we can pack into a vec4
    turbulence = Math.floor(turbulence * 254);

    // clamp our value to between 0. and 1.
    const { maxSource, maxVel } = this;
    velX = Math.floor(maxSource * ((velX - -maxVel) / (maxVel - -maxVel)));
    velY = Math.floor(maxSource * ((velY - -maxVel) / (maxVel - -maxVel)));
    velZ = Math.floor(maxSource * ((velZ - -maxVel) / (maxVel - -maxVel)));

    this.velCol.array[i * 4 + 0] = this.decodeFloat(
      velX,
      velY,
      velZ,
      turbulence
    ); //vel

    var rgb = this.hexToRgb(color);

    for (var c = 0; c < rgb.length; c++) {
      rgb[c] = Math.floor(
        rgb[c] + particleSystem.random() * colorRandomness * 254
      );
      if (rgb[c] > 254) rgb[c] = 254;
      if (rgb[c] < 0) rgb[c] = 0;
    }

    if (options.color)
      this.velCol.array[i * 4 + 1] = this.decodeFloat(
        rgb[0],
        rgb[1],
        rgb[2],
        254
      ); //color
    this.velCol.array[i * 4 + 2] =
      size + particleSystem.random() * sizeRandomness; //size
    this.velCol.array[i * 4 + 3] = lifetime; //lifespan

    this.indexSizeBlur.array[i * 3 + 1] = 1;
    this.indexSizeBlur.array[i * 3 + 2] = blur;

    if (this.offset == 0) {
      this.offset = this.PARTICLE_CURSOR;
    }

    this.count++;

    this.PARTICLE_CURSOR++;

    if (this.PARTICLE_CURSOR >= this.PARTICLE_COUNT) {
      this.PARTICLE_CURSOR = 0;
    }

    this.particleUpdate = true;
  }

  update(time, mouse = { x: 0, y: 0 }) {
    this.time = time;
    this.particleShaderMat.uniforms["uTime"].value = time;
    // this.particleShaderMat.uniforms['uMouse'].value.x = mouse.x
    // this.particleShaderMat.uniforms['uMouse'].value.y = mouse.y
    this.geometryUpdate();
  }

  geometryUpdate() {
    if (this.particleUpdate == true) {
      this.particleUpdate = false;

      // if we can get away with a partial buffer update, do so
      if (this.offset + this.count < this.PARTICLE_COUNT) {
        this.posStart.updateRange.offset = this.velCol.updateRange.offset =
          this.offset * 4;
        this.posStart.updateRange.count = this.velCol.updateRange.count =
          this.count * 4;
      } else {
        this.posStart.updateRange.offset = 0;
        this.posStart.updateRange.count = this.velCol.updateRange.count =
          this.PARTICLE_COUNT * 4;
      }

      this.posStart.needsUpdate = true;

      this.velCol.needsUpdate = true;
      this.indexSizeBlur.needsUpdate = true;

      this.offset = 0;
      this.count = 0;
    }
  }
  decodeFloat(x, y, z, w) {
    this.UINT8_VIEW[0] = Math.floor(w);
    this.UINT8_VIEW[1] = Math.floor(z);
    this.UINT8_VIEW[2] = Math.floor(y);
    this.UINT8_VIEW[3] = Math.floor(x);
    return this.FLOAT_VIEW[0];
  }

  componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  rgbToHex(r, g, b) {
    return (
      "#" +
      this.componentToHex(r) +
      this.componentToHex(g) +
      this.componentToHex(b)
    );
  }

  hexToRgb(hex) {
    var r = hex >> 16;
    var g = (hex & 0x00ff00) >> 8;
    var b = hex & 0x0000ff;

    if (r > 0) r--;
    if (g > 0) g--;
    if (b > 0) b--;

    return [r, g, b];
  }
}
