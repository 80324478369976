import './Toaster.css';

let delay = 4000;
let parent, node, timeout;

export function setDelay(ms) {
	delay = ms;
}

export function setParent(node) {
	parent = node;
}

export function toast(message) {
	if (!node) {
		const wrapper = document.createElement('div');
		wrapper.className = 'toaster';
		node = document.createElement('div');
		node.className = 'toast slide-out';
		wrapper.appendChild(node);
		(parent || document.body).appendChild(wrapper);
	}
	setTimeout(() => {
		node.textContent = message;
		node.className = 'toast slide-in';
		if (timeout) clearTimeout(timeout);
		timeout = setTimeout(() => {
			node.className = 'toast slide-out';
			timeout = null;
		}, delay);
	}, 0);
}
