import * as THREE from "three";
import typeMan from "@cloud/TypeManager";
import VisComp from "@three-extra/VisComp";
import LightSys from "@three-extra/lights/LightSys";

class TriRot extends LightSys {
  constructor() {
    super();
    this.isVisual = true;
    this.updateColors = this.updateColors.bind(this);
  }
  start() {
    super.start();
    this.props = this.inputs.getObj();
    //for(let el of Object.keys(props)) this[el]=props[el];
    //this.cont3D = this.props.cont3D;
    this.compL1 = new THREE.Group();
    this.cont3D.add(this.compL1);
    this.compL2 = new THREE.Group();
    this.cont3D.add(this.compL2);
    this.compL3 = new THREE.Group();
    this.cont3D.add(this.compL3);

    this.dirLight1 = new THREE.DirectionalLight(0xffffff);
    this.dirLight2 = new THREE.DirectionalLight(0xffffff);
    this.dirLight3 = new THREE.DirectionalLight(0xffffff);
    this.ambLight = new THREE.AmbientLight(0x111111);

    this.dirLight1.intensity = 1;
    this.dirLight1.position.set(0, 10, 0);
    this.compL1.add(this.dirLight1);
    this.compL1.rotation.z = ((Math.PI * 2) / 3) * 0;

    this.dirLight2.intensity = 1;
    this.dirLight2.position.set(0, 10, 0);
    this.compL2.add(this.dirLight2);
    this.compL2.rotation.z = ((Math.PI * 2) / 3) * 1;

    this.dirLight3.intensity = 1;
    this.dirLight3.position.set(0, 10, 0);
    this.compL3.add(this.dirLight3);
    this.compL3.rotation.z = ((Math.PI * 2) / 3) * 2;

    this.cont3D.add(this.ambLight);
    this.alpha = 0;

    //Start Rot
    const mul = Math.PI / 180;
    this.compL1.rotation.x = this.props.rotation[0].x * mul;
    this.compL1.rotation.y = this.props.rotation[0].y * mul;
    this.compL1.rotation.z = this.props.rotation[0].z * mul;
    this.compL2.rotation.x = this.props.rotation[1].x * mul;
    this.compL2.rotation.y = this.props.rotation[1].y * mul;
    this.compL2.rotation.z = this.props.rotation[1].z * mul;
    this.compL3.rotation.x = this.props.rotation[2].x * mul;
    this.compL3.rotation.y = this.props.rotation[2].y * mul;
    this.compL3.rotation.z = this.props.rotation[2].z * mul;

    //colors
    this.updateColors();

    this.inputs.listeners.add("colA", this.updateColors);
    /*
		this.inputs.listeners.add('speed', event => {
			this.props.speed = event.data;
		});
		*/
  }

  updateColors() {
    const colA = this.inputs.get("colA");
    //console.log('TriRot.updateColors colA:', colA);
    this.colors = [];
    if (colA == null)
      this.colors.push(
        new THREE.Color(0xffffff),
        new THREE.Color(0xffffff),
        new THREE.Color(0xffffff)
      );
    else
      for (let i = 0; i < 3; i++)
        this.colors.push(new THREE.Color(colA.getNext()));
    this.dirLight1.color.set(this.colors[0]);
    this.dirLight2.color.set(this.colors[1]);
    this.dirLight3.color.set(this.colors[2]);
    //console.log(this.colors);
  }

  update(dt) {
    //console.log(dt);
    const lightSpeed = this.props.speed * dt;
    const speeds = this.props.rotationSpeed;
    this.compL1.rotation.x += speeds[0].x * lightSpeed;
    this.compL1.rotation.y += speeds[0].y * lightSpeed;
    this.compL1.rotation.z += speeds[0].z * lightSpeed;
    this.compL2.rotation.x += speeds[1].x * lightSpeed;
    this.compL2.rotation.y += speeds[1].y * lightSpeed;
    this.compL2.rotation.z += speeds[1].z * lightSpeed;
    this.compL3.rotation.x += speeds[2].x * lightSpeed;
    this.compL3.rotation.y += speeds[2].y * lightSpeed;
    this.compL3.rotation.z += speeds[2].z * lightSpeed;
  }
  destroy() {}
}
/*
const typeDef = {
	name: 'VJY5.Lights.TriRot',
	category: 2,
	construct: 'component',
	properties: {

	}
};
typeMan.addTypeDef(typeDef, TriRot);
*/
typeMan.registerClass(typeMan.typeNameToId("LightSys.TriRot"), TriRot);
export default TriRot;
