import EasingValue from "@data-trans/EasingValue";
import VirtualScroll from "virtual-scroll";
import device from "@input/Device";

const rotationPerCanvasWidth = Math.PI * 1.2;
const rotationPerCanvasHeight = Math.PI * 1.2;

const buttonsMap = ["e", "r", "t", "y", "u", "i", "o", "p", "l", "k"];

class MKB {
  constructor(channelCount, listeners) {
    this.enabled = false;
    this.available = true;
    this.active = false;

    this.speedX = 0;
    this.speedY = 0;
    this.speedZ = 0;
    this.speedHold = false;
    this.lookX = 0;
    this.lookY = 0;
    this.lookZ = 0;
    this.lookHold = false;

    this.channels = new Array(channelCount).fill(0);
    this.buttons = new Array(buttonsMap.length);

    this._params = {};

    this._buttons = buttonsMap.map((key) => ({ key, pressed: false }));

    this._activeInNextFrame = false;
    this._mouseWheel = new EasingValue(0, 0, 12, 0.0005);
    this._documentMouseWheel = new EasingValue(0, 0, 30, 0.05);
    this.currentScroll = 0;
    this._keySpeedZ = 0;

    this._onMouseMove = this._onMouseMove.bind(this);
    this._onMouseDown = this._onMouseDown.bind(this);
    this._onMouseExit = this._onMouseExit.bind(this);
    this._onMouseWheel = this._onMouseWheel.bind(this);
    this._onKeyDown = this._onKeyDown.bind(this);
    this._onKeyUp = this._onKeyUp.bind(this);
    this._onWheel = this._onWheel.bind(this);
    this._onMouseWheel = this._onMouseWheel.bind(this);

    this.onResize();
    window.addEventListener("resize", this.onResize);

    window.mkb = this;
  }

  setCanvas(canvas) {
    this._canvas = canvas;
  }

  setParams(params, customScroll) {
    if (params.enabled === true) this.enable(customScroll);
    else if (params.enabled === false) this.disable();
  }

  enable(customScroll = false) {
    if (!this.enabled && this._canvas) {
      let targetDiv;

      // The below is leftover from VistaJet / EY, leaving it here in case we need to do any fixes on these
      // if there is HTML content, canvas might be hidden so we listen to scroll events from the body instead
      if (
        (document.getElementById("content") &&
          document.getElementById("content").firstChild) ||
        document.getElementById("fauxBody")
      ) {
        targetDiv = document.body;
      } else targetDiv = this._canvas;

      // We scroll the content div instead of document.body to avoid canvas resizing
      // when scrolling on mobile
      targetDiv = this._canvas;
      if (
        document.getElementById("content") &&
        document.getElementById("content").firstChild
      )
        targetDiv = document.getElementById("content");
      this._targetDiv = targetDiv;

      // !FABRICANT
      // targetDiv = document.body
      targetDiv.addEventListener("mousemove", this._onMouseMove);
      targetDiv.addEventListener("mousedown", this._onMouseDown);
      targetDiv.addEventListener("mouseup", this._onMouseExit);
      targetDiv.addEventListener("mouseleave", this._onMouseExit);

      // better wheel event, just in use for custom scroll projects for now
      if (customScroll) {
        targetDiv.addEventListener("wheel", this._onWheel, { passive: true });
      } else {
        // Deprecated events, in use for all of our stuff
        targetDiv.addEventListener("mousewheel", this._onMouseWheel, {
          passive: true,
        });
        targetDiv.addEventListener("DOMMouseScroll", this._onMouseWheel, {
          passive: true,
        });
      }

      document.addEventListener("keydown", this._onKeyDown);
      document.addEventListener("keyup", this._onKeyUp);

      this.enabled = true;
    }
  }
  // temp method to swith from deprecated mousewheel / dommousescroll events to nw wheen events
  updateWheelListeners() {
    const targetDiv = this._targetDiv;
    targetDiv.removeEventListener("mousewheel", this._onMouseWheel, {
      passive: true,
    });
    targetDiv.removeEventListener("DOMMouseScroll", this._onMouseWheel, {
      passive: true,
    });
    targetDiv.addEventListener("wheel", this._onWheel, { passive: true });
  }

  // use this later when switching to wheel events
  _onWheel(event) {
    // mouse wheel + ctrl key makes the user zoom, ignore scroll in tath case
    if (event.ctrlKey) return;
    let delta = event.deltaY / 20;

    this._mouseWheel.jumpTo(delta);

    this.wheelDelta = delta;
  }
  _onMouseWheel(event) {
    // TODO normalize value

    // +info: http://phrogz.net/js/wheeldelta.html
    let delta = 0;
    if (event.wheelDelta) {
      delta = event.wheelDelta / (Math.abs(event.wheelDelta) > 100 ? 500 : 40);
    } else {
      delta = event.detail / 10;
    }
    //this._wheelSpeedZ = -delta * 15;
    this._mouseWheel.jumpTo(-delta * 6);

    this.scrollY = window.scrollY;

    // event.preventDefault();
    // event.stopImmediatePropagation();
  }

  disable() {
    if (this.enabled) {
      let targetDiv;

      // if there is HTML content, canvas might be hidden so we listen to scroll events from the body instead
      if (
        document.getElementById("content") &&
        document.getElementById("content").firstChild
      ) {
        targetDiv = document.body;
      } else targetDiv = this._canvas;

      targetDiv.removeEventListener("mousemove", this._onMouseMove);
      targetDiv.removeEventListener("mousedown", this._onMouseDown);
      targetDiv.removeEventListener("mouseup", this._onMouseExit);
      targetDiv.removeEventListener("mouseleave", this._onMouseExit);
      targetDiv.removeEventListener("mousewheel", this._onMouseWheel);
      targetDiv.removeEventListener("DOMMouseScroll", this._onMouseWheel);

      document.removeEventListener("keydown", this._onKeyDown);
      document.removeEventListener("keyup", this._onKeyUp);
      this.enabled = false;
    }
  }

  onResize = () => {
    this.scrollHeight = document.body.scrollHeight;
  };

  update() {
    this.active = this._activeInNextFrame;

    if (this._posCurrent) {
      const dx =
        (this._posLast.x - this._posCurrent.x) / this._canvas.clientWidth;
      const dy =
        (this._posLast.y - this._posCurrent.y) / this._canvas.clientHeight;
      this._posLast.x = this._posCurrent.x;
      this._posLast.y = this._posCurrent.y;
      this.lookX = dx * rotationPerCanvasWidth;
      this.lookY = dy * rotationPerCanvasHeight;
    }

    /*if (this._mouseWheel) {
			this.speedZ = this._wheelSpeedZ;
			this._mouseWheel = false;
		} else {*/

    this.speedZ = -this._keySpeedZ * 5 + this._mouseWheel.get();
    // console.log( this._keySpeedZ )
    if (this._mouseWheel.target !== 0) this._mouseWheel.set(0);
    if (this._documentMouseWheel.target !== 0) this._documentMouseWheel.set(0);

    for (let i = 0; i < this.buttons.length; i++) {
      const button = this._buttons[i];

      if (!this.buttons[i] && button.pressed && !button.changeRecorded) {
        button.changeRecorded = true;
        this.buttons[i] = true;
      } else {
        this.buttons[i] = false;
      }

      if (!button.pressed) {
        button.changeRecorded = false;
      }
    }

    this._activeInNextFrame = !!(
      this.speedZ !== 0 ||
      this.speedX !== 0 ||
      this._posCurrent ||
      this._activeSlider
    );
    if (this.onAfterUpdate) this.onAfterUpdate();
  }

  _onMouseMove(event) {
    if (this._posCurrent) {
      this._posCurrent.x = event.clientX;
      this._posCurrent.y = event.clientY;
    }
  }

  _onMouseDown(event) {
    this._posLast = { x: event.clientX, y: event.clientY };
    this._posCurrent = { x: event.clientX, y: event.clientY };
    this.lookHold = true;
  }

  _onMouseExit(event) {
    this._posCurrent = null;
    this.lookX = 0;
    this.lookY = 0;
    this.lookHold = false;
  }

  _onKeyUp(event) {
    switch (event.key) {
      case "ArrowUp":
      case "ArrowDown":
      case "w":
      case "s":
        this._keySpeedZ = 0;
        break;
      case "ArrowLeft":
      case "ArrowRight":
      case "a":
      case "d":
        this.speedX = 0;
        break;
      default:
        break;
    }

    for (const button of this._buttons) {
      if (event.key === button.key) {
        button.pressed = false;
      }
    }
  }

  _onKeyDown(event) {
    switch (event.key) {
      case "ArrowUp":
      case "w":
        this._keySpeedZ = -1;
        break;
      case "ArrowDown":
      case "s":
        this._keySpeedZ = 1;
        break;
      case "ArrowLeft":
      case "a":
        this.speedX = -1;
        break;
      case "ArrowRight":
      case "d":
        this.speedX = 1;
        break;
      default:
        break;
    }

    for (const button of this._buttons) {
      if (event.key === button.key) {
        button.pressed = true;
      }
    }
  }
}

export default MKB;
