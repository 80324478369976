import cloud from "@cloud/VJYCloudClient";
import globals from "@rt/globals";

/**
 * If `obj` is a valid link of type `id` return its body.
 * @param {VJYDocLink} obj
 * @returns {?VJYDocLinkBody}
 */
export function getIdLink(obj) {
  if (
    typeof obj === "object" &&
    obj !== null &&
    obj[">link"] &&
    obj[">link"].id
  ) {
    return obj[">link"];
  }
}

/**
 * @param {VJYDocLink} link
 * @param {VJYDoc} doc
 * @returns {boolean}
 */
export function linkMatchesDoc(link, doc) {
  link = getIdLink(link);
  if (!link) return false;
  if (typeof doc !== "object") return false;
  return link.id === doc._id;
}

/**
 * Try to convert `queryLike` to a {@link FindQuery}. A string is interpreted as
 * an ObjectID hex string, a link is converted to a query.
 * @param {FindQuery|VJYDocLink|string} queryLike
 * @returns {FindQuery}
 */
export function getQuery(queryLike) {
  if (!queryLike) return {};
  if (typeof queryLike === "string") return { id: queryLike };
  if (typeof queryLike === "object") {
    if (queryLike[">link"]) {
      const query = { ...queryLike[">link"] };
      if (query.type) {
        query.t = query.type;
        delete query.type;
      }
      return query;
    }
    return queryLike;
  }
  return {};
}
/**
 *
 * @param {CloudDoc} obj - a cloud document with type FindQuery
 */

export function queryObjectToFindQuery(obj) {
  const findQuery = {
    t: undefined,
    id: undefined,
    n: undefined,
    fields: undefined,
    scope: undefined,
    owner: undefined,
    subclasses: undefined,
  };
  for (let key in obj) findQuery[key] = obj[key];

  for (let key in findQuery)
    if (
      findQuery[key] === undefined ||
      findQuery[key] === null ||
      findQuery[key] === "" ||
      (Array.isArray(findQuery[key]) && findQuery[key].length === 0)
    )
      delete findQuery[key];

  let isValid = false;
  for (let key in findQuery) {
    if (findQuery[key]) isValid = true;
  }
  if (!isValid) return null;
  return findQuery;
}

/**
 *
 * @param {FindQuery} parentQuery
 * @param {FindQuery} childQuery
 */

export function mergeFindQueryWithParentQuery(parentQuery, childQuery) {
  const findQuery = Object.assign({}, parentQuery);
  for (let key in childQuery) {
    if (childQuery[key] !== undefined || childQuery[key] !== null)
      findQuery[key] = childQuery[key];
  }
  return findQuery;
}

const { typeIdsByName } = globals;
const typeNamesById = {};
for (let key in typeIdsByName) {
  typeNamesById[typeIdsByName[key]] = key;
}

export function getTypeMeta(type, typeDecl) {
  if (!type.meta && !type.subclasses) return "";
  try {
    let meta = "";

    if (type.subclasses) {
      meta += `  * @subclasses ${type.subclasses}\n`;
    }
    if (type.level) {
      meta += `  * @level ${type.level}`;
    }
    if (type.meta) {
      meta += `  * @meta ${JSON.stringify(type.meta)}`;
    }
    // single key, means typeDecl only has a type field - close the comment
    if (Object.keys(typeDecl).length === 1) {
      return `  /** 
      ${meta}
        */
      `;
    }
    return `  /** 
    ${meta}
      
    `;
  } catch (err) {
    console.log(type, typeDecl, err);
    throw err;
  }
}
export function getTypeString(type) {
  const { args, meta } = type;

  // basic type
  if (!args) {
    return type.type;
  }
  if (type.type === "Array") {
    return `${args[0].type}[]`;
  }
  if (type.type === "Pattern" || type.type === "Sequence") {
    return `${type.type}<${args[0].type}>`;
  }
}
export function parseMetaLine(lineString, currentMeta) {
  //https://typeofnan.dev/how-to-capture-between-two-characters-in-javascript-using-regex/
  // find the property name: it's the string contained between @ and an empty space
  // then replace these characters with null string

  const match = lineString.match(/@.+?\s/);
  if (!match) return null;

  const propName = match[0].replace(/@|\s/g, "");

  let propData = lineString.replace(/@.+?\s|\*/g, "").replace(/\s/, "");

  if (propName === "subclasses" && propData === "false") propData = "false";
  else if (propName === "subclasses" && propData === "true") propData = "true";
  else if (lineString.match("@") && !lineString.match("meta")) {
    const prop = lineString
      .replace(/\*\s*@\s*/, "")
      .trim()
      .split(" ")[0];
    const value = lineString
      .replace(prop, "")
      .replace(/\s+@\s+/, "")
      .replace(/\*/, "")
      .replace(":", "")
      .replace("*", "")
      .replace(/"/g, "")
      .trim();

    return {
      prop,
      value,
    };
  } else if (propData.match(/{/)) propData = JSON.parse(propData);

  currentMeta[propName] = propData;
}
export function parseLine(lineString, currentMeta) {
  let propName = "f";
  let propType = "bar";
  let args = null;

  // begin line comment
  if (lineString.match(/\/\*\*/)) {
    return {
      beginMeta: true,
    };
  }
  // end line comment
  if (lineString.match(/\*\//)) {
    return {
      endMeta: true,
    };
  }
  if (currentMeta) {
    return parseMetaLine(lineString, currentMeta);
  }

  if (!lineString.match(":")) return null;

  const colonIndex = lineString.indexOf(":");
  const first = lineString.slice(0, colonIndex);
  const second = lineString.slice(colonIndex + 1);

  const splitLine = [first, second];

  // remove whitespace characters
  propName = splitLine[0].replace(/\s/g, "");
  // remove ; at end of line and ignore any whitespace characters before / after type name
  propType = splitLine[1].replace(/;/g, "").trim();
  // console.log("prop type ", propType);

  // remove square brackets from property type with an alien looking RegExp
  if (propType.match(/\[\]/)) {
    args = [
      {
        type: propType.replace(/\[\]/, ""),
      },
    ];
    propType = "Array";
  }

  // not a Pattern, stop here
  if (!propType.match(/</))
    return {
      propName,
      propType,
      args,
    };

  const split = propType.split("<");
  const patternType = split[1].replace(/>/, "");
  args = [
    {
      type: patternType,
    },
  ];

  if (propType.match("Sequence")) propType = "Sequence";
  else propType = "Pattern";

  return {
    propName,
    propType,
    args,
  };
}
