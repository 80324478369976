
import * as THREE from 'three'
import inputManager from '@input/InputManager';
import DomManager from '@input/DomManager';
/**
 * This function must be bound to a VisComp and called in its start function
 * It replaces the current renderer with a WebGL1Renderer
 * This is useful in cases where we use FBOUtils, to avoid No OES_texture_float support for float textures
 * In the future, we should be able to remove this and replace FBOUtils with GPUComputationHelper, like here https://threejs.org/examples/?q=birds#webgl_gpgpu_birds
 */
export default function forceWebGL1() {

    // create new cavas and remove old one from DOM
    const currentCanvas = this.renderer.canvas
    const newCanvas = document.createElement('canvas')
    newCanvas.id = 'canvas'
    const canvasContainer = currentCanvas.parentNode
    canvasContainer.removeChild(currentCanvas)
    canvasContainer.appendChild(newCanvas)


    const { props } = this.renderer
    let antialias, alpha;
    if (props && props.settings && props.settings.renderer) {
        antialias = props.settings.renderer.antialias
        alpha = props.settings.renderer.alpha
    }
    // set renderComp canvas 
    this.renderer.canvas = newCanvas

    // create WebGL 1 renderer
    this.renderer.renderer = new THREE.WebGL1Renderer({ preserveDrawingBuffer: false, canvas: newCanvas, antialias: true, alpha });

    // set renderer properties
    this.renderer.renderer.setPixelRatio(window.devicePixelRatio || 1);
    if (window.innerWidth > 600) this.renderer.renderer.setPixelRatio(1)
    if (props && props.settings && props.settings.renderer) {
        this.renderer.renderer.physicallyCorrectLights = this.props.physicallyCorrectLights || false;
        if (props.settings.renderer.outputEncoding) this.renderer.renderer.outputEncoding = [
            THREE.LinearEncoding,
            THREE.sRGBEncoding,
            THREE.GammaEncoding,
            THREE.RGBEEncoding,
            THREE.LogLuvEncoding,
            THREE.RGBM7Encoding,
            THREE.RGBM16Encoding,
            THREE.RGBDEncoding,
            THREE.BasicDepthPacking,
            THREE.RGBADepthPacking,
        ][props.settings.renderer.outputEncoding]
        if (props.settings.renderer.toneMapping) this.renderer.renderer.toneMapping = [
            THREE.NoToneMapping,
            THREE.LinearToneMapping,
            THREE.ReinhardToneMapping,
            THREE.CineonToneMapping,
            THREE.ACESFilmicToneMapping][props.settings.renderer.toneMapping]
        if (props.settings.renderer.toneMappingExposure) this.renderer.renderer.toneMappingExposure = props.settings.renderer.toneMappingExposure
        if (props.settings.renderer.shadowMapEnabled) this.renderer.renderer.shadowMap.enabled = true
        if (props.settings.renderer.shadowMapType) this.renderer.renderer.shadowMap.type = [
            THREE.BasicShadowMap,
            THREE.PCFShadowMap,
            THREE.PCFSoftShadowMap,
            THREE.VSMShadowMap
        ][props.settings.renderer.shadowMapType]
        if (props.settings.renderer.gammaFactor) this.renderer.renderer.gammaFactor = props.settings.renderer.gammaFactor
        if (props.settings.renderer.gammaOutput) this.renderer.renderer.gammaOutput = props.settings.renderer.gammaOutput
    }
    this.renderer.setProps(this.renderer.props)

    // attach new canvas to app and add listeners
    const { app } = this.scene
    app.canvas = newCanvas
    // Dom Manager 
    app.domManager = new DomManager({
        dom: "main",
        canvas: newCanvas
    })
    app.domManager.listeners.add("resize", e => {
        app.setCanvasDimensions(e.data.width, e.data.height)
    })
    app.domManager.start()
    app.domManager.onResize()


    // remove old canvas listeners
    const { _mkb, _touch, _gamepad, _midi } = inputManager

    // update inputs
    if (_mkb.enabled) _mkb.disable();
    if (_touch.enabled) _touch.disable();
    if (_gamepad.enabled) _gamepad.disable();
    if (_midi.enabled) _midi.disable();

    // restart inputManager with new canvas
    inputManager.setCanvas(newCanvas);
    inputManager.setParams(app.params.settings.inputMan);





}