import * as THREE from 'three';

import Component from '@rt/Component';

import cloud from '@cloud/VJYCloudClient';
import extAssetCache from './ExtAssetCache';

import factoryMat from '@three-extra/asset/MaterialManager';
import factoryGeom from '@three-extra/asset/GeometryManager';





/**
 * Class for creating Object3D patterns from:
 *  Geometry Pattern + Material Pattern
 *  Geometry Pattern
 *  Model Pattern
 *  Model + Material Pattern
 */
export default class Object3DManager {

    constructor() {
            this.uuid = THREE.Math.generateUUID()
            this.container = new THREE.Group()
            this.objectIndex = 0
            this.elems = []
            this.objects = []
        }
        /**
         * Builds the objects 
         * @param {*} param0 
         * @returns Object3D containing 
         */
    async start({
            geometriesName,
            materialsName,
            modelsName,
            count,
            visComp
        }) {


            if (!visComp) console.warn("Object3DManager expected a VisComp", this.uuid, this)

            this.visComp = visComp
            this.count = count

            const geometries = visComp.inputs.get(geometriesName)
            const materials = visComp.inputs.get(materialsName)
            const models = visComp.inputs.get(modelsName)


            if (geometries && materials && models) console.warn("Object3DManager received geometries, materials and models. This may cause unexpected behaviour", this.uuid, this)

            if ( models) await this.buildModels({ models, count })
            else if (models && materials) this.buildModelsAndMat({ models, materials, count })
            else if (geometries && materials) this.buildGeomAndMat({ geometries, materials, count })

            for ( let elem of this.elems ){
                elem.userData.isOriginal = true
                elem.userData.hasBeenUsed= false // keep track of whether the mesh has already been used so we can clone it if necessary
            }



        }
        /**
         * Build a pattern of models
         */
    async buildModels({ models, count }) {

        this.mode = "models"

        const promises = []
            // load models from external asset cache
        for (let i = 0; i < models.count; i++) {
            const doc = cloud.getDoc(models.getNext())
            promises.push(extAssetCache.load(doc))

        }
        const values = await Promise.all(promises)
        for (let model of values) {
          //  console.log(model)
            this.elems.push(model)
        }
    }
    buildMaterials({ materials }) {

        this.materials = []
        for (let i = 0; i < materials.count; i++) {
            this.materials.push(
                factoryMat.build({
                    base: this.visComp.scene.baseMat,
                    def: this.visComp.scene.defaultMat,
                    asset: materials.getNext()
                }))
        };
    }
    buildGeometries({ geometries }) {



            this.geometries = [];
            for (let i = 0; i < geometries.count; i++) {
                this.geometries.push(factoryGeom.build(geometries.getNext(), { geom: this.visComp._defGeom }))
            };
        }
        /**
         * Build a pattern of models and overwrite their materials
         */
    buildModelsAndMat({ materialsName, modelsName }) {
            this.buildModels(modelsName)
            this.buildMaterials(materialsName)

        }
        /**
         * Build a pattern of objects from geometry and material
         */
    buildGeomAndMat({ geometries, materials }) {
            this.buildGeometries({ geometries })
            this.buildMaterials({ materials })

            let count = Math.max(this.geometries.length, this.materials.length)

            for (let i = 0; i < count; i++) {
                const g = this.geometries[i % this.geometries.length]
                const m = this.materials[i % this.materials.length]
                const mesh = new THREE.Mesh(g, m)
                this.elems.push(mesh)
            }

            console.log(this.elems)
        }
        /**
         * Build a pattern of objects from geometries with no material
         */
    buildGeomOnly() {}


    /**
     * returns the follwing element in the pattern, cloning it if necessary
     */
    getNext() {
      //  console.log( this.objectIndex, this.elems.length )
        const mesh = this.getMesh( this.objectIndex )
        this.objectIndex++
        this.objectIndex = this.objectIndex % this.elems.length 
        return mesh
    }

    /**
     * 
     * @param {*} index get mesh at index, cloning it if necessary
     */
    getMesh( index ){
       
        let mesh 
       
        if ( !this.elems[ index % this.elems.length  ].userData.hasBeenUsed ){ // mesh not used yet, return original copy
            
            mesh = this.elems[ index % this.elems.length]
            mesh.userData.hasBeenUsed = true 
        } else if ( this.mode === "models" ) {
            
            mesh = cloneGLTFmodel( this.elems[ index % this.elems.length ] )
            mesh.userData.manager = this // do this after cloning to avoid endless recursion
        } else {
            mesh = this.elems[ index % this.elems.length ].clone()
            mesh.userData.manager = this // do this after cloning to avoid endless recursion
       
        }
 
        this.objects.push(mesh)

        return mesh 
    }
  


    dispose() {
        if (this.mode !== "models") {

        }

    }

}



function cloneGLTFmodel(model) {
    const scene = new THREE.Scene()
    const obj = model.children[0]
    const clone = obj.clone(true) // true parameter makes the clone recursive
    scene.add(clone)

    return scene
}