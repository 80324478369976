import * as THREE from 'three';
import VisComp from '@three-extra/VisComp';
import typeManager from '@cloud/TypeManager';

import factoryMat from '@three-extra/asset/MaterialManager';
import factoryGeom from '@three-extra/asset/GeometryManager';

import typeMan from "@cloud/TypeManager"
import cloud from "@cloud/VJYCloudClient"
import ParticleSystem from "@three-extra/particles/particleClass"

import PointSpritesGeometry from "@three-extra/geometry/PointSpritesGeometry"
import PointSpritesMaterial from "@three-extra/materials/PointSpritesMaterial"

import isIOS from "@three-extra/profiling/isIOS"
import shaderUtils from '@three-extra/util/ShaderUtils';



const { floor, random, abs, sign, min, max, sqrt, cbrt, PI } = Math
function randItem(arr) {
    const ind = floor(random() * arr.length)
    return arr[ind]
}
function lerp(x, y, t) {

    return (1 - t) * x + t * y;

}

class SpriteSystem extends VisComp {
    constructor() {
        super();
        // document.addEventListener("click", () => console.log(this)) // debug helper
    }

    start() {
        super.start();



        this.count = this.inputs.get("count") !== undefined ? this.inputs.get("count") : 10000
        this.activePointCount = this.inputs.get("activePointCount") !== undefined ? this.inputs.get("activePointCount") : this.count / 10
        this.dimensions = this.inputs.get("dimensions") !== undefined ? this.inputs.get("dimensions") : new THREE.Vector3(1500, 1500, 1500)
        this.activePointDimensions = this.inputs.get("activePointDimensions") !== undefined ? this.inputs.get("activePointDimensions") : new THREE.Vector3(500, 500, 500)
        this.spriteSize = this.inputs.get("spriteSize") !== undefined ? this.inputs.get("spriteSize") : 20
        this.particleSize = this.inputs.get("particleSize") !== undefined ? this.inputs.get("particleSize") : 10
        this.emptyRadius = this.inputs.get("emptyRadius") !== undefined ? this.inputs.get("emptyRadius") : this.dimensions.length() / 20

        this.lineCount = this.inputs.get("animatedLineCount") !== undefined ? this.inputs.get("animatedLineCount") : this.count / 20

        this.particlesPerLine = this.inputs.get("particlesPerLine") || 100
        if ( isIOS()  && this.particlesPerLine ) this.particlesPerLine = 1  
        this.maxParticleLife = this.inputs.get("maxParticleLife") || 1

        this.polygonSides = this.inputs.get("polygonSides") || [3]

        this.minLineAnimDuration = this.inputs.get("minLineAnimDuration") !== undefined ? this.inputs.get("minLineAnimDuration") : 1
        this.maxLineAnimDuration = this.inputs.get("maxLineAnimDuration") !== undefined ? this.inputs.get("maxLineAnimDuration") : 2

        this.minFadeDuration = this.inputs.get("minFadeDuration") !== undefined ? this.inputs.get("minFadeDuration") : 2
        this.maxFadeDuration = this.inputs.get("maxFadeDuration") !== undefined ? this.inputs.get("maxFadeDuration") : 2

        this.frameCount = 16

        this.group = new THREE.Group()
        this.cont3D.add(this.group)

        this.generateMat();
        this.generateGeom()

        const points = new THREE.Points(this.geom, this.spriteMat)
        this.group.add(points);

        // TODO: use an octtree for nearest neighbour calculations
        // https://github.com/mrdoob/three.js/blob/dev/examples/jsm/utils/TypedArrayUtils.js

        console.time("close")
        this.calculateClosest()
        console.timeEnd("close")
     
        this.initLines()
        // this.initStaticLines()

        this.initPS()
        this.initFade()
        this.frames = 0
        this.time = 0
    }

    /*
    * GENERATE ASSETS >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    */
    // MATERIALS
    generateMat() {

        // SPRITE 
        let mat = this.inputs.get('spriteMat');
        this.mat = [];

        const map = this.inputs.get("map")
        console.log("map", map)
        if (map) this.map = new THREE.TextureLoader().load(map.elems[0].baseObj.asset[">ext"].url)
        else this.map = null
        console.log(this.map, "this ma^")

        for (let i = 0; i < mat.count; i++) {
            this.mat.push(factoryMat.build({ base: this.scene.baseMat, def: this.scene.baseMat, asset: mat.getNext() }));
        }
        // STATIC LINES
        this.staticLineColors = []
        mat = this.inputs.get("staticLineColors")
        if (mat === undefined) this.staticLineColors = [new THREE.Color(0, 0.5, 0.9), new THREE.Color(0, 0.7, 0.5)]
        else for (let i = 0; i < mat.count; i++) {
            this.staticLineColors.push(new THREE.Color(mat.getNext()));
        }

        // ACTIVE LINES
        mat = this.inputs.get("activeLineColors")
        this.activeLineColors = []
        if (mat === undefined) this.activeLineColors = [new THREE.Color(0, 0.8, 0.5), new THREE.Color(0, 0.8, 0.7)]
        else for (let i = 0; i < mat.count; i++) {
            this.activeLineColors.push(new THREE.Color(mat.getNext()));
        }

        if (this.mat[0].userData.doc && this.mat[0].userData.doc.m.fields.frameCount) this.frameCount = parseInt(this.mat[0].userData.doc.m.fields.frameCount)

        if (this.mat[0].map) {
            this.mat[0].map.wrapS = THREE.RepeatWrapping;
            this.mat[0].map.wrapT = THREE.RepeatWrapping;
        }
        const asset = this.inputs.get('spriteMat').elems[0]

        let shaderCode = null
        if (typeMan.isCompatible(asset[">link"].t, "Shader.ProceduralTexture")) {

            const doc = cloud.getDoc(asset['>link'])

            let tt = typeMan.getTypeDef(asset['>link'].type);

            let docCode = cloud.getDoc(tt.classDecl['>link']);

            shaderCode = {
                code: docCode.d.code,
                functions: docCode.d.functions,
                uniforms: shaderUtils.typeDefToUniforms(tt, doc.d)
            };
        }
        this.spriteMat = new PointSpritesMaterial({
            size: this.spriteSize,
            blending: THREE.AdditiveBlending,
            color: 0xffffff,
            map: this.map
        }, shaderCode)


    }
    // GEOM
    generateGeom() {
        var vertices = [];
        const frameIndexesA = []
        const frameIndexesB = []
        const aniPercs = []

        this.points = []

        const v = new THREE.Vector3()
        const minRadiusSq = this.emptyRadius ** 2 // this.dimensions.lengthSq() / 10
        // generate particles outside of the empty radius 
        this.emptyRadius = 0
        const radiusMin = (this.me.camera.position.z - this.emptyRadius)
        const radiusMax = (this.me.camera.position.z + this.emptyRadius)

        this.mode = this.inputs.get("mode") || "cube"

        if (this.inputs.get("geom")) {
            const geomA = this.inputs.get('geom');
            this.geometries = [];
            for (let i = 0; i < geomA.count; i++) this.geometries.push(factoryGeom.build(geomA.getNext(), { geom: this._defGeom }));
            console.log(this.geometries, this.inputs.get("geom"))
            const g = this.geometries[0]
            const v = new THREE.Vector3()
            const v1 = new THREE.Vector3()
            const v2 = new THREE.Vector3()

            const step = 0.05 // temple

            for (var i = 0; i < g.attributes.position.count; i++) {
                v1.set(g.attributes.position.array[i * 3], g.attributes.position.array[i * 3 + 1], g.attributes.position.array[i * 3 + 2])
                v2.set(g.attributes.position.array[((i + 1) * 3) % g.attributes.position.array.length], g.attributes.position.array[((i * 3 + 3) + 1) % g.attributes.position.array.length], g.attributes.position.array[((i * 3 + 3) + 2) % g.attributes.position.array.length])
                const d = v1.distanceTo(v2)
                const count = floor(d / step) + 1
                //     for ( let ii = 0; ii < count ; ii ++ ) {

                //         v.lerpVectors( v1,
                //             v2,
                //               ii / count 
                //             )
                //         v.multiplyScalar( 200 )
                //             if (i < this.activePointCount) this.points.push({
                //                     pos: new THREE.Vector3(v.x, v.y, v.z),
                //                     ind: i
                //             })

                //             vertices.push(v.x, v.y, v.z);
                //             frameIndexesA.push(floor(random() * this.frameCount))
                //             frameIndexesB.push(floor(random() * this.frameCount))
                //             aniPercs.push(0)

                //    }
                v.copy(v1)
                //v.multiplyScalar( 100 )
                if (i < this.activePointCount) this.points.push({
                    pos: new THREE.Vector3(v.x, v.y, v.z),
                    ind: i
                })

                vertices.push(v.x, v.y, v.z);
                frameIndexesA.push(floor(random() * this.frameCount))
                frameIndexesB.push(floor(random() * this.frameCount))
                aniPercs.push(0)




            }
            //  for ( let i = 0; i < g.attributes.inde)
            this.count = g.attributes.position.count //* 20 
        }

        else if (this.mode === "cube") {
            for (var i = 0; i < this.count; i++) {
                // points between which the lines will be drawn 
                const v2 = new THREE.Vector2()
                do {
                    if (i < this.activePointCount) v.set(
                        THREE.Math.randFloatSpread(this.activePointDimensions.x),
                        THREE.Math.randFloatSpread(this.activePointDimensions.y),
                        THREE.Math.randFloatSpread(this.activePointDimensions.z)
                    )
                    else v.set(
                        THREE.Math.randFloatSpread(this.dimensions.x),
                        THREE.Math.randFloatSpread(this.dimensions.y),
                        THREE.Math.randFloatSpread(this.dimensions.z)
                    )
                    v2.set(v.x, v.z)
                } while (v.length() < 50 )
                if (i < this.activePointCount) this.points.push({
                    pos: new THREE.Vector3(v.x, v.y, v.z),
                    ind: i
                })

                vertices.push(v.x, v.y, v.z);
                frameIndexesA.push(floor(random() * this.frameCount))
                frameIndexesB.push(floor(random() * this.frameCount))
                aniPercs.push(0)

            }
        }

        else if (this.mode === "sphere") {
            const sp = new THREE.Spherical()

            for (var i = 0; i < this.count; i++) {
                // points between which the lines will be drawn 
                const v2 = new THREE.Vector2()

                if ( random() > 0.3 ) { // random point on the sphere 
                    let x1, x2
                    do {
                        x1 = random() * 2 - 1
                        x2 = random() * 2 - 1
                    } while (x1 ** 2 + x2 ** 2 >= 1)
                    const x = 2 * x1 * sqrt(1 - x1 ** 2 - x2 ** 2)
                    const y = 2 * x2 * sqrt(1 - x1 ** 2 - x2 ** 2)
                    const z = 1 - 2 * (x1 ** 2 + x2 ** 2)
                    v.set(x, y, z)
                    v.setLength(this.dimensions.z)
                } else { // random point inside the sphere
                    const phi = random() * PI * 2 
                    const costheta = random() * 2 -  1
                    const u = random()
    
                    const theta = Math.acos(costheta)
                    const r = this.dimensions.z * cbrt(u)
    
                    sp.set( r, phi, theta )
                    v.setFromSpherical(sp)

                }
              
                if (i < this.activePointCount) this.points.push({
                    pos: new THREE.Vector3(v.x, v.y, v.z),
                    ind: i
                })

                vertices.push(v.x, v.y, v.z);
                frameIndexesA.push(floor(random() * this.frameCount))
                frameIndexesB.push(floor(random() * this.frameCount))
                aniPercs.push(0)

            }
        }

        // this.mode = "fill"
        // fill the space
        else if (this.mode === "fill") {
            const sp = new THREE.Spherical()
            const emptyRadius = this.inputs.get('emptyRadius') || 0
            let x = 0
            let y = 0
            let z = 0 

            this.mat.blending = THREE.AdditiveBlending

            console.time( "geom")
            for (var i = 0; i < this.count; i++) {
                
                const boundary = this.dimensions.z
                do{
                    x = THREE.Math.randFloatSpread(boundary);
                    y = THREE.Math.randFloatSpread(boundary);
                    z = THREE.Math.randFloatSpread(boundary);
                }while(x*x+y*y+z*z < emptyRadius ** 2)

                if (i < this.activePointCount) this.points.push({
                    pos: new THREE.Vector3(x, y, z),
                    ind: i
                })


                vertices.push(x, y, z);
                frameIndexesA.push(floor(random() * this.frameCount))
                frameIndexesB.push(floor(random() * this.frameCount))
                aniPercs.push(0)

            }
            
        }
        // just the faces of the shape
        else if (this.mode === "edges") {
            const sp = new THREE.Spherical()
            const v = new THREE.Vector3()
            const radius = 100
            for (var i = 0; i < this.count; i++) {
                const radius = i < this.activePointCount ? this.activePointDimensions.z : this.dimensions.z

                // uniform points on a sphere
                // credit: Marsaglia (1972),  https://mathworld.wolfram.com/SpherePointPicking.html
                let x1, x2
                do {
                    x1 = random() * 2 - 1
                    x2 = random() * 2 - 1
                } while (x1 ** 2 + x2 ** 2 >= 1)
                const x = 2 * x1 * sqrt(1 - x1 ** 2 - x2 ** 2)
                const y = 2 * x2 * sqrt(1 - x1 ** 2 - x2 ** 2)
                const z = 1 - 2 * (x1 ** 2 + x2 ** 2)


               //  https://stackoverflow.com/questions/9600801/evenly-distributing-n-points-on-a-sphere

                // phi = arccos(1 - 2*indices/num_pts)
                // theta = pi * (1 + 5**0.5) * indices

                // x, y, z = cos(theta) * sin(phi), sin(theta) * sin(phi), cos(phi);
                // const { cos, sin } = Math 
                // const phi = Math.acos(1 - 2*i/this.count)
                // const theta = Math.PI * (1 + 5**0.5) * i
                // const x = cos(theta) * sin(phi)
                // const y = sin(theta) * sin(phi)
                // const z =   cos(phi)


                v.set(x, y, z)
                v.setLength(radius)

                if (i < this.activePointCount) this.points.push({
                    pos: new THREE.Vector3(v.x, v.y, v.z),
                    ind: i
                })




                vertices.push(v.x, v.y, v.z);
                frameIndexesA.push(floor(random() * this.frameCount))
                frameIndexesB.push(floor(random() * this.frameCount))
                aniPercs.push(0)

            }
        }

        this.geom = new PointSpritesGeometry({
            frameIndexesA,
            frameIndexesB,
            vertices,
            aniPercs
        })
    }
    // PARTICLES
    initPS() {
        this.particleSystem = new ParticleSystem({
            maxParticles: this.lineCount * this.particlesPerLine,

            isMoving: false
        });
        this.group.add(this.particleSystem);
        this.particleOptions = {
            position: new THREE.Vector3(0, 0, 0),
            positionRandomness: 0,
            velocity: new THREE.Vector3(0, 0, 0),
            velocityRandomness: 0,
            color: 0x66e0ff,
            colorRandomness: 0,
            turbulence: 0,
            lifetime: 5,
            size: this.particleSize ,
            sizeRandomness: 1,
            smoothPosition: false,
            scale: 1
        };
    }

    // calculate each point's closest neighbours
    calculateClosest() {
        for (let i = 0; i < this.points.length; i++) {
            const point = this.points[i]
            if (point.nearest !== undefined) continue
            point.nearest = []
            for (let c = 0; c < 4; c++) {
                let minD = Infinity
                let minInd = - 1
                for (let ii = 0; ii < this.points.length; ii++) {
                    if (i === ii) continue
                    if (point.nearest.indexOf(ii) > -1) {
                        continue
                    }
                    const d = point.pos.distanceToSquared(this.points[ii].pos)
                    if (d < minD) {
                        minD = d
                        minInd = ii
                    }
                }
                point.nearest.push(minInd)
            }
        }
    }

    // LINES 
    // Create animated line geometry
    // and lineElem array to hold animation state
    initLines() {

        this.lineElems = []

        const positions = []
        var colors = [];

        let lineInd = 0

        for (let i = 0; i < floor(this.lineCount / 3); i++) {


            const pointCount = randItem(this.polygonSides)
            let prev, curr
            const points = []
            let linePoints = []
            for (let i = 0; i < pointCount; i++) {
                if (i === 0) {
                    curr = randItem(this.points)
                    prev = curr
                    points.push(curr)
                    continue
                }
                const availablePoints = prev.nearest.filter(p => points.indexOf(p) < 0)
                if (!availablePoints.length) continue
                curr = this.points[randItem(availablePoints)]
                prev = curr
                points.push(curr)
            }

            for (let i = 0; i < points.length; i++) {
                linePoints.push([points[i], points[(i + 1) % points.length], lineInd++])
            }


            // ADD VERTICES / COLORS TO BUFFER
            for (let ii = 0; ii < 3; ii++) {
                const colA = randItem(this.staticLineColors)
                const colB = randItem(this.staticLineColors)

                const start = linePoints[ii][0]
                const target = linePoints[ii][1]

                positions.push(
                    start.pos.x, start.pos.y, start.pos.z,
                    target.pos.x, target.pos.y, target.pos.z
                )
                colors.push(
                    colA.r, colA.g, colA.b,
                    colB.r, colB.g, colB.b
                )
            }

            // ADD lineElem TO ANIM ARRAY
            const duration = this.minLineAnimDuration + random() * (this.maxLineAnimDuration - this.minLineAnimDuration)
            const aniStage = randItem(["growOut", "growIn"])
            //  const aniStage = "growOut"
            const perc = 0

            let lines = []
            for (let line of linePoints) {
                const arr = line
                lines.push({
                    start: arr[0].ind,
                    target: arr[1].ind,
                    ind: arr[2]
                })
            }
            const lineElem = {
                points: points,
                duration: duration,
                cursor: 0,
                perc: perc,
                aniStage: aniStage,
                lines: lines
            }


            this.lineElems.push(lineElem)
        }

     

        // CREATE LINE

        var geometry = new THREE.BufferGeometry();
        var material = new THREE.LineBasicMaterial({ vertexColors: true, transparent: true, opacity: 0.8 });

        this.lineBuffer = new THREE.Float32BufferAttribute(positions, 3)
        this.lineColors = new THREE.Float32BufferAttribute(colors, 3)

        geometry.setAttribute('position', this.lineBuffer);
        geometry.setAttribute('color', this.lineColors);

        const line = new THREE.LineSegments(geometry, material);
        this.lineGeom = geometry

        this.activeLine = line
        this.group.add(line)
    }
    initFade() {
        this.fadeElems = {}
        const count = this.inputs.get("fadeCount") !== undefined ? this.inputs.get("fadeCount") : this.count / 2
        const indexMap = {}
        for (let i = 0; i < count; i++) {
            this.addFade()
        }
    }
    // make a sprite fade to another frame
    addFade(ind = null, duration = null) {
        let count = 0
        if (!ind) {
            ind = floor(random() * this.count)
            // make sure we only have one anim per elem 
            while (this.fadeElems[ind] !== undefined) {
                ind = floor(random() * this.count)
                count++
                if (count > 5) return null
            }
        }

        if (!duration) duration = this.minFadeDuration + (this.maxFadeDuration - this.minFadeDuration) * random()
        const el = {
            ind: ind,
            perc: 0,
            duration: duration
        }
        this.fadeElems[ind] = el
        this.geom.setAnimPercentage(ind, 0)
        this.geom.setTargetFrame(ind, floor(random() * this.frameCount))
        return duration
    }


    // SPRITE ANIMATION
    updateFade(dt) {

        for (let key in this.fadeElems) {
            const el = this.fadeElems[key]
            el.perc += dt / el.duration // * el.direction
            if (abs(el.perc) >= 1) el.perc = 1 // *  el.direction
            this.geom.setAnimPercentage(el.ind, el.perc)
            if (el.perc === 1) {
                // this.frameBufferA.array[el.ind] = this.frameBufferB.array[el.ind]
                this.geom.setStartFrame(el.ind, this.geom.frameBufferB.array[el.ind])
                this.geom.setAnimPercentage(el.ind, 0)
                delete this.fadeElems[key]
                this.addFade()
            }
        }
        this.geom.update()
    }
    // LINE ANIMATION
    updatePolygonAnim(i, dt) {
        const lineElem = this.lineElems[i]

        lineElem.perc += dt / lineElem.duration
        if (lineElem.perc > 1) lineElem.perc = 1
        if (lineElem.perc < 1) return

        lineElem.perc = 0


        if (lineElem.aniStage === "growOut" || lineElem.aniStage === "wait") {
            if (lineElem.aniStage === "growOut") for (let line of lineElem.lines) {
                // let tmp = line.start
                // line.start = line.target
                // line.target = tmp

                this.addFade(line.start, line.duration)
                this.addFade(line.target, line.duration)
            }
            if (lineElem.aniStage === "growOut") lineElem.aniStage = "wait"
            else if (lineElem.aniStage === "wait") lineElem.aniStage = "growIn"
            return
        }



        for (let line of lineElem.lines) {
            this.addFade(line.start, line.duration)
            this.addFade(line.target, line.duration)
        }


        // switch to new lineElem 

        if (random() > 0.5) this.resetPolygon(lineElem)

        lineElem.aniStage = "growOut"

    }

    // sets a lineElem anim to new points
    resetPolygon(lineElem) {
        const newPoints = []
        let prev
        for (let i = 0; i < lineElem.points.length; i++) {
            let curr
            if (i === 0) {
                curr = randItem(this.points)
                newPoints.push(curr)
                prev = curr
                continue
            }
            // console.log( newPoints, curr, i , prev )
            curr = this.points[randItem(prev.nearest.filter(p => newPoints.indexOf(p) < 0))]
            newPoints.push(curr)
            prev = curr

        }
        lineElem.points = newPoints

        for (let i = 0; i < lineElem.lines.length; i++) {
            const line = lineElem.lines[i]
            line.start = newPoints[i].ind
            line.target = newPoints[(i + 1) % newPoints.length].ind
        }
    }

    updateLinePos(line, perc, aniStage) {
        const v = new THREE.Vector3()
        const v2 = new THREE.Vector3()
        const i = line.ind
        const startPos = this.points[line.start].pos
        const endPos = this.points[line.target].pos

        v.subVectors(endPos, startPos)
        v.multiplyScalar(perc)
        v2.addVectors(startPos, v)


        const r = lerp(this.lineColors.array[i * 6 + 0], this.lineColors.array[i * 6 + 3], perc)
        const g = lerp(this.lineColors.array[i * 6 + 1], this.lineColors.array[i * 6 + 4], perc)
        const b = lerp(this.lineColors.array[i * 6 + 2], this.lineColors.array[i * 6 + 5], perc)
        const col = new THREE.Color(r, g, b)
        this.particleOptions.color = col.getHex()

        // growing out
        if (aniStage === "growOut") {

            this.lineBuffer.array[i * 6 + 0] = startPos.x
            this.lineBuffer.array[i * 6 + 1] = startPos.y
            this.lineBuffer.array[i * 6 + 2] = startPos.z

            this.lineBuffer.array[i * 6 + 0 + 3] = v2.x
            this.lineBuffer.array[i * 6 + 1 + 3] = v2.y
            this.lineBuffer.array[i * 6 + 2 + 3] = v2.z



            this.particleOptions.position.copy(v2)


        }
        // growing in
        if (aniStage === "growIn") {

            this.lineBuffer.array[i * 6 + 0] = v2.x
            this.lineBuffer.array[i * 6 + 1] = v2.y
            this.lineBuffer.array[i * 6 + 2] = v2.z

            this.lineBuffer.array[i * 6 + 3] = endPos.x
            this.lineBuffer.array[i * 6 + 4] = endPos.y
            this.lineBuffer.array[i * 6 + 5] = endPos.z

            this.particleOptions.position.copy(endPos)
        }

        this.newParticlesPerFrame = 1
        let count = this.newParticlesPerFrame
        count = 1 
        if (aniStage === "growOut") count = Math.ceil(perc * count)
     
     
        for (let i = 0; i < count; i++) {
           this.particleOptions.lifetime = 1+ random() * this.maxParticleLife  // add 1 to make sure size value computed in vertex shader stays positive
            if (aniStage === "growOut") this.particleOptions.size = Math.ceil((perc + 1) * 0.5 * this.particleSize)
            else this.particleOptions.size = this.particleSize 
            
           this.particleSystem.spawnParticle(this.particleOptions)
        }
    }


    update(dt) {

        this.time += dt
        super.update( dt )

        // update crossfading sprites
        this.updateFade(dt)


        for (let i = 0; i < this.lineElems.length; i++) {
            this.updatePolygonAnim(i, dt)
            for (let l of this.lineElems[i].lines) {
                this.updateLinePos(l, this.lineElems[i].perc, this.lineElems[i].aniStage)
            }
        }
        this.lineBuffer.needsUpdate = true

        if (this.particleSystem) this.particleSystem.update(this.time);

        this.frames++
    }

    dispose() {
        super.dispose()
    }

}



typeManager.registerClass("5ec523e94d0cb40017f53c90", SpriteSystem);

export default SpriteSystem;


//

// initStaticLines() {
//     var geometry = new THREE.BufferGeometry();
//     var material = new THREE.LineBasicMaterial({ vertexColors: true });

//     const count = this.inputs.get("staticLineGroupsCount") !== undefined ? this.inputs.get("staticLineGroupsCount") : this.count / 20

//     const minLineCount = this.inputs.get("minStaticLineCount") !== undefined ? this.inputs.get("minStaticLineCount") : 3
//     const maxLineCount = this.inputs.get("maxStaticLineCount") !== undefined ? this.inputs.get("maxStaticLineCount") : 15
//     // create groups
//     const positions = []
//     const colors = []
//     for (let i = 0; i < count; i++) {
//         const a = randItem(this.points)
//         console.log("A", a)
//         const b = this.points[randItem(a.nearest)]
//         const c = this.points[randItem(a.nearest.filter(p => p.ind !== b.ind))]
//         const lines = [[a, b], [b, c], [a, c]]
//         for (let ii = 0; ii < 3; ii++) {
//             const colA = randItem(this.staticLineColors)
//             const colB = randItem(this.staticLineColors)

//             const start = lines[ii][0]
//             const target = lines[ii][1]
//             positions.push(
//                 start.pos.x, start.pos.y, start.pos.z,
//                 target.pos.x, target.pos.y, target.pos.z
//             )
//             colors.push(
//                 colA.r, colA.g, colA.b,
//                 colB.r, colB.g, colB.b
//             )
//         }

//     }

//     const lineBuffer = new THREE.Float32BufferAttribute(positions, 3)
//     const colorBuffer = new THREE.Float32BufferAttribute(colors, 3)
//     geometry.setAttribute('position', lineBuffer);
//     geometry.setAttribute('color', colorBuffer);
//     const line = new THREE.LineSegments(geometry, material);
//     this.group.add(line)
//     this.staticLine = line
//     this.staticLineBuffer = lineBuffer
// }


//   updateLineAnimOld(i, dt) {
//         const line = this.lines[i]

//         line.perc += dt / line.duration
//         if (line.perc > 1) line.perc = 1
//         if (line.perc < 1) return



//         line.perc = 0

//         if (line.aniStage === 0) {
//             line.aniStage = 1
//             this.addFade(line.start, line.duration)
//             this.addFade(line.target, line.duration)
//             return
//         }
//         this.addFade(line.start, line.duration)
//         this.addFade(line.target, line.duration)
//         line.aniStage = 0
//         const r = random()

//         // randomly delete lines
//         if (r < 0.1) {
//             this.lines.pop()
//             return
//         }

//         line.start = line.target

//         let newTarget = randItem(this.points[line.start].nearest)
//         while (newTarget === line.target) newTarget = randItem(this.points[line.start].nearest)
//         line.target = newTarget


//         // randomly add new line 
//         if (this.lines.length < this.lineCount && r > 0.5) {
//             this.addLine(line.start, newTarget)
//             // for ( let i = 0; i < 100; i ++ ) this.addParticle( line.start, newTarget)
//         }
//     }

// initStaticLinesOld() {
//     var geometry = new THREE.BufferGeometry();
//     var material = new THREE.LineBasicMaterial({ vertexColors: true });

//     const count = this.inputs.get("staticLineGroupsCount") !== undefined ? this.inputs.get("staticLineGroupsCount") : this.count / 20

//     const minLineCount = this.inputs.get("minStaticLineCount") !== undefined ? this.inputs.get("minStaticLineCount") : 3
//     const maxLineCount = this.inputs.get("maxStaticLineCount") !== undefined ? this.inputs.get("maxStaticLineCount") : 15
//     // create groups
//     const positions = []
//     const colors = []
//     for (let i = 0; i < count; i++) {
//         // let start = randItem( this.points )
//         // const count = minLineCount + floor( ( maxLineCount - minLineCount ) * random() )
//         // for (let ii = 0; ii < count; ii++) {
        //     const colA = randItem( this.staticLineColors )
        //     const colB = randItem( this.staticLineColors )

        //     const target = this.points[ randItem( start.nearest) ]
        //     positions.push(
        //         start.pos.x, start.pos.y, start.pos.z,
        //         target.pos.x, target.pos.y, target.pos.z
        //     )
        //     colors.push(
        //         colA.r, colA.g, colA.b,
        //         colB.r, colB.g, colB.b
        //     )
        //     let newStart = this.points[randItem(target.nearest)]
        //     while (newStart.ind === start.ind) newStart = this.points[randItem(target.nearest)]
        //     start = newStart
        // }

//         const a = randItem(this.points)
//         console.log("A", a)
//         const b = this.points[randItem(a.nearest)]
//         const c = this.points[randItem(a.nearest.filter(p => p.ind !== b.ind))]
//         const lines = [[a, b], [b, c], [a, c]]
//         for (let ii = 0; ii < 3; ii++) {
//             const colA = randItem(this.staticLineColors)
//             const colB = randItem(this.staticLineColors)

//             const start = lines[ii][0]
//             const target = lines[ii][1]
//             positions.push(
//                 start.pos.x, start.pos.y, start.pos.z,
//                 target.pos.x, target.pos.y, target.pos.z
//             )
//             colors.push(
//                 colA.r, colA.g, colA.b,
//                 colB.r, colB.g, colB.b
//             )
//         }

//     }

//     const lineBuffer = new THREE.Float32BufferAttribute(positions, 3)
//     const colorBuffer = new THREE.Float32BufferAttribute(colors, 3)
//     geometry.setAttribute('position', lineBuffer);
//     geometry.setAttribute('color', colorBuffer);
//     const line = new THREE.LineSegments(geometry, material);
//     this.group.add(line)
//     this.staticLine = line
//     this.staticLineBuffer = lineBuffer
// }
// initStaticLines() {
//     var geometry = new THREE.BufferGeometry();
//     var material = new THREE.LineBasicMaterial({ vertexColors: true });

//     const count = this.inputs.get("staticLineGroupsCount") !== undefined ? this.inputs.get("staticLineGroupsCount") : this.count / 20

//     const minLineCount = this.inputs.get("minStaticLineCount") !== undefined ? this.inputs.get("minStaticLineCount") : 3
//     const maxLineCount = this.inputs.get("maxStaticLineCount") !== undefined ? this.inputs.get("maxStaticLineCount") : 15
//     // create groups
//     const positions = []
//     const colors = []
//     for (let i = 0; i < count; i++) {
//         const a = randItem(this.points)
//         console.log("A", a)
//         const b = this.points[randItem(a.nearest)]
//         const c = this.points[randItem(a.nearest.filter(p => p.ind !== b.ind))]
//         const lines = [[a, b], [b, c], [a, c]]
//         for (let ii = 0; ii < 3; ii++) {
//             const colA = randItem(this.staticLineColors)
//             const colB = randItem(this.staticLineColors)

//             const start = lines[ii][0]
//             const target = lines[ii][1]
//             positions.push(
//                 start.pos.x, start.pos.y, start.pos.z,
//                 target.pos.x, target.pos.y, target.pos.z
//             )
//             colors.push(
//                 colA.r, colA.g, colA.b,
//                 colB.r, colB.g, colB.b
//             )
//         }

//     }

//     const lineBuffer = new THREE.Float32BufferAttribute(positions, 3)
//     const colorBuffer = new THREE.Float32BufferAttribute(colors, 3)
//     geometry.setAttribute('position', lineBuffer);
//     geometry.setAttribute('color', colorBuffer);
//     const line = new THREE.LineSegments(geometry, material);
//     this.group.add(line)
//     this.staticLine = line
//     this.staticLineBuffer = lineBuffer
// }