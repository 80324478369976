import * as THREE from "three";
import VisComp from "@three-extra/VisComp";
import typeManager from "@cloud/TypeManager";

import factoryMat from "@three-extra/asset/MaterialManager";
import factoryGeom from "@three-extra/asset/GeometryManager";

import input from "@input/InputManager";

/*
    // VisComp /////////////////////////////////////////////
	
	this.scene ( SceneSingle )
	- our class to manage to scene

	this.cont3D ( Group )
	- add all your visual stuff to this
	- it already contains the Lights - /if VisComp has any
	
	this.renderer ( RenderComp )
	- our rendering component
	- THREE.Renderer is inside

	this.me ( Me )
	- representation of the user
	- camera is inside

*/

/*
	// Custom parameters:
	count : int
	dimX : float
    geom : Pattern<Geometry>
    mat : Pattern<Material>
*/
class HoSWeb extends VisComp {
  constructor() {
    super();
    this.onResize = this.onResize.bind(this);
  }

  start() {
    super.start();

    this.geom = new THREE.BoxGeometry();
    this.geoms = [
      new THREE.IcosahedronGeometry(),
      new THREE.DodecahedronGeometry(),
      new THREE.TetrahedronGeometry(),
      new THREE.BoxGeometry(),
      new THREE.OctahedronGeometry(),
    ];
    //mat
    this.baseMat = new THREE.MeshPhongMaterial({
      blending: THREE.AdditiveBlending,
    });
    this.generateMat();
    this.inputs.listeners.add("matA", this.generateMat.bind(this));
    this.matWF = new THREE.MeshBasicMaterial({
      wireframe: true,
      blending: THREE.AdditiveBlending,
      color: 0x333333,
    });

    this.mat = new THREE.MeshPhongMaterial();
    this.elems = [];
    this.elemsH = [];
    this.me.camera.position.set(0, 0, 0);
    this.sectionRect = this.renderer.screenDimZ(10);
    this.spaceRect = this.renderer.screenDimZ(10);
    this.rotSection = (30 / 180) * Math.PI;

    const geomStartInd = this.inputs.get("geomStartInd") || 0;
    const texStartInd = this.inputs.get("texStartInd") || 0;
    for (let i = 0; i < 4; i++) {
      let el = {};
      el.obj = new THREE.Mesh(
        this.geoms[(i + geomStartInd) % this.geoms.length],
        this.materials[(i + texStartInd) % this.materials.length]
      );
      el.objWF = new THREE.Mesh(this.geoms[i], this.matWF);
      let alpha = this.rotSection * i + (i > 0 ? (25 / 180) * Math.PI : 0);
      el.obj.position.set(
        Math.sin(alpha) * 10,
        -(i - 0.5) * this.sectionRect.y,
        -Math.cos(alpha) * 10
      );
      el.obj.scale.set(1.5, 1.5, 1.5);
      el.objWF.scale.set(3, 3, 3);
      el.objWF.position.copy(el.obj.position);

      this.cont3D.add(el.obj);
      this.cont3D.add(el.objWF);
      this.elems.push(el);
    }

    var starsGeometry = new THREE.BufferGeometry();
    const position = [];
    var star = new THREE.Vector3();
    for (var i = 0; i < 10000; i++) {
      star.x = THREE.Math.randFloatSpread(200);
      star.y = THREE.Math.randFloatSpread(200);
      star.z = THREE.Math.randFloatSpread(200);

      position.push(star.x, star.y, star.z);
    }
    starsGeometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(position, 3)
    );
    this.matInd = 0;

    this.baseMatStars = new THREE.PointsMaterial({
      size: 0.2,
      blending: THREE.AdditiveBlending,
      color: 0xaaaaaa,
    });
    this.generateMatStars();
    this.inputs.listeners.add("matStars", this.generateMatStars.bind(this));
    this.starField = new THREE.Points(starsGeometry, this.materialsStars[0]);
    this.cont3D.add(this.starField);

    this.dumpOffset = 0;
    this.dumpTarget = 0;
    this.rotPerc = 1;

    /*
        this.geom2 = new THREE.SphereGeometry();
        this.mesh2 = new THREE.Mesh(this.geom2,this.mat);
        this.cont3D.add(this.mesh2);
        this.mesh2.position.copy(this.elems[this.elems.length-1].obj.position);
        this.mesh2.position.y-=4;
        */
    input.listeners.add("onscroll", this.onScroll.bind(this));
    this.renderer.outputs.listeners.add("width", this.onResize);
    this.renderer.outputs.listeners.add("height", this.onResize);
    this.offset = 0;
    this.onResize();
  }

  generateMat() {
    const mat = this.inputs.get("matA");
    this.materials = [];
    for (let i = 0; i < mat.count; i++)
      this.materials.push(
        factoryMat.build({
          base: this.baseMat,
          def: this.defaultMat,
          asset: mat.getNext(),
        })
      );

    //for(let i=0;i<this.cont3D.children.length;i++) this.cont3D.children[i].material = this.materials[i%this.materials.length];
    console.log(this.materials);
  }
  generateMatStars() {
    const mat = this.inputs.get("matStars");
    this.materialsStars = [];
    for (let i = 0; i < mat.count; i++)
      this.materialsStars.push(
        factoryMat.build({
          base: this.baseMatStars,
          def: this.baseMatStars,
          asset: mat.getNext(),
        })
      );

    //for(let i=0;i<this.cont3D.children.length;i++) this.cont3D.children[i].material = this.materials[i%this.materials.length];
    // console.log(this.materials);
  }

  onResize() {
    this.spaceRect = this.renderer.screenDimZ(10);
    var posRel = (this.offset / this.renderer.height) * this.spaceRect.y;
    this.me.camera.position.y = -posRel;
    /*for(let i=0;i<this.elemsH.length;i++){
            let el=this.elemsH[i];
            el.obj.position.set(this.spaceRect.x*0.6*(i/4-0.5),0,0);
            el.obj.scale.set(1,1,1);
        }*/
    this.y0 = this.inputs.get("y0"); //0.05;
    this.rot0 = (this.inputs.get("rot0") / 180) * Math.PI;
    this.rotSection = (this.inputs.get("rotSection") / 180) * Math.PI;

    //Responsive Positions
    this.rotPerc = (this.renderer.width - 768) / (1200 - 768);
    if (this.renderer.width > 1200) this.rotPerc = 1;
    if (this.renderer.width <= 768) this.rotPerc = 0;

    for (let i = 0; i < this.elems.length; i++) {
      let el = this.elems[i];

      let alpha =
        (this.rot0 + this.rotSection * (i + (i > 0 ? 0.5 : 0))) * this.rotPerc;
      el.obj.position.set(
        Math.sin(alpha) * 10,
        -(i + this.y0) * this.sectionRect.y,
        -Math.cos(alpha) * 10
      );

      el.objWF.position.copy(el.obj.position);
    }
  }
  onScroll(e) {
    this.spaceRect = this.renderer.screenDimZ(10);
    var deltaRel = (e.data.delta / this.renderer.height) * this.spaceRect.y;
    var posRel = (e.data.offset / this.renderer.height) * this.spaceRect.y;
    this.offset = e.data.offset;
    //console.log(e);
    //this.me.camera.position.y+=-deltaRel;//e.data.delta*0.01;

    this.dumpTarget = e.data.offset;
  }

  update(dt) {
    this.dumpOffset += (this.dumpTarget - this.dumpOffset) * 0.05;
    this.me.camera.position.y =
      (-this.dumpOffset / this.renderer.height) * this.spaceRect.y;
    this.me.camera.rotation.y =
      (-this.dumpOffset / this.renderer.height) *
      this.rotSection *
      this.rotPerc;

    for (let i = 0; i < this.elems.length; i++) {
      let el = this.elems[i];
      el.obj.rotation.y += dt * ((i % 2) * 2 - 1) * 0.5;
      el.obj.rotation.x += -dt * 0.3456 * 0.5;
      el.objWF.rotation.y -= dt * ((i % 2) * 2 - 1) * 0.2;
      el.objWF.rotation.x -= -dt * 0.3456 * 0.2;
    }
  }

  dispose() {}
}

typeManager.registerClass("5e1c8833f89554001739a4b2", HoSWeb);

export default HoSWeb;
