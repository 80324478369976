import typeManager from "@cloud/TypeManager";
import cloud from "@cloud/VJYCloudClient";
import objMan from "@cloud/ObjectManager";
import Listeners from "@rt/Listeners";
import input from "@input/InputManager";
import parseMidiMessage from "@input/MIDITools";
import MIDIRouter from "@audio/MIDIRouter";

import "@audio/Synth/MicroKORG";
import "@audio/Synth/NordLead4";
import "@audio/Synth/KorgRadias";
import "@audio/Synth/KorgMS2000";
import "@audio/Synth/NordModularG2";
import "@audio/Synth/VirusTi";
import "@audio/Synth/RolandJP8080";

class MusicSetup {
  constructor() {
    this.listeners = new Listeners();
    console.log("MS constructor");
    this.ready = false;
    this.presetsReady = false;
    this.instrumentsReady = false;
  }

  start() {
    console.log("MS start");
    //GET MUSIC SETUP
    this.docMusicSetup = cloud.getDoc({
      t: typeManager.typeNameToId("MusicSetup"),
    });
    console.log("MS", this.docMusicSetup);
    this.params = this.docMusicSetup.d;

    //Presets
    this.myName = this.docMusicSetup.m.n;
    cloud
      .find({ t: "MusicSetup>" + this.myName })
      // .find("5eb06e709707fd00174caddf")
      .then(this.buildPresets.bind(this));
    this.preset = {
      routerInd: 0,
      prgInds: [0, 0, 0],
    };

    //Get MIDI Access
    if (input._midi.enabled) {
      if (input._midi.midiAccess != null) this.connectMIDI();
      else input.listeners.add("midi:access", this.connectMIDI.bind(this));
    } else {
      console.log("MS > No MIDI");
      this.devices = [];
      this.ready = true;
      this.listeners.fire("Ready");
    }
  }

  // MIDI CONNECTED ////////////////////////////////////////////////////////////
  connectMIDI() {
    console.log("connect midi");
    this.initDevices();
    this.initRouter();
    //this.setClockRouterInd(0);
    this.instrumentsReady = true;
    if (!this.ready && this.instrumentsReady && this.presetsReady) {
      this.ready = true;
      this.listeners.fire("Ready");
    }
  }

  /************************************************************
   * PRESET MANAGEMENT
   ****************************************************************/
  buildPresets(ev) {
    console.log("Build Presets", ev);
    this.presetList = [{ name: "- none -", value: -1 }];
    this.presets = ev.docs;
    this.presetInd = -1;
    for (let i = 0; i < ev.docs.length; i++) {
      this.presetList.push({ value: i, name: ev.docs[i].m.n });
    }
    this.presetsReady = true;
    if (!this.ready && this.instrumentsReady && this.presetsReady) {
      this.ready = true;
      this.listeners.fire("Ready");
    }
  }
  getPreset() {
    const ret = {};
    //Devices
    ret.prgs = [];
    for (let i = 0; i < this.devices.length; i++) {
      if (this.devices[i].obj.hasPrograms) {
        ret.prgs.push({
          bank: this.devices[i].obj.ind.bank,
          prg: this.devices[i].obj.ind.prg,

          prgInd: this.devices[i].obj.ind,
          prgCode: this.devices[i].obj.indToCode(this.devices[i].obj.ind),
          prgName: this.devices[i].obj.getPatch(this.devices[i].obj.ind).name,
          //prgName:this.devices[i].obj
          deviceId: this.devices[i].doc._id,
          deviceName: this.devices[i].doc.m.n,
        });
      }
    }
    //Router
    if (this.router.enabled) ret.router = this.router.getPreset();
    else if (this.presets[this.presetInd] != null)
      ret.router = this.presets[this.presetInd].d.router;
    return ret;
  }
  updatePreset() {
    console.log("Update Preset");
    const prs = this.getPreset();
    console.log("preset:", prs);
    this.presets[this.presetInd].d = prs;
    cloud.update(this.presets[this.presetInd]);
  }
  insertPreset(name) {
    const doc = {
      t: "MusicSetup>" + this.myName,
      m: { n: name },
      d: this.getPreset(),
    };
    console.log("INSERT", doc);
    cloud.insert(doc);
  }
  setPresetInd(ind) {
    this.presetInd = ind;
    this.preset = this.presets[this.presetInd].d;

    for (let i = 0; i < this.preset.prgs.length; i++) {
      if (this.preset.prgs[i].deviceId != null) {
        let devInd = -1;
        for (let ii = 0; ii < this.devices.length; ii++)
          if (this.devices[ii].doc._id == this.preset.prgs[i].deviceId)
            devInd = ii;
        if (devInd >= 0)
          this.devices[devInd].obj.setPrg(this.preset.prgs[i].prgInd);
      } else {
        // Old format
        this.devices[i].obj.setPrg(this.preset.prgs[i]);
      }
    }
    if (this.preset.router != null) this.router.setPreset(this.preset.router);
    //this.setRouterInd(this.preset.routerInd);
    //this.setClockRouterInd(this.preset.clockRouterInd);
  }

  /**************************************************************
   * ROUTER
   **************************************************************/
  initRouter() {
    if (this.router) this.router.dispose();
    this.router = new MIDIRouter(this.docMusicSetup.d.router);
  }

  /*****************************************************************
   * MIDI Devices
   ****************************************************************/
  initDevices() {
    //Synths
    const synthDocs = this.docMusicSetup.d.devices;

    this.devices = [];
    console.log(synthDocs);
    for (let i = 0; i < synthDocs.length; i++) {
      let se = { doc: cloud.getDoc(synthDocs[i]) };
      if (!se.doc) continue;

      se.name = se.doc.m.n;
      se.type = "synth";
      if (se.doc.m.tags != null && se.doc.m.tags.length > 0)
        se.type = se.doc.m.tags[0];

      se.obj = objMan.deserialize(synthDocs[i]);

      this.devices.push(se);
    }
  }
  getInstrumentsByType(type) {
    let list = [];
    console.log("getInstrumentsByType", this.devices);
    if (this.router != null && this.router.enabled) {
      for (let i = 0; i < this.devices.length; i++)
        if (this.devices[i].doc._id == this.router.sourceDoc._id)
          return [this.devices[i].obj];
    } else {
      for (let i = 0; i < this.devices.length; i++)
        if (type == "" || this.devices[i].type == type)
          list.push(this.devices[i].obj);
    }
    return list;
  }
  getInstrumentById(id) {
    for (let i = 0; i < this.devices.length; i++)
      if (this.devices[i].doc._id == id) return this.devices[i].obj;
    return null;
  }

  update(dt) {
    for (let i = 0; i < this.devices.length; i++)
      if (this.devices[i].obj.update) this.devices[i].obj.update(dt);
  }

  dispose() {}
}

typeManager.registerClass("MusicSetup", MusicSetup);

export default MusicSetup;
