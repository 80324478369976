export function fragShader(  code = null, functions = null ) {
    return `
    float scaleLinear(float value, vec2 valueDomain) {
         return (value - valueDomain.x) / (valueDomain.y - valueDomain.x);
    }
    float scaleLinear(float value, vec2 valueDomain, vec2 valueRange) {
         return mix(valueRange.x, valueRange.y, scaleLinear(value, valueDomain));
    }
    varying vec4 vColor;
    varying vec3 pos;
    varying float lifeLeft;
    uniform sampler2D tSprite;
    varying float particleInd;
    varying float size;
    varying float blur;
    uniform sampler2D tNoise;
    uniform sampler2D tBlur;
    ${ functions  ?  functions : ""}
    void main() {
 
        vec4 tex = texture2D( tSprite, gl_PointCoord );
        vec4 texNoise = texture2D( tNoise, gl_PointCoord );
        vec4 texBlur = texture2D( tBlur, gl_PointCoord );
        float alpha = 0.5 ; 
        vec3 col = vColor.rgb;
        ${ code ? code : ""}

      
        gl_FragColor = vec4( col*tex.a, alpha * tex.a );

       //  gl_FragColor = vec4( 0., 1., 1. , 1. );
    }
`
}