import * as THREE from "three";
import VisComp from "@three-extra/VisComp";
import typeManager from "@cloud/TypeManager";

import gm from "@three-extra/asset/GeometryManager";
import typeMan from "@cloud/TypeManager";
import materialManager from "@three-extra/asset/MaterialManager";
import shaderUtils from "@three-extra/util/ShaderUtils";
import cloud from "@cloud/VJYCloudClient";
import objMan from "@cloud/ObjectManager";

import factoryMat from "@three-extra/asset/MaterialManager";
import factoryGeom from "@three-extra/asset/GeometryManager";
const { typeNameToId } = typeMan;
class GeometryPreview extends VisComp {
  constructor() {
    super();
    this.update = this.update.bind(this);

    window.p = this;
  }

  start(doc, canvas) {
    super.start();

    const asset = this.inputs.get("_asset"); // { '>link': { id: this.doc._id } };
    this[">link"] = { id: asset._id };
    this.doc = cloud.getDoc(asset);

    this.generateGeom();
    this.generateMatA();
    this.material = this.mesh = new THREE.Mesh(
      this.geometry,
      this.materials[0]
    );
    this.cont3D.add(this.mesh);

    for (let key in this.doc.d) {
      this.inputs.listeners.add(key, this.generateGeom);
    }

    console.log("PREVIEW STARTED", this);

    this.matIndex = 0;
    document.addEventListener("keydown", (e) => {
      if (e.code === "Space") {
        this.matIndex++;
        this.matIndex = this.matIndex % this.materials.length;
        this.mesh.material = this.materials[this.matIndex];
      }
    });

    this.update(0);
  }
  generateGeom = (e) => {
    console.log("gen ", e, this.doc.d);
    if (e) {
      this.doc.d[e.type] = e.data;
    }
    if (this.geometry) this.geometry.dispose();
    const geomA = this.inputs.get("_asset");
    this.geometry = factoryGeom.build(geomA, { geom: this._defGeom });
    if (this.mesh) this.mesh.geometry = this.geometry;
  };

  generateMatA() {
    const matA = this.inputs.get("materials");
    this.materials = [
      new THREE.MeshNormalMaterial(),
      new THREE.MeshNormalMaterial({ wireframe: true }),
    ];
    if (matA == null) this.materials.push(this._defMat);
    else
      for (let i = 0; i < matA.count; i++) {
        this.materials.push(
          factoryMat.build({
            base: new THREE.MeshBasicMaterial(),
            def: this._defMat,
            asset: matA.getNext(),
          })
        );
      }
    this.materials.forEach((m) => {
      m.side = THREE.DoubleSide;
    });
  }

  //called every frame, dt: time passed between frames
  update(dt) {
    super.update(dt);

    if (!dt) return;

    this.time += dt;

    //	this.mesh.rotation.y += dt * 0.1
  }

  dispose() {
    this.material.dispose();
    if (this.geometry) this.geometry.dispose();
    for (let s in this.steps) if (s.geom) s.geom.dispose();
    this.cont3D.remove(this.mesh);
  }
}

// DO NOT DELETE THIS SHIT

typeManager.registerClass(typeNameToId("Preview.Geometry"), GeometryPreview);

export default GeometryPreview;
