import typeManager from "@cloud/TypeManager";
import * as THREE from "three";
import AbstractField from "./AbstractField";

import ParticleSystem from "@three-extra/particles/basicOld/particleClass";
import cloud from "@cloud/VJYCloudClient";
import factoryMat from "@three-extra/asset/MaterialManager";

// resources:
// https://www.khanacademy.org/math/multivariable-calculus/thinking-about-multivariable-function/ways-to-represent-multivariable-functions/a/vector-fields
const { floor, abs, sin, cos, round, random } = Math;
class ParticleField extends AbstractField {
  constructor() {
    super();
    this.dispose = this.dispose.bind(this);

    // document.addEventListener("click", _=> console.log( this ))
  }
  start() {
    super.start();

    this.inputs.listeners.add("fragmentShader", this.regenerateObj.bind(this));
    this.inputs.listeners.add("vertexShader", this.regenerateObj.bind(this));
    this.inputs.listeners.add("particleOptions", this.regenerateObj.bind(this));
  }

  regenerateObj() {
    this.particleSystem.particleShaderMat.dispose();
    this.particleSystem.particleNoiseTex &&
      this.particleSystem.particleNoiseTex.dispose();
    this.particleSystem.particleSpriteTex &&
      this.particleSystem.particleSpriteTex.dispose();
    this.particleSystem.particleBlurTex &&
      this.particleSystem.particleBlurTex.dispose();
    for (let child of this.particleSystem.children) {
      for (let child2 of child.children) child.particleShaderGeo.dispose();
    }
    this.cont3D.remove(this.particleSystem);
    //   delete this.field
    this.vShader = null;
    this.fShader = null;
    this.uniforms = null;
    this.generateUniforms();
    this.generateShaders();
    this.generateGeom();
    this.generateObj();
    this.assignColors();
  }
  assignColors() {
    const sec = this.inputs.get("secondaryColors");
    const secColors = [];
    if (sec) for (let el of sec.elems) secColors.push(new THREE.Color(el));

    let mode = this.inputs.get("colorDistributionMode") || 0;
    const count = this.inputs.get("count");
    if (!count.z && count.x && count.y) {
      count.z = count.y;
      count.z = 0;
    }

    for (
      let i = 0;
      i <
      this.particleSystem.children[0].children[0].geometry.attributes.position
        .count;
      i++
    ) {
      let col;
      if (mode === 0) col = this.colors[i % this.colors.length];
      if (mode === 1) col = this.colors[floor(random() * this.colors.length)];
      if (mode === 2) col = this.colors[(i % count.x) % this.colors.length];
      if (mode === 3)
        col = this.colors[floor(i / count.x) % this.colors.length];

      if (Math.random() < this.inputs.get("colorRandomness") || 0)
        col = this.colors[floor(random() * this.colors.length)];

      var rgb = this.particleSystem.children[0].hexToRgb(col.getHex());
      let c = this.particleSystem.children[0].decodeFloat(
        rgb[0],
        rgb[1],
        rgb[2],
        254
      );
      this.particleSystem.children[0].velCol.array[i * 4 + 1] = c;
      this.particleSystem.children[0].indexSizeBlur.array[i * 3 + 2] =
        1 - floor(i / count.x) / count.x;
    }

    this.particleSystem.children[0].velCol.needsUpdate = true;
    if (!sec) return;

    const secCount = floor(count.x * count.z * 0.03);

    for (let i = 0; i < floor(count.x * 0.2); i++) {
      const length = count.z;
      let startInd = (i * floor(random() * 8)) % count.x;

      for (let ii = 0; ii < length; ii++) {
        const ind = (startInd + count.x * ii) % (count.x * count.z - 1);

        let col = secColors[floor(random() * secColors.length)];
        if (Math.random() > 0.75)
          col = secColors[floor(random() * secColors.length)];
        var rgb = this.particleSystem.children[0].hexToRgb(col.getHex());
        let c = this.particleSystem.children[0].decodeFloat(
          rgb[0],
          rgb[1],
          rgb[2],
          254
        );
        this.particleSystem.children[0].velCol.array[ind * 4 + 1] = c;
        this.particleSystem.children[0].indexSizeBlur.array[ind * 3 + 1] = 1.1;
      }
    }
  }

  generateUniforms() {
    const particleOptions = this.inputs.get("particleOptions");
    this.uniforms = {
      uScale: {
        value: particleOptions ? particleOptions.size : 10,
        type: "float",
      },
      uBlur: {
        value:
          this.inputs.get("blurScale") !== undefined
            ? this.inputs.get("blurScale")
            : 0,
        type: "float",
      },
      blurScale: {
        value:
          this.inputs.get("blurScale") !== undefined
            ? this.inputs.get("blurScale")
            : 0,
        type: "float",
      },
    };
    super.generateUniforms();
  }

  generateObj() {
    let count = { ...this.inputs.get("count") };
    console.log("GEN OBJ COUNT", count);
    if (!count.z && count.x && count.y) {
      console.log("swap z and y", count.z, count.y);
      count.z = count.y;
      count.y = 0;
    }
    const particleCount = count.x * count.z;
    const dim = { ...this.inputs.get("dim") };
    if (!dim.z && dim.x && dim.y) {
      dim.z = dim.y;
      dim.y = 0;
    }

    const particleOptions = this.inputs.get("particleOptions");
    this.options = {
      position: new THREE.Vector3(0, 0, 0),
      positionRandomness: 0,
      velocity: new THREE.Vector3(0, 0, 0),
      velocityRandomness: 0,
      color: 0x00ffff,
      colorRandomness: 0,
      turbulence: 0,
      lifetime: 1,
      size: 10,
      sizeRandomness: 0,
      smoothPosition: false,
      infiniteLife: true,
      isMoving: false,
      maxParticles: particleCount,
      vertShader: this.vShader,
      fragShader: this.fShader,
      // depthWrite: true,
      uniforms: this.uniforms,
      particleSpriteTex: false,
    };
    if (particleOptions)
      for (let key in particleOptions) this.options[key] = particleOptions[key];
    this.options.particleSpriteTex = particleOptions
      ? cloud.getDoc(particleOptions.particleSpriteTex).d
      : false;
    this.options.map = factoryMat.assetToTexture(
      cloud.getDoc(particleOptions.particleSpriteTex)
    );

    this.particleSystem = new ParticleSystem(this.options);
    const { particleSystem } = this;

    this.cont3D.add(this.particleSystem);

    const countX = count.x;
    const countZ = count.z;

    for (var i = 0; i < particleCount; i++) {
      const x = i % countX;
      const z = floor(i / countX);

      this.options.position.x = -dim.x / 2 + (x * dim.x) / countX;
      this.options.position.z = -dim.z / 2 + (z * dim.z) / countZ;

      this.options.blur = (1 - z / countZ) ** 4;

      if (random() > 0.6) this.options.blur += (Math.random() - 0.5) * 0.1;
      if (this.options.blur < 0) this.options.blur = 0;

      particleSystem.spawnParticle(this.options);
    }
  }

  update(dt) {
    super.update(dt);

    const particleOptions = this.inputs.get("particleOptions");

    this.uniforms.uScale.value = particleOptions ? particleOptions.size : 10;
    this.uniforms.uBlur.value =
      this.inputs.get("blurScale") !== undefined
        ? this.inputs.get("blurScale")
        : 0;
    this.uniforms.blurScale.value =
      this.inputs.get("blurScale") !== undefined
        ? this.inputs.get("blurScale")
        : 0;
    this.particleSystem.update(this.time);
  }
}
// typeManager.registerClass("5f0523b09913260017a9ec50", ParticleField);
export default ParticleField;
