import * as THREE from "three";
import Component from "@rt/Component";
import typeManager from "@cloud/TypeManager";
import objectManager from "@cloud/ObjectManager";
import factoryMat from "@three-extra/asset/MaterialManager";
import factoryGeom from "@three-extra/asset/GeometryManager";
import cloud from "@cloud/VJYCloudClient";
import musicMeta from "@audio/MusicMeta";

import cloneDeep from "lodash/cloneDeep";
import CanvasTexture from "./CanvasTexture";

const { Color, MathUtils } = THREE;
function regularpolygon(ctx, x, y, radius, sides, offset) {
  if (sides < 3) return;
  ctx.beginPath();
  var a = (Math.PI * 2) / sides;
  ctx.translate(x, y);

  ctx.moveTo(radius, 0);
  for (var i = 1; i < sides; i++) {
    ctx.lineTo(
      radius * Math.cos(a * i + offset),
      radius * Math.sin(a * i + offset)
    );
  }
  ctx.closePath();
  ctx.fill();

  ctx.translate(-x, -y);
}

class LinearSpline {
  constructor(lerp) {
    this._points = [];
    this._lerp = lerp;
  }

  AddPoint(t, d) {
    this._points.push([t, d]);
  }

  Get(t) {
    let p1 = 0;

    for (let i = 0; i < this._points.length; i++) {
      if (this._points[i][0] >= t) {
        break;
      }
      p1 = i;
    }

    const p2 = Math.min(this._points.length - 1, p1 + 1);

    if (p1 == p2) {
      return this._points[p1][1];
    }

    return this._lerp(
      (t - this._points[p1][0]) / (this._points[p2][0] - this._points[p1][0]),
      this._points[p1][1],
      this._points[p2][1]
    );
  }
}

class DrawMidi extends CanvasTexture {
  start() {
    super.start();
    this.col = new Color();
    window.dm = this;

    const { ctx } = this;

    try {
      this.controller = musicMeta.setup.getInstrumentsByType("synth")[0];
      if (!this.controller) throw new Error();
    } catch (error) {
      console.warn("No synth found");

      throw error;
    }
    console.log("draw midi", this);

    this.x = 0; // this.inputs.get("width") / 2;
    this.y = 0; //this.inputs.get("height") / 2;
    ctx.clearRect(0, 0, this.inputs.get("width"), this.inputs.get("height"));

    ctx.fillStyle = "#000000";
    ctx.clearRect(0, 0, this.inputs.get("width"), this.inputs.get("height"));
    ctx.fillRect(0, 0, this.inputs.get("width"), this.inputs.get("height"));

    ctx.translate(this.inputs.get("width") / 2, this.inputs.get("height") / 2);

    this.time = 0;

    this.channels = this.controller.channels.map((channel) => {
      return { curr: {}, prev: {} };
    });
    // this.copyCurrValues();
    // this.copyPrevValues();
    this.lastHighestNoteIndex = 0;
    // this.drawPosMode = "random";

    this._colourSpline = new LinearSpline((t, a, b) => {
      const c = a.clone();
      return c.lerp(b, t);
    });
    const colors = this.inputs.getObject("colors");
    console.log("colors", colors);
    for (let i = 0; i < colors.length; i++) {
      this._colourSpline.AddPoint(i / colors.length, colors[i]);
    }

    this.drawPosMode = this.inputs.getObject("drawingMode");
    this.doSymmetry = this.inputs.getObject("doSymmetry");

    this.op = this.inputs.getObject("globalCompositeOperation");
    ctx.globalCompositeOperation = this.op;
  }
  copyCurrValues() {
    for (let i = 0; i < this.channels.length; i++) {
      const thisChannel = this.channels[i];
      const musicChannel = this.controller.channels[i];
      for (let key in musicChannel) {
        thisChannel.curr[key] = musicChannel[key];
      }
    }
  }
  copyPrevValues() {
    for (let i = 0; i < this.channels.length; i++) {
      const thisChannel = this.channels[i];
      const musicChannel = this.controller.channels[i];
      for (let key in musicChannel) {
        thisChannel.prev[key] = musicChannel[key];
      }
    }
  }

  update(dt) {
    // this.copyCurrValues();
    const { ctx } = this;

    const op = this.inputs.getObject("globalCompositeOperation");
    if (op !== this.op) {
      this.op = op;
      ctx.globalCompositeOperation = this.op;
    }

    // ctx.globalCompositeOperation = "xor";
    this.time += dt;

    if (this.drawPos === "random") {
      this.x = MathUtils.randFloatSpread(this.inputs.get("width")) / 2;
      this.y = MathUtils.randFloatSpread(this.inputs.get("width")) / 2;
    }
    if (this.drawPosMode === "dir") {
    }

    // circle, default
    const r = (this.time * 20) % (this.inputs.get("width") / 2);
    this.x = Math.sin(this.time) * r;
    this.y = Math.cos(this.time) * r;

    // square
    if (this.drawPosMode === "Square") {
      const w = this.inputs.get("width") / 10;
      const h = this.inputs.get("height") / 10;
      const l = w * h;

      const index = Math.floor((this.time * 1) / 0.016) % l;

      this.y = (index % h) - h / 2;
      this.x = index / w - w / 2;

      this.y *= 10;
      this.x *= 10;
    }

    const notes = [];
    let highestNoteIndex = 0;
    for (let i = 0; i < this.controller.channels.length; i++) {
      const channel = this.controller.channels[i];
      if (channel.fired) {
        // console.log(1);
        notes.push({ i, val: channel.val });
        highestNoteIndex = i;
      }
    }

    let { x, y } = this;
    // console.log(x, y);
    if (notes.length) {
      //   ctx.fillStyle = "#ffffff";
      //   ctx.fillRect(
      //     -this.inputs.get("width") / 2,
      //     -this.inputs.get("height") / 2,
      //     this.inputs.get("width"),
      //     this.inputs.get("height")
      //   );
    }
    if (notes.length < 3) {
      for (let ii = 0; ii < notes.length; ii++) {
        const col = this._colourSpline.Get((notes[ii].i % 12) / 12);
        ctx.globalAlpha = notes[ii].val * 3;
        ctx.fillStyle = "#" + col.getHexString();

        ctx.beginPath();
        const radius = notes[ii].val * 10 + 10;
        const offsetX = MathUtils.randFloatSpread(radius + 30);
        const offsetY = MathUtils.randFloatSpread(radius + 30);
        ctx.arc(x + offsetX, y + offsetY, radius, 0, 2 * Math.PI, false);
        ctx.fill();
      }
    } else {
      const sumVal = notes.reduce((acc, curr) => acc + curr.val, 0);
      ctx.globalAlpha = (sumVal / notes.length) * 3;
      this.col.setRGB(sumVal / notes.length, 0, 1 - sumVal / notes.length);
      const col = this._colourSpline.Get((notes[0].i % 12) / 12);
      ctx.fillStyle = "#" + col.getHexString();
      const radius = sumVal * notes.length * 10 + 10;

      regularpolygon(
        this.ctx,
        x,
        y,
        radius,
        notes.length,
        Math.random() * Math.PI
      );
    }

    // draw symettry
    if (this.doSymmetry) {
      x = -x;
      y = -y;

      if (notes.length < 3) {
        for (let ii = 0; ii < notes.length; ii++) {
          const col = this._colourSpline.Get((notes[ii].i % 12) / 12);
          ctx.globalAlpha = notes[ii].val * 3;
          ctx.fillStyle = "#" + col.getHexString();

          ctx.beginPath();
          const radius = notes[ii].val * 10 + 10;
          const offsetX = MathUtils.randFloatSpread(radius + 30);
          const offsetY = MathUtils.randFloatSpread(radius + 30);
          ctx.arc(x + offsetX, y + offsetY, radius, 0, 2 * Math.PI, false);
          ctx.fill();
        }
      } else {
        const sumVal = notes.reduce((acc, curr) => acc + curr.val, 0);
        ctx.globalAlpha = (sumVal / notes.length) * 3;
        this.col.setRGB(sumVal / notes.length, 0, 1 - sumVal / notes.length);
        const col = this._colourSpline.Get((notes[0].i % 12) / 12);
        ctx.fillStyle = "#" + col.getHexString();
        const radius = sumVal * notes.length * 10 + 10;

        regularpolygon(
          this.ctx,
          x,
          y,
          radius,
          notes.length,
          Math.random() * Math.PI
        );
      }
    }

    // ctx.fillStyle = "#ff0000";
    // ctx.beginPath();
    // const radius = 10;

    // ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
    // ctx.fill();

    // save values of previous frame
    // this.copyPrevValues();
  }

  dispose() {}
}

typeManager.registerClass("64da1c182c1fbfcc99e2078f", DrawMidi);
export default DrawMidi;
