export default {
  uniforms: {
    uTime: {
      type: "float",
      value: 0,
    },
  },
  attributes: {
    offset: {
      type: "float",
    },
  },
  vertex: {
    code: `
            gl_PointSize *= abs( sin(uTime +offset ));
        `,
  },
};
