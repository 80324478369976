import * as THREE from "three";
import merge from "lodash/merge";
import objMan from "@cloud/ObjectManager";
import cloud from "@cloud/VJYCloudClient";
import extAssetCache from "@three-extra/asset/ExtAssetCache";
import Component from "@rt/Component";
import EffectRenderer from "./EffectRenderer";
import typeMan from "@cloud/TypeManager";
import factoryMat from "@three-extra/asset/MaterialManager";
const { typeNameToId } = typeMan;
class RenderComp extends Component {
  constructor() {
    super();
    this.props = {
      clearColor: 0x000000,
      camera: {
        type: "Perspective",
      },
    };
    this.outputs.set("width", 100);
    this.outputs.set("height", 100);
    this.backgroundEnabled = true;
    this.backgroundDecl = null;
  }

  enableLegacyRendering(doIt = true) {
    if (!doIt) {
      if (this.legacyRendering === false) return;
      this.renderer = new THREE.WebGLRenderer({
        preserveDrawingBuffer: false,
        canvas: this.canvas,
        antialias: true,
        // alpha,
      });
      this.renderer.useLegacyLights = false;
      this.renderer.outputColorSpace = THREE.SRGBColorSpace;
      THREE.ColorManagement.enabled = true;
      this.legacyRendering = false;
      return;
    }
    if (this.legacyRendering) return;
    this.renderer = new THREE.WebGLRenderer({
      preserveDrawingBuffer: false,
      canvas: this.canvas,
      antialias: true,
      // alpha,
    });
    this.renderer.useLegacyLights = true;
    this.renderer.outputColorSpace = THREE.NoColorSpace;
    THREE.ColorManagement.enabled = false;
    this.legacyRendering = true;
  }
  start(props) {
    merge(this.props, props);

    let antialias, alpha;
    if (props && props.settings && props.settings.renderer) {
      antialias = props.settings.renderer.antialias;
      alpha = props.settings.renderer.alpha;
    }

    this.renderer = new THREE.WebGLRenderer({
      preserveDrawingBuffer: false,
      canvas: this.canvas,
      antialias: true,
      alpha,
    });

    this.renderer.setPixelRatio(window.devicePixelRatio || 1);
    if (window.innerWidth > 600) this.renderer.setPixelRatio(1);
    if (props && props.settings && props.settings.renderer) {
      this.renderer.physicallyCorrectLights =
        this.props.physicallyCorrectLights || false;
      if (props.settings.renderer.outputEncoding)
        // this.renderer.outputEncoding = [
        //   THREE.LinearEncoding,
        //   THREE.sRGBEncoding,
        //   THREE.GammaEncoding,
        //   THREE.RGBEEncoding,
        //   THREE.LogLuvEncoding,
        //   THREE.RGBM7Encoding,
        //   THREE.RGBM16Encoding,
        //   THREE.RGBDEncoding,
        //   THREE.BasicDepthPacking,
        //   THREE.RGBADepthPacking,
        // ][props.settings.renderer.outputEncoding];
        this.renderer.outputColorSpace = [
          THREE.LinearSRGBColorSpace,
          THREE.SRGBColorSpace,
          THREE.GammaEncoding,
          THREE.RGBEEncoding,
          THREE.LogLuvEncoding,
          THREE.RGBM7Encoding,
          THREE.RGBM16Encoding,
          THREE.RGBDEncoding,
          THREE.BasicDepthPacking,
          THREE.RGBADepthPacking,
        ][props.settings.renderer.outputEncoding];
      if (props.settings.renderer.toneMapping)
        this.renderer.toneMapping = [
          THREE.NoToneMapping,
          THREE.LinearToneMapping,
          THREE.ReinhardToneMapping,
          THREE.CineonToneMapping,
          THREE.ACESFilmicToneMapping,
        ][props.settings.renderer.toneMapping];
      if (props.settings.renderer.toneMappingExposure)
        this.renderer.toneMappingExposure =
          props.settings.renderer.toneMappingExposure;
      if (props.settings.renderer.shadowMapEnabled)
        this.renderer.shadowMap.enabled = true;
      if (props.settings.renderer.shadowMapType)
        this.renderer.shadowMap.type = [
          THREE.BasicShadowMap,
          THREE.PCFShadowMap,
          THREE.PCFSoftShadowMap,
          THREE.VSMShadowMap,
        ][props.settings.renderer.shadowMapType];
      if (props.settings.renderer.gammaFactor)
        this.renderer.gammaFactor = props.settings.renderer.gammaFactor;
      if (props.settings.renderer.gammaOutput)
        this.renderer.gammaOutput = props.settings.renderer.gammaOutput;
    }

    switch (this.props.camera.type) {
      case "Perspective":
        this.camera = new THREE.PerspectiveCamera(60, 1, 0.1, 2000);
        break;
      case "Orthographic":
      default:
        this.camera = new THREE.OrthographicCamera(-50, 50, 50, -50, 1, 1000);
        break;
    }
    this.scene = new THREE.Scene();
    this.scene.name = "Root Scene";
    this.setProps(this.props);
  }

  setProps(p) {
    if (p.clearColor) {
      this.renderer.setClearColor(new THREE.Color(this.props.clearColor), 1);
    }
    // console.log("RenderComp > SETPROPS", p);

    if (p.background) {
      this.backgroundDecl = p.background;
      if (this.backgroundEnabled) {
        //console.log("RenderComp > BACKGROUND decl",p.background);
        let asset = objMan.deserialize(p.background);

        //Clear Up Shader background
        if (this.bgMesh !== undefined) {
          if (this.bgMesh.material) this.bgMesh.material.dispose();
          if (this.bgMesh.geometry) this.bgMesh.geometry.dispose();
          if (this.bgMesh.parent) this.bgMesh.parent.remove(this.bgMesh);
        }

        //Pattern or Single elem
        if (asset.getNext) asset = asset.getNext();
        if (typeof asset === "string") {
          this.scene.background = new THREE.Color(asset);
          return;
        }

        const { t, m } = cloud.getDoc(asset);

        // Texture2D /////////////////////////////////////////////////////////
        if (
          t === typeNameToId("Texture2D") ||
          t === typeNameToId("HDRTexture")
        ) {
          const res = extAssetCache.get(asset);
          if (t === typeNameToId("HDRTexture")) {
            res.texture.mapping = THREE.EquirectangularReflectionMapping;
          }
          //Equirectangular Skybox
          if (m.tags && m.tags.length > 0 && m.tags[0] == "equirectangular") {
            let rt = new THREE.WebGLRenderTargetCube(512, 512);

            let texCube = rt.fromEquirectangularTexture(
              this.renderer,
              res.texture
            );
            this.scene.background = texCube;
            //Normal 2D texture
          } else {
            this.scene.background = res.texture;
          }
        }

        // Skybox ////////////////////////////////////////////////////////////
        if (t === typeNameToId("Skybox")) {
          let order = ["left", "right", "top", "bottom", "front", "back"];
          let images = [];

          for (let i = 0; i < 6; i++)
            images[i] = extAssetCache.get(asset[order[i]]).texture.image;
          let tex = new THREE.CubeTexture(images);
          tex.colorSpace = THREE.SRGBColorSpace;
          tex.needsUpdate = true;
          this.scene.background = tex;
        }

        // Shader //////////////////////////////////////////////////////////////
        if (typeMan.isCompatible(t, typeNameToId("Shader.ProceduralTexture"))) {
          //console.log('SHADER BACKGROUND')
          const mat = factoryMat.build({
            base: new THREE.MeshBasicMaterial(),
            def: new THREE.MeshBasicMaterial(),
            asset: p.background,
          });
          mat.side = THREE.DoubleSide;
          mat.depthWrite = false;
          const z = this.camera.far - 10;
          const dim = this.screenDimZ(z);
          const g = new THREE.PlaneGeometry();
          const mesh = new THREE.Mesh(g, mat);
          mesh.scale.set(dim.x, dim.y, 1);
          mesh.material.side = THREE.DoubleSide;
          //	mesh.rotation.x = Math.PI / 2
          mesh.position.set(0, 0, -z);
          mesh.renderOrder = -Infinity;
          this.scene.add(this.camera);
          this.camera.add(mesh);
          this.bgMesh = mesh;
        }
      }
      // Setting no background 'null'
    } else if ("background" in p || p.background === null) {
      // Clearing previous Shader background
      if (this.bgMesh !== undefined) {
        if (this.bgMesh.material) this.bgMesh.material.dispose();
        if (this.bgMesh.geometry) this.bgMesh.geometry.dispose();
        if (this.bgMesh.parent) this.bgMesh.parent.remove(this.bgMesh);
      }

      this.backgroundDecl = p.background;
      this.scene.background = null;
    }

    // FOG //////////////////////////////////////////////////////////////////
    if (p.fog && p.fog.enabled) {
      if (!this.scene.fog) {
        this.scene.fog = new THREE.Fog(p.fog.color, p.fog.near, p.fog.far);
      } else {
        this.scene.fog.color.set(p.fog.color);
        this.scene.fog.near = p.fog.near;
        this.scene.fog.far = p.fog.far;
      }
    } else if ("fog" in p) {
      this.scene.fog = null;
    }

    // EFFECT /////////////////////////////////////////////////////////////////
    if (p.effect !== undefined) {
      if (this.effectRenderer) {
        // restore renderer's render target and delete effect renderer
        this.effectRenderer.detach();
        this.effectRenderer = null;
      }
      if (p.effect) {
        this.props.effect = objMan.deserialize(p.effect, {
          level: 1,
          type: typeNameToId("Effect"),
        });

        this.effectRenderer = new EffectRenderer(
          this.renderer,
          this.scene,
          this.camera,
          { width: this.width, height: this.height }
        );
        this.effectRenderer.setParams(this.props.effect);
        if (
          this.props.effect.bufferClear !== undefined &&
          !this.props.effect.bufferClear
        ) {
          this.disableBackground();
        } else this.enableBackground();
      } else {
        this.enableBackground();
      }
      //console.log(">>>> EFF",p.effect,this.effectRenderer);
    }
  }
  disableBackground() {
    if (this.backgroundEnabled) {
      let tempbg = this.backgroundDecl;
      this.setProps({ background: null });
      this.backgroundDecl = tempbg;
      this.backgroundEnabled = false;
    }
  }
  enableBackground() {
    if (!this.backgroundEnabled) {
      this.backgroundEnabled = true;
      this.setProps({ background: this.backgroundDecl });
    }
  }
  setCanvasDimensions(width, height) {
    this.width = width;
    this.height = height;
    this.outputs.set("width", width);
    this.outputs.set("height", height);
    switch (this.props.camera.type) {
      case "Orthographic":
        this.camera.left = -this.width / 2;
        this.camera.right = this.width / 2;
        this.camera.top = this.height / 2;
        this.camera.bottom = -this.height / 2;
        break;
      case "Perspective":
        this.camera.aspect = this.width / this.height;
        break;
    }

    this.camera.updateProjectionMatrix();

    if (this.bgMesh !== undefined) {
      // Shader background resize
      const z = this.camera.far - 10;
      const dim = this.screenDimZ(z);
      this.bgMesh.scale.set(dim.x, dim.y, 1);
    }

    if (this.effectRenderer)
      this.effectRenderer.setSize(this.width, this.height);
    else {
      this.renderer.setSize(this.width, this.height, false);
    }
  }
  screenDimZ(z) {
    const height =
      2 * Math.tan((this.camera.fov / 360) * Math.PI) * Math.abs(z);
    return { x: height * this.camera.aspect, y: height };
  }
  screenHeightToZ(height) {
    return height / 2 / Math.tan((this.camera.fov / 360) * Math.PI);
  }
  update(dt) {
    // return
    if (this.pauseRendering) return;
    if (this.effectRenderer) {
      this.effectRenderer.update(dt);
      //console.log("Update - Effect");
    } else {
      //console.log("Update - No Effect");
      this.renderer.render(this.scene, this.camera);
    }
    if (this.graphEditor) this.graphEditor.update(dt);
  }
}

export default RenderComp;
