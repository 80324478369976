import * as THREE from 'three';
import VisComp from '@three-extra/VisComp';
import typeManager from '@cloud/TypeManager';
import inputManager from '@input/InputManager';

import Reflector from "@three-extra/reflection/Reflector"
import Mirror from "@three-extra/reflection/CubeCamMirror"


import factoryMat from '@three-extra/asset/MaterialManager';
import factoryGeom from '@three-extra/asset/GeometryManager';

import cloneDeep from "lodash/cloneDeep"


// https://github.com/mrdoob/three.js/blob/dev/examples/webgl_materials_cubemap_dynamic.html

const { sin, cos, abs, PI } = Math
class MirrorMesh extends VisComp {
    constructor() {

        super();
        this.update = this.update.bind(this)
        const r = new Reflector()
       


        this.frameCount = 0


    }

    start() {
        super.start();
        this.generateGeom()
        this.generateMirror()
    }


    generateMirror() {

        this.resolution = 1
        var WIDTH = window.innerWidth;
        var HEIGHT = window.innerHeight;

        const options = cloneDeep(this.inputs.get("options")) ||  {}

        const defaultOptions = {
            clipBias: 0.003,
            resolution: 1,
            color: 0x777777
        }

        for (let key in defaultOptions) {
            if (options[key] || options[key] !== undefined) continue
            options[key] = defaultOptions[key]
        }
        options.textureWidth = WIDTH * window.devicePixelRatio * options.resolution
        options.textureHeight = HEIGHT * window.devicePixelRatio * options.resolution

        this.mirror = new Reflector(this.geometries[0], options);
        this.cont3D.add(this.mirror)



    }





    //called every frame, dt: time passed between frames
    update(dt) {

        super.update(dt)

        this.frameCount++
            this.time += dt


    }

    generateGeom() {
        const geom = this.inputs.get('geometry');
        this.geometries = [];
        for (let i = 0; i < geom.count; i++) {
            this.geometries.push(factoryGeom.build(geom.getNext(), { geom: this._defGeom }))
        };
    }



    addInputListeners() {

    }

    updateParams() {

        for (let param of this.params) {
            this[param] = this.inputs.get(param)
        }
    }




    dispose() {
        super.dispose()
    }



}



// DO NOT DELETE THIS SHIT

typeManager.registerClass("6054cb22aeedcc0017ece72e", MirrorMesh);

export default MirrorMesh;