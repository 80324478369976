import * as THREE from 'three';
import { Inputs, Outputs } from './InputsOutputs';



/**
 * creates a THREE.Object3D extended with reactive IO
 * @param {string} type any THREE.Object3D compatible type 
 * @returns 
 */
function createReactiveObject3D( type ){
    const object = new THREE[ type ]()
    object.inputs = new Inputs()
    object.outputs = new Outputs()

    object.inputs.typeDef = {
 
            "name": "Transform",
            "category": 1,
            "csClass": "",
            "properties": {
                "position": {
                    "type": {
                        "type": "Vector3"
                    }
                },
                "rotation": {
                    "type": {
                        "type": "Vector3"
                    }
                },
                "scale": {
                    "type": {
                        "type": "Vector3"
                    }
                },
                "quaternion": {
                    "type": {
                        "type": "Quaternion"
                    }
                }
            },
            "classType": 0,
            "isGeneric": false,
            "isEnum": false,
            "defaultValue": {
                "position": {
                    "x": 0,
                    "y": 0,
                    "z": 0
                },
                "rotation": {
                    "x": 0,
                    "y": 0,
                    "z": 0
                },
                "scale": {
                    "x": 1,
                    "y": 1,
                    "z": 1
                }
            
        }
    }
    return object 
}

export default createReactiveObject3D