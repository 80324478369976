import * as THREE from "three";
import { extAssetCache } from "@three-extra";
import VisComp from "@three-extra/VisComp";
import typeManager from "@cloud/TypeManager";
import cloud from "@cloud/VJYCloudClient";
const { typeNameToId } = typeManager;

class ModelPreview extends VisComp {
  constructor() {
    super();
    this.update = this.update.bind(this);

    window.p = this;
  }

  async start(doc = null, canvas) {
    await super.start();
    // this.renderer.renderer.setClearColor( 0xffffff)

    console.log("Mode l Preview inputs", this.inputs.getObj());

    console.log("OG DOC", cloud.getDoc(this[">link"]));

    let asset;

    if (doc) {
      this.doc = doc;
      asset = { id: doc._id, [">link"]: { type: doc.t } };
    } else {
      asset = this.inputs.get("_asset") || this.inputs.get("model"); // { '>link': { id: this.doc._id } };
      this[">link"] = { id: asset._id };
      this.doc = cloud.getDoc(asset);
    }

    console.log("Model preview link", this[">link"]);
    console.log("Model preview doc", this.doc);

    this.rotSpeed = 0.002;

    let isPreview = true;
    if (!doc) {
      // if the start function doesn't receive a doc, this means that the composition has been instantiated through the view/edit app
      doc = cloud.getDoc(this.inputs.get("_asset"));
      isPreview = false;
    }

    const model = await extAssetCache.load(this.doc);

    this.cont3D.add(model);

    if (isPreview) {
      this.scene.comp = this;
      var box = new THREE.Box3().setFromObject(model);
      const height = box.max.y - box.min.y;
      model.scale.setScalar((5 * 1) / height);

      this.me.camera.position.set(0, 10, 0);
      this.me.camera.lookAt(new THREE.Vector3());
      this.light = new THREE.AmbientLight();
      this.dirLight = new THREE.DirectionalLight();
      this.dirLight.castShadow = true;
      this.dirLight.position.set(0, 30, 0);
      this.cont3D.add(this.dirLight);
      this.dirLight.target = new THREE.Object3D();
      this.light.position.set(10, 100, 10);
      this.cont3D.add(this.light);
    }

    this.isPreview = isPreview;

    this.model = model;

    if (
      model.userData.model &&
      model.userData.model.animations &&
      model.userData.model.animations.length
    ) {
      // Create an AnimationMixer, and get the list of AnimationClip instances
      this.mixer = new THREE.AnimationMixer(model);
      const clips = model.userData.model.animations;

      this.clipIndex = 0;

      this.clip = clips[this.clipIndex];

      if (model.userData.model.cameras && model.userData.model.cameras.length) {
        model.userData.model.cameras[0].attach(this.me.camera);

        this.action = this.mixer.clipAction(this.clip);
        this.action.play();

        if (clips.length > 1) {
          for (let i = 1; i < clips.length; i++) {
            const clip = clips[i];
            const action = this.mixer.clipAction(clip);
            action.play();
          }
        }
        // this.me.ctrl.setActive( false )
      } else {
        this.action = this.mixer.clipAction(this.clip);
        this.action.play();
      }

      this.clipTime = 0;
    }

    this.time = 0;

    //if ( this.isChild ) return

    this.cont3D.traverse((c) => {
      if (c.type === "AmbientLight") return;
      c.castShadow = true;
      c.receiveShadow = true;
    });
    this.renderer.renderer.shadowMap.enabled = true;
    if (this.scene.lightSys)
      this.scene.lightSys.cont3D.traverse((c) => {
        if (c.type !== "AmbientLight" && c.type !== "HemisphereLight")
          c.castShadow = true;
      });

    const encoding = THREE.sRGBEncoding;
    if (this.renderer.scene.background && this.renderer.scene.background.uuid) {
      model.traverse((m) => {
        if (!m.material) return;
        m.material.envMap = this.renderer.scene.background;

        const { material } = m;

        if (material.map) material.map.encoding = encoding;
        if (material.emissiveMap) material.emissiveMap.encoding = encoding;
        if (material.map || material.emissiveMap) material.needsUpdate = true;
      });
      this.renderer.scene.background.encoding = THREE.sRGBEncoding;
    }

    // for ( let c of this._children ) c.cont3D.traverse( c => {
    // 	c.castShadow = false
    // 	c.receiveShadow = false
    // })

    // if ( this.cont3D.getObjectByName("screen"))  this.cont3D.getObjectByName("screen").material = new THREE.MeshBasicMaterial({color:0xff0000})
    // if ( ! this.cont3D.getObjectByName("Pedestal_bottom")) return
    // this.cont3D.getObjectByName("Pedestal_bottom").castShadow = false
    // this.cont3D.getObjectByName("Pedestal_bottom").receiveShadow = false
    const params = new URLSearchParams(window.location.search);
    let rotY = params.get("rotY") || 0;
    rotY *= Math.PI / 180;
    this.cont3D.rotation.y = rotY;

    this.update(0);
  }

  //called every frame, dt: time passed between frames
  update(dt) {
    super.update(dt);
    if (!this.model) return;
    if (this.mixer && this.clip) {
      this.mixer.update(dt);
      this.clipTime += dt;

      if (
        this.clipTime >= this.clip.duration &&
        !this.inputs.get("animateOnce")
      ) {
        this.clipIndex++;
        this.clipIndex =
          this.clipIndex % this.model.userData.model.animations.length;

        this.clip = this.model.userData.model.animations[this.clipIndex];

        this.clipTime = 0;

        this.action.stop();

        this.action = this.mixer.clipAction(this.clip);
        this.action.play();
      }
    }
    if (!this.isPreview) return;
    this.model.rotation.y += dt * 0.1;
    this.model.rotation.x += dt * 0.1;
  }

  dispose() {
    this.cont3D.remove(this.mesh);
  }
}

// DO NOT DELETE THIS SHIT

typeManager.registerClass(typeNameToId("Preview.Model"), ModelPreview);
typeManager.registerClass(typeNameToId("Comp.ModelPreview"), ModelPreview);

export default ModelPreview;
