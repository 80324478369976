import * as THREE from "three";
import typeManager from "@cloud/TypeManager";
import extAssetCache from "./ExtAssetCache";
import cloud from "@cloud/VJYCloudClient";
import WavyCylinder from "../geometry/WavyCylinder";
import ShroomGeometry from "@three-extra/geometry/ShroomGeometry";
import MengerSponge from "@three-extra/geometry/MengerSponge";
import Pipe from "@three-extra/geometry/Pipe";
import ObjectManager from "@cloud/ObjectManager";
import factoryGeom from "@three-extra/asset/GeometryManager";
import ThreeBSP from "@three-extra/util/ThreeBSP";
import QuxSphereGeometry from "@three-extra/geometry/QuxSphereGeometry";

import "@three-extra/util/BufferGeometryUtils";
import "@three-extra/util/ParametricGeometries";
import "@three-extra/util/GeometryUtils";

import "@three-extra/util/BufferGeometryToIndexed";
import objMan from "../../cloud/ObjectManager";

import {
  SphereGeometry,
  BoxGeometry,
  Vector2,
  PlaneGeometry,
  IcosahedronGeometry,
  TorusKnotGeometry,
  LatheGeometry,
  DodecahedronGeometry,
  OctahedronGeometry,
  TetrahedronGeometry,
  CircleGeometry,
  ConeGeometry,
  TorusGeometry,
  RingGeometry,
  TubeGeometry,
  ParametricGeometry,
  Shape,
  ExtrudeGeometry,
  BufferGeometryUtils,
  ParametricGeometries,
} from "three";

const { typeNameToId } = typeManager;

class GeometryManager {
  constructor() {
    this.isFactory = true;
    this.uuids = {};
    this.objUpdate = [];
  }
  /*
	decl : doc / link / Geometry
	*/
  build(decl, def = {}) {
    if (decl.isGeometry) return decl;
    if (decl[">link"] != null && decl[">link"].solved) return decl;
    let doc = decl;
    if (decl[">link"]) doc = cloud.getDoc(decl);
    const typeDef = typeManager.getTypeDef(doc.t);
    let type = doc.t;

    // cloud class
    if (typeDef.classType === 2) {
      const geom = objMan.instantiateComp(decl, {}, true);
      geom.start();
      this.objUpdate.push(geom);
      return geom;
    }

    switch (type) {
      case "THREE.Geometry":
        return decl;
      case typeNameToId("Geometry.WavyCylinder"):
        const wavy = new WavyCylinder(doc.d);
        this.uuids[wavy.uuid] = true;
        this.objUpdate.push(wavy);
        return wavy;

      // case "Geometry.MengerSponge":
      //   const sponge = new MengerSponge(doc.d);
      //   this.uuids[sponge.uuid] = true;
      //   this.objUpdate.push(sponge);
      //   return sponge;

      case typeNameToId("Geometry.ShroomGeometry"):
        const shroom = new ShroomGeometry(doc.d);
        this.uuids[shroom.uuid] = true;
        this.objUpdate.push(shroom);
        return shroom;

      case typeNameToId("Geometry.Pipe"):
        const pipe = new Pipe(doc.d);
        this.uuids[pipe.uuid] = true;
        this.objUpdate.push(pipe);
        return pipe;

      case typeNameToId("Geometry.Sphere"):
        const sphere = new SphereGeometry(
          doc.d.radius,
          doc.d.widthSegments,
          doc.d.heightSegments,
          doc.d.phiStart ? (doc.d.phiStart * Math.PI) / 180 : undefined,
          doc.d.phiLength ? (doc.d.phiLength * Math.PI) / 180 : undefined,
          doc.d.thetaStart ? (doc.d.thetaStart * Math.PI) / 180 : undefined,
          doc.d.thetaLength ? (doc.d.thetaLength * Math.PI) / 180 : undefined
        );
        this.uuids[sphere.uuid] = true;
        return sphere;

      case typeNameToId("Geometry.BoxSphere"):
        // geometry
        var geometry = new BoxGeometry(
          doc.d.width,
          doc.d.height,
          doc.d.depth,
          doc.d.widthSegments,
          doc.d.heightSegments,
          doc.d.depthSegments
        );

        {
          const { array, count } = geometry.attributes.position;
          const v = new THREE.Vector3();
          // morph box into a sphere
          for (var i = 0; i < count; i++) {
            v.set(array[i * 3 + 0], array[i * 3 + 1], array[i * 3 + 2]);
            v.normalize().multiplyScalar(10); // or whatever size you want
            array[i * 3 + 0] = v.x;
            array[i * 3 + 1] = v.y;
            array[i * 3 + 2] = v.z;
          }
        }

        // texture is a collage; set offset/repeat per material index
        var repeat = new Vector2(1 / 3, 1 / 2);
        var offsets = [
          new Vector2(0, 0),
          new Vector2(0, 1 / 2),
          new Vector2(1 / 3, 0),
          new Vector2(1 / 3, 1 / 2),
          new Vector2(2 / 3, 0),
          new Vector2(2 / 3, 1 / 2),
        ];

        // redefine vertex normals consistent with a sphere; reset UVs
        // for (var i = 0; i < geometry.faces.length; i++) {
        //   var face = geometry.faces[i];

        //   face.vertexNormals[0].copy(geometry.vertices[face.a]).normalize();
        //   face.vertexNormals[1].copy(geometry.vertices[face.b]).normalize();
        //   face.vertexNormals[2].copy(geometry.vertices[face.c]).normalize();

        //   var uvs = geometry.faceVertexUvs[0];

        //   for (var j = 0; j < 3; j++) {
        //     uvs[i][j].multiply(repeat).add(offsets[face.materialIndex]);
        //   }

        //   // face.normal - will not be used; don't worry about it
        // }
        return geometry;

      case typeNameToId("Geometry.Cube"):
        const cube = new BoxGeometry(
          doc.d.width,
          doc.d.height,
          doc.d.depth,
          doc.d.widthSegments || 1,
          doc.d.heightSegments || 1,
          doc.d.depthSegments || 1
        );
        this.uuids[cube.uuid] = true;
        return cube;

      case typeNameToId("Geometry.Plane"):
        const plane = new PlaneGeometry(
          doc.d.width,
          doc.d.height,
          doc.d.widthSegments,
          doc.d.heightSegments
        );
        this.uuids[plane.uuid] = true;
        return plane;

      case typeNameToId("Geometry.Icosahedron"):
        const icosa = new IcosahedronGeometry(doc.d.radius, doc.d.detail);
        this.uuids[icosa.uuid] = true;
        return icosa;

      case typeNameToId("Geometry.TorusKnot"):
        const torusKnot = new TorusKnotGeometry(
          doc.d.radius,
          doc.d.tube,
          doc.d.tubularSegments,
          doc.d.radialSegments,
          doc.d.p,
          doc.d.q
        );
        this.uuids[torusKnot.uuid] = true;
        return torusKnot;

      case typeNameToId("Geometry.Lathe"):
        const lathe = new LatheGeometry(
          doc.d.points,
          doc.d.segments,
          doc.d.phiStart,
          doc.d.phiLength
        );
        this.uuids[lathe.uuid] = true;
        return lathe;

      case typeNameToId("Geometry.Dodecahedron"):
        const dod = new DodecahedronGeometry(doc.d.radius, doc.d.detail);
        this.uuids[dod.uuid] = true;
        return dod;

      case typeNameToId("Geometry.Octahedron"):
        const oct = new OctahedronGeometry(doc.d.radius, doc.d.detail);
        this.uuids[oct.uuid] = true;
        return oct;

      case typeNameToId("Geometry.Tetrahedron"):
        const tet = new TetrahedronGeometry(doc.d.radius, doc.d.detail);
        this.uuids[tet.uuid] = true;
        return tet;

      case typeNameToId("Geometry.Circle"):
        const cir = new CircleGeometry(
          doc.d.radius,
          doc.d.segments,
          doc.d.thetaStart,
          doc.d.thetaLength
        );
        this.uuids[cir.uuid] = true;
        return cir;

      case typeNameToId("Geometry.Cone"):
        const cone = new ConeGeometry(
          doc.d.radius,
          doc.d.height,
          doc.d.radialSegments,
          doc.d.heightSegments,
          doc.d.openEnded,
          doc.d.thetaStart,
          doc.d.thetaLength
        );
        this.uuids[cone.uuid] = true;
        return cone;

      case typeNameToId("Geometry.Torus"):
        const torus = new TorusGeometry(
          doc.d.radius,
          doc.d.tube,
          doc.d.radialSegments,
          doc.d.tubularSegments,
          doc.d.arc
        );
        this.uuids[torus.uuid] = true;
        return torus;

      case typeNameToId("Geometry.Ring"):
        const ring = new RingGeometry(
          doc.d.innerRadius,
          doc.d.outerRadius,
          doc.d.thetaSegments,
          doc.d.phiSegments,
          doc.d.thetaStart,
          doc.d.thetaLength
        );
        this.uuids[ring.uuid] = true;
        return ring;

      case typeNameToId("Geometry.Tube"):
        const tube = new TubeGeometry(
          ObjectManager.deserialize(doc.d.path),
          doc.d.tubularSegments,
          doc.d.radius,
          doc.d.radialSegments,
          doc.d.closed
        );
        this.uuids[tube.uuid] = true;
        return tube;

      case typeNameToId("Geometry.Merge"):
        let s = ObjectManager.deserialize(doc.d.geometries);
        if (s[">link"]) s = ObjectManager.deserialize(s);
        const geometries = [];
        for (let el of s.elems) {
          let next = s.getNext();

          // TODO : all this is a bit hacky, in order to make PreviewCanvas work for merge geoms containing geoms with nested geoms
          if (next[">link"]) next = ObjectManager.deserialize(next);
          if (next[">link"]) next = ObjectManager.deserialize(next);
          if (next[">link"]) next = ObjectManager.deserialize(next);

          geometries.push(factoryGeom.build(next, { geom: null }));
          // in order for mergeBufferGeometries to work, all geometries need to be indexed
          if (!geometries[geometries.length - 1].indices)
            geometries[geometries.length - 1] =
              geometries[geometries.length - 1].toIndexed();
        }

        const g = BufferGeometryUtils.mergeBufferGeometries(geometries);
        this.uuids[g.uuid] = true;
        return g;

      case typeNameToId("Geometry.Parametric"):
        let fn;
        // if function is "klein", "mobius" or "mobius3d" use the functions from THREE
        // else evaluate the function string
        if (ParametricGeometries[doc.d.func])
          fn = ParametricGeometries[doc.d.func];
        else fn = Function("u", "v", "vec", doc.d.func);
        const para = new ParametricGeometry(fn, doc.d.slices, doc.d.stacks);
        this.uuids[para.uuid] = true;
        return para;

      case "Geometry.ConstructiveSolid": // TODO: add support for subtract/ union / add
        // const deser = ObjectManager.deserialize(doc.d.geometries);
        // const bufferGeometries = [];
        // for (let el of deser.elems) {
        //   bufferGeometries.push(
        //     factoryGeom.build(deser.getNext(), { geom: null })
        //   );
        // }
        // const geoms = [];

        // let newBSP;
        // for (let bg of bufferGeometries) {
        //   const g = new THREE.Geometry();
        //   g.fromBufferGeometry(bg);
        //   geoms.push(g);
        // }
        // let bsp1 = new ThreeBSP(geoms[0]);
        // for (let g of geoms) {
        //   let bsp2 = new ThreeBSP(g);
        //   newBSP = bsp1.subtract(bsp2);
        //   bsp1 = bsp2;
        // }

        // const bufG = new THREE.BufferGeometry();

        // return bufG.fromGeometry(newBSP.toGeometry());
        return null;

      case typeNameToId("Geometry.Extrude"): // TODO: add curve selection between points (ie bezierCurveTo)
        const options = {
          curveSegments: doc.d.curveSegments,
          steps: doc.d.steps,
          depth: doc.d.depth,
          bevelEnabled: doc.d.bevelEnabled,
          bevelThickness: doc.d.bevelThickness,
          bevelSize: doc.d.bevelSize,
          bevelOffset: doc.d.bevelOffset,
          bevelSegments: doc.d.bevelSegments,
          extrudePath: ObjectManager.deserialize(doc.d.extrudePath),
        };
        if (doc.d.UVGenerator) {
          let { generateTopUV, generateSideWallUV } = doc.d.UVGenerator;
          generateSideWallUV = cloud.getDoc(generateSideWallUV);
          generateTopUV = cloud.getDoc(generateTopUV);
          generateSideWallUV = Function(
            "return (" + generateSideWallUV.d.code + ")"
          )();
          generateTopUV = Function("return (" + generateTopUV.d.code + ")")();

          options.UVGenerator = {
            generateSideWallUV,
            generateTopUV,
          };
        }

        const data = ObjectManager.deserialize(doc.d.shapes);
        var shape = new Shape();
        const { points } = data[0];
        shape.moveTo(points[0].x, points[0].y);
        for (let i = 0; i < points.length; i++) {
          const p = points[i];
          shape.lineTo(p.x, p.y);
        }
        // shape.lineTo( points[0].x, points[0].y );c

        const ext = new ExtrudeGeometry(shape, options);
        this.uuids[ext.uuid] = true;
        return ext;

      case typeNameToId("Geometry.Model"):
        return extAssetCache.get(decl);

      case typeNameToId("Geometry.QuxSphereGeometry"):
        const quxSphere = new QuxSphereGeometry(doc.d);
        this.uuids[quxSphere.uuid] = true;
        this.objUpdate.push(quxSphere);
        return quxSphere;

      default:
        return def.geom || {};
    }
  }

  /**
   * Dispose geometries created by build()
   * @param {object|object[]} geometries - geometry or array of geometries
   */
  dispose(geometries) {
    geometries = Array.isArray(geometries) ? geometries : [geometries];
    for (const g of geometries) {
      if (typeof g === "object" && g.uuid && this.uuids[g.uuid]) {
        delete this.uuids[g.uuid];
        g.dispose();
      }
    }
  }

  update(dt) {
    for (let i = 0; i < this.objUpdate.length; i++) {
      this.objUpdate[i].update(dt);
    }
  }
}

const geometryManager = new GeometryManager();
typeManager.registerClass(typeNameToId("Geometry.Sphere"), geometryManager);
typeManager.registerClass(typeNameToId("Geometry.TorusKnot"), geometryManager);
typeManager.registerClass(typeNameToId("Geometry.Cube"), geometryManager);
typeManager.registerClass(typeNameToId("Geometry.Parametric"), geometryManager);
typeManager.registerClass(typeNameToId("Geometry.Lathe"), geometryManager);
typeManager.registerClass(
  typeNameToId("Geometry.Icosahedron"),
  geometryManager
);
typeManager.registerClass(
  typeNameToId("Geometry.Dodecahedron"),
  geometryManager
);
typeManager.registerClass(
  typeNameToId("Geometry.Tetrahedron"),
  geometryManager
);
typeManager.registerClass(typeNameToId("Geometry.Octahedron"), geometryManager);
typeManager.registerClass(typeNameToId("Geometry.Circle"), geometryManager);
typeManager.registerClass(typeNameToId("Geometry.Torus"), geometryManager);
typeManager.registerClass(typeNameToId("Geometry.Tube"), geometryManager);
typeManager.registerClass(typeNameToId("Geometry.Cone"), geometryManager);
typeManager.registerClass(typeNameToId("Geometry.Ring"), geometryManager);
typeManager.registerClass(typeNameToId("Geometry.Merge"), geometryManager);
typeManager.registerClass(typeNameToId("Geometry.Extrude"), geometryManager);
typeManager.registerClass(typeNameToId("Geometry.Plane"), geometryManager);

export default geometryManager;
