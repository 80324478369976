import typeManager from "@cloud/TypeManager";
import MIDIDevice from "./MIDIDevice";
const { typeNameToId } = typeManager;

class NordLead4 extends MIDIDevice {
  constructor(params) {
    super(params);
    this.hasPrograms = true;
    console.log("NordLead4", params);
    //this.convert();
    //Process Programs //////////////////////////////////////////////////////

    for (let i = 0; i < 4; i++) {
      const bank = [];
      for (let ii = 0; ii < 99; ii++)
        bank.push({
          name: "-------",
          type: "",
          code: this.indToCode({ bank: i, prg: ii }),
        });
      this.banks.push(bank);
    }
    for (let i = 0; i < params.bank.length; i++) {
      let patch = params.bank[i];
      let ind = this.codeToInd(patch.code);

      this.banks[ind.bank][ind.prg] = patch;
      if (patch.rating != null && patch.rating > 0) this.favorits.push(ind);
      if (patch.type != null && patch.type != "") {
        let catInd = this.catNameToInd(patch.type);
        if (catInd < 0) {
          this.categoryNames.push(patch.type);
          this.categories.push([]);
          catInd = this.categories.length - 1;
        }
        this.categories[catInd].push(ind);
      }
    }
  }

  getBanks() {
    return ["Bank 1", "Bank 2", "Bank 3", "Bank 4"];
  }

  convert() {
    let data = `<table>
        </table>`;
    let parser = new DOMParser();
    let xml = parser.parseFromString(data, "text/xml");
    //console.log(xml);
    let ret = [];
    let list = xml.children[0].children;
    for (var i = 0; i < list.length; i++) {
      let el = list[i];
      ret.push({
        code:
          el.children[1].textContent.substr(5) +
          "." +
          el.children[2].textContent,
        name: el.children[3].textContent,
        type: el.children[4].textContent,
      });
    }
    console.log(ret);
  }
}
typeManager.registerClass(typeNameToId("Synth.NordLead4"), NordLead4);

export default NordLead4;

/*
	{
				"patch": "In The Air 2Nite DLX",
				"code": "2.55",
				"desc": "wide & strong, organ like lead, best with cutoff & distort on pedal",
				"rating": 10,
				"type": "Lead"
			},
			{
				"code": "2.75",
				"desc": "piano",
				"type": "Keys"
			},
			{
				"code": "2.80",
				"patch": "The Padder US",
				"type": "Pad"
			},
			{
				"code": "2.93",
				"type": "Fantasy",
				"desc": "cool stuff",
				"patch": "Eorzea KU "
            }
*/
