import * as THREE from "three";
import VisComp from "@three-extra/VisComp";
import typeManager from "@cloud/TypeManager";

import gm from "@three-extra/asset/GeometryManager";
import typeMan from "@cloud/TypeManager";
import materialManager from "@three-extra/asset/MaterialManager";
import shaderUtils from "@three-extra/util/ShaderUtils";
import cloud from "@cloud/VJYCloudClient";
import objMan from "@cloud/ObjectManager";
const { typeNameToId } = typeMan;
class EffectPreview extends VisComp {
  constructor() {
    super();
    this.update = this.update.bind(this);
    this.stepGeom = this.stepGeom.bind(this);
    window.p = this;
  }

  start(doc, canvas) {
    super.start();

    console.log("Effect Preview comp", this);

    let asset;

    // Preview
    if (!doc) {
      //

      this.viewer = "edit";

      document.addEventListener(
        "keyup",
        (e) => e.code === "Space" && this.stepGeom()
      );

      asset = this.inputs.get("_asset"); // { '>link': { id: this.doc._id } };
      console.log("asset", asset, this.inputs.getObj());
      this[">link"] = { id: asset._id };
      this.doc = cloud.getDoc(asset);

      // Preview Canvas
    } else {
      this.viewer = "preview";
      this.renderer.renderer.domElement.addEventListener(
        "click",
        this.stepGeom
      );
      this.doc = doc;
      asset = { ">link": { id: this.doc._id } };

      this[">link"] = { id: asset._id };
    }

    this.stepType = "geometry";

    this.light = new THREE.DirectionalLight(0xffffff, 0.9);
    this.light.position.set(10, 10, 10);
    this.cont3D.add(this.light);
    this.rotSpeed = 0.002;

    this.rotSpeed = 0.02;
    this.renderer.setProps({ effect: asset });

    this.steps = [
      {
        geom: new THREE.BoxGeometry(0.5, 2, 0.5, 10, 10, 10),
        matOptions: {
          wireframe: false,
        },
      },
      {
        geom: new THREE.BoxGeometry(0.5, 2, 0.5, 10, 10, 10),
        matOptions: {
          wireframe: true,
        },
      },
      {
        geom: new THREE.SphereGeometry(0.3, 16, 16),
        matOptions: {
          wireframe: false,
        },
      },
      {
        geom: new THREE.SphereGeometry(0.3, 16, 16),
        matOptions: {
          wireframe: true,
        },
      },
      {
        geom: new THREE.SphereGeometry(0.3, 16, 16),
        matOptions: {
          wireframe: false,
        },
        material: new THREE.MeshBasicMaterial({ color: 0xff0000 }),
      },
      {
        geom: new THREE.SphereGeometry(0.3, 16, 16),
        matOptions: {
          wireframe: false,
        },
        material: new THREE.MeshBasicMaterial({ color: 0x00ff00 }),
      },
      {
        geom: new THREE.SphereGeometry(0.3, 16, 16),
        matOptions: {
          wireframe: false,
        },
        material: new THREE.MeshBasicMaterial({ color: 0x0000ff }),
      },
    ];

    this.material = new THREE.MeshNormalMaterial();

    this.geometry = this.steps[0].geom;
    this.stepInd = 0;

    this.mesh = new THREE.Mesh(this.geometry, this.material);
    this.mesh.rotation.y = Math.PI / 4;
    this.obj = new THREE.Object3D();
    this.cont3D.add(this.obj);
    if (this.viewer === "preview") this.mesh.position.set(0, 0, -2);
    this.obj.add(this.mesh);

    this.time = 0;

    this.update(0);
  }

  // changes geometry when preview canvas is clicked
  stepGeom() {
    this.stepInd++;
    this.stepInd = this.stepInd % this.steps.length;

    this.mesh.geometry = this.steps[this.stepInd].geom;
    if (this.steps[this.stepInd].material)
      this.mesh.material = this.steps[this.stepInd].material;
    else this.mesh.material = this.material;
    for (let key in this.steps[this.stepInd].matOptions)
      this.mesh.material[key] = this.steps[this.stepInd].matOptions[key];
    this.mesh.material.needsUpdate = true;
    this.mesh.rotation.set(0, 0, 0);
    this.mesh.position.set(0, 0, 0);
    this.mesh.rotation.y = Math.PI / 4;
    if (this.viewer === "preview") this.mesh.position.set(0, 0, -2);
  }

  //called every frame, dt: time passed between frames
  update(dt) {
    super.update(dt);
    if (!dt) return;

    this.time += dt;

    this.obj.rotation.z += this.rotSpeed;

    if (this.stepInd > 1) {
      this.mesh.position.x = Math.sin(this.time * 2) * 0.75;
      this.mesh.position.y = Math.cos(this.time * 2) * 0.75;
    }
  }

  dispose() {
    this.material.dispose();
    if (this.geometry) this.geometry.dispose();
    for (let s in this.steps) if (s.geom) s.geom.dispose();
    this.cont3D.remove(this.mesh);
  }
}

// DO NOT DELETE THIS SHIT

typeManager.registerClass(typeNameToId("Preview.Effect"), EffectPreview);

export default EffectPreview;
