import * as THREE from "three";
import musicMeta from "./MusicMeta";
import merge from "lodash/merge";

import EasingValue2 from "@data-trans/EasingValue2";

class AudioManager {
  constructor() {
    // None / Input / Play
    this.mode = "None";
    //for audio playback
    this.playing = false;
    this.audioLoaded = this.audioLoaded.bind(this);
    this.audioDataLoaded = this.audioDataLoaded.bind(this);
    //this.loadProgress = this.loadProgress.bind(this);
    //this.audioProcess = this.audioProcess.bind(this);

    this.params = {
      mode: "None",
      musicMeta: {},
    };
    this.inputDevices = [];
    this.analysers = [];
    this.audioCtxResumed = false;
    this.beatCount = 0;
    this.beatFloat = 0;
    this.beatFired = false;
    this.beatStarted = false;
    this.beatChannel = new EasingValue2(0, 1, 1, 10, 0.01);
    this.beatChannel.jumpTo(0);
    this.time = 0;
    if (!musicMeta) return;
    musicMeta.beatChannel = this.beatChannel;
    musicMeta.beatFloat = 0;
  }
  setParams(params) {
    merge(this.params, params);
    this.setMode(this.params.mode);
    musicMeta.setParams(params.musicMeta);
    musicMeta.inputs.listeners.fire("setParams", {
      audioMan: this.params,
      musicMeta: params.musicMeta,
    });
  }
  start() {
    musicMeta.start();
  }

  setMode(mode) {
    this.playing = false;

    if (this.audio) {
      console.log("AUDIO", this.audio);
      try {
        // https://threejs.org/docs/?q=audio#api/en/audio/Audio
        this.audio.stop();
        this.audio.disconnect();
        // https://developer.mozilla.org/en-US/docs/Web/API/AudioContext/close
        this.audioCtx.close();
        this.splitterNode.disconnect();
        this.bufferSource.disconnect();

        delete this.audioData0;
        delete this.audioData1;
        delete this.analyser0;
        delete this.analyser1;
        this.camera.remove(this.listener);
        delete this.listener;
        delete this.req;
        delete this.audioBuffer;
        delete this.bufferSource;
        this.audioCtxResumed = false;
        delete this.audioCtx;

        if (this.resumeAudioContext)
          window.removeEventListener("click", this.resumeAudioContext);
        if (this.clickListeners) {
          for (let listener of this.clickListeners)
            window.removeEventListener("click", listener);
        }
        if (this.req) this.req.abort();
      } catch (err) {
        console.log("AudioMan.setMode error >>> ", err);
      }
    }
    if (this.mode === "Play") {
      //this.camera.remove(this.listener);
      //this.audio.stop();
    } else if (this.mode === "Input") {
      //this.scriptNode.removeEventListener('audioprocess', this._audioProcess);
      //this.streamSource.disconnnect();
      // this.audioCtx.close();
    }

    this.mode = mode;
  }
  async startAudio() {
    const { mode } = this;
    if (mode === "Play") {
      await this.initPlay();
    } else if (mode === "Input") {
      this.initInput();
    } else if (mode === "Demo") {
      this.initDemo();
    }
  }

  /********************************************************************************
   * PLAY
   **********************************************************************************/
  async initPlay() {
    console.log("AM - initPlay");
    this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    //Source Node
    this.bufferSource = this.audioCtx.createBufferSource();
    this.bufferSource.channelInterpretation = "discrete";
    //window.streamSource = this.streamSource;
    this.splitterNode = this.audioCtx.createChannelSplitter(2);
    try {
      // XXX Fails on firefox
      // InvalidStateError: An attempt was made to use an object that is not, or is no longer, usable
      this.splitterNode.channelInterpretation = "discrete";
    } catch (err) {}
    //window.splitterNode = this.splitterNode;
    this.bufferSource.connect(this.splitterNode);

    const bufferLength = this.params.musicMeta.waveData.bufferLength;
    this.audioData0 = new Float32Array(bufferLength);
    this.audioData1 = new Float32Array(bufferLength);

    //this.scriptNode = this.audioCtx.createScriptProcessor(bufferLength, 1, 1);
    //this.scriptNode.addEventListener('audioprocess', this._audioProcess);

    this.analyser0 = this.audioCtx.createAnalyser();
    this.analyser0.fftSize = bufferLength;
    this.splitterNode.connect(this.analyser0, 0, 0);

    this.analyser1 = this.audioCtx.createAnalyser();
    this.analyser1.fftSize = bufferLength;
    this.splitterNode.connect(this.analyser1, 1, 0);

    //For playing back the sound buffer to the listener
    this.listener = new THREE.AudioListener();
    this.camera.add(this.listener);
    this.audio = new THREE.Audio(this.listener);

    await this.startPlay(this.params.clip);
    console.log("AM - initPlay2");
    // window.addEventListener("click", resumeAudioContext);
    const resumeAudioContext = () => {
      console.log("AM - resumeAudioCtx 1");
      if (!this.audioCtxResumed) {
        console.log("AM - resumeAudioCtx 2");
        this.audioCtx.resume();
        this.audioCtxResumed = true;
        if (this.params.clip) this.startPlayBack(this.params.clip);
      }
    };
    this.resumeAudioContext = resumeAudioContext;
    resumeAudioContext();
  }

  async startPlay(clip) {
    console.log("start play", clip.url, this.mode, this);
    if (this.mode !== "Play") return;
    console.log("Play Music", clip.url);
    console.log("CTX Time", this.audioCtx.currentTime);
    this.clip = clip;
    if (this.loadingUI) this.loadingUI.open("Loading Music");
    const request = new XMLHttpRequest();
    this.req = request;
    await new Promise((resolve, reject) => {
      request.open("GET", clip.url, true);
      request.responseType = "arraybuffer";
      request.onload = async (buffer) => {
        console.log("AM audio loaded");
        await this.audioDataLoaded(buffer);
        resolve();
      };
      // This requires content length headers to be set on the server, else e.total = 0
      let prog = 0;
      request.addEventListener("progress", (e) => {
        let progress = e.loaded / e.total;
        if (progress === Infinity) {
          const aug = Math.max((1 - prog) * 0.05, 0.01);
          prog += aug;
          prog = Math.min(prog, 0.99);
          progress = prog;
        }
        musicMeta.inputs.listeners.fire("audioLoadProgress", progress);
      });
      request.send();
    });
  }
  async audioDataLoaded() {
    console.log("Audio Data Loaded");
    console.log("CTX Time", this.audioCtx.currentTime);
    const audioData = this.req.response;
    const buffer = await this.audioCtx.decodeAudioData(audioData);
    this.audioLoaded(buffer);
  }
  audioLoaded(buffer) {
    console.log("Audio Loaded", buffer);
    console.log("CTX Time", this.audioCtx.currentTime);
    this.audioBuffer = buffer;
    //this.audioData=buffer.getChannelData(0);
    //this.audioData = new Float32Array;
    //buffer.copyFromChannel(this.audioData, 1, 0);

    //console.log("audioData", this.audioData);
    this.bufferSource.buffer = buffer;
    this.bufferSource.loop = true;
    this.bufferSource.start();

    this.audio.setBuffer(buffer);

    musicMeta.inputs.listeners.fire("audioLoaded", true);
  }
  startPlayBack = (_) => {
    console.log(this.bufferSource);
    this.playing = true;
    this.pos = 0;
    this.playing = true;
    musicMeta.startMusic();

    //playing back the music to the listener

    this.audio.setLoop(this.clip.loop || false);
    this.audio.play();
    this.contextTime0 = this.audioCtx.currentTime;
    if (this.loadingUI) this.loadingUI.close();
    musicMeta.inputs.listeners.fire("play", true);
  };
  /*
	loadProgress(xhr) {
		// console.log('progress', xhr.loaded / this.clip.fileSize);
		if (this.loadingUI) this.loadingUI.progress(xhr.loaded / this.clip.fileSize);
	}
	

	
	audioProcess(event) {
		this.audioData = event.inputBuffer.getChannelData(0);
		this.playing = true;
	}
	*/

  /************************************************************************************
   * INPUT
   *************************************************************************************/
  async initInput() {
    //List of available input devices
    try {
      const list = await navigator.mediaDevices.enumerateDevices();
      for (const el of list) {
        if (el.kind === "audioinput") {
          //sconsole.log('INPUT ', el.label);
          if (this.sysUI) this.sysUI.addDevice("audioinput", el.label);
          this.inputDevices.push({
            deviceId: el.deviceId,
            label: el.label,
            kind: el.kind,
          });
        }
      }
      this.initInput2();
    } catch (err) {
      console.error(err);
    }
  }
  initDemo() {
    let bufferLength = this.params.musicMeta.waveData.bufferLength;

    const elAnalyser = {};
    elAnalyser.audioData = new Float32Array(bufferLength);
    elAnalyser.audioDataFreq = new Float32Array(bufferLength);

    this.analysers.push({
      ind: 0,
      audioData: elAnalyser.audioData,
      audioDataFreq: elAnalyser.audioDataFreq,
      type: "Demo",
    });
    console.log("YO ", this);
  }
  initInput2() {
    this.usedDevices = [];
    this.inputsReady = 0;
    this.inputsCount = this.params.inputs.length;

    this.audioContexts = [];

    this.clickListeners = [];
    for (const inp of this.params.inputs) {
      const el = {
        params: inp,
        constraints: { audio: { echoCancellation: false } },
      };
      if (inp.label !== "" && inp.label !== "Default")
        for (const dev of this.inputDevices)
          if (dev.label === inp.label)
            el.constraints.audio.deviceId = dev.deviceId;
      el.audioCtx = new (window.AudioContext || window.webkitAudioContext)();
      this.audioContexts.push(el.audioCtx);

      console.log("STATE", el.audioCtx.state);

      // If audio context is already running because user previously accepted permissions, start straight away
      if (el.audioCtx.state === "running")
        musicMeta.inputs.listeners.fire("play", true);
      // else wait for a user click
      else {
        const clickListener = () => {
          if (!this.audioCtxResumed) {
            el.audioCtx.resume();
            console.log("Resumed", el.audioCtx);
            this.audioCtxResumed = true;

            musicMeta.inputs.listeners.fire("play", true);
          }
        };
        this.clickListeners.push(clickListener);
        window.addEventListener("click", clickListener);
      }

      if (navigator.mediaDevices) {
        navigator.mediaDevices
          .getUserMedia(el.constraints)
          .then(this.initInputOnStream.bind(this, el), (error) => {
            this.initInputOnError(el, error);
          });
      } else {
        navigator.getUserMedia(
          el.constraints,
          this.initInputOnStream.bind(this, el),
          (error) => {
            console.log(error, el);
          }
        );
      }
    }
  }
  initInputOnError(el, error) {
    if (this.sysUI) this.sysUI.addDevice("error", el.params.label);
    if (navigator.mediaDevices) {
      // TODO
      //	this._initInputOnError doesn;t exist
      // navigator.mediaDevices.getUserMedia(el.constraints).then(this._initInputOnStream.bind(this, el), error => {
      // 	this._initInputOnError(el, error);
      // });
    } else {
      navigator.getUserMedia(
        el.constraints,
        this._initInputOnStream.bind(this, el),
        (error) => {
          console.log(error, el);
        }
      );
    }
  }
  initInputOnStream(el, stream) {
    //console.log('STARTED', el);
    if (this.sysUI) this.sysUI.addDevice("active", el.params.label);
    //console.log("Track",stream.getAudioTracks());
    el.streamSource = el.audioCtx.createMediaStreamSource(stream);
    let bufferLength = this.params.musicMeta.waveData.bufferLength;

    this.analysers = this.analysers.filter((el) => el.type !== "Demo");
    const myAnalysers = [];
    let elAnalyser;
    switch (el.params.mode) {
      case "mix":
        elAnalyser = {};
        elAnalyser.audioData = new Float32Array(bufferLength);
        elAnalyser.audioDataFreq = new Float32Array(bufferLength);

        elAnalyser.analyser = el.audioCtx.createAnalyser();
        elAnalyser.analyser.fftSize = bufferLength;
        el.streamSource.connect(elAnalyser.analyser);
        myAnalysers.push(elAnalyser);
        break;
      case "split":
        el.streamSource.channelInterpretation = "discrete";
        el.splitterNode = el.audioCtx.createChannelSplitter(2);
        el.splitterNode.channelInterpretation = "discrete";
        el.streamSource.connect(el.splitterNode);
        //Anal 0
        elAnalyser = {};
        elAnalyser.audioData = new Float32Array(bufferLength);
        elAnalyser.audioDataFreq = new Float32Array(bufferLength);
        elAnalyser.analyser = el.audioCtx.createAnalyser();
        elAnalyser.analyser.fftSize = bufferLength;
        el.splitterNode.connect(elAnalyser.analyser, 0, 0);
        myAnalysers.push(elAnalyser);
        //Anal 1
        elAnalyser = {};
        elAnalyser.audioData = new Float32Array(bufferLength);
        elAnalyser.audioDataFreq = new Float32Array(bufferLength);
        elAnalyser.analyser = el.audioCtx.createAnalyser();
        elAnalyser.analyser.fftSize = bufferLength;
        el.splitterNode.connect(elAnalyser.analyser, 1, 0);
        myAnalysers.push(elAnalyser);
        break;
      default:
        break;
    }

    //channels for MusicMeta
    for (const ch of el.params.channels) {
      const src = myAnalysers[ch.src];

      this.analysers.push({
        ind: ch.ind,
        audioData: src.audioData,
        analyser: src.analyser,
        audioDataFreq: src.audioDataFreq,
      });
    }

    //Ready ?
    this.inputsReady++;
    if (this.inputsReady === this.inputsCount) {
      this.playing = true;
      //console.log('Playing', this.analysers);
      musicMeta.startMusic();
    }
    if (this.audioCtxResumed) musicMeta.inputs.listeners.fire("play", true);
  }

  setBPM(bpm) {
    if (!this.params.clip) {
      this.params.clip = {};
    }
    this.params.clip.bpm = bpm;
    if (!this.params.clip.bpmStart) this.params.clip.bpmStart = 0;
  }

  /************************************************************************************
   * UPDATE
   *************************************************************************************/
  update(dt) {
    if (this.playing) {
      if (this.mode === "Play") {
        this.pos = this.audioCtx.currentTime - this.contextTime0;
        //console.log(this.pos);
        if (this.params.clip.bpm) {
          if (this.beatFired) {
            this.beatFired = false;
            this.beatChannel.set(0);
          }
          if (this.pos > this.params.clip.bpmStart) {
            if (!this.beatStarted) {
              this.beatStarted = true;
              this.beatFired = true;
              this.beatChannel.set(1);
            }
            this.beatFloat =
              (this.pos - this.params.clip.bpmStart) /
              (60 / this.params.clip.bpm);
            musicMeta.beatFloat = this.beatFloat;
            if (this.beatCount < Math.floor(this.beatFloat)) {
              this.beatFired = true;
              this.beatChannel.set(1);
              this.beatCount = Math.floor(this.beatFloat);
            }
          }
          this.beatChannel.update();
        }
        //this.pos = (performance.now() - this.audioDataT0) / 1000;
        //const ind0 = Math.floor(this.pos * this.audioBuffer.sampleRate) % (this.audioData0.length);
        this.analyser0.getFloatTimeDomainData(this.audioData0);
        musicMeta.feedWave(0, this.audioData0, 0);
        this.analyser1.getFloatTimeDomainData(this.audioData1);
        musicMeta.feedWave(1, this.audioData1, 0);

        //console.log("UP");
      } else if (this.mode === "Input") {
        for (const el of this.analysers) {
          if (!el.analyser) continue;
          el.analyser.getFloatTimeDomainData(el.audioData);
          el.analyser.getFloatFrequencyData(el.audioDataFreq);
          musicMeta.feedWave(el.ind, el.audioData, 0, el.audioDataFreq);
        }

        // this.analysers[0].analyser.getFloatFrequencyData( this.audioFrequencyData )
        // this.analysers[0].analyser.getFloatTimeDomainData( this.audioTimeData )

        // this.dataTexTime.needsUpdate = true
        // this.dataTexFreq.needsUpdate = true
        /*let el=this.analysers[0];
				el.analyser.getFloatTimeDomainData(el.audioData);
				musicMeta.feedWave(0,el.audioData, 0);
				musicMeta.feedWave(1,el.audioData, 0);*/
      } else if (this.mode === "Demo") {
        for (const el of this.analysers) {
          let length = el.audioData.length;
          for (let i = 0; i < length; i++) {
            el.audioData[i] =
              Math.sin(this.time + (i / length) * Math.PI * 2 * 6) * 0.05 +
              Math.sin(
                this.time +
                  (i / length) *
                    Math.PI *
                    2 *
                    7.2 *
                    Math.sin(-0.5 * this.time + i / length)
              ) *
                0.025;
          }
          length = el.audioDataFreq.length;
          for (let i = 0; i < length; i++) {
            el.audioDataFreq[i] =
              Math.cos(this.time + (i / length) * Math.PI * 2 * 9) * 0.05 +
              Math.cos(
                this.time +
                  (i / length) *
                    Math.PI *
                    2 *
                    13 *
                    Math.sin(-0.5 * this.time + i / length)
              ) *
                0.0252;
          }
          musicMeta.feedWave(el.ind, el.audioData, 0, el.audioDataFreq);
        }
      }
    }
    this.time += dt;
    musicMeta.update(dt);
  }
  play = (_) => {
    if (this.playing) return;

    if (this.mode === "Play") {
      this.audio.play();
    }
    this.playing = true;
  };
  pause = (_) => {
    if (!this.playing) return;

    if (this.mode === "Play") {
      this.audio.pause();
    }
    if (this.mode === "Input") {
    }
    this.playing = false;
  };
}

// export as a singleton
const audioManager = new AudioManager();
export default audioManager;
