function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    console.log("Fallback: Copying text command was " + msg);
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}
function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log("Async: Copying to clipboard was successful!");
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
}

class Ctrl {
  constructor(camera, targetObj, canvas) {
    this._camera = camera;
    this._targetObj = targetObj;
    this._canvas = canvas;
  }

  start() {
    this.setActive(true);
    document.addEventListener("keyup", this._onKeyUpCopyTransform);
  }

  //   copies camera and target obj transforms to clipboard when ctrl + K is pressed
  _onKeyUpCopyTransform = (e) => {
    if (e.key === "k" && e.ctrlKey) {
      console.log(this);
      const c = this._camera;
      const t = this._targetObj;

      const camPos = JSON.stringify(c.position, null, 2);
      const rot = JSON.stringify(c.rotation, null, 2);
      const quat = JSON.stringify(c.quaternion, null, 2);

      const tPos = JSON.stringify(t.position, null, 2);
      const ts = JSON.stringify(t.scale, null, 2);
      const trot = JSON.stringify(t.rotation, null, 2);
      const tquat = JSON.stringify(t.quaternion, null, 2);

      const st =
        "Camera:  \n" +
        "position: " +
        camPos +
        "\n" +
        "rotation: " +
        rot +
        "\n" +
        "quaternion: " +
        quat +
        "\n" +
        "Target: \n" +
        "position: " +
        tPos +
        "\n" +
        "scale: " +
        ts +
        "\n" +
        "rotation: " +
        trot +
        "\n" +
        "quaternion: " +
        tquat +
        "\n";

      console.log(st);
      copyTextToClipboard(st);
    }
  };

  update(dt) {}

  destroy() {
    if (this._active) this.setActive(false);
    document.removeEventListener("keyup", this._onKeyUpCopyTransform);
  }

  setActive(active) {}
}

export default Ctrl;
