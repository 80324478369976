import * as THREE from "three";
import VisComp from "@three-extra/VisComp";
import typeManager from "@cloud/TypeManager";

import factoryMat from "@three-extra/asset/MaterialManager";
import factoryGeom from "@three-extra/asset/GeometryManager";
const { typeNameToId } = typeManager;
class MeshLab extends VisComp {
  constructor() {
    super();
  }
  start() {
    super.start();
    //geomA
    this._defGeom = new THREE.BoxGeometry();
    this.generateGeomA();
    this.inputs.listeners.add("geomA", this.onGeomA.bind(this));

    //matA
    this._baseMat = this.scene.baseMat;
    this._defMat = new THREE.MeshNormalMaterial();
    this.generateMatA();
    this.inputs.listeners.add("matA", this.onMatA.bind(this));

    this.mesh = new THREE.Mesh(this.geometries[0], this.materials[0]);
    const transform = this.inputs.get("transform");
    if (transform != null) {
      this.mesh.scale.copy(transform.scale);
      this.mesh.rotation.set(
        (transform.rotation.x / 180) * Math.PI,
        (transform.rotation.y / 180) * Math.PI,
        (transform.rotation.z / 180) * Math.PI
      );
      this.mesh.position.copy(transform.position);
    }
    this.cont3D.add(this.mesh);
  }
  generateGeomA() {
    const geomA = this.inputs.get("geomA");
    this.geometries = [];
    for (let i = 0; i < geomA.count; i++)
      this.geometries.push(
        factoryGeom.build(geomA.getNext(), { geom: this._defGeom })
      );
  }
  onGeomA(event) {
    this.generateGeomA();
    if (this.geometries.length > 0) {
      this.mesh.geometry = this.geometries[0];
    }
  }
  generateMatA() {
    const matA = this.inputs.get("matA");
    this.materials = [];
    if (matA == null) this.materials.push(this._defMat);
    else
      for (let i = 0; i < matA.count; i++)
        this.materials.push(
          factoryMat.build({
            base: this._baseMat,
            def: this._defMat,
            asset: matA.getNext(),
          })
        );
    this.materials.forEach((m) => {
      m.side = THREE.DoubleSide;
    });
  }
  onMatA(event) {
    this.generateMatA();
    if (this.materials.length > 0) {
      this.mesh.material = this.materials[0];
    }
  }
  update(dt) {
    super.update(dt);
  }

  dispose() {
    if (this.materials.length > 0) {
      for (let m of this.materials) m.dispose();
    }
    if (this.geometries.length > 0) {
      for (let g of this.geometries) g.dispose();
    }
    this.cont3D.remove(this.mesh);
  }
}

typeManager.registerClass(typeNameToId("Comp.MeshLab"), MeshLab);

export { MeshLab as default };
