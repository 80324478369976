import typeManager from "@cloud/TypeManager";
import input from "@input/InputManager";
import parseMidiMessage from "@input/MIDITools";
import MIDIDevice from "./MIDIDevice";
const { typeNameToId } = typeManager;
class MicroKORG extends MIDIDevice {
  constructor(params) {
    super(params);
    this.hasPrograms = true;
    //console.log("MicroKORG",params);

    //Process Programs
    for (let i = 0; i < 8; i++) {
      const bank = [];
      for (let ii = 0; ii < 4; ii++) {
        const subbank = [];
        for (let iii = 0; iii < 8; iii++)
          subbank.push({
            name: "-------",
            type: "",
            code: this.indToCode({ bank: i, subbank: ii, prg: iii }),
          });
        bank.push(subbank);
      }
      this.banks.push(bank);
    }
    for (let i = 0; i < params.bank.length; i++) {
      let patch = params.bank[i];
      let ind = this.codeToInd(patch.code);

      this.banks[ind.bank][ind.subbank][ind.prg] = patch;
      if (patch.rating != null && patch.rating > 0) this.favorits.push(ind);
      if (patch.type != null && patch.type != "") {
        let catInd = this.catNameToInd(patch.type);
        if (catInd < 0) {
          this.categoryNames.push(patch.type);
          this.categories.push([]);
          catInd = this.categories.length - 1;
        }
        this.categories[catInd].push(ind);
      }
    }
    this.hasSubbanks = true;
    this.ind.subbank = 0;
  }

  // On MIDI Msg ///////////////////////////////////////////////////////////////////////////
  onMidiMsg(ev) {
    //console.log(ev);
    let msg = parseMidiMessage(ev.data);
    if (msg.type == "PrgChg") {
      let midiBank = this.ind.subbank <= 1 ? 0 : 1;
      let ind1 = msg.value % 8;
      let ind2 = Math.floor(msg.value / 8);
      let ind3 = 0;
      if (msg.value >= 64) {
        ind2 -= 8;
        ind3 = 1;
      }
      let subbankInd = ind3 + midiBank * 2;
      this.updatePrg({ bank: ind2, prg: ind1, subbank: subbankInd });
    }
    if (msg.type == "CC" && msg.cc == 32) {
      this.updatePrg({ subbank: msg.value * 2 + (this.ind.subbank % 2) });
    }
    //console.log(msg);
    this.checkChannels(msg);
    this.listeners.fire("MidiMessage", ev.data);
  }

  /**********************************************
   * Banks & Programs
   **********************************************/
  indToCode(ind) {
    const subbanks = ["A", "B", "C", "D"];
    return subbanks[ind.subbank] + "." + (ind.bank + 1) + "" + (ind.prg + 1);
  }
  codeToInd(code) {
    let subbankInd = -1;
    if (code[0] == "A") subbankInd = 0;
    if (code[0] == "B") subbankInd = 1;
    if (code[0] == "C") subbankInd = 2;
    if (code[0] == "D") subbankInd = 3;
    let bankInd = parseInt(code[2]) - 1;
    let prgInd = parseInt(code[3]) - 1;
    return { prg: prgInd, bank: bankInd, subbank: subbankInd };
  }
  codeToMIDI(code) {
    let ret = { type: "PrgBankChg", prg: -1, bank: -1 };
    let v1 = 0;
    if (code[0] == "A") {
      v1 = 0;
      ret.bank = 0;
    }
    if (code[0] == "B") {
      v1 = 1;
      ret.bank = 0;
    }
    if (code[0] == "C") {
      v1 = 0;
      ret.bank = 1;
    }
    if (code[0] == "D") {
      v1 = 1;
      ret.bank = 1;
    }
    let v2 = parseInt(code[2]) - 1;
    let v3 = parseInt(code[3]) - 1;
    ret.prg = v1 * 64 + v2 * 8 + v3;
    return ret;
  }
  setPrg(p) {
    if (p.prg != null) this.ind.prg = p.prg;
    if (p.bank != null) this.ind.bank = p.bank;
    if (p.subbank != null) this.ind.subbank = p.subbank;
    let code = this.banks[this.ind.bank][this.ind.subbank][this.ind.prg].code;
    console.log(code);
    if (this.outputConnected)
      input._midi.sendMidiMsg(this.devOut, 0, this.codeToMIDI(code));
    this.updatePrg(p);
  }
  updatePrg(p) {
    if (p.prg != null) this.ind.prg = p.prg;
    if (p.bank != null) this.ind.bank = p.bank;
    if (p.subbank != null) this.ind.subbank = p.subbank;
    this.listeners.fire("PrgChg", {
      prg: this.ind.prg,
      bank: this.ind.bank,
      subbank: this.ind.subbank,
    });
  }
  getPatch(ind) {
    return this.banks[ind.bank][ind.subbank][ind.prg];
  }
  getSubbanks() {
    return ["A", "B", "C", "D"];
  }
  getBanks() {
    const banks = [
      "Trance",
      "Techno/House",
      "Electronica",
      "DNB/Breaks",
      "HipHop/Vintage",
      "Retro",
      "S.E./Hit",
      "Vocoder",
    ];

    const list = [];
    //for(let i=0;i<subbanks.length;i++) for(let ii=0;ii<banks.length;ii++) list.push(subbanks[i]+"."+(ii+1)+" "+banks[ii]);
    for (let ii = 0; ii < banks.length; ii++)
      list.push(ii + 1 + " " + banks[ii]);

    return list;
  }

  convert() {
    let data = ``;
    let list = data.split("\n");
    let ret = [];
    for (let i = 0; i < list.length; i++) {
      let el = {};
      let row = list[i];
      el.code = row.substr(0, 4);
      el.name = row.substring(row.indexOf(")") + 2, row.lastIndexOf(" "));
      el.type = row.substr(row.lastIndexOf(" ") + 1);
      ret.push(el);
    }
    console.log(ret);
  }
}
typeManager.registerClass(typeNameToId("Synth.microKORG"), MicroKORG);

export default MicroKORG;
/*
		{
				"code": "A.67",
				"patch": "Cerebellum",
				"desc": "cute little bells",
				"rating": 10,
				"type": "Keys"
			},
			{
				"code": "B.15",
				"patch": "Weepy Lead",
				"desc": "my first fav trancy Lead",
				"rating": 10,
				"type": "Lead"
			},
			{
				"code": "B.62",
				"patch": "Water Edge",
				"desc": "arty arp ... mod turns crazy !",
				"rating": 10,
				"type": "Arp"
			},
			{
				"code": "A.15",
				"patch": "Vizilo"
            }
            */
/*
codeToPrgBank(code,type){
		let ret={type:"PrgBankChg",prg:-1,bank:-1};
		switch(type){
			case "Synth.microKORG":
				let v1 =  0;
				if(code[0]=="A") {v1=0;ret.bank=0;}
				if(code[0]=="B") {v1=1;ret.bank=0;}
				if(code[0]=="C") {v1=0;ret.bank=1;}
				if(code[0]=="D") {v1=1;ret.bank=1;}
				let v2 = parseInt(code[2])-1;
				let v3 = parseInt(code[3])-1;
				ret.prg = v1*64+v2*8+v3;
				break;
			case "Synth.VirusTi":
				v1 = code.charCodeAt(4)-65;
				v2 = parseInt(code.substr(6));
				ret.bank = v1+(code.indexOf("ROM")>=0?4:0);
				ret.prg = v2;
			break;
		
			break;
			case "Synth.NordLead4":
			case "Synth.NordModularG2":
				v1 = parseInt(code[0])-1;
				v2 = parseInt(code.substr(2));
				ret.bank = v1;
				ret.prg=v2-1;
				break;
		}
		return ret;
	}
*/
