import * as THREE from 'three';

export default class PointSpritesGeometry extends THREE.BufferGeometry {
    constructor( attributes = {
        frameIndexesA: [], frameIndexB: [], aniPercs: [], vertices: []
    } ) {
        super()

        this.frameBufferA = new THREE.Float32BufferAttribute( attributes.frameIndexesA, 1);
        this.frameBufferB = new THREE.Float32BufferAttribute( attributes.frameIndexesB, 1);
        this.aniPerc = new THREE.Float32BufferAttribute( attributes.aniPercs, 1);
        this.vertices = new THREE.Float32BufferAttribute(attributes.vertices, 3)

        this.setAttribute('position', this.vertices );
        this.setAttribute('frameIndexA', this.frameBufferA);
        this.setAttribute('frameIndexB', this.frameBufferB);
        this.setAttribute('perc', this.aniPerc);
    }
    setAnimPercentage( index, percentage ) {
        this.aniPerc.array[ index ] = percentage
    }
    setStartFrame( index, frame ) {
        this.frameBufferA.array[ index ] = frame
    }
    setTargetFrame( index, frame ) {
        this.frameBufferB.array[ index ] = frame
    }
    getStartFrame( index ) {
        return this.frameBufferA.array[ index ] 
    }
    getTargetFrame( index ) {
        return this.frameBufferB.array[ index ] 
    }
    setPos( index, vec){
        this.vertices.array[ index * 3 + 0  ] = vec.x 
        this.vertices.array[ index * 3 + 1 ] = vec.y 
        this.vertices.array[ index  * 3 + 2 ] = vec.z
    }
    update() {
        this.aniPerc.needsUpdate = true
        this.frameBufferA.needsUpdate = true
        this.frameBufferB.needsUpdate = true
        this.vertices.needsUpdate = true 
        
    }
}