import * as THREE from "three";
import VisComp from "@three-extra/VisComp";
import typeManager from "@cloud/TypeManager";

/*
    // VisComp /////////////////////////////////////////////
	
	this.scene ( SceneSingle )
	- our class to manage to scene

	this.cont3D ( Group )
	- add all your visual stuff to this
	- it already contains the Lights - /if VisComp has any
	
	this.renderer ( RenderComp )
	- our rendering component
	- THREE.Renderer is inside

	this.me ( Me )
	- representation of the user
	- camera is inside

*/

/*
	// Arthurs custom parameters:
	count : int
	dimX : float
    geom : Pattern<Geometry>
    mat : Pattern<Material>
*/

// Let's try to make a particle system

class AxesHelper extends VisComp {
  constructor() {
    super();
  }

  start() {
    super.start();

    const axesHelper = new THREE.AxesHelper(this.inputs.get("size"));
    this.cont3D.add(axesHelper);
  }
  update(dt) {
    super.update(dt);
  }
}

typeManager.registerClass("60649477d92185001721142f", AxesHelper);

export default AxesHelper;
