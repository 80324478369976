// XX packages version for 3A pages - remove unecessary imports, just keep the ones needed for cloud classes to work

// import * as audio from "./audio";
import * as cloud from "./cloud";
import * as compositions from "./compositions";
import * as data from "./data-trans";
import * as input from "./input";
import * as rt from "./rt";
import * as three from "./three-extra";
// import * as ui from "./ui";

// TEMP

console.log("[BUILDINFO]");

export {
  //  audio,
  cloud,
  compositions,
  data,
  input,
  rt,
  three,
  //   ui
};
