import * as THREE from "three";
import ShaderModules from "@three-extra/shaders/ShaderModules";
import materialManager from "@three-extra/asset/MaterialManager";
import cloud from "@cloud/VJYCloudClient";
import cloneDeep from "lodash/cloneDeep";
import { typeManager } from "@cloud/index";
const { typeNameToId, typeIdToName } = typeManager;

class ShaderUtils {
  buildMaterial(decl) {
    //Base
    const baseShader = ShaderModules["Base" + decl.base];
    ////console.log(baseShader);

    //Uniforms ////////////////////////////////////////////////////////////////////////
    const uniforms = THREE.UniformsUtils.clone(baseShader.uniforms);
    uniforms.iTime = { value: 0 };
    ////console.log(uniforms);
    for (var i in decl.baseParams) uniforms[i].value = decl.baseParams[i];
    for (let i = 0; i < decl.vertexModules.length; i++) {
      for (const ii in ShaderModules["Vertex" + decl.vertexModules[i]].uniforms)
        uniforms[ii] = {
          value:
            ShaderModules["Vertex" + decl.vertexModules[i]].uniforms[ii].value,
        };
    }
    for (let i = 0; i < decl.fragmentModules.length; i++) {
      for (const ii in ShaderModules["Fragment" + decl.fragmentModules[i]]
        .uniforms)
        uniforms[ii] = {
          value:
            ShaderModules["Fragment" + decl.fragmentModules[i]].uniforms[ii]
              .value,
        };
    }

    //Vertex Prg /////////////////////////////////////////////////////////////////////
    let vertexPrg = baseShader.vertex.declarations;
    for (let i = 0; i < decl.vertexModules.length; i++) {
      vertexPrg += this.attributesToGLSLDecl(
        ShaderModules["Vertex" + decl.vertexModules[i]].attributes
      );
      vertexPrg += this.uniformsToGLSLDecl(
        ShaderModules["Vertex" + decl.vertexModules[i]].uniforms
      );
    }
    vertexPrg += baseShader.vertex.mainA;
    for (let i = 0; i < decl.vertexModules.length; i++) {
      vertexPrg += ShaderModules["Vertex" + decl.vertexModules[0]].code;
    }
    vertexPrg += baseShader.vertex.mainB;
    ////console.log("Vertex PRG",vertexPrg);

    //Fragment Prg //////////////////////////////////////////////////////////////////
    //const fragmentPrg = baseShader.fragment;
    let fragmentPrg = baseShader.fragment.declarations;
    for (let i = 0; i < decl.fragmentModules.length; i++) {
      fragmentPrg += this.uniformsToGLSLDecl(
        ShaderModules["Fragment" + decl.fragmentModules[i]].uniforms
      );
    }
    for (let i = 0; i < decl.fragmentModules.length; i++) {
      //fragmentPrg += this.attributesToGLSLDecl(ShaderModules['Fragment' + decl.fragmentModules[i]].attributes);
      if (ShaderModules["Fragment" + decl.fragmentModules[i]].functions)
        fragmentPrg +=
          ShaderModules["Fragment" + decl.fragmentModules[i]].functions;
    }
    fragmentPrg += baseShader.fragment.mainA;
    for (let i = 0; i < decl.fragmentModules.length; i++) {
      fragmentPrg += ShaderModules["Fragment" + decl.fragmentModules[i]].code;
    }
    fragmentPrg += baseShader.fragment.mainB;
    ////console.log(fragmentPrg);
    const shader = new THREE.ShaderMaterial({
      uniforms: uniforms,
      vertexShader: vertexPrg,
      fragmentShader: fragmentPrg,
      lights: baseShader.lights,
      defines: {
        USE_UV: "",
      },
    });
    // console.log("SHADER", shader);
    for (const i in decl.params) shader[i] = decl.params[i];
    return shader;
  }
  buildMaterialTest(decl) {
    //Base
    const baseShader = ShaderModules["Base" + decl.base];
    //console.log("TEST > BASE SHADER",baseShader);

    //Uniforms ////////////////////////////////////////////////////////////////////////
    const uniforms = THREE.UniformsUtils.clone(baseShader.uniforms);
    uniforms.iTime = { value: 0 };
    if (decl.proceduralTexture.uniforms)
      for (var i in decl.proceduralTexture.uniforms)
        uniforms[i] = decl.proceduralTexture.uniforms[i];
    //console.log(uniforms);
    for (var i in decl.baseParams) uniforms[i].value = decl.baseParams[i];

    //Fragment PRG ////////////////////////////////////////////////////////////////////

    let fragmentPrg = "";

    if (typeof baseShader.fragment == "string")
      fragmentPrg = baseShader.fragment;
    else {
      //const fragmentPrg = baseShader.fragment;
      //console.log("TEST> Injecting ProceduralTex",decl.proceduralTexture);
      fragmentPrg = baseShader.fragment.declarations;
      fragmentPrg += this.uniformsToGLSLDecl({
        iTime: { type: "float", value: 0 },
      });
      ////console.log (this.uniformsToGLSLDecl({iTime:{type:"float",value:0}}));
      //fragmentPrg+="uniform float iTime;\n";

      if (decl.proceduralTexture) {
        if (decl.proceduralTexture.uniforms)
          fragmentPrg += this.uniformsToGLSLDecl(
            decl.proceduralTexture.uniforms
          );

        if (decl.proceduralTexture.functions)
          fragmentPrg += decl.proceduralTexture.functions;
      }
      fragmentPrg += baseShader.fragment.mainA;
      if (decl.proceduralTexture) {
        if (decl.proceduralTexture.code)
          fragmentPrg += decl.proceduralTexture.code;
      }
      fragmentPrg += baseShader.fragment.mainB;
    }

    //fragmentPrg = baseShader.fragment.declarations+baseShader.fragment.mainA+baseShader.fragment.mainB;

    const shader = new THREE.ShaderMaterial({
      uniforms: uniforms,
      vertexShader: baseShader.vertex.declarations,
      fragmentShader: fragmentPrg,
      lights: baseShader.lights,
    });
    for (const i in decl.params) shader[i] = decl.params[i];

    return shader;
  }
  buildMaterial2(decl) {
    //console.log( "BUILD MAT", decl )
    //Base
    const baseShader = ShaderModules["Base" + decl.base];
    //console.log("BASE SHADER",baseShader);

    //Uniforms ////////////////////////////////////////////////////////////////////////
    const uniforms = THREE.UniformsUtils.clone(baseShader.uniforms);
    uniforms.iTime = { value: 0 };
    if (decl.proceduralTexture.uniforms)
      for (var i in decl.proceduralTexture.uniforms)
        uniforms[i] = decl.proceduralTexture.uniforms[i];
    //console.log('UNIFORMS:',uniforms);
    for (var i in decl.baseParams) uniforms[i].value = decl.baseParams[i];

    //Custom inputs
    /*if(decl.proceduralTexture){
			for (const ii in .uniforms) uniforms[ii] = {value: ShaderModules['Fragment' + decl.fragmentModules[i]].uniforms[ii].value};
		}*/

    let vertexPrg = "";
    if (typeof baseShader.vertex == "string") vertexPrg = baseShader.vertex;
    else {
      //Vertex Prg /////////////////////////////////////////////////////////////////////
      vertexPrg = baseShader.vertex.declarations;
      /*for(let i=0;i<decl.vertexModules.length;i++){
				vertexPrg += this.attributesToGLSLDecl(ShaderModules['Vertex' + decl.vertexModules[i]].attributes);
				vertexPrg += this.uniformsToGLSLDecl(ShaderModules['Vertex' + decl.vertexModules[i]].uniforms);
			}*/
      vertexPrg += baseShader.vertex.mainA;
      /*for(let i=0;i<decl.vertexModules.length;i++){
				vertexPrg += ShaderModules['Vertex' + decl.vertexModules[0]].code;
			}*/
      vertexPrg += baseShader.vertex.mainB;
      ////console.log("Vertex PRG",vertexPrg);
    }

    let fragmentPrg = "";
    if (typeof baseShader.fragment == "string")
      fragmentPrg = baseShader.fragment;
    else {
      //Fragment Prg //////////////////////////////////////////////////////////////////
      //const fragmentPrg = baseShader.fragment;

      //	fragmentPrg = "#extension GL_OES_standard_derivatives : enable \n"
      // fragmentPrg += "#extension GL_EXT_shader_texture_lod : enable \n"

      fragmentPrg += baseShader.fragment.declarations;

      fragmentPrg += this.uniformsToGLSLDecl({
        iTime: { type: "float", value: 0 },
      });

      if (decl.proceduralTexture) {
        if (decl.proceduralTexture.uniforms)
          fragmentPrg += this.uniformsToGLSLDecl(
            decl.proceduralTexture.uniforms
          );
        fragmentPrg += "//[FRAGMENT_FUNCTIONS_START]\n";
        if (decl.proceduralTexture.functions)
          fragmentPrg +=
            typeof decl.proceduralTexture.functions === "string"
              ? decl.proceduralTexture.functions
              : ""; // if there are no functions, proeduralTexture.functions is an empty object so we need to convert it to a string
        fragmentPrg += "//[FRAGMENT_FUNCTIONS_END]\n";
      }
      fragmentPrg += baseShader.fragment.mainA;
      if (decl.proceduralTexture) {
        //console.log("frag1", fragmentPrg, decl.proceduralTexture.code)
        fragmentPrg += "float vjyTime = iTime;\n";
        fragmentPrg += "//[FRAGMENT_CODE_START]\n";
        fragmentPrg += decl.proceduralTexture.code;
        fragmentPrg += "//[FRAGMENT_CODE_END]\n";
        //console.log("frag2", fragmentPrg,)
      }
      fragmentPrg += baseShader.fragment.mainB;
    }
    const params = {
      uniforms: uniforms,
      vertexShader: vertexPrg,
      fragmentShader: fragmentPrg,
      lights: baseShader.lights,
      extensions: {
        derivatives: true,
      },
      defines: {
        USE_UV: "",
      },
    };

    if (decl.base === "Sprite") {
      params.defines = {
        USE_UV: 1,
      };
    }
    // console.log("frag", fragmentPrg,)
    //console.log("FRAGMENT PRG",fragmentPrg);
    const shader = new THREE.ShaderMaterial(params);
    for (const i in decl.params) shader[i] = decl.params[i];

    // console.log( "SHADER", shader  )
    return shader;
  }
  uniformsToGLSLDecl(uniforms) {
    let str = "";

    for (const i in uniforms) {
      if (
        Array.isArray(uniforms[i].value) &&
        typeof uniforms[i].value[0] === "object"
      ) {
        str +=
          "uniform " +
          (uniforms[i].type === "boolean" ? "bool" : uniforms[i].type) +
          " " +
          i +
          "[" +
          uniforms[i].value.length +
          "]" +
          ";\n";
        str +=
          "const int " + i + "Length = " + uniforms[i].value.length + ";\n";
        continue;
      }
      let type = uniforms[i].type;
      str += "uniform " + type + " " + i + ";\n";
    }
    // console.log("UNIFORMS", str);

    return str;
  }
  attributesToGLSLDecl(attributes) {
    let str = "";
    for (const i in attributes)
      str += "attribute " + attributes[i].type + " " + i + ";\n";
    return str;
  }
  typeDefToUniforms(tt, dd) {
    let list = {};
    let props = tt.properties;
    dd = cloneDeep(dd);

    for (let i in dd) {
      if (!dd[i]) continue;
      if (dd[i][">link"]) {
        const doc = cloud.getDoc(dd[i]);

        if (
          doc &&
          (typeManager.isCompatible(doc.t, typeNameToId("Texture2D")) ||
            typeManager.isCompatible(doc.t, typeNameToId("CanvasTexture")))
        ) {
          const tex = materialManager.assetToTexture(dd[i]);
          if (doc.t !== typeNameToId("VideoTexture")) {
            tex.wrapS = THREE.RepeatWrapping;
            tex.wrapT = THREE.RepeatWrapping;
          }

          dd[i] = tex;
          continue;
        }
        if (doc && doc.t === typeNameToId("Sequence<Color>")) {
          const colors = [];
          for (let el of doc.d.elems) {
            const col = new THREE.Color(el);
            colors.push(new THREE.Vector3(col.r, col.g, col.b));
          }
          props[i].type.type = typeNameToId("Sequence<Color>");
          dd[i] = colors;
        }
      }
    }
    for (const i in props) {
      switch (props[i].type.type) {
        case typeNameToId("Color"):
          let ccc = new THREE.Color(dd[i]);
          //console.log("Color",ccc);
          list[i] = { type: "vec3", value: [ccc.r, ccc.g, ccc.b] };
          break;
        case typeNameToId("float"):
        case typeNameToId("int"):
          list[i] = { type: typeIdToName(props[i].type.type), value: dd[i] };
          break;
        case typeNameToId("boolean"):
          list[i] = { type: "bool", value: dd[i] };
          break;
        case typeNameToId("Vector2"):
          list[i] = { type: "vec2", value: [dd[i].x, dd[i].y] };
          break;
        case typeNameToId("Vector3"):
          list[i] = { type: "vec3", value: [dd[i].x, dd[i].y, dd[i].z] };
          break;
        case typeNameToId("Vector4"):
          list[i] = {
            type: "vec4",
            value: [dd[i].x, dd[i].y, dd[i].z, dd[i].w],
          };
          break;
        case typeNameToId("Texture2D"):
        case typeNameToId("VideoTexture"):
          list[i] = { type: "sampler2D", value: dd[i] };
          break;
        case typeNameToId("Sequence<Color>"):
          list[i] = { type: "vec3", value: dd[i] };
          break;
      }
    }

    return list;
  }
}

export default new ShaderUtils();
