import * as THREE from "three";
import VisComp from "@three-extra/VisComp";
import typeManager from "@cloud/TypeManager";
import inputManager from "@input/InputManager";

import factoryMat from "@three-extra/asset/MaterialManager";
import factoryGeom from "@three-extra/asset/GeometryManager";

import cloud from "@cloud/VJYCloudClient";

import "./styles.css";
import GraphRouter from "@rt/nodes/GraphRouter";
import nodeTypes from "@rt/nodes/Nodes";

import html from "@ui/HTMLUtils";
import { toast } from "@ui/Toaster";

/*
    // VisComp /////////////////////////////////////////////
	
	this.scene ( SceneSingle )
	- our class to manage to scene

	this.cont3D ( Group )
	- add all your visual stuff to this
	- it already contains the Lights - /if VisComp has any
	
	this.renderer ( RenderComp )
	- our rendering component
	- THREE.Renderer is inside

	this.me ( Me )
	- representation of the user
	- camera is inside

*/

// getting input paramaters:
// param = this.inputs.get( param name )
// updating when inputs change:
// this.inputs.listeners.add( param name, this.functionDependingOnParam.bind(this));

// TO DO
// reset nodes with inner state (eg time node ) to 0 on double click
const color = new THREE.Color();
class GraphEditor extends VisComp {
  constructor(graphDoc, myNodes) {
    super();
    this.preventEventDefault = this.preventEventDefault.bind(this);
    this.addNode = this.addNode.bind(this);

    this.update = this.update.bind(this);

    if (graphDoc !== undefined) this.graphDoc = graphDoc;
    if (myNodes !== undefined) this.myNodes = myNodes;

    //	document.addEventListener("click", ()=> console.log( this )) // debug helper
    this.resolution = new THREE.Vector2(window.innerWidth, window.innerHeight);

    this.internalUpdate = true;

    window.cloud = cloud;
    console.log("NEW GRAPH EDITOR INIT");

    this.divGraph = document.getElementById("graphContainer");
    if (!this.divGraph) {
      this.divGraph = document.createElement("div");
      this.divGraph.id = "graphContainer";
    }

    this.divGraph.className = "Graph";
    this.divGraph.visible = true;

    window.g = this;
  }

  start(appendToDoc = true) {
    super.start();
    if (this.renderer && this.renderer.camera) {
      // this.renderer.scene.background = new THREE.Color( 1, 0, 0 )
      this.renderer.camera.position.set(0, 0, 10);
    }
    this.scene.app.domManager.listeners.add("resize", this.onCanvasDim);

    this.errorCount = 0;

    this.mouse = {
      x: 0,
      y: 0,
    };
    this.canMove = false;

    this.onCanvasDim();
    this.buildGraph(appendToDoc);
    this.addUI();

    document.addEventListener("mousemove", this.updateMousePosition.bind(this));
    window.addEventListener("resize", this.onCanvasDim.bind(this));
    document.addEventListener("mouseup", (e) => {
      this.unSelectNode();
      this.mousedown = false;
    });

    //  document.addEventListener("click", this.unSelectNode.bind( this ) )

    const deleteConnection = this.deleteConnection.bind(this);
    const deleteNode = this.deleteNode.bind(this);

    const addInputToNode = this.addInputToNode.bind(this);
    const toggleUIOverlay = this.toggleUIOverlay.bind(this);

    const _this = this;
    document.addEventListener("keydown", (e) => {
      if (e.code === "Backspace") {
        // backspace
        deleteConnection();
        deleteNode();
      }
      if (e.code === "Space") {
        // backspace
        //  _this.canMove = !_this.canMove
      }
      if (e.code === "KeyA") {
        addInputToNode();
      }
      if (e.key === "g" && e.ctrlKey && e.altKey) {
        //  toggleUIOverlay()
      }
    });

    this.update();
  }

  toggleUIOverlay() {
    console.log("TOGGLE GRAPH");

    let oldClass = "InvisibleG";
    let newClass = "VisibleG";

    if (this.divGraph.visible) {
      oldClass = "VisibleG";
      newClass = "InvisibleG";
      for (let con of this.conVisuals) con.visible = false;
    } else {
      for (let con of this.conVisuals) con.visible = true;
    }

    this.divGraph.classList.remove(oldClass);
    this.divGraph.classList.add(newClass);

    this.divGraph.visible = !this.divGraph.visible;
  }

  onCanvasDim = () => {
    // coordinates of screen
    const rect = this.renderer.screenDimZ(10);

    rect.x = rect.x;
    this.screenRect = { x: rect.x / 2, y: rect.y / 2 };

    //  console.log("RESIZE")
  };
  addUI() {
    // add UI for save / new node
    const divUI = document.createElement("div");
    divUI.classList.add("UI");

    // const closeBtn = html.addDiv( divUI, "CloseBtn" )
    // closeBtn.innerText = "BBBBBBB"

    const saveButton = document.createElement("div");
    saveButton.textContent = "SAVE GRAPH";
    saveButton.classList.add("Button");
    saveButton.addEventListener("click", this.saveGraph.bind(this));
    divUI.appendChild(saveButton);

    const newCont = html.addDiv(divUI, "");
    newCont.style.display = "flex";

    const newButton = document.createElement("div");
    newButton.textContent = "NEW TRANSFORMATION NODE";
    newButton.classList.add("Button");
    newButton.addEventListener("click", (_) => this.addNode("trans"));
    newCont.appendChild(newButton);

    this.newNodeDropdown = document.createElement("select");
    this.newNodeDropdown.classList.add("GraphSelect");
    this.newNodeDropdown.classList.add("Button");
    const transNodes = nodeTypes; //.filter( n => !n.ext )
    for (let key in transNodes) {
      if (transNodes[key].ext) continue;

      const node = document.createElement("option");
      node.value = key;
      node.textContent = key;
      // node.className = "DropdownOption" + " Row" + i % 2

      this.newNodeDropdown.appendChild(node);
    }
    newCont.appendChild(this.newNodeDropdown);

    const newExtCont = html.addDiv(divUI, "");
    newExtCont.style.display = "flex";

    const newExtButton = document.createElement("div");
    newExtButton.textContent = "NEW EXTERNAL NODE";
    newExtButton.classList.add("Button");
    newExtButton.addEventListener("click", (_) => this.addNode("ext"));
    newExtCont.appendChild(newExtButton);

    this.newExtNodeDropdown = document.createElement("select");
    const extNodes = nodeTypes; //.filter( n => n.ext )
    this.newExtNodeDropdown.classList.add("GraphSelect");
    this.newExtNodeDropdown.classList.add("Button");

    for (let key in extNodes) {
      if (!extNodes[key].ext) continue;
      const type = key;
      const node = document.createElement("option");
      node.value = key;
      node.textContent = key;
      // node.className = "DropdownOption" + " Row" + i % 2

      this.newExtNodeDropdown.appendChild(node);
    }

    newExtCont.appendChild(this.newExtNodeDropdown);

    this.divGraph.appendChild(divUI);

    //////
    const newInputButton = document.createElement("div");
    newInputButton.textContent = "ADD INPUT TO NODE";
    newInputButton.classList.add("Button");
    newInputButton.addEventListener("click", this.addInputToNode.bind(this));
    //  divUI.appendChild( newInputButton)

    var x = document.createElement("INPUT");
    x.setAttribute("type", "text");
    x.setAttribute("placeholder", "new node input name here");
    //divUI.appendChild( x )
    this.textInput = x;
    ///////
  }
  addNode(type) {
    // adds new node from the UI

    this.lastNodeId++;

    const typeInd =
      type === "trans"
        ? this.newNodeDropdown.value
        : this.newExtNodeDropdown.value;
    console.warn(typeInd);
    const data = { id: this.lastNodeId };
    var node = new nodeTypes[typeInd].class(data);
    node.id = THREE.Math.generateUUID(); // typeof data.id === "string"? data.id : data.id.toString();
    node.type = typeInd;
    node.internal = true;
    this.graph.nodes[node.id] = node;

    console.log("ADDED NODE", node, this.graph);

    this.buildNode(node);
  }

  addInputToNode() {
    if (!this.selectedNode) return;
    console.log(this.selectedNode);

    console.log(this.selectedNode);

    const key = this.textInput.value;
    const ref = this.nodeVisuals[this.selectedNode.id].inputs;
    const type = "inputs";

    this.selectedNode.inputs.set(key, 0);
    const label = this.addIOLabel(this.selectedNode, ref, key, type);

    if (Object.keys(this.selectedNode.inputs._ns).length % 2)
      label.classList.add("Col0");
    else label.classList.add("Col1");

    label.ind = Object.keys(this.selectedNode.inputs._ns).length - 1;
    this.nodeVisuals[this.selectedNode.id].container.appendChild(label);

    if (this.graph.nodes[this.selectedNode.id].addInput)
      this.graph.nodes[this.selectedNode.id].addInput(key);
    else this.graph.nodes[this.selectedNode.id].inputs.set(key, 0);
  }

  buildGraph(appendToDoc = true) {
    this.doc =
      this.graphDoc !== undefined
        ? cloud.getDoc(this.graphDoc)
        : cloud.getDoc(this.inputs.get("graph"));
    this.graphDecl = this.doc.d;

    //save visual meta
    this.visuals = {};

    for (var i = 0; i < this.graphDecl.nodes.length; i++) {
      this.visuals[this.graphDecl.nodes[i].id] = this.graphDecl.nodes[i].visual;
    }

    // In Edit app, graph is created by the root EditorApp component
    // When mounting the GraphEditor component, the graph will be attached to the AppSingle
    if (this.scene.app._previewGraph) {
      this.graph = this.scene.app._previewGraph;
    }

    if (!this.graph || this.graph === undefined)
      this.graph = new GraphRouter(this.graphDecl, []);
    this.graph.update(0);

    this.nodeWidth = 120;
    this.gridRowWidth = 200;
    this.gridRowHeight = 200;
    this.rowHeight = 20;

    this.highlightedRows = []; // the ones mouse is hovering over
    this.selectedRows = []; // the ones that have been clicked on
    this.selectedNode = null; // node that has been clicked on

    //    if ( appendToDoc ) document.body.appendChild( this.divGraph )
    if (!this.divGraph.parentElement) document.body.appendChild(this.divGraph);

    this.nodeVisuals = {}; // contains the nodes' HTML representation
    this.conVisuals = []; // contains the connections THREE line representation
    this.lastNodeId = 0; // store highest node ID, increment it and use it as ID when creating a new node

    for (let key of Object.keys(this.graph.nodes)) {
      const node = this.graph.nodes[key];
      if (parseFloat(node.id) > this.lastNodeId)
        this.lastNodeId = parseFloat(node.id);
      this.buildNode(node);
    }

    this.conBaseMat = new THREE.LineBasicMaterial({
      // material for connections
      color: new THREE.Color("blue"),
      linewidth: 5,
      linecap: "round", //ignored by WebGLRenderer
      linejoin: "round", //ignored by WebGLRenderer
    });

    this.conHighlighMat = new THREE.LineBasicMaterial({
      // material for highlighted connections
      color: new THREE.Color("green"),
      linewidth: 5,
      linecap: "round", //ignored by WebGLRenderer
      linejoin: "round", //ignored by WebGLRenderer
    });

    for (let i = 0; i < this.graph.connections.length; i++) {
      const con = this.graph.connections[i];
      con.ind = i;
      this.buildConnectionLine(con);
    }
  }

  buildNode(node) {
    // builds the HTML representation of node

    // Container
    const n = document.createElement("div");

    n.classList.add("Node");
    n.style.width = this.nodeWidth + "px";

    const index = Object.keys(this.nodeVisuals).length;
    // Title
    const p = document.createElement("div");
    p.innerText = index + "." + node.type;
    p.classList.add("NodeTitle");
    n.appendChild(p);

    // Click listened
    const selectNode = this.selectNode.bind(this);
    p.addEventListener("mousedown", (e) => {
      this.mousedown = true;
      selectNode(e, node);
    });

    let height = this.rowHeight;
    const divInputs = [];
    const divOutputs = [];

    // Add refs to HTML in hashmap
    this.nodeVisuals[node.id] = {
      container: n,
      inputs: {},
      outputs: {},
      pos: this.visuals[node.id]
        ? this.visuals[node.id].position
        : { x: 0.5, y: 0.5 },
    };

    if (node.type === "eval") {
      const p = document.createElement("div");
      p.innerText = node.funcStr;
      p.classList.add("NodeTitle");
      n.appendChild(p);

      p.contentEditable = true;

      this.nodeVisuals[node.id].function = p;

      const updateNodeFunction = this.updateNodeFunction.bind(this);
      p.addEventListener("keydown", (e) => updateNodeFunction(e, node));
    }

    if (
      node.type === "midi" ||
      node.type === "midiDrums" ||
      node.type === "midiSynth" ||
      node.type === "midiCCs"
    ) {
      const p = document.createElement("div");
      p.innerText = node.midiInputName;
      p.classList.add("NodeTitle");
      n.appendChild(p);

      p.contentEditable = true;

      this.nodeVisuals[node.id].midiInputName = p;

      const updateMidiNoteInput = this.updateMidiNoteInput.bind(this);
      p.addEventListener("keydown", (e) => updateMidiNoteInput(e, node));
    }

    if (!this.visuals[node.id])
      this.visuals[node.id] = { position: this.nodeVisuals[node.id].pos };

    // Create labels for all inputs and outputs
    for (let type of ["inputs", "outputs"]) {
      if (node[type]) {
        const keys = Object.keys(node[type]._ns);
        height += Object.keys(node[type]._ns).length * this.rowHeight;
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i]; // input/output name
          const ref = this.nodeVisuals[node.id][type];
          divInputs.push(this.addIOLabel(node, ref, key, type));
        }
      }
    }

    //Add zigzag
    let maxCount =
      divInputs.length > divOutputs.length
        ? divInputs.length
        : divOutputs.length;
    let rowCount = 0;
    for (let i = 0; i < maxCount; i++) {
      if (divInputs.length - 1 >= i) {
        // divInputs[i].classList.add("Col0");
        divInputs[i].ind = rowCount;
        n.appendChild(divInputs[i]);
        rowCount++;
      }
      if (divOutputs.length - 1 >= i) {
        // divOutputs[i].classList.add("Col1");
        divOutputs[i].ind = rowCount;
        n.appendChild(divOutputs[i]);
        rowCount++;
      }
    }

    n.style.height = height;
    let visual = this.visuals[node.id];
    if (!visual) return;

    n.style.left = visual.position.x * this.gridRowWidth + "px";
    n.style.top = visual.position.y * this.gridRowHeight + "px";

    this.divGraph.appendChild(n);
  }

  addIOLabel(node, ref, key, type) {
    ref[key] = {};

    const colClass = type === "inputs" ? "Col0" : "Col1";
    let divRow = document.createElement("div");
    divRow.className = "NodeRow";
    divRow.classList.add(colClass);

    ref[key].NodeRow = divRow;

    const value = document.createElement("div");
    value.innerText = node.inputs._ns[key];

    const valueClass = type === "inputs" ? "ValueIn" : "ValueOut";
    value.classList.add(valueClass);
    divRow.appendChild(value);
    ref[key][valueClass] = value;
    value.contentEditable = true;

    const updateFixedValue = this.updateFixedValue.bind(this);
    value.addEventListener("keydown", (e) =>
      updateFixedValue(e, node, key, type)
    );

    const name = document.createElement("div");
    name.innerText = key;
    const nameClass = type === "inputs" ? "NameIn" : "NameOut";
    name.classList.add(nameClass);
    divRow.appendChild(name);
    ref[key][nameClass] = name;

    // name.contentEditable = true
    // const updateLabelName = this.updateLabelName.bind(this)
    // name.addEventListener("keydown",  e => updateLabelName( e, node, key, type ) )

    const unHighlightRows = this.unHighlightRows.bind(this);
    divRow.addEventListener("mouseout", unHighlightRows);

    const showConnection = this.showConnection.bind(this);
    divRow.addEventListener("mouseover", () => showConnection(node, key, type));

    const selectRow = this.selectRow.bind(this);
    divRow.addEventListener("click", () => selectRow(divRow, key, type, node));
    // divRow.appendChild( d )

    return divRow;
  }

  // updateLabelName( e, node, prop, type ) { // updates input name via text field

  //     if ( e.code !== "Enter" ) return // enter key
  //     e.preventDefault()
  //     let val = e.target.textContent
  //     this.nodeVisuals[ node.id ][ type ][ prop ].NameIn.textContent = val
  //    // this.graph.nodes[ node.id ][ type ]._ns[ prop ] = val // update graph
  //     this.unSelectRows()

  // }

  updateMousePosition(e) {
    this.mouse.x = e.clientX;
    this.mouse.y = e.clientY;
  }
  selectNode(e, node) {
    e.stopPropagation();
    console.log("select", node);
    this.unSelectNode();
    this.selectedNode = this.selectedNode ? null : node;
    if (!this.selectedNode) return;
    const vis = this.nodeVisuals[this.selectedNode.id].container;
    vis.classList.add("SelectedNode");
  }
  preventEventDefault(e) {
    e.preventDefault();
  }
  unSelectNode() {
    if (this.selectedNode) {
      const vis = this.nodeVisuals[this.selectedNode.id].container;
      vis.classList.remove("SelectedNode");
      this.selectedNode = null;
    }
  }

  moveNode() {
    // moves a node with the mouse after it has been selected
    if (!this.selectedNode) return;
    const vis = this.nodeVisuals[this.selectedNode.id].container;

    const rect = this.divGraph.getBoundingClientRect();

    vis.style.left = this.mouse.x - 40 - rect.left + "px";
    vis.style.top = this.mouse.y - 40 - rect.top + "px";

    this.nodeVisuals[this.selectedNode.id].pos.x =
      (this.mouse.x - 40) / this.gridRowWidth;
    this.nodeVisuals[this.selectedNode.id].pos.y =
      (this.mouse.y - 40) / this.gridRowHeight;
  }
  deleteNode() {
    if (!this.selectedNode) return;
    this.selectedCon = null;
    const node = this.selectedNode;

    const cons = [];
    this.graph.connections = this.graph.connections.filter((con) => {
      if (con.nodeA !== node.id && con.nodeB !== node.id) {
        return true;
      }
      cons.push(con);
    });

    for (let con of cons) {
      this.deleteConnection(con);
    }
    const vis = this.nodeVisuals[node.id].container;
    vis.parentNode.removeChild(vis);

    delete this.nodeVisuals[node.id];
    delete this.graph.nodes[node.id];
    this.selectedNode = null;
    this.selectedRows = [];
  }

  updateFixedValue(e, node, prop, type) {
    // updates input value via text field
    // console.log( 'UPDATE FIXED VALUE', e, node, prop, type )
    if (e.code !== "Enter") return; // enter key
    e.preventDefault();

    let val = e.target.textContent;

    if (val[0] === "#") {
      // color
      val = val;
    } else {
      val = parseFloat(e.target.textContent);
      if (Number.isNaN(val)) {
        val = 0;
      }
    }

    this.nodeVisuals[node.id][type][prop].ValueIn.textContent = val;
    this.graph.nodes[node.id][type]._ns[prop] = val; // update graph

    console.log("update ", val, this.graph.nodes[node.id][type]._ns[prop]);
    this.unSelectRows();

    e.target.scrollLeft = 0;
    e.target.blur();
  }
  updateNodeFunction(e, node) {
    // updates function for eval noe
    if (e.code !== "Enter") return; // enter key
    e.preventDefault();

    node.setFunction(e.target.textContent);
  }

  updateMidiNoteInput(e, node) {
    // updates function for eval noe
    if (e.code !== "Enter") return; // enter key
    e.preventDefault();

    node.setInput(e.target.textContent);
  }

  calcRowRelPos(nodeId, prop, type) {
    // calculates the 2D coordinates of selected row

    //    console.log( this, nodeId, prop, type )
    const nodeVisual = this.nodeVisuals[nodeId];

    const rowVisual = nodeVisual[type][prop].NodeRow;

    let { left, top, height } = nodeVisual.container.style;
    left = parseFloat(left);
    top = parseFloat(top);

    const cx = type === "inputs" ? left : left + this.nodeWidth; // 40px comes from .Graph style
    const cy = top + rowVisual.offsetTop + 10; // 40px comes from .Graph style

    const rect = this.divGraph.getBoundingClientRect();
    // calculate the row's height within the node
    let xr = cx / rect.width;
    xr = (xr - 0.5) * 2;
    let yr = cy / rect.height;
    yr = (0.5 - yr) * 2;

    return { x: xr, y: yr };
  }
  selectRow(row, name, type, node) {
    // called when a row is clicked
    // selects a row, can add a connection
    // if another row is already selected, check if it belongs to another node
    if (this.selectedRows.length === 0) {
      this.selectedRows.push({
        row: row,
        name: name,
        node: node,
        type: type,
      });
      row.classList.add("Clicked");
      if (type === "inputs") {
        const cons = this.findConnections(node, name, type);
        if (!cons) {
          // selected an input with no connections: edit it with text field
        } else {
          this.selectedCon = cons[0];
        }
      }
      return;
    }

    // if no or the two rows are both inputs (or outputs)
    // unselect the already selected node
    const r = this.selectedRows[0];
    if (type === r.type || node.id === r.node.id) {
      r.row.classList.remove("Clicked");
    } else {
      const cons = this.findConnections(node, name, type);

      if (!cons) {
        const connection = {
          nodeA: type === "outputs" ? node.id : r.node.id,
          nodeB: type === "inputs" ? node.id : r.node.id,
          propA: type === "outputs" ? name : r.name,
          propB: type === "inputs" ? name : r.name,
          ind: this.graph.connections.length,
        };
        r.row.classList.remove("Clicked");
        this.graph.connections.push(connection);
        this.buildConnectionLine(connection);
        this.graph.updateFinalNodeConnectedInputList();
      } else if (type === "inputs") {
        // when an input with connections is selected, it will only have one connection
        this.selectedCon = cons[0]; // only one connection per input
      }
    }
    this.selectedRows = [];
  }
  unHighlightRows() {
    // unhighlights rows/connections on mouseout
    if (!this.highlightedRows) return;

    for (let n of this.highlightedRows) {
      n.classList.remove("InSelected");
      n.classList.remove("OutSelected");
    }
    this.highlightedRows = [];
    for (let con of this.conVisuals) {
      con.material = this.conBaseMat;
      con.material.needsUpdate = true;
    }
  }
  unSelectRows() {
    // unselects row when click satisfies certain conditions
    if (!this.selectedRows) return;

    for (let n of this.selectedRows) {
      n.row.classList.remove("Clicked");
      n.row.classList.remove("Clicked");
    }
    this.selectedRows = [];
  }

  buildConnectionLine(con) {
    // connetion example
    // {nodeA: "time", nodeB: "1", propA: "time", propB: "val"}
    // transform nodes' coordinates to 3D

    // nodes' positions relative to canvas
    const posA = this.calcRowRelPos(con.nodeA, con.propA, "outputs");
    const posB = this.calcRowRelPos(con.nodeB, con.propB, "inputs");

    // project them onto screen
    posA.x *= this.screenRect.x;
    posA.y *= this.screenRect.y;

    posB.x *= this.screenRect.x;
    posB.y *= this.screenRect.y;

    // the positions are obtained from left and top coordinates

    // build line joining them

    var geometry = new THREE.BufferGeometry();
    const vertices = new Float32Array(6);
    vertices[0] = posA.x;
    vertices[1] = posA.y;
    vertices[2] = 0;
    vertices[3] = posB.x;
    vertices[4] = posB.y;
    vertices[5] = 0;
    geometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(vertices, 3)
    );

    var line = new THREE.Line(geometry, this.conBaseMat);
    line.userData.ind = con.ind;
    this.cont3D.add(line);
    this.conVisuals.push(line);

    // save line
  }
  isIdenticalConnection(conA, conB) {
    return (
      conA.nodeA === conB.nodeA &&
      conA.nodeB === conB.nodeB &&
      conA.propA === conB.propA &&
      conA.propB === conB.propB
    );
  }
  findConnections(node, key, type) {
    // returns array of connections or null

    let con = [];
    if (type === "inputs") {
      // input node is nodeB
      con = this.graph.connections.filter((con) => {
        return con.nodeB === node.id && con.propB === key;
      });
    } else {
      con = this.graph.connections.filter((con) => {
        // output node is node A
        return con.nodeA === node.id && con.propA === key;
      });
    }
    return con.length ? con : null;
  }
  showConnection(node, key, type) {
    // highlights connected values and line in green

    const cons = this.findConnections(node, key, type);

    if (!cons) return;
    for (let con of cons) {
      const nodeA = this.nodeVisuals[con.nodeA];
      const nodeB = this.nodeVisuals[con.nodeB];
      const propA = con.propA;
      const propB = con.propB;

      nodeA.outputs[propA].NodeRow.classList.add("OutSelected");
      nodeB.inputs[propB].NodeRow.classList.add("InSelected");

      const line = this.conVisuals.find((c) => c.userData.ind === con.ind);
      if (!line) {
        console.log("no line for: ", con, propA, propB);
      }
      line.material = this.conHighlighMat;
      line.material.needsUpdate = true;

      this.highlightedRows.push(
        nodeA.outputs[propA].NodeRow,
        nodeB.inputs[propB].NodeRow
      );
    }
  }
  deleteConnection(connection) {
    let con;
    if (this.selectedCon) {
      con = this.selectedCon;
    } else {
      con = connection;
    }

    if (!con || typeof con === "undefined") {
      return;
    }
    this.graph.connections = this.graph.connections.filter(
      (c) => c.ind !== con.ind
    );
    const conToDel = this.conVisuals.find((c) => c.userData.ind === con.ind);
    this.graph.updateFinalNodeConnectedInputList();
    if (!conToDel) return;

    conToDel.geometry.dispose();

    this.cont3D.remove(conToDel);
    this.conVisuals = this.conVisuals.filter((c) => c.userData.ind !== con.ind);
    this.unHighlightRows();
    this.unSelectRows();
  }
  updateConnectionLine(con, ind) {
    // updates connection line position in case its nodes have moves

    // connetion example
    // {nodeA: "time", nodeB: "1", propA: "time", propB: "val"}
    // transform nodes' coordinates to 3D

    // nodes' positions relative to canvas
    const posA = this.calcRowRelPos(con.nodeA, con.propA, "outputs");
    const posB = this.calcRowRelPos(con.nodeB, con.propB, "inputs");

    // project them onto screen
    posA.x *= this.screenRect.x;
    posA.y *= this.screenRect.y;

    posB.x *= this.screenRect.x;
    posB.y *= this.screenRect.y;

    const line = this.conVisuals[ind];

    line.geometry.attributes.position.array[0] = posA.x;
    line.geometry.attributes.position.array[1] = posA.y;
    //  line.geometry.attributes.position.array[ 2 ] = 0
    line.geometry.attributes.position.array[3] = posB.x;
    line.geometry.attributes.position.array[4] = posB.y;
    line.geometry.attributes.position.needsUpdate = true;
  }
  //called every frame, dt: time passed between frames
  update(dt) {
    if (!dt) return;

    // console.log('Update graph')
    // if ( ! this.divGraph.visible ) return this.graph.update( dt )

    try {
      if (this.internalUpdate) this.graph.update(dt);
      if (this.mousedown) {
        this.moveNode();
      }
      this.updateLines();
      this.updateNodes();
    } catch (err) {
      console.warn("Error >>>> ", err);
      if (this.errorCount === 0)
        toast("Error in update function, check the console for more details");
      this.errorCount++;
    }
  }
  updateLines() {
    for (let i = 0; i < this.graph.connections.length; i++) {
      this.updateConnectionLine(this.graph.connections[i], i);
    }
  }
  updateNodes() {
    const nodeNames = Object.keys(this.graph.nodes);
    for (let nodeName of nodeNames) {
      // update nodes

      const label = this.nodeVisuals[nodeName];
      const node = this.graph.nodes[nodeName];

      if (node.inputs._ns) {
        const inputNames = Object.keys(node.inputs._ns);
        for (let i = 0; i < inputNames.length; i++) {
          const inputName = inputNames[i];
          const val = label.inputs[inputName].ValueIn;
          let v;
          if (node.inputs._ns[inputName].isVector3) {
            const vec = node.inputs._ns[inputName];
            v = `{x:${vec.x},y:${vec.y},z:${vec.z}}`;
          } else if (node.inputs._ns[inputName].isVector2) {
            const vec = node.inputs._ns[inputName];
            v = `{x:${vec.x},y:${vec.y}}`;
          } else if (node.inputs._ns[inputName].isColor) {
            const col = node.inputs._ns[inputName];
            v = col.getHexString();
            val.style.backgroundColor = "#" + v;
          } else if (typeof node.inputs._ns[inputName] === "string") {
            color.set(node.inputs._ns[inputName]);

            val.style.backgroundColor = "#" + color.getHexString();
          } else {
            v = parseFloat(node.inputs._ns[inputName]).toFixed(4);
          }

          if (typeof v === "number" && v >= 0) v = "+" + v;
          const cons = this.findConnections(node, inputName, "inputs");
          if (cons) val.textContent = v;
        }
      }
      if (node.outputs._ns) {
        const outputNames = Object.keys(node.outputs._ns);

        for (let i = 0; i < outputNames.length; i++) {
          const outputName = outputNames[i];
          let val;
          try {
            val = label.outputs[outputName].ValueOut;
          } catch (err) {
            return;
            if (!window._errorCount) window.errorCount = 1;
            if (window._errorCount > 10) return;
            console.log(
              "ERROR UPADTING",
              "outputname",
              outputName,
              "label",
              label,
              err
            );
            //throw new Error()
            window._errorCount++;
            continue;
          }

          let v;
          if (node.outputs._ns[outputName].isVector3) {
            const vec = node.outputs._ns[outputName];
            v = `{x:${vec.x},y:${vec.y},z:${vec.z}}`;
          } else if (node.outputs._ns[outputName].isVector2) {
            const vec = node.outputs._ns[outputName];
            v = `{x:${vec.x},y:${vec.y}}`;
          } else if (node.outputs._ns[outputName].isColor) {
            const col = node.outputs._ns[outputName];
            v = col.getHexString();
            val.style.backgroundColor = "#" + v;
          } else {
            v = parseFloat(node.outputs._ns[outputName]).toFixed(4);
          }
          if (v >= 0) v = "+" + v;
          val.textContent = v;
        }
      }
    }
  }

  saveGraph() {
    // saves current graph to the cloud
    console.log("SAVE GRAPH ");
    const graph = {};
    graph.conns = this.graph.connections;
    graph.nodes = [];
    const keys = Object.keys(this.graph.nodes);
    for (let i = 0; i < keys.length; i++) {
      graph.nodes.push(this.serialiseNode(this.graph.nodes[keys[i]], i));
    }

    this.doc.d = graph;
    cloud
      .update(this.doc)
      .then((res) => {
        if (res && res.docs && res.docs.length) {
          toast("Graph updated");
          const event = new CustomEvent("graph:save", {
            detail: {
              doc: res.docs[0],
            },
          });
          window.dispatchEvent(event);
        }

        console.log(res);
      })
      .catch((err) => {
        toast("Error updating graph: " + err.message);
        console.log(err);
      });
  }
  serialiseNode(node) {
    // transforms the node data into a format readable by GraphRouter class
    // find the index of the nodetype
    let type = node.type;
    // nodeTypes.forEach( (n, i ) => {
    //     if ( n.t === node.type ) {
    //         type = i

    //     }

    //  })
    const serialisedNode = {
      id: node.id,
      type: type,
      visual: {
        position: this.nodeVisuals[node.id].pos,
      },
    };
    if (
      node.inputs._ns &&
      typeof node.inputs._ns === "object" &&
      Object.keys(node.inputs._ns).length
    ) {
      serialisedNode.inputs = node.inputs._ns;

      for (let key in serialisedNode.inputs) {
        if (serialisedNode.inputs[key].isColor) {
          console.warn("serialise color");
          serialisedNode.inputs[key] =
            "#" + serialisedNode.inputs[key].getHexString();
        }
      }
    }

    if (node.funcStr) serialisedNode.funcStr = node.funcStr; // if Eval Node
    if (node.midiInputName) serialisedNode.midiInputName = node.midiInputName;
    if (node.CCnotes) serialisedNode.CCnotes = node.CCnotes;

    return serialisedNode;
  }
}

// DO NOT DELETE THIS SHIT

typeManager.registerClass(
  typeManager.typeNameToId("Comp.GraphEditor"),
  GraphEditor
);

export default GraphEditor;
