import cloud from "@cloud/VJYCloudClient";
import input from "@input/InputManager";
import parseMidiMessage from "@input/MIDITools";
import Listeners from "@rt/Listeners";

class MIDIRouter {
  /*
	params
		source : Synth
		targets : Synth[]
	*/

  constructor(params) {
    this.params = params;
    this.enabled = params.enabled;
    this.listeners = new Listeners();
    if (this.enabled) {
      console.log("Router Init", params);

      // Connect to Source
      let doc = cloud.getDoc(params.source);
      this.sourceDoc = doc;
      this.sourceDevice = input._midi.findInputDevice(doc.d.midiDeviceIn);
      if (this.sourceDevice != null) {
        this.sourceDevice.addEventListener(
          "midimessage",
          this.onMidiMsg.bind(this)
        );
      } else {
        this.enabled = false;
      }

      // Connect to Clock Source
      this.clockEnabled = false;
      if (params.clockEnabled) {
        doc = cloud.getDoc(params.clockSource);
        this.clockSourceDevice = input._midi.findInputDevice(
          doc.d.midiDeviceIn
        );
        if (this.clockSourceDevice != null) {
          this.clockSourceDevice.addEventListener(
            "midimessage",
            this.onMidiMsgClock.bind(this)
          );
          this.clockEnabled = true;
        } else {
          this.clockEnabled = false;
        }
      }

      //console.log("Router Source",this.sourceDevice);

      // Find Targets
      this.targetDevices = [];
      this.targetsEnabled = [];
      this.targetsRange = [];
      this.targetDocs = [];
      for (let i = 0; i < this.params.targets.length; i++) {
        let doc = cloud.getDoc(params.targets[i]);
        this.targetDevices.push(
          input._midi.findOutputDevice(doc.d.midiDeviceOut)
        );
        this.targetsEnabled.push(true);
        this.targetsRange.push({ type: "Full" });
        this.targetDocs.push(doc);
      }
      //console.log("Router Targets",this.targetDevices);

      // Create Filters
      this.sourceFilter = new MIDIFilterBasic(this.params.sourceFilter);

      //Pedals //////////////////////////////////////////////////////////////
      this.pedalsEnabled = false;
      doc = cloud.getDoc(params.pedals);
      this.pedalsDevice = input._midi.findInputDevice(doc.d.midiDeviceIn);
      if (this.pedalsDevice != null) {
        this.pedalsEnabled = true;
        this.pedalsDevice.addEventListener(
          "midimessage",
          this.onMidiMsgPedals.bind(this)
        );
      }
    }
  }
  getPreset() {
    let preset = {};
    preset.targets = [];
    if (this.enabled) {
      for (let i = 0; i < this.targetDevices.length; i++) {
        preset.targets.push({
          enabled: this.targetsEnabled[i],
          noteRange: this.targetsRange[i],
          deviceId: this.targetDocs[i]._id,
          deviceName: this.targetDocs[i].m.n,
        });
      }
    }
    return preset;
  }
  setPreset(preset) {
    console.log("Router - setPreset");
    if (preset == null) return;
    if (preset.targets != null) {
      for (let i = 0; i < preset.targets.length; i++) {
        for (let ii = 0; ii < this.targetDevices.length; ii++) {
          if (preset.targets[i].deviceId == this.targetDocs[ii]._id) {
            this.targetsEnabled[ii] = preset.targets[i].enabled;
            this.targetsRange[ii] = preset.targets[i].noteRange;
          }
        }
      }
    }
    this.listeners.fire("Update");
  }
  onMidiMsg(ev) {
    if (this.enabled) {
      let msgData = ev.data;
      let msg = parseMidiMessage(ev.data);
      console.log("Router In", msg, ev.data);
      console.log("Filter:", this.sourceFilter.filterMessage(msg));
      // source filter
      if (this.sourceFilter.filterMessage(msg)) {
        // source trans
        switch (msg.type) {
          case "CC":
            if (msg.cc == 11) {
              msgData[1] = 1;
            }
          case "NoteOn":
          case "NoteOff":
            // if(msg.note <60) doit=false;
            break;
        }
        // hit targets
        for (let i = 0; i < this.targetDevices.length; i++) {
          if (this.targetsEnabled[i] && this.targetDevices[i] != null) {
            //Custom Device Specific Transforms
            switch (this.targetDocs[i].t) {
              case "Synth.RolandJP8080":
                if (msg.type == "CC" && msg.cc == 11) {
                  msgData = [13 * 16, msg.value];
                }
                break;
              case "Synth.KorgRadias":
                break;
              default:
            }

            //Note Range
            if (msg.type == "NoteOn" || msg.type == "NoteOff") {
              let sendNote = false;
              console.log(i, this.targetsRange[i].type);
              switch (this.targetsRange[i].type) {
                case "Full":
                  sendNote = true;
                  break;
                case "Treble":
                  sendNote = msg.note >= 53;
                  break;
                case "Bass":
                  sendNote = msg.note < 53;
                  break;
              }
              if (sendNote)
                input._midi.sendMidiRawMsg(this.targetDevices[i], msgData);
            } else {
              input._midi.sendMidiRawMsg(this.targetDevices[i], msgData);
            }
          }
        }
      }
    }
  }
  onMidiMsgClock(ev) {
    if (this.enabled && this.clockEnabled) {
      let msgData = ev.data;
      let msg = parseMidiMessage(ev.data);
      //console.log("CLK");
      // hit targets
      for (let i = 0; i < this.targetDevices.length; i++) {
        if (this.targetsEnabled[i] && this.targetDevices[i] != null) {
          //console.log(this.targetDocs[i].t);
          input._midi.sendMidiRawMsg(this.targetDevices[i], msgData);
        }
      }
      this.listeners.fire("Clock", ev.data);
    }
  }

  onMidiMsgPedals(ev) {
    if (this.pedalsEnabled) {
      let msgData = ev.data;
      let msg = parseMidiMessage(ev.data);
      console.log("Router Pedals In", msg);
      //console.log("Filter:",this.sourceFilter.filterMessage(msg));
      // source filter
      if (msg.type == "CC" && msg.cc == 28) {
        msg.cc = 1;
        for (let i = 0; i < this.targetDevices.length; i++) {
          if (this.targetsEnabled[i] && this.targetDevices[i] != null) {
            console.log("Router.Pedal", this.targetDocs[i].t, msg);
            input._midi.sendMidiMsg(this.targetDevices[i], 0, msg);
            //input._midi.sendMidiRawMsg(this.targetDevices[i],[176,1,msg.value]);
          }
        }
      }
      return;
      switch (msg.type) {
        case "CC":
          if (msg.cc == 27) {
            console.log("Pedal A", msg.value);
            let volA = 0;
            let volB = 127;
            /*
						if(msg.value<64){
							volA=127;
							volB=msg.value*2;
						}else{
							volA=127-(msg.value-64)*2;
							volB=127;
						}*/
            volA = 127 - msg.value;
            msgData[1] = 7;
            msgData[2] = volA;
            if (this.targetsEnabled[0] && this.targetDevices[0] != null)
              input._midi.sendMidiRawMsg(this.targetDevices[0], msgData);
            //msgData[2]=volB;
            //if(this.targetsEnabled[1] && this.targetDevices[1]!=null) input._midi.sendMidiRawMsg(this.targetDevices[1],msgData);
          }
          if (msg.cc == 28) {
            console.log("Pedal B", msg.value);
            msgData[1] = 1;
            if (this.targetsEnabled[1] && this.targetDevices[1] != null)
              input._midi.sendMidiRawMsg(this.targetDevices[1], msgData);
          }
          break;
      }
    }
  }
}
/*
params:
	enabled : boolean
	note : FilterBasic
	cc : FilterBasic
	prgchg : FilterBasic
	others : boolean

FilterBasic
	default:true/false
	listIn:[],
	listEx:[],
	rangeIn:[],
	rangeEx:[]
*/

class MIDIFilterBasic {
  constructor(params) {
    this.params = params;
  }
  filterMessage(msg) {
    if (!this.params.enabled) return true;
    switch (msg.type) {
      case "NoteOn":
      case "NoteOff":
        return this.checkCondition(msg.note, this.params.note);
        break;

      case "CC":
        return this.checkCondition(msg.value, this.params.cc);
        break;
      case "PrgChg":
        return this.checkCondition(msg.value, this.params.prgchg);
        break;
    }
    return this.params.others;
  }
  checkCondition(value, filter) {
    //all / none
    let ret = filter.default;
    //list
    if (filter.listIn != null) ret = ret || filter.listIn.includes(value);
    if (filter.listEx != null) ret = ret && !filter.listEx.includes(value);
    //range
    if (filter.rangeIn != null)
      ret = ret || this.checkRanges(value, filter.rangeIn);
    if (filter.rangeEx != null)
      ret = ret && !this.checkRanges(value, filter.rangeEx);
    return ret;
  }
  checkRanges(value, ranges) {
    return value >= ranges[0] && value <= ranges[1];
  }
}

class MIDITrans {
  constructor(params) {}
  transMessage(msg) {
    return msg;
  }
}

export default MIDIRouter;
/*
	ROUTWER UI
	if(this.router.enabled){
				this.router.div = html.addDiv(this.divSetup,"MusicSetup-Router");
				
				this.router.divInfo = html.addDiv(this.router.div,"MusicSetup-Router-Info","ROUTER");
				html.addBr(this.router.divInfo);
				this.router.cbEnabled = html.addCheckbox(this.router.divInfo,"",true);
				this.router.cbEnabled.onchange = this.updateRouterCbs;
				html.addLabel(this.router.divInfo,"","enabled");
				html.addBr(this.router.divInfo);
				for(let i=0;i<this.router.tos.length;i++){
					this.router.tos[i].cbEnabled = html.addCheckbox(this.router.divInfo,"",this.router.tos[i].enabled);
					this.router.tos[i].cbEnabled.onchange = this.updateRouterCbs;
					html.addLabel(this.router.divInfo,"",this.router.tos[i].name);
					html.addBr(this.router.divInfo);
				}
			
				this.router.divData = html.addDiv(this.router.div,"MusicSetup-Router-Data");
				
			}

*/

/*

	INIT
		//Clock
		this.devClock = input._midi.findInputDevice("Clock");
		if(this.devClock!=null) {
			//console.log("CLOCK",this.devClock);
			this.devClock.onmidimessage = this.onMidiClock.bind(this);
		}
		//Router
		const routerDoc = this.inputs.get("routers")[this.preset.routerInd];
		this.midiRouter = new MIDIRouter(routerDoc);



		
	updateRouterCbs(){
		this.router.enabled = this.router.cbEnabled.checked;
		for(let i=0;i<this.router.tos.length;i++) this.router.tos[i].enabled = this.router.tos[i].cbEnabled.checked; 
	}
	onMidiClock(ev){
		//ROUTER
		if(this.router.enabled){
	
				let msgData = ev.data;
				//this.router.divData.innerHTML+=msgData+"; ";
				for(let i=0;i<this.router.tos.length;i++){
					if(this.router.tos[i].enabled) input._midi.sendMidiRawMsg(this.router.tos[i].dev,msgData);
				}
			}
	}

	ON MSG


*/
