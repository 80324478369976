import { TextureLoader, FileLoader, VideoTexture, WebGLRenderer } from "three";
import modelLoader from "@three-extra/asset/ModelLoader";
import { readGif } from "@three-extra/asset/GIF";
import cloud from "@cloud/VJYCloudClient";
import { clone } from "@three-extra/util/SkeletonUtils";

import { cloneDeep } from "lodash";
import Listeners from "@rt/Listeners";
import monitor from "@rt/monitoring/Monitor";
import typeManager from "@cloud/TypeManager";
const { typeNameToId } = typeManager;
const renderer = new WebGLRenderer({
  canvas: document.createElement("canvas"),
});

// https://github.com/mrdoob/three.js/issues/10439
// Adapted from https://github.com/mrdoob/three.js/issues/10439#issuecomment-293260145
// import * as THREE from "three";

/**
 * THREE's texture loader doesn't support onProgress events, because it uses image tags under the hood.
 *
 * A relatively simple workaround is to AJAX the file into the cache with a FileLoader, create an image
 * from the Blob, then extract that into a texture with a separate TextureLoader call.
 *
 * The cache is in memory, so this will work even if the server doesn't return a cache-control header.
 *
 * Loads THREE Textures with progress events.
 * @extends THREE.TextureLoader
 */
// class AsyncTextureLoader extends THREE.TextureLoader {
//   constructor() {
//     super();
//     // Turn on shared caching for FileLoader, ImageLoader and TextureLoader
//     THREE.Cache.enabled = true;

//     this.fileLoader = new THREE.FileLoader();
//     this.fileLoader.setResponseType("blob");
//   }

//   load(url, onProgress) {
//     const mimeOverrides = [["svg", "image/svg+xml"]];

//     return new Promise((resolve, reject) => {
//       /**
//        * The cache is currently storing a Blob, but we need to cast it to an Image or else it
//        * won't work as a texture. TextureLoader won't do this automatically.
//        *
//        * Use an arrow function to prevent creating a new this binding, which would prevent the
//        * usage of super methods.
//        */
//       const cacheImage = (blob) => {
//         // Change the MIME type if necessary
//         const extension = url.match(/\.([\w\d]+)$/);
//         for (let override of mimeOverrides) {
//           if (extension[1] == override[0]) {
//             blob = new Blob([blob], { type: override[1] });

//             break;
//           }
//         }

//         // Object URLs should be released as soon as is safe, to free memory
//         const objUrl = URL.createObjectURL(blob);
//         const image = document.createElementNS(
//           "http://www.w3.org/1999/xhtml",
//           "img"
//         );

//         const errorHandler = (error) => {
//           reject(error);
//         };
//         const loadHandler = () => {
//           // Cache the texture
//           THREE.Cache.add(url, image);
//           // Release the object URL
//           URL.revokeObjectURL(objUrl);
//           // Remove image listeners
//           image.removeEventListener("error", errorHandler, false);
//           image.removeEventListener("load", loadHandler, false);
//           document.body.removeChild(image);
//           // Load image as texture
//           super.load(url, resolve, () => {}, reject);
//         };

//         // Add image listeners
//         image.addEventListener("error", errorHandler, false);
//         image.addEventListener("load", loadHandler, false);

//         image.src = objUrl;
//         image.style.visibility = "hidden";
//         document.body.appendChild(image);
//       };

//       const cacheEntry = THREE.Cache.get(url);
//       if (cacheEntry) {
//         // Load cached image as texture
//         super.load(url, resolve, () => {}, reject);
//       } else {
//         this.fileLoader.load(url, cacheImage, onProgress, reject);
//       }
//     });
//   }
// }

class ExtAssetCache {
  constructor() {
    this.typesById = {};
    this.texLoader = new TextureLoader();
    // this.texLoader = new AjaxTextureLoader();
    this.fileLoader = new FileLoader();
    this.fileLoader.responseType = "arraybuffer";
    this.textures = {};
    this.textureUrlsById = {};
    this.geometries = {};
    this.models = {};

    this.listeners = new Listeners();
    cloud.registerExternalAssetCache(this);

    window.assetCount = 0;
    window.ea = this;
  }

  get(docOrLinkOrId) {
    const id = getId(docOrLinkOrId);
    const type = this.typesById[id];
    if (
      type === typeNameToId("Texture2D") ||
      type === typeNameToId("HDRTexture")
    ) {
      const url = this.textureUrlsById[id];
      return this.textures[url];
    } else if (type === typeNameToId("Geometry.Model")) {
      return this.geometries[id];
    } else if (type === typeNameToId("Model")) {
      // THREE.js loaders don't return an Object3D, but instead an object containring scene / animations etc
      // the ModelLoader processes this so that the loader returns a scene, and we add a reference to the object returned by the THREE.js loaders
      // to that scene's userData ( userData.initModel )
      // before cloning, we need to remove that object to avoid circular dependencies, and after that we can reisntate it
      const { userData } = this.models[id];
      this.models[id].userData = {};
      const _clone = clone(this.models[id]);
      _clone.userData = userData;
      this.models[id].userData = userData;
      return _clone;
    }
  }

  dispose(docOrLinkOrId) {
    const id = getId(docOrLinkOrId);
    const type = this.typesById[id];
    if (
      type === typeNameToId("Texture2D") ||
      type === typeNameToId("HDRTexture")
    ) {
      const url = this.textureUrlsById[id];
      this.disposeTexture(url);
      delete this.typesById[id];
      delete this.textureUrlsById[id];
    } else if (type === typeNameToId("Geometry.Model")) {
      // TODO dispose
      delete this.geometries[id];
    } else if (type === typeNameToId("Model")) {
      // TODO dispose
      delete this.models[id];
    }
  }

  async load(doc) {
    // don't load thumbnails if cloud settings ask for it
    const { _settings } = cloud;
    if (
      !_settings.loadThumbnails &&
      doc?.m?.tags &&
      doc?.m?.tags.length &&
      doc?.m?.tags.includes("thumbnail")
    ) {
      monitor.log("ExtAssetCache", "ignore thumbnail load", { doc });
      return;
    }
    if (_settings.noLoad) return;

    let asset;
    if (
      doc.t === typeNameToId("Texture2D") ||
      doc.t === typeNameToId("VideoTexture") ||
      doc.t === typeNameToId("HDRTexture")
    ) {
      let { url } = doc.d.baseObj.asset[">ext"];
      if (this.baseUrl && !url.startsWith("http")) url = this.baseUrl + url;
      const options = getOptions(doc.m);
      if (doc.t === typeNameToId("VideoTexture")) options.video = true;
      if (doc.t === typeNameToId("HDRTexture")) options.hdr = true;
      asset = await this.loadTexture(url, options);
      asset.userData = asset.userData || {};
      asset.userData.doc = doc;
      this.textureUrlsById[doc._id] = url;
      this.typesById[doc._id] = doc.t;
    } else if (doc.t === typeNameToId("Geometry.Model")) {
      if (this.geometries[doc._id]) return this.geometries[doc._id];
      // const { scene, initModel }= await this.loadModel(doc.d);
      // const { geometry } = scene; // XXX
      const model = await this.loadModel(doc.d, doc);
      const { geometry } = model; // XXX
      asset = geometry;
      this.geometries[doc._id] = geometry;
      this.typesById[doc._id] = doc.t;
    } else if (doc.t === typeNameToId("Model")) {
      if (this.models[doc._id]) {
        return this.models[doc._id];
      }

      asset = await this.loadModel(doc.d, doc);
      this.models[doc._id] = asset;
      this.typesById[doc._id] = doc.t;
    } else if (doc.t === typeNameToId("HDRTexture")) {
    } else if (doc.t === typeNameToId("ConditionalModel")) {
    }

    window.assetCount++;
    if (this.hasPreloader) {
      this.listeners.fire("assetLoaded", { doc, asset });
    }

    monitor.log("ExtAssetCache", "load", { doc, asset });
    return asset;
  }

  loadModel(d, doc) {
    if (this.baseUrl) {
      d = cloneDeep(d);
      const ext = d.asset[">ext"];
      ext.url = this.baseUrl + ext.url;
    }
    return modelLoader.load(d, doc);
  }

  async loadTexture(url, options = {}) {
    if (this.textures[url]) return this.textures[url];
    let result;
    if (options.gif) {
      const data = await new Promise((resolve, reject) => {
        this.fileLoader.load(url, resolve, null, reject);
      });
      if (!data) throw new Error("No data");
      const gif = await readGif(new Uint8Array(data));
      result = {
        type: "animated:multi",
        textures: gif.textures,
        delays: gif.delays,
      };
    } else if (options.video) {
      const video = document.createElement("video");
      document.body.appendChild(video);
      video.style.display = "none";
      video.crossOrigin = "anonymous";
      video.src = url;

      video.load();

      document.addEventListener("click", (_) => video.play(), { once: true });
      result = new VideoTexture(video);
    } else if (options.hdr) {
      if (!this.RGBELoader) {
        console.warn(
          "ExtAssetCache >>> no RGBELoader present to loader HDR texture"
        );
        console.log(this);
        throw new Error(
          "ExtAssetCache >>> no RGBELoader present to loader HDR texture"
        );
      }
      const texture = await new Promise((resolve, reject) => {
        console.log("RGBE URL", url);
        this.RGBELoader.load(url, function (texture, textureData) {
          resolve(texture);
        });
      });
      result = {
        type: "still",
        texture,
      };
    } else {
      const texture = await new Promise((resolve, reject) => {
        this.texLoader.load(
          url,
          resolve,
          (e) => console.log("tex load", e),
          reject
        );
      });
      if (options.sprites) {
        result = {
          type: "animated:sprites",
          texture,
          frameCount: options.frameCount,
          frameWidth: options.frameWidth,
          frameHeight: options.frameHeight,
          fps: options.fps,
        };
      } else {
        result = {
          type: "still",
          texture,
        };
      }
    }
    if (options.video) window.tex = result;
    this.textures[url] = result;
    return result;
  }

  disposeTexture(url) {
    if (this.textures[url]) {
      const { type } = this.textures[url];
      if (type === "animated:multi") {
        for (const texture of this.textures[url].textures) {
          texture.dispose();
        }
      } else {
        this.textures[url].texture.dispose();
      }
      delete this.textures[url];
    }
  }

  setBaseUrl(baseUrl) {
    this.baseUrl = baseUrl;
  }
}

function getId(docOrLinkOrId) {
  if (typeof docOrLinkOrId === "object") {
    if (docOrLinkOrId._id) return docOrLinkOrId._id;
    else if (docOrLinkOrId[">link"]) return docOrLinkOrId[">link"].id;
  }
  return docOrLinkOrId;
}

function getOptions(m = {}) {
  const options = {};
  if (m.fields) {
    if (m.fields.format === "gif") {
      options.gif = true;
    } else if (m.tags && m.tags.indexOf("spritesheet") >= 0) {
      options.sprites = true;
      options.frameCount = parseInt(m.fields.frameCount, 10);
      options.frameWidth = parseInt(m.fields.frameWidth, 10);
      options.frameHeight = parseInt(m.fields.frameHeight, 10);
      options.fps = parseFloat(m.fields.fps);
    }
  }
  return options;
}

const extAssetCache = new ExtAssetCache();
export default extAssetCache;
