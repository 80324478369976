import * as THREE from "three";
import Component from "@rt/Component";
import typeManager from "@cloud/TypeManager";
import objectManager from "@cloud/ObjectManager";

import cloud from "@cloud/VJYCloudClient";
import { fSplice } from "@data-trans/fsplice";

import VisCompUpdater from "@rt/nodes/ProxyUpdaters/VisCompUpdater";
const { typeNameToId } = typeManager;

class VisComp extends Component {
  constructor() {
    super();
    this.isVisual = true;
    this.inputs.component = this;
    this.inputs.autoUpdate = false;

    this.inputs.setAutoUpdate = (autoUpdate) => {
      if (autoUpdate) {
        const obj = this.inputs.getAll();
        for (let key in obj) {
          if (this[key]) {
            console.warn(
              `Composition with id ${
                this[">link"].id
              } already has a property ${key} of type ${typeof this[
                key
              ]} that will be overwritten by its inputs. Please update its TypeDefinition or code to avoid errors. For now, composition.${key} will not be set to the value from teh input. `
            );
            continue;
          }
          this[key] = obj[key];
        }
      }

      this.inputs.autoUpdate = autoUpdate;
    };
  }
  start() {
    // console.log("VISCOMP START !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",this);
    this.container = this.cont3D || new THREE.Group();
    this.container.visComp = this;

    this.cont3D = this.container;

    this.app = {
      scene: this.scene,
      renderer: this.renderer,
      me: this.me,
    };

    this.parent = null;
    this._children = [];
    this._toUpdate = [];
    this._rebuild();

    for (let child of this._children) {
      child.parent = this;
      child.me = this.me;
      this._toUpdate.push(child);
    }

    this.inputs.listeners.add("children", this._rebuild.bind(this));
    this.inputs.listeners.add(
      "childrenTrans",
      this._updateContainersTransform.bind(this)
    );

    // Add listener for all changes
    this.inputs.listeners.add(null, this._inputChanged);

    this._updateContainersTransform();

    const doc = cloud.getDoc(this[">link"]);

    if (!doc.d[">animation"] || this?.scene?.app?.isIframePreview) return;
    const graphDoc = cloud.getDoc(doc.d[">animation"]);
    const updater = new VisCompUpdater({
      graphDoc: graphDoc,
      target: this,
    });
    this._toUpdate.push(updater);
  }
  /**
   * Private method, transforms the event data if necessary
   * @param {*} ev
   */
  _inputChanged = (ev) => {
    const { type } = ev;

    const { baseMat, defaultMat } = this.scene;
    const defGeom = this._defGeom;

    if (this.inputs.autoUpdate)
      this[type] = this.inputs.getObject(type, { baseMat, defGeom, defGeom });

    this.inputChanged({
      type,
    });
  };
  /**
   * Overwritten by child classes
   * @param {} ev
   */
  inputChanged(ev) {}
  _updateContainersTransform() {
    const params = this.inputs.get("childrenTrans");
    if (params == null || params.length == 0) return;
    for (let i = 0; i < this._children.length; i++) {
      const container = params[i % params.length];
      for (let axis of ["x", "y", "z"]) {
        if (container.position[axis] !== undefined)
          this._children[i].cont3D.position[axis] = container.position[axis];
        if (container.rotation[axis] !== undefined)
          this._children[i].cont3D.rotation[axis] =
            (container.rotation[axis] / 180) * Math.PI;
        if (container.scale[axis] !== undefined)
          this._children[i].cont3D.scale[axis] = container.scale[axis];
      }
    }
  }

  update(dt) {
    if (this._toUpdate)
      for (const composition of this._toUpdate) {
        if (composition.update) composition.update(dt);
      }
  }
  _rebuild() {
    fSplice(this._children, 0, build, destroy, this._children, this.cont3D);
    const presets = this.inputs.get("children");
    const containers = this.inputs.get("childrenTrans");
    if (presets != null && containers != null) {
      const count = Math.min(presets.length, containers.length);
      fSplice(
        this._children,
        count,
        build,
        destroy,
        this._children,
        this.cont3D,
        this.app,
        presets,
        containers
      );
    }
  }
  dispose() {
    fSplice(this._children, 0, build, destroy, this._children, this.cont3D);
    if (this.cont3D.parent) this.cont3D.parent.remove(this.cont3D);
    if (!this?.scene?.app?.params?.asset) return;

    const html = [
      ...document.querySelectorAll(`[data-comp_id="${this[">link"].id}"]`),
    ];
    console.log("APP", this.app, html);
    for (let el of html) el.remove();
  }
}
function build(index, compositions, container, globals, presets, containers) {
  globals.cont3D = new THREE.Group();
  globals.container = new THREE.Group();
  globals.cont3D = globals.container;
  const preset = cloud.getDoc(presets[index]);
  const composition = objectManager.instantiateComp(preset, globals);
  if (!composition) {
    console.warn("VisComp > unable to build preset", preset);
    return;
  }
  container.add(composition.container);
  const { x, y, z } = containers[index].position;

  composition.container.position.set(x, y, z);

  compositions.push(composition);
  composition.start();
}

function destroy(index, compositions, container) {
  const composition = compositions[index];
  container.remove(composition.cont3D);
  compositions.splice(index, 1);
  composition.dispose();
}

window.VisComp = VisComp;
typeManager.registerClass(typeNameToId("VisComp"), VisComp);
export default VisComp;
