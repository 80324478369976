import * as THREE from "three";
import VisComp from "@three-extra/VisComp";
import typeManager from "@cloud/TypeManager";
import factoryMat from "@three-extra/asset/MaterialManager";
import factoryGeom from "@three-extra/asset/GeometryManager";
import shaderUtils from "@three-extra/util/ShaderUtils";
import typeMan from "@cloud/TypeManager";
import materialManager from "@three-extra/asset/MaterialManager";
import EasingValue from "@data-trans/EasingValue";
import cloud from "@cloud/VJYCloudClient";
import Reflector from "@three-extra/reflection/Reflector";
import cloneDeep from "lodash/cloneDeep";
import audioManager from "../../audio/AudioManager";
import musicMeta from "../../audio/MusicMeta";
import inputManager from "../../input/InputManager";
const { typeNameToId } = typeMan;
function lerp(a, b, t) {
  return t * a + (1 - t) * b;
}

function applyTransform({ transform, object }) {
  if (!transform) return;

  for (let axis of ["x", "y", "z"]) {
    if (transform.position && transform.position[axis] !== undefined)
      object.position[axis] = transform.position[axis];
    if (transform.rotation && transform.rotation[axis] !== undefined)
      object.rotation[axis] = (transform.rotation[axis] / 180) * Math.PI;
    if (transform.scale && transform.scale[axis] !== undefined)
      object.scale[axis] = transform.scale[axis];
  }
}
const v1 = new THREE.Vector3();
const v2 = new THREE.Vector3();
class MeshLabNew extends VisComp {
  constructor() {
    super();
  }
  start() {
    super.start();
    this.generateGeom();
    this.generateMat();
    this.generateVertexShader();
    this.generateMirror();

    this.easedTriggerLeft = new EasingValue(0, 0, 20, 0.01);
    this.easedTriggerRight = new EasingValue(0, 0, 20, 0.01);
    this.mesh = new THREE.Mesh(this.geometries[0], this.materials[0]);
    this.audioManager = audioManager;
    this.musicMeta = musicMeta;

    window.mm = musicMeta;
    window.p = this;

    if (this.inputs.get("useMaterialsArray")) {
      this.mesh.material = this.materials;
    }

    const transform = this.inputs.get("transform");

    if (transform && transform.rotation) {
      if (transform.rotation.x)
        this.mesh.geometry.rotateX(transform.rotation.x);
      if (transform.rotation.y)
        this.mesh.geometry.rotateY(transform.rotation.y);
      if (transform.rotation.z)
        this.mesh.geometry.rotateZ(transform.rotation.z);
    }
    if (transform && transform.position) {
      const pos = new THREE.Vector3();

      pos.set(
        transform.position.x || 0,
        transform.position.y || 0,
        transform.position.z || 0
      );
      this.mesh.geometry.translate(pos.x, pos.y, pos.z);
    }
    if (transform && transform.scale) {
      const scale = new THREE.Vector3();
      scale.set(
        transform.scale.x || 1,
        transform.scale.y || 1,
        transform.scale.z || 1
      );
      this.mesh.geometry.scale(scale.x, scale.y, scale.z);
    }

    this.cont3D.add(this.mesh);

    this.currMouse = new THREE.Vector2();
    this.easedMouse = new THREE.Vector2();
    this.mouseTarget = new THREE.Vector2();

    this.mouseDistance = 0;

    this.generateAnimators();

    this.mouseDeltaEasing = new EasingValue(0, 0, 2, 0.0001);

    const targetDiv = document.querySelector("canvas");
    targetDiv.addEventListener("mousemove", this.onMouseMove.bind(this));
    targetDiv.addEventListener("touchmove", this.onTouchMove.bind(this));

    if (this.inputs.get("onStart")) {
      console.log(this.inputs.get("onStart"));
      const func = Function("return (" + this.inputs.get("onStart") + ")")();
      this.onStart = func.bind(this);
      this.onStart();
    }
    if (this.inputs.get("onUpdate")) {
      const func = Function("return (" + this.inputs.get("onUpdate") + ")")();
      this.onUpdate = func.bind(this);
    }

    this.dt = 0;
    this.time = 0;
  }

  generateAnimators() {
    const startScripts = this.inputs.get("animators");
    this.animators = [];
    for (let i in startScripts) {
      const s = startScripts[i].animator;
      const doc = cloud.getDoc(s);
      let tt = typeMan.getTypeDef(doc.t);

      let docCode = cloud.getDoc(tt.classDecl[">link"]);

      const func = Function("return (" + docCode.d.code + ")")();
      const animator = new func({
        target: this,
        params: doc.d,
        targetPath: startScripts[i].targetPath,
        timeOffset: startScripts[i].timeOffset || 0,
        speed: startScripts[i].speed || 1,
        visComp: this,
      });

      animator.setTarget = function (value) {
        const path = this.targetPath.split(".");
        let target = this.target;

        for (let i = 0; i < path.length - 1; i++) {
          target = target[path[i]];
        }

        // float to float
        if (
          typeof value === "number" &&
          typeof target[path[path.length - 1]] === "number"
        ) {
          target[path[path.length - 1]] = value;

          // vector to vector
        } else if (typeof value !== "number") {
          target[path[path.length - 1]].copy(value);
          // float to vector
        } else {
          target[path[path.length - 1]].setScalar(value);
        }
      };
      animator.setTarget = animator.setTarget.bind(animator);

      this.animators.push(animator);
    }
  }

  onMouseMove = (event) => {
    this.currMouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.currMouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    this.easedMouse.x = lerp(this.easedMouse.x, this.currMouse.x, 0.7);
    this.easedMouse.y = lerp(this.easedMouse.y, this.currMouse.y, 0.7);
  };
  onTouchMove = (touches) => {
    const event = event.touches[0];
    this.currMouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.currMouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    this.easedMouse.x = lerp(this.easedMouse.x, this.currMouse.x, 0.7);
    this.easedMouse.y = lerp(this.easedMouse.y, this.currMouse.y, 0.7);
  };
  generateGeom() {
    if (this.geometries) this.geometries.forEach((g) => g.dispose());
    const geomA = this.inputs.get("geometry");

    this.geometries = [];
    if (!geomA) return;
    for (let i = 0; i < geomA.count; i++)
      this.geometries.push(
        factoryGeom.build(geomA.getNext(), { geom: this._defGeom })
      );
    this.geometries = this.geometries.map((g) => g.clone());
    this.geometry = this.geometries[0];
    this.geometry.computeBoundingSphere();
    console.log("GEOM", this.geometry);
  }

  generateMat() {
    if (this.materials) this.materials.forEach((m) => m.dispose());

    this.materials = [];
    this._baseMat = this.scene.baseMat;
    this._defMat = this._defMat || new THREE.MeshNormalMaterial();

    const matA = this.inputs.get("material");

    if (matA == null) this.materials.push(this._defMat);
    else
      for (let i = 0; i < matA.count; i++)
        this.materials.push(
          factoryMat.build({
            base: this._baseMat,
            def: this._defMat,
            asset: matA.getNext(),
          })
        );
    if (this.inputs.get("useMaterialsArray")) {
      if (!this.geometry.groups)
        throw new Error(
          "Cannot use material array when geometry does not use groups"
        );

      if (this.geometry.groups.length > this.materials.length) {
        const newMat = [...this.materials];
        const num = this.geometry.groups.length - this.materials.length;

        for (let i = 0; i < num; i++) {
          newMat.push(this.materials[i % this.materials.length]);
        }
        this.materials = newMat;
      }
    }

    this.materials.forEach((m) => {
      m.side = THREE.DoubleSide;
      m.transparent = true;
      if (this.inputs.get("useBackgroundAsEnvMap")) {
        m.envMap = this.renderer.scene.background;
        m.envMapIntensity = 10;
      }
    });
    this.mat = this.materials[0];
  }

  generateVertexShader() {
    const vDoc = this.inputs.get("vertexShader");
    if (!vDoc) return;

    let shaderCode = { code: "", functions: "" };
    const doc = cloud.getDoc(vDoc[">link"]);

    let tt = typeMan.getTypeDef(vDoc[">link"].type);
    //console.log("TT",tt);
    let docCode = cloud.getDoc(tt.classDecl[">link"]);

    this.vertexUniforms = shaderUtils.typeDefToUniforms(tt, doc.d);

    shaderCode = {
      code: docCode.d.code || "",
      functions: docCode.d.functions || "",
      uniforms: shaderUtils.uniformsToGLSLDecl(this.vertexUniforms),
    };

    this.vertexShaderCode = shaderCode;

    this.materials.forEach(this.injectVertexShader);
  }

  injectVertexShader = (mat) => {
    // check if material is already registered as a shader with materialmanager
    const isAlreadyBeingUpdated = materialManager.animUniform.find((el) => {
      return el.mat.uuid === mat.uuid;
    });

    if (!isAlreadyBeingUpdated) {
      materialManager.animUniform.push({ mat });
    }

    // necessary to avoid crashing the materialManager update loop
    if (!mat.uniforms) {
      mat.uniforms = {
        iTime: {
          value: 0,
        },
      };

      for (let key in this.vertexUniforms) {
        mat.uniforms[key] = this.vertexUniforms[key];
      }
    }

    mat.onBeforeCompile = (shader) => {
      // necessary for the uniforms to be assigned to the shader
      if (!shader.uniforms) {
        shader.uniforms = mat.uniforms;
      }
      for (let key in this.vertexUniforms) {
        shader.uniforms[key] = this.vertexUniforms[key];
      }

      shader.uniforms.iTime = {
        value: 0,
      };
      shader.vertexShader = shader.vertexShader

        // uniforms and functions
        .replace(
          "#include <clipping_planes_pars_vertex>",

          `
			uniform float iTime;
			${this.vertexShaderCode.uniforms}
			${this.vertexShaderCode.functions}
			#include <clipping_planes_pars_vertex>`
        )
        .replace(
          "#include <project_vertex>",

          `
				${this.vertexShaderCode.code}
				#include <project_vertex>
				`
        );

      // console.log( shader.vertexShader )
    };
  };

  updateMapping = (mapping) => {
    const { uniforms } = this.mat;
    const { from, to, scale } = mapping;

    let thisVal = this;
    const fromPath = from.split(".");

    //		 console.log( fromPath )
    for (let i = 0; i <= fromPath.length - 1; i++) {
      thisVal = thisVal[fromPath[i]];
      //console.log( thisVal, i, fromPath[i])
    }
    // console.log( thisVal)

    const toPath = to.split(".");

    // uniform value is a float
    if (toPath.length === 1 && this.mat.uniforms[to])
      this.mat.uniforms[to].value = thisVal * (scale || 1);

    // uniform value is a vector
    if (toPath.length === 2) {
      const indexes = {
        x: 0,
        y: 1,
        z: 2,

        r: 0,
        g: 1,
        b: 2,
      };
      const toIndex = indexes[toPath[1]];
      this.materials.forEach((m) => {
        if (m.uniforms && m.uniforms[toPath[0]]) {
          m.uniforms[toPath[0]].value[toIndex] = thisVal * (scale || 1);
        }
      });
    }
  };

  update(dt) {
    super.update(dt);

    this.easedTriggerRight.set(inputManager.channels[1] * 10);

    this.rightTrigger = this.easedTriggerRight.get();

    this.easedTriggerLeft.set(inputManager.channels[0] * 10);

    this.leftTrigger = this.easedTriggerLeft.get();
    if (this.onUpdate) this.onUpdate(dt);

    if (!Number.isNaN(dt)) this.dt = dt;

    this.mouseTarget.x +=
      (this.currMouse.x - this.mouseTarget.x) *
      (this.inputs.get("mouseDamping") || 0.2);
    this.mouseTarget.y +=
      (this.currMouse.y - this.mouseTarget.y) *
      (this.inputs.get("mouseDamping") || 0.2);

    // console.log( this.mouseTarget.x, this.mouseTarget.y )

    const dm = this.mouseTarget.length() - this.easedMouse.length();
    this.mouseDeltaEasing.set(dm);
    const deltaMouse = this.mouseDeltaEasing.get();
    this.mouseDistance += Math.abs(deltaMouse) * dt * 10;
    this.deltaMouse = Math.abs(deltaMouse);

    this.easedMouse.copy(this.mouseTarget);

    this.time += dt * (this.inputs.get("speed") || 1);
    if (this.inputs.get("mouseDeltaTimeInfluence"))
      this.time += Math.abs(deltaMouse);

    const mappings = this.inputs.get("uniformMappings");
    if (mappings && mappings.length) mappings.forEach(this.updateMapping);
    this.animators.forEach((a) => {
      a.update();
    });
  }

  generateMirror() {
    this.resolution = 1;
    var WIDTH = window.innerWidth;
    var HEIGHT = window.innerHeight;

    const params = cloneDeep(this.inputs.get("mirrorOptions")) || {};

    if (!params.active) return;

    const options = params.reflectorOptions || {};
    options.dim = params.dim || new THREE.Vector3(10);

    console.warn(params, options);

    const g = new THREE.PlaneGeometry(options.dim.x || 10, options.dim.y || 10);

    const defaultOptions = {
      clipBias: 0.003,
      resolution: 1,
      color: 0x777777,
    };

    for (let key in defaultOptions) {
      if (options[key] || options[key] !== undefined) continue;
      options[key] = defaultOptions[key];
    }
    options.textureWidth = WIDTH * window.devicePixelRatio * options.resolution;
    options.textureHeight =
      HEIGHT * window.devicePixelRatio * options.resolution;

    this.mirror = new Reflector(g, options);

    this.cont3D.add(this.mirror);

    const { transform } = params;

    applyTransform({ transform, object: this.mirror });
  }

  dispose() {
    super.dispose();
    if (this.materials.length > 0) {
      for (let m of this.materials) m.dispose();
    }
    if (this.geometries.length > 0) {
      for (let g of this.geometries) g.dispose();
    }
    this.cont3D.remove(this.mesh);

    if (this.mirror) {
      this.mirror.geometry.dispose();
      this.mirror.material.dispose();
      this.cont3D.remove(this.mirror);
    }
  }
}

typeManager.registerClass(typeNameToId("Comp.MeshLabNew"), MeshLabNew);

export default MeshLabNew;
