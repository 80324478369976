export default {
  vertex: {
    declarationsEntryPoint: "#include <clipping_planes_pars_vertex>",
    codeEntryPoint: "#include <color_vertex>",
  },
  fragment: {
    declarationsEntryPoint: "#include <clipping_planes_pars_fragment>",
    codeEntryPoint: "#include <alphatest_fragment>",
  },
};
