// import "@three-extra/GenGO/General";
// import "@three-extra/GenGO/EndlessMatrix";
// import "@three-extra/GenGO/EndlessPath";
// import "@three-extra/GenGO/Lissajous";

// import "@three-extra/GenGO/EndlessMatrixNew";

// //HTML
// import "./andor/HTMLOverlay";

// PREVIEW

// Paralax Web /////////////////////////////////////
// import "./Landing/Cubistan";
// import "./Landing/PlatonicSolids";
import "./Landing/HoSWeb";
// import "./Landing/SVWeb";
// import "./Landing/LineField";
// import "./Landing/FBOParticles";
// import "./Landing/ParticleField";
// import "./Landing/SpriteSystem";
// import "./Landing/Globe";
// import "./Landing/City";
// import "./Landing/BasicScroll";
// import "./Landing/StaticObjects";

// import "./andor/ClientFrozen";

// // DV ////////////////////////////////////////////////
// import "./DV/BasicChart";
// import "./DV/ConvertData";

// //uses deprecated THREE.Geometry
// // import '../DV/PieChart/PieChart';
// import "./DV/PieChart/LabelPerfTest";

// import "./Globe/comp";
// import "./Globe/GlobeModel";
// import "./Globe/GlobeLine";
// import "./Globe/GlobeTexture";
// import "./Globe/ConnectedGlobe";
// import "./Globe/GlobeBase";
// import "./Globe/DataGlobe";
// import "./Globe/GlobeDataBars";
// import "./Globe/GlobeDataBarsMulti";
// import "./Globe/comp";

// import "./ProceduralCities/CityBlockComp";
// import "./ProceduralCities/FutureCity";
// import "./ProceduralCities/Building.SimpleBox";
// import "./ProceduralCities/Building.StackedBoxes";
// import "./ProceduralCities/CityRoad";
// import "./ProceduralCities/GridCity";
// import "./andor/InternationalHouse";

// // Photo Gallery /////////////////////////////////////////
// import "./PhotoGalleries/TorusGallery";
// import "./PhotoGalleries/SphereGallery";
// import "./PhotoGalleries/ZFlowGallery";
// import "./PhotoGalleries/EndlessGridGallery";
// import "./PhotoGalleries/MapGallery";
// import "./PhotoGalleries/EndlessMatrixGallery";
// import "./PhotoGalleries/EndlessCylinderGallery";
// import "./PhotoGalleries/PhotoExhibition";
// import "./PhotoGalleries/CarouselGallery";
// import "./PhotoGalleries/CarouselGallery2";
// import "./PhotoGalleries/ZFlowTimelineGallery";

// import "./PhotoGalleries/ShopifyCarouselGallery";
// import "./PhotoGalleries/AmazonCarouselGallery";

// Fields & Particles ///////////////////////////////////
// import "./Field/AbstractField";
// // import "./Field/LineField";
// // import "./Field/ParticleStream";
// import "./Field/ParticleGeom";
// import "./Field/ParticleCloud";
// import "./Field/ParticleCloudNew";
// import "./Field/Lines";
// import "./Field/Particles";
// import "./Field/FieldNew/Particles";

// import "./particles/SpriteSystemNew";
// import "./particles/SpriteSystemConnections";
// import "./arthur/ParticleField";
// import "./Field/LineField";

// import "./arthur/SpriteSnow";
// import "./arthur/Reaction";

// // Demo ///////////////////////////////////////
// import "./Demos/DemoControls";
// import "./Demos/Particles/BasicParticles";

// import "./Demos/ParticlesNew/Basic";
// import "./Demos/ParticlesNew/FragmentShader";
// import "./Demos/ParticlesNew/PositionAnimation";
// import "./Demos/DemoPerspective";
// import "./arthur/FBOParticleLab/FBOParticleLab";
// import "./Demos/Particles/JSPointParticles";
// import "./Demos/Particles/FBOParticles";
// import "./Demos/Particles/JSVectorFieldParticles";
// import "./Demos/Particles/GPUParticles";
// import "./Demos/ReflectionRefraction/Reflection";

// import "./Demos/SceneToTexture";

// import "./Demos/domsync/DemoComp";

// import "./zete/Multi";
// import "./zete/MultiSerial";
// import "./zete/MultiParallel";
// import "./zete/MultiContext";
// import "./zete/MultiMatrix";

// import "./arthur/ParticleText/CustomParticleText";
// import "./arthur/PhotoEffects/PhotoEffects";
// import "./arthur/ParallaxSections/ParallaxObjects";
// import "./arthur/ParallaxSections/ParallaxSectionsContainer";

// import "./Demos/Controls/TownSquarePlaneControl";

// // AV ///////////////////////////////////////////////
// import "./AV/AVSoundWave";
// import "./AV/AVParticles";
// import "./AV/AVAudioParticles";
// import "./AV/AVMidiParticles";
// import "./AV/AVMidiParticlesTube";
// import "./arthur/AVJanuary/comp";
// import "./arthur/AVJanuary/AV2";
// import "./AV/AVSpiralOfOctaves";
// import "./AV/AVGenGO";
// import "./AV/AVSynth";
// import "./AV/AVDrums";
// import "./AV/AVBells";
// import "./AV/JustWave";
// import "./andor/MusicSetup/MusicSetupLab";

// AND-OR ////////////////////////////////////////////////////
// import "./andor/AnyGrid";

// import "./andor/DrawMe";
// import "./andor/Brush";
// import "./andor/FrostMe";
// import "./andor/Snowflake";
// import "./andor/PathLab";
// import "./andor/VJY4Space";
// import "./andor/SpringBalls";
// import "./andor/Mandala/Mandala";
// import "./andor/Mandala/MandalaObjects";
// import "./andor/AbstractJoy/Phenakistoscope";
// import "./andor/AbstractJoy/FlipBoard";
// import "./andor/AbstractJoy/Zoetrope";
// import "./andor/SpriteField";
// import "./andor/StringArt";
// import "./andor/Flipper";
// import "./andor/Mandala/MovingFlow";
// import "./andor/GeraldsGrid";
// import "./AV/JustTrance";

// Lututu //////////////////////////////////////////////////////////////

//import "./BogRoll"
// import "./arthur/SortingTings/comp";

// import "./arthur/GameOfLife";

// import "./arthur/Spirograph";
// import "./arthur/PhotoVoxeliser";
// import "./arthur/Danswer";

// import "./arthur/elderscroll";

// import "./arthur/FusionDrive";

// import "./arthur/Reflectomorph";

// import "./arthur/SpaceFiller";

// import "./arthur/KeyboardVis";

// import "./arthur/Quaternionion";

// import "./Demos/MouseTest";

// import "./arthur/PathObjects";

// // Game

// import "./arthur/ModelScreens";

// import "./arthur/Room";
// import "./arthur/RoomNavigator";

// import "./zete/MultiMatrix";

/*
import "./VisJet/comp"



import "./VisJet/VisjetAnimTest"
import "./VisJet/VisjetPhotoTrans"
import "./VisJet/VisjetPhotoTransAct3"
import "./VisJet/VisjetImageSequence"
import '../PhotoEffects/PhotoEffects';

import '../doritos/doritosMockup'

import "./xfinity/xfinityMockup"

import "./EY/comp"
import '../EY/parallaxDemo'
import '../EY/parallaxPages/belonging'
import '../EY/parallaxPages/programmes'
import '../EY/parallaxPages/whyey'
import '../EY/parallaxPages/technology'
import '../EY/parallaxPages/locations'
import "./ParallaxSections/ParallaxObjects"
import "./ParallaxSections/ParallaxSectionsContainer"

*/

// import '../LeapTest'
// import "./LeapHand"

// import '../Roche/SusvimoTester'
// import '../Roche/ImageZoomTest'

// Zete ////////////////////////////////////////////////
// import "./zete/MorphingStructs";
// import "./andor/Spriteful";
// import "./zete/Hemispheres";
// import "./zete/Pulsar";
// import "./andor/IDDQD";
// import "./zete/Trifrac";
// import "./zete/Friday";
// import "./zete/Terrain";
// import "./zete/Ringify";
// import "./andor/KaVR";
// import "./zete/Thursday";
// import "./zete/Acuario";
// import "./zete/Erizo";

// 8th Wall

// import "./XR8/XR8AbstractTarget";

import Object3DManager from "@three-extra/asset/Object3DManager";
// import SpriteWall from "@three-extra/mesh/SpriteWall";
// import SpriteGroup from "@three-extra/group/SpriteGroup";
import shaderUtils from "@three-extra/util/ShaderUtils";
import Noise from "@three-extra/math/Noise";
import ShaderUtilsNew from "@three-extra/util/ShaderUtilsNew";
import ShaderChunks from "@three-extra/shaders/ShaderChunks.js";
import { Tweener, Easing, Tween } from "@data-trans/tweener";
import { interpolateColorArray } from "@data-trans/interpolateArray";
import EasingValue2 from "@data-trans/EasingValue2";
import ParticleSystem2 from "@three-extra/particles/particleClass";
import EasyParticles from "@three-extra/particles/easyParticles/particleClass";

import PointSpritesGeometry from "@three-extra/geometry/PointSpritesGeometry";
import PointSpritesMaterial from "@three-extra/materials/PointSpritesMaterial";

import isIOS from "@three-extra/profiling/isIOS";
import typeManager from "@cloud/TypeManager";

import { BezierMesh } from "troika-three-utils";
import { InstancedUniformsMesh } from "three-instanced-uniforms-mesh";

import FBOUtils from "@three-extra/fbo/fboUtils";
import EasingValue from "@data-trans/EasingValue";

import forceWebGL1 from "@three-extra/util/forceWebGL1";
import { createDerivedMaterial } from "troika-three-utils";
import { Text } from "troika-three-text";

import cloneDeep from "lodash/cloneDeep";
import EasingFunctions from "@data-trans/EasingFunctions";
import EasingValueLoop from "@data-trans/EasingValueLoop";
import ParticleSystem from "@three-extra/particles/basic/particleClass";
import AbstractField from "@compositions/Field/AbstractField";
import ParticleField from "@compositions/Field/ParticleField";
import SpriteSystem from "@compositions/particles/SpriteSystem";
import Ctrl from "@three-extra/ctrl/Ctrl";
import CanvasTexture from "@three-extra/textures/CanvasTexture";
// import musicMeta from "@audio/MusicMeta";
// import audioManager from "@audio/AudioManager";
import inputManager from "@input/InputManager";
import factoryMat from "@three-extra/asset/MaterialManager";
import factoryGeom from "@three-extra/asset/GeometryManager";
import { Inputs, Outputs } from "@rt/InputsOutputs";

import { interpolateArray } from "@data-trans/interpolateArray";
import objectManager from "@cloud/ObjectManager";

// import { SVGLoader } from "../../node_modules/three/examples/jsm/loaders/SVGLoader.js";
// import { FontLoader } from "../../node_modules/three/examples/jsm/loaders/FontLoader.js";
// import { TextGeometry } from "../../node_modules/three/examples/jsm/geometries/TextGeometry.js";
// import { debounce } from "lodash/debounce";

// import { MeshTransmissionMaterial } from "@three-extra/materials/MeshTransmissionMaterial";

// import { MeshLineGeometry, MeshLineMaterial, raycast } from "meshline";
// import { MeshReflectorMaterial } from "@three-extra/drei-vanilla/src/materials/MeshReflectorMaterial.ts";
// import { SpotLightMaterial } from "@three-extra/drei-vanilla/src/materials/SpotLightMaterial.ts";

import * as cloud from "../cloud";
import * as three from "../three-extra";

// import * as maath from "maath";

// window.maath = maath;
// window.MeshLineGeometry = MeshLineGeometry;
// window.MeshLineMaterial = MeshLineMaterial;

// window.MeshTransmissionMaterial = MeshTransmissionMaterial;
// window.SpotLightMaterial = SpotLightMaterial;
// window.MeshReflectorMaterial = MeshReflectorMaterial;
// window.EasyParticles = EasyParticles;
window.Object3DManager = Object3DManager;
// window.SpriteWall = SpriteWall;
// window.SpriteGroup = SpriteGroup;
window.shaderUtils = shaderUtils;
window.interpolateColorArray = interpolateColorArray;
window.Noise = Noise;
window.ShaderUtilsNew = ShaderUtilsNew;
window.ShaderChunks = ShaderChunks;
window.Tweener = Tweener;
window.Easing = Easing;
window.EasingValue2 = EasingValue2;
window.ParticleSystem2 = ParticleSystem2;
window.PointSpritesGeometry = PointSpritesGeometry;
window.PointSpritesMaterial = PointSpritesMaterial;
window.isIOS = isIOS;
window.typeManager = typeManager;
// window.BezierMesh = BezierMesh;
// window.InstancedUniformsMesh = InstancedUniformsMesh;
window.FBOUtils = FBOUtils;
window.EasingValue = EasingValue;
window.forceWebGL1 = forceWebGL1;
window.shaderUtils = shaderUtils;
// window.createDerivedMaterial = createDerivedMaterial;
// window.Text = Text;
window.Tween = Tween;

window.ParticleSystem = ParticleSystem;
window.AbstractField = AbstractField;
window.ParticleField = ParticleField;
window.SpriteSystem = SpriteSystem;
window.Ctrl = Ctrl;
window.CanvasTexture = CanvasTexture;
window.cloneDeep = cloneDeep;
window.EasingFunctions = EasingFunctions;
window.EasingValue = EasingValue;
window.EasingValueLoop = EasingValueLoop;
window.inputManager = inputManager;
window.objectManager = objectManager;
// window.audioManager = audioManager;
// window.musicMeta = musicMeta;
window.objectManager = objectManager;

window.factoryGeom = factoryGeom;
window.factoryMat = factoryMat;
window.materialManager = factoryMat;
window.Inputs = Inputs;
window.Easing = Easing;
window.interpolateArray = interpolateArray;
window.cloudClient = cloud.cloudClient;
// window.mm = musicMeta;

window.VisComp = three.VisComp;

// window.SVGLoader = SVGLoader;
// window.FontLoader = FontLoader;
// window.TextGeometry = TextGeometry;
// window.debounce = debounce;

// export { default as PreviewComp } from "../three-extra/Preview/PreviewComp";
// export { default as ProceduralTexturePreview } from "../three-extra/Preview/ProceduralTexturePreview";
// export { default as EffectPreview } from "../three-extra/Preview/EffectPreview";
// export { default as ModelPreview } from "../three-extra/Preview/ModelPreview";
// export { default as CanvasTexturePreview } from "../three-extra/Preview/CanvasTexturePreview";
