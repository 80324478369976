import * as THREE from 'three';
import VisComp from '@three-extra/VisComp';
import typeManager from '@cloud/TypeManager';
import inputManager from '@input/InputManager';
import cloud from "@cloud/VJYCloudClient"
import factoryMat from '@three-extra/asset/MaterialManager';
import factoryGeom from '@three-extra/asset/GeometryManager';
import objectManager from '@cloud/ObjectManager';

import extAssetCache from '@three-extra/asset/ExtAssetCache';
import cloneDeep from "lodash/cloneDeep"

import createReactiveObject3D from "@rt/createReactiveObject3D"

import GraphUpdater from "@rt/nodes/ProxyUpdaters/Object3DUpdater"

class Object3D extends VisComp {

    constructor() {

        super();
        this.update = this.update.bind(this)

        document.addEventListener("click", () => console.log(this)) // debug helper
        this.resolution = new THREE.Vector2(window.innerWidth, window.innerHeight)
        window.THREE = THREE

        this.index = 0
    }

    async start() {

        

        super.start();

        this.scene.setDefaultMat( new THREE.MeshPhongMaterial())
        this.scene.isLit = true 
       
        const doc = cloud.getDoc(this.inputs.get("object3D"))
        // doc =   cloud.getDoc(this.inputs.get("object3D"))
        // const doc = cloud.getDoc(this.inputs.get("object3D"))

        console.warn( doc )

        await this.deserialise(doc, this.cont3D)

        this.index ++ 

       
   
        
    }

    async deserialise(doc, parent) {
         
        console.warn( doc.d.name , doc, parent )
        const { t } = doc
        const {   code, asset } = doc.d
        let {  children , transform } = doc.d

        const objectParamKeys = ["children","transform","code","asset"]

        const lightTypes = [
            "AmbientLight",
            "DirectionalLight",
            "HemisphereLight",
            "PointLight",
            "RectAreaLight",
            "SpotLight"
        ]

        const object3DTypes = [
            "Mesh",
            "Line",
            "LineSegments",
            "LineLoop",
            "Points",
            "Sprite"
        ]


        let object3D
        
        if (t === "Object3D") {

            object3D = createReactiveObject3D( t )

            if (asset) {

                const cloudDoc = cloud.getDoc(asset) 
            
                
                const type = cloudDoc.t

                if (type === "Model") object3D = await extAssetCache.load(cloudDoc)


                // when the object3D exists as a cloud asset, use the children from its own doc
                // TODO what about cases where the object has children in its cloud doc, and children in the scene graph?
                if (typeManager.isCompatible(type, "Object3D")) {
                    // console.warn("CLOUD CHILDRE", doc.d.name )
                    children = cloudDoc.d.children
                }

            } else if ( doc.d.geometry && doc.d.material ){
                object3D = createReactiveObject3D( t )
            
                const geomDoc = cloud.getDoc( doc.d.geometry )
        
                if ( geomDoc.t.indexOf( "Sequence") === 0 ){
                    object3D.geometry = factoryGeom.build( geomDoc.d.elems[ 0 ])
                } else {
                    object3D.geometry = factoryGeom.build(doc.d.geometry)
                }
    
         
                object3D.material = factoryMat.build({
                    base: this.scene.baseMat,
                    def: this.scene.defaultMat,
                    asset: doc.d.material
                })
            }

        } else if ( object3DTypes.indexOf(t ) >= 0  ) {

            object3D = createReactiveObject3D( t )
            
            const geomDoc = cloud.getDoc( doc.d.geometry )
    
            if ( geomDoc.t.indexOf( "Sequence") === 0 ){
                object3D.geometry = factoryGeom.build( geomDoc.d.elems[ 0 ])
            } else {
                object3D.geometry = factoryGeom.build(doc.d.geometry)
            }

     
            object3D.material = factoryMat.build({
                base: this.scene.baseMat,
                def: this.scene.defaultMat,
                asset: doc.d.material
            })


        }  else if ( lightTypes.indexOf( t ) >= 0 ){
            object3D = createReactiveObject3D( t )
            

            const objectParamKeys = ["children","transform"]

            for ( let key in doc.d ){

                if ( objectParamKeys.indexOf( key ) >= 0 ) continue
                object3D[ key ] = doc.d[ key ]
            }

        } else {
            console.warn( doc )
            throw new Error("No compatible type found in doc: ", doc)

        }


        parent.add(object3D)
       
        //  console.warn(doc.d.name, transform, doc )

        if (transform) {
          
            console.warn('Set trans', doc.d.name , transform)
            for (let axis of ["x", "y", "z"]) {
                if (transform.position && transform.position[axis] !== undefined) object3D.position[axis] = transform.position[axis]
                if (transform.rotation && transform.rotation[axis] !== undefined) object3D.rotation[axis] = transform.rotation[axis] / 180 * Math.PI;
                if (transform.scale && transform.scale[axis] !== undefined) object3D.scale[axis] = transform.scale[axis]

            }
        }
        if (code) {

            const { scene, renderer, me } = this
            const globals = { scene, renderer, me }

            for (let script of code) {

                
                const scriptDoc = cloneDeep( cloud.getDoc( script.asset ) )

                if ( scriptDoc.t === "AniGraph"){

                    // console.warn( "Script Doc >>>>> ", scriptDoc)
                    const updater = new GraphUpdater({
                        graphDoc: scriptDoc,
                        target: object3D 
                    })
                    this._toUpdate.push( updater )

                } else {
                    if ( script.d ){
                        for ( let key in script.d ){
                            if ( objectParamKeys.indexOf( key )>= 0 ) continue 
                            scriptDoc.d[ key ] = script.d[ key ]
                        }
                    }
    
    
                   
                    // note: both VisComp and LightSys are instantiated by instantiateComp
    
                    const comp = objectManager.instantiateComp( scriptDoc, globals)
                    // console.warn( comp , doc.d.name, object3D, doc)
                    // comp.inputs.setObj( scriptDoc.d )
                    comp.cont3D = object3D
                    this._toUpdate.push(comp)
    
                    
                    comp.start()

                }


               



            }
        }

        object3D.name = doc.d.name

        if (!children) return
        for (let child of children) await this.deserialise(child, object3D)

    }




    //called every frame, dt: time passed between frames
    update(dt) {

        super.update(dt)

    }




    dispose() {
        /*
        this.container.remove(...this.container.children);
        for (const geom of this._geometries) geom.dispose();
        for (const mat of this._materials) mat.dispose();
        this._geometries = null;
        this._materials = null;
        */
    }



}


// DO NOT DELETE THIS SHIT

typeManager.registerClass("6069d988e556c700170eae7e", Object3D);

export default Object3D;