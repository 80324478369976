import * as THREE from "three";
import cloud from "@cloud/VJYCloudClient";
import tm from "../cloud/TypeManager";
import objMan from "@cloud/ObjectManager";
import Listeners from "@rt/Listeners";
import GraphRouter from "@rt/nodes/GraphRouter";
import { CloudClient } from "../cloud/VJYCloudClient";

class SceneSingle {
  constructor() {
    this.listeners = new Listeners();
    //this.dominantContext = 'composition';
    //this.updateSceneContext = this.updateSceneContext.bind(this);
    //this.onGlobalContextChange = this.onGlobalContextChange.bind(this);
    //this.onCompContextChange = this.onCompContextChange.bind(this);
    //this.suspendCompFire=false;
    this.scenePropListeners = {
      ctrl: this.onCtrlChange.bind(this),
      effect: this.onEffectChange.bind(this),
      background: this.onBackgroundChange.bind(this),
      light: this.onLightChange.bind(this),
      lightColor: this.onLightColorChange.bind(this),
      fog: this.onFogChange.bind(this),
    };
    this.takeThumbnailScreenshot = this.takeThumbnailScreenshot.bind(this);
    this.enableScreenshot = false;
  }

  start() {
    if (this.enableScreenshot)
      document.addEventListener("keyup", this.takeThumbnailScreenshot);

    // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
    // console.log("> Scene Single ");
    // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
    this.renderer = this.renderComp;
    //Base Materials for the Scene ( LightSys dependent )
    this.isLit = false;
    this.baseMatUnlit = new THREE.MeshBasicMaterial();
    this.baseMatLit = new THREE.MeshPhongMaterial();
    this.baseMat = this.isLit ? this.baseMatLit : this.baseMatUnlit;
    this.defaultMat = new THREE.MeshNormalMaterial();

    if (!this.cont3D) this.cont3D = new THREE.Group();

    /*
		//Scene Context ( Global + Comp Context merged )
		this.context = new Context();
		this.contextProps=["effect","background","light","colA","geomA","matA"];
		//until serialisatior can't figure it out
		this.contextPropLevels={
			effect:2,
			background:2,
			light:2,
			colA:1,
			geomA:1,
			matA:1
		}

		// Context props handled by the Scene
		this.context.listeners.add('background', this.onBackgroundChange);
		this.context.listeners.add('effect', this.onEffectChange);
		this.context.listeners.add('light', this.onLightChange);
		this.context.listeners.add('colA', this.onLightColorChange);

		//Listen to Global Context changes
		if (this.globalContext) {
			this.globalContext.listeners.add('', this.onGlobalContextChange);
		}
		*/
  }
  // SCREENSHOTS
  async takeThumbnailScreenshot(event) {
    // on MacOS, Alt + T is a shortcut for †
    if (
      (event.key !== "t" && event.key !== "†") ||
      !event.ctrlKey ||
      !event.altKey
    )
      return;
    if (event.preventDefault) event.preventDefault();

    if (!this.comp)
      return console.log(
        "Comp not loaded yet, please try again in a few seconds"
      );
    //console.log("TAKING SCREENSHOT");
    const { renderer } = this.comp;
    const { canvas } = renderer;

    const current = document.createElement("canvas");
    const { preserveDrawingBuffer } = renderer;
    renderer.preserveDrawingBuffer = true;
    const ctx = current.getContext("2d");
    current.width = 512;
    current.height = 512;
    const sW = canvas.width;
    const sH = canvas.height;
    const dW = current.width;
    const dH = current.height;
    const sqD = sW < sH ? sW : sH;
    const x = Math.round((sW - sqD) / 2);
    const y = Math.round((sH - sqD) / 2);

    //	console.log(canvas, ctx)
    requestAnimationFrame(async () => {
      ctx.drawImage(canvas, x, y, sqD, sqD, 0, 0, dW, dH);
      renderer.preserveDrawingBuffer = preserveDrawingBuffer;
      //this.setState({ previewUpdated: true });

      // document.body.appendChild( current )
      // current.style = `
      // position: fixed;
      // top: 0;
      // left: 0;
      // height: 500px;
      // width: 500px;
      // z-index: 9999`
      const imgBlob = await new Promise((resolve) =>
        current.toBlob(resolve, "image/jpeg", 0.9)
      );
      //console.log( imgBlob)
      this.previewCanvas = current;

      // iframe - don't take screenshot, send data to parent ( Edit App )
      if (window.location !== window.parent.location) {
        window.parent.postMessage(
          {
            type: "screenshot",
            data: imgBlob,
          },
          "*"
        );
      } else {
        const previewLink = await this.uploadThumbnailScreenshot(null, imgBlob);
        await this.updateDocThumbnail(previewLink);
      }
    });
  }
  async updateDocThumbnail(previewLink) {
    this.doc.gen = this.doc.gen || {};
    this.doc.gen.preview = this.doc.gen.preview || {};
    this.doc.gen.preview.source = previewLink;
    try {
      await cloud.update(this.doc);
      //	console.log("Thumbnail updated");
    } catch (err) {
      //toast("An error occurred, check the console for more info")
      console.log(err);
    }
  }

  async uploadThumbnailScreenshot(textureId, imgBlob) {
    const cloudinary = await cloud.getCloudinaryConfig();
    const { upload_preset, cloud_name } = cloudinary.result;
    const current = this.previewCanvas;

    const formData = new FormData();
    formData.append("tags", "WEBGL");
    formData.append("upload_preset", upload_preset);
    formData.append("file", imgBlob, "image.jpg");

    const url = `https://api.cloudinary.com/v1_1/${cloud_name}/auto/upload`;
    let res = await fetch(url, { method: "post", body: formData });
    res = await res.json();
    if (res.error) throw res.error;
    const doc = cloud.cloudinaryTextureResponseToDoc(res);
    delete doc.m.n;
    if (textureId) {
      doc._id = textureId;
      doc.m.hidden = true;
      if (!doc.m.tags) doc.m.tags = ["thumbnail"];
      res = await cloud.update(doc);
    } else {
      doc.m.tags = ["thumbnail"];
      doc.m.hidden = true;
      res = await cloud.insert(doc);
    }
    const texDoc = res.docs[0];
    return { ">link": { id: texDoc._id } };
  }
  /*
	updateCompContext(){

	}
	updateSceneContext() {
		const ctx = {};
		const globalObj = this.globalContext ? this.globalContext.getObj() : {};
		const globalCtx = {}
		const compObj = this.comp.inputs.getObj();
		const compCtx = {}
		for(let prop of this.contextProps) {
			if(compObj[prop]) compCtx[prop]=objMan.cloneLink(compObj[prop],2);
			if(globalObj[prop]) globalCtx[prop]=objMan.cloneLink(globalObj[prop],2);
		}

		switch (this.dominantContext) {
			case 'global':
				merge(ctx, compCtx, globalCtx);
				break;
			case 'composition':
				merge(ctx, globalCtx, compCtx);
				break;
			default:
				break;
		}
		this.context.setObj(ctx);
		this.suspendCompFire=true;
		for(let prop of this.contextProps) this.comp.inputs.set(prop,objMan.cloneLink(this.context.get(prop),this.contextPropLevels[prop]));
		this.suspendCompFire=false;
	}

	updateSceneContextProp(prop){
		let valG = this.globalContext.get(prop);
		let valC = this.comp.inputs.get(prop);
		let val;
		switch (this.dominantContext) {
			case 'global':
				if(valG) val = valG;
					else if(valC) val=objMan.cloneLink(valC,2);
				break;
			case 'composition':
				if(valC) val = objMan.cloneLink(valC,2);
					else if(valG) val=valG;
				break;
			default:
				break;
		}
		this.context.set(prop,val);
	}
	*/
  onCompChange(ev) {
    switch (ev.type) {
      case "background":
        this.onBackgroundChange(ev);
        break;
      case "effect":
        this.onEffectChange(ev);
        break;
      case "light":
        this.onLightChange(ev);
        break;
      case "lightColor":
        this.onLightColorChange(ev);
        break;
    }
  }

  // Get current composition
  getComposition() {
    return objMan.cloneLink(this.comp, 1);
  }

  // Set a new composition
  async setComposition(link, compOverrideSettings) {
    const doc = cloud.getDoc(link);
    this.doc = doc;

    if (doc.d.legacyRendering) {
      this.renderer.enableLegacyRendering(true);
    } else {
      this.renderer.enableLegacyRendering(false);
    }

    if (compOverrideSettings) {
      console.log("Comp override settings", compOverrideSettings);
      //this.doc = cloneDeep( this.doc )
      const typeDef = tm.getTypeDef(doc.t);
      console.log("TYPEDEF", typeDef);

      for (let key in compOverrideSettings) {
        const { type } = typeDef.properties[key].type;
        if (["int", "float", "Color", "string"].includes(type)) {
          this.doc.d[key] = compOverrideSettings[key];
        } else if (type === "Texture2D") {
          const textureDoc = cloud.getDoc(this.doc.d[key]);
          textureDoc.d.baseObj.asset[">ext"].url = compOverrideSettings[key];
          await cloud._extAssetCache.load(textureDoc);
          console.log("TEX DOC", textureDoc);
        } else {
          //this.doc.d[ key ] = compOverrideSettings[ key ]
        }
      }
    }
    // console.log('SceneSingle >> Set Preset', doc.m.n, doc);

    //Dispose Comp, if new preset is an other type
    // && cloud.getDoc(this.comp).t !== doc.t ;
    if (this.comp) {
      for (const prop of Object.keys(this.scenePropListeners)) {
        this.comp.inputs.listeners.remove(prop, this.scenePropListeners[prop]);
      }
      this.cont3D.remove(this.comp.cont3D);
      this.comp.dispose();
      this.comp = null;
      if (this.graph) this.graph = null;
      //console.log("Previous comp disposed");
    }

    //If we need to build a new composition
    let isNew = false;
    if (!this.comp) {
      isNew = true;
      // Add HTML / CSS if present
      if (this.app.appDecl.project.allowSceneHTML) {
        if (doc.d.contentHtml != null && doc.d.contentHtml.length > 0) {
          console.log("LINK", link);
          this.app.addHTML(doc.d.contentHtml, link);
        }
        if (doc.d.contentCss != null && doc.d.contentCss.length > 0) {
          this.app.addCSS(doc.d.contentCss);
        }
      }
      // Creating new composition
      //	console.log("SceneSingle >> Instantiate Comp", link);
      this.compCont3D = new THREE.Group();
      this.compCont3D.name = "CompCont3D";
      this.cont3D.add(this.compCont3D);

      this.comp = objMan.instantiateComp(
        link,
        {
          scene: this,
          cont3D: this.compCont3D,
          renderer: this.renderComp,
          me: this.me,
        },
        false
      );
      // console.log("SceneSingle >> Instantiate Comp", this.comp);
      // Listen To Comp Context properties
      for (const prop of Object.keys(this.scenePropListeners)) {
        this.comp.inputs.listeners.add(prop, this.scenePropListeners[prop]);
      }
    }

    // Set new preset for the Comp
    // console.log('SceneSingle >> Comp.setObj', doc.d);

    this.comp.inputs.setObj(doc.d);

    // XXX update link if comp was created before
    // move this to objman?
    this.comp[">link"] = { id: doc._id, type: doc.t, t: doc.t };

    // Start Comp
    if (isNew) {
      // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
      // console.log('SceneSingle >> Start Comp', this.comp);
      await this.comp.start();
    }

    this.listeners.fire("after-set-composition", { doc, comp: this.comp });
  }

  // Scene Context Change : Executing changes
  onCtrlChange(e) {
    this.me.setProps({ ctrl: e.data });
  }
  onBackgroundChange(e) {
    this.renderComp.setProps({ background: e.data });
  }

  onEffectChange(e) {
    this.renderComp.setProps({ effect: e.data });
  }

  onLightChange(e) {
    this.setLightSys(e.data);
  }

  onLightColorChange(e) {
    if (this.lightSys)
      this.lightSys.inputs.set("colA", objMan.cloneLink(e.data, 1));
  }

  onFogChange(event) {
    this.renderComp.setProps({ fog: event.data });
  }

  // Set a new preset for the Light System
  setLightSys(link) {
    //console.log('LIGHT !!!');
    //console.log('Light link', link);
    //Destroy Prev
    if (this.lightSys) {
      this.cont3D.remove(this.lightSys.cont3D);
      this.lightSys.dispose();
      this.lightSys = null;
    }

    if (link != null) {
      const doc = cloud.getDoc(link);
      //console.log('LightSys doc', doc);
      if (doc.m.n != "No Light") {
        this.lightSys = objMan.instantiateComp(link, {
          scene: this,
          renderer: this.renderComp,
          me: this.me,
        });

        if (!this.lightSys) {
          this.isLit = false;
          console.warn(
            "Scene Single > unable to instantiate light system",
            link
          );
        } else {
          //console.log('LightSys comp', this.lightSys);
          let contextLightColor = this.comp.inputs.get("lightColor");

          //console.log(this.comp.inputs._ns,this.comp.inputs._ns.lightColor);
          //console.log("LS",contextLightColor);
          if (contextLightColor != null)
            doc.d.colA = objMan.cloneLink(contextLightColor, 1);
          const props = objMan.deserialize(doc.d);
          this.lightSys.inputs.setObj(doc.d);
          this.lightSys.start();
          this.cont3D.add(this.lightSys.cont3D);
          this.isLit = true;
        }
      } else {
        this.isLit = false;
      }
    } else {
      this.isLit = false;
    }

    this.baseMat = this.isLit ? this.baseMatLit : this.baseMatUnlit;
  }
  setDefaultMat(mat) {
    this.baseMat = mat;
  }

  update(dt) {
    if (!this.comp) return;
    this.comp.update(dt);
    if (this.lightSys) this.lightSys.update(dt);
  }
  dispose() {
    if (this.enableScreenshot)
      document.removeEventListener("keyup", this.takeThumbnailScreenshot);
  }
}

export default SceneSingle;
