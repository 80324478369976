import vjycc from "@cloud/VJYCloudClient";
import typeManager from "@cloud/TypeManager";
import * as THREE from "three";
import VisComp from "@three-extra/VisComp";

import shaderUtils from "@three-extra/util/ShaderUtils";
import cloud from "@cloud/VJYCloudClient";
import input from "@input/InputManager";
import typeMan from "@cloud/TypeManager";

// resources:
// https://www.khanacademy.org/math/multivariable-calculus/thinking-about-multivariable-function/ways-to-represent-multivariable-functions/a/vector-fields
const { floor, abs, sin, cos, round, random } = Math;
class AbstractField extends VisComp {
  constructor() {
    super();
    this.dispose = this.dispose.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    window.comp = this;
  }

  dispose() {
    super.dispose();
    input.listeners.remove("onscroll", this.onScroll);
    document.removeEventListener("mousemove", this.onMouseMove);
  }

  start() {
    super.start();

    this.dumpOffset = 0;
    this.dumpTarget = 0;
    this.mousePosition = new THREE.Vector2();
    input.listeners.add("onscroll", this.onScroll);
    document.addEventListener("mousemove", this.onMouseMove);

    let dim = {
      x: this.inputs.get("dim").x,
      y: this.inputs.get("dim").y,
      z: this.inputs.get("dim").z,
    };
    if (!dim.z && dim.x && dim.y) {
      // console.log( "swap z and y", count.z, count.y )
      dim.z = dim.y;
      dim.y = 0;
    }
    //  this.inputs.set( "dim", dim)

    const count = this.inputs.get("count");
    if (!count.z && count.x && count.y) {
      count.z = count.y;
      count.z = 0;
    }
    //  this.inputs.set("count", count )

    this.generateUniforms();
    this.generateColorPalette();
    this.generateShaders();

    this.generateGeom();
    this.generateMat();

    this.generateObj();
    this.assignColors();

    this.time = 0;

    this.dead = false;

    this.inputs.listeners.add("colors", this.updateColors.bind(this));
    this.inputs.listeners.add("secondaryColors", this.updateColors.bind(this));
    this.inputs.listeners.add(
      "colorDistributionMode",
      this.updateColors.bind(this)
    );
    this.inputs.listeners.add("colorRandomness", this.updateColors.bind(this));
    this.inputs.listeners.add("count", this.regenerateObj.bind(this));
    this.inputs.listeners.add("dim", this.regenerateObj.bind(this));
    this.update(0);
  }
  onScroll = (e) => {
    this.dumpTarget = e.data.percent * 100;
  };
  onMouseMove(event) {
    this.mousePosition.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.mousePosition.y = -(event.clientY / window.innerHeight) * 2 + 1;
  }

  updateColors() {
    this.generateColorPalette();
    this.assignColors();
  }

  generateGeom() {}
  generateMat() {}
  generateUniforms() {
    if (!this.uniforms) this.uniforms = {};
    let dim = {
      x: this.inputs.get("dim").x,
      y: this.inputs.get("dim").y,
      z: this.inputs.get("dim").z,
    };
    if (!dim.z && dim.x && dim.y) {
      // console.log( "swap z and y", count.z, count.y )
      dim.z = dim.y;
      dim.y = 0;
    }

    const uniforms = {
      uDim: {
        value: dim,
        type: "vec3",
      },
      uCamPos: {
        value: new THREE.Vector3(),
        type: "vec3",
      },
      uScroll: {
        value: 0,
        type: "float",
      },
      uScrollDelta: {
        value: 0,
        type: "float",
      },
      uScrollTotal: {
        value: 0,
        type: "float",
      },
      uTime: {
        value: 0,
        type: "float",
      },
      uSpeed: { value: this.inputs.get("speed") || 1, type: "float" },
      uAmp: { value: this.inputs.get("amplitude") || 1, type: "float" },
      uAmpStart: {
        value: this.inputs.get("amplitudeStart") || 1,
        type: "float",
      },
      uAmpEnd: { value: this.inputs.get("amplitudeEnd") || 1, type: "float" },
      uMouse: { value: new THREE.Vector2(), type: "vec2" },
    };
    for (let key in uniforms) this.uniforms[key] = uniforms[key];
  }
  generateShaderCode(asset) {
    if (!asset) return null;
    let tt = typeMan.getTypeDef(asset[">link"].type);

    let docCode = cloud.getDoc(tt.classDecl[">link"]);
    const doc = cloud.getDoc(asset[">link"]);

    docCode.d.functions =
      typeof docCode.d.functions === "string" ? docCode.d.functions : "";

    const shaderCode = {
      code: docCode.d.code,
      functions:
        shaderUtils.uniformsToGLSLDecl(
          shaderUtils.typeDefToUniforms(tt, doc.d)
        ) + docCode.d.functions,
      uniforms: shaderUtils.typeDefToUniforms(tt, doc.d),
    };
    //  console.log( shaderCode.functions , docCode.d.functions  , doc.d, tt   )
    return shaderCode;
  }
  generateShaders() {
    // const vShader = this.inputs.get("vertexShaderCode")
    // const fShader = this.inputs.get("fragmentShaderCode")

    // const glslUniforms = shaderUtils.uniformsToGLSLDecl(this.uniforms)

    // if (vShader) vShader.functions = glslUniforms + vShader.functions
    // if (fShader) fShader.functions = glslUniforms + fShader.functions

    // this.vShader = vShader
    // this.fShader = fShader

    const glslUniforms = shaderUtils.uniformsToGLSLDecl(this.uniforms);

    this.vShader = this.generateShaderCode(this.inputs.get("vertexShader")) || {
      code: "",
      functions: "",
    };

    this.vShader.functions = glslUniforms + this.vShader.functions;
    for (let key in this.vShader.uniforms)
      this.uniforms[key] = this.vShader.uniforms[key];

    this.fShader = this.generateShaderCode(
      this.inputs.get("fragmentShader")
    ) || { code: "", functions: "" };

    this.fShader.functions = glslUniforms + this.fShader.functions;
    for (let key in this.fShader.uniforms)
      this.uniforms[key] = this.fShader.uniforms[key];
  }

  generateColorPalette() {
    if (this.inputs.get("colors")) {
      this.colors = [];
      const color = this.inputs.get("colors");
      for (let i = 0; i < color.count; i++) {
        const c = new THREE.Color(color.getNext());
        this.colors.push(c);
        // this.uniforms["uCol" + i] = { value: c, type: "vec3" }
      }
    }
  }

  assignColors() {}
  generateObj() {}
  regenerateObj() {}

  update(dt) {
    super.update(dt);
    if (this.dead) return;
    this.time += dt * (this.speedMul ?? 1);

    if (!Object.keys(this.uniforms).length) return;
    this.uniforms.uTime.value += dt;

    if (this.parentComp) this.uniforms.uTime.value = this.parentComp.time;

    this.uniforms.uSpeed.value = this.inputs.get("speed") || 1;
    this.uniforms.uAmp.value = this.inputs.get("amplitude") || 1;
    this.uniforms.uAmpStart.value = this.inputs.get("amplitudeStart") || 1;
    this.uniforms.uAmpEnd.value = this.inputs.get("amplitudeEnd") || 1;

    const pos = this.me.camera.position.clone();
    this.cont3D.worldToLocal(pos);
    this.uniforms.uCamPos.value.copy(pos);

    const dumpAmount = (this.dumpTarget - this.dumpOffset) * 0.06;
    const prevDump = this.dumpOffset;
    this.dumpOffset += dumpAmount;
    this.uniforms.uScroll.value = this.dumpOffset / 100;
    this.uniforms.uScrollTotal.value += this.dumpOffset / 100;
    this.uniforms.uScrollDelta.value = abs(prevDump - this.dumpOffset);

    this.uniforms.uMouse.value.copy(this.mousePosition);
  }
}

typeManager.registerClass("Comp.AbstractField", AbstractField);
export default AbstractField;
