export function vertShader(code = null, functions = null) {
  functions = functions || "";
  functions = functions
    .split(";")
    .filter(
      (l) => !l.match("uTime") && !l.match("uScale") && !l.match("blurScale")
    )
    .join(";");

  return `
      precision highp float;
  
      const vec4 bitSh = vec4(256. * 256. * 256., 256. * 256., 256., 1.);
      const vec4 bitMsk = vec4(0.,vec3(1./256.0));
      const vec4 bitShifts = vec4(1.) / bitSh;
  
      #define FLOAT_MAX  1.70141184e38
      #define FLOAT_MIN  1.17549435e-38
  
      ${functions ? functions : ""}
  
      lowp vec4 encode_float(highp float v) {
          highp float av = abs(v);
          //Handle special cases
          if(av < FLOAT_MIN) {
          return vec4(0.0, 0.0, 0.0, 0.0);
          } else if(v > FLOAT_MAX) {
          return vec4(127.0, 128.0, 0.0, 0.0) / 255.0;
          } else if(v < -FLOAT_MAX) {
          return vec4(255.0, 128.0, 0.0, 0.0) / 255.0;
          }
          highp vec4 c = vec4(0,0,0,0);
          //Compute exponent and mantissa
          highp float e = floor(log2(av));
          highp float m = av * pow(2.0, -e) - 1.0;
          c[1] = floor(128.0 * m);
          m -= c[1] / 128.0;
          c[2] = floor(32768.0 * m);
          m -= c[2] / 32768.0;
          c[3] = floor(8388608.0 * m);
          //Unpack exponent
          highp float ebias = e + 127.0;
          c[0] = floor(ebias / 2.0);
          ebias -= c[0] * 2.0;
          c[1] += floor(ebias) * 128.0;
          //Unpack sign bit
          c[0] += 128.0 * step(0.0, -v);
          //Scale back to range
          return c / 255.0;
      }
  
    
   
      uniform sampler2D tNoise;
   
      
  
  
      attribute vec4 particlePositionsStartTime;
      attribute vec4 particleVelColSizeLife;
      attribute float particleIndex;
      attribute vec3 particleIndexSizeBlur;
  
      varying vec4 vColor;
      varying float lifeLeft;
      varying vec3 pos;
  
      varying float particleInd;
      varying float size;
      varying float blur;
  
      uniform float uTime;
      uniform float uScale;
      uniform float blurScale;
  
      
      void main() {
  
          particleInd = particleIndexSizeBlur.x ;
          // unpack things from our attributes
          vColor = encode_float( particleVelColSizeLife.y );
  
          // convert our velocity back into a value we can use
          vec4 velTurb = encode_float( particleVelColSizeLife.x );
          vec3 velocity = vec3( velTurb.xyz );
          float turbulence = velTurb.w;
        
  
          float timeElapsed = uTime - particlePositionsStartTime.a;
          lifeLeft = 1. - (timeElapsed / particleVelColSizeLife.w);
  
  
          pos = particlePositionsStartTime.xyz ;
  
          
          size = particleIndexSizeBlur.y;
          blur = particleIndexSizeBlur.z;
          
          ${code ? code : ""}
    
         
          gl_PointSize =  uScale * (1. +  blurScale * blur ) * size  ;
          
          gl_Position = projectionMatrix * modelViewMatrix * vec4( pos, 1.0 );
       
      }
      `;
}
