import * as THREE from "three";
import typeManager from "@cloud/TypeManager";
const { typeNameToId } = typeManager;
//Get renamed at some point - for compatibility
if (THREE.MathUtils == null) THREE.MathUtils = THREE.Math;
if (THREE.Math == null) THREE.Math = THREE.MathUtils;

//console.log("THREETypeDef typeManager:",typeManager);
typeManager.registerClass(typeNameToId("Curve.Line"), THREE.LineCurve3);
typeManager.registerClass(
  typeNameToId("Curve.CatmullRom"),
  THREE.CatmullRomCurve3
);
typeManager.registerClass(
  typeNameToId("Curve.CubicBezier"),
  THREE.CubicBezierCurve3
);
typeManager.registerClass(typeNameToId("Curve.Path"), THREE.CurvePath);

class ParametricCurve extends THREE.Curve {
  constructor(args) {
    super();
    this.getPoint = Function("return (" + args.getPoint + ")")();
    this.scale = args.scale || 1;
  }
}

//typeManager.addTypeDef({name: 'Curve.Parametric', category: 2, construct: 'constructor(props)', properties: {}}, ParametricCurve);
typeManager.registerClass(typeNameToId("Curve.Parametric"), ParametricCurve);
// typeManager.registerClass("Curve.Parametric",ParametricCurve);
typeManager.registerClass(typeNameToId("Shape"), THREE.Shape);

typeManager.registerClass(
  typeNameToId("Color"),
  (color) => new THREE.Color(color)
);
typeManager.registerClass(
  typeNameToId("Vector3"),
  (vector3) => new THREE.Vector3(vector3.x, vector3.y, vector3.z)
);

typeManager.addTypeDef(
  {
    name: "THREE.Vector2",
    category: 2,
    construct: "[name]=val",
    properties: {},
  },
  THREE.Vector2
);
typeManager.addTypeDef(
  {
    name: "THREE.MeshPhongMaterial",
    category: 2,
    construct: "constructor(props)",
    properties: {},
  },
  THREE.MeshPhongMaterial
);

typeManager.addTypeDef(
  {
    name: "THREE.BoxGeometry",
    category: 2,
    construct: "constructor(...args)",
    properties: {},
  },
  THREE.BoxGeometry
);
typeManager.addTypeDef(
  {
    name: "THREE.SphereGeometry",
    category: 2,
    construct: "constructor(...args)",
    properties: {},
  },
  THREE.SphereGeometry
);
typeManager.addTypeDef(
  {
    name: "THREE.TetrahedronGeometry",
    category: 2,
    construct: "constructor(...args)",
    properties: {},
  },
  THREE.TetrahedronGeometry
);
typeManager.addTypeDef(
  {
    name: "THREE.ConeGeometry",
    category: 2,
    construct: "constructor(...args)",
    properties: {},
  },
  THREE.ConeGeometry
);

typeManager.addTypeDef(
  {
    name: "THREE.BoxGeometry",
    category: 2,
    construct: "constructor(...args)",
    properties: {},
  },
  THREE.BoxGeometry
);
typeManager.addTypeDef(
  {
    name: "THREE.SphereGeometry",
    category: 2,
    construct: "constructor(...args)",
    properties: {},
  },
  THREE.SphereGeometry
);
typeManager.addTypeDef(
  {
    name: "THREE.TetrahedronGeometry",
    category: 2,
    construct: "constructor(...args)",
    properties: {},
  },
  THREE.TetrahedronGeometry
);
typeManager.addTypeDef(
  {
    name: "THREE.ConeGeometry",
    category: 2,
    construct: "constructor(...args)",
    properties: {},
  },
  THREE.ConeGeometry
);
typeManager.addTypeDef(
  {
    name: "THREE.TorusGeometry",
    category: 2,
    construct: "constructor(...args)",
    properties: {},
  },
  THREE.TorusGeometry
);
typeManager.addTypeDef(
  {
    name: "THREE.PlaneGeometry",
    category: 2,
    construct: "constructor(...args)",
    properties: {},
  },
  THREE.PlaneGeometry
);
typeManager.addTypeDef(
  {
    name: "THREE.CylinderGeometry",
    category: 2,
    construct: "constructor(...args)",
    properties: {},
  },
  THREE.CylinderGeometry
);
typeManager.addTypeDef(
  {
    name: "THREE.ConeGeometry",
    category: 2,
    construct: "constructor(...args)",
    properties: {},
  },
  THREE.ConeGeometry
);
typeManager.addTypeDef(
  {
    name: "THREE.CircleGeometry",
    category: 2,
    construct: "constructor(...args)",
    properties: {},
  },
  THREE.CircleGeometry
);
typeManager.addTypeDef(
  {
    name: "THREE.OctahedronGeometry",
    category: 2,
    construct: "constructor(...args)",
    properties: {},
  },
  THREE.OctahedronGeometry
);
typeManager.addTypeDef(
  {
    name: "THREE.IcosahedronGeometry",
    category: 2,
    construct: "constructor(...args)",
    properties: {},
  },
  THREE.IcosahedronGeometry
);

export default {};
