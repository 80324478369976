import * as THREE from "three";
import VisComp from "@three-extra/VisComp";
import typeManager from "@cloud/TypeManager";

import gm from "@three-extra/asset/GeometryManager";
import typeMan from "@cloud/TypeManager";
import materialManager from "@three-extra/asset/MaterialManager";
import shaderUtils from "@three-extra/util/ShaderUtils";
import cloud from "@cloud/VJYCloudClient";
import objMan from "@cloud/ObjectManager";
import { cloneDeep } from "lodash";
const { typeNameToId } = typeMan;

class CanvasTexturePreview extends VisComp {
  constructor() {
    super();
    this.update = this.update.bind(this);
    this.stepGeom = this.stepGeom.bind(this);
    window.pt = this;
    window.matMan = materialManager;

    //console.log('MatMan', materialManager)
  }

  start(doc, canvas) {
    super.start();
    //console.log('Start ProcTex preview', doc)

    let asset;
    // View App
    if (!doc) {
      //
      //	console.log('Start ProcTex preview', cloneDeep(this.dummyLink))

      const dummyDoc = this.dummyLink
        ? cloud.getDoc(this.dummyLink)
        : cloud.getDoc(this[">link"]);
      if (!this.dummyLink) this.dummyLink = this[">link"];
      //	console.log(dummyDoc.d.shader)
      if (!this.doc && dummyDoc.d.shader) {
        doc = cloud.getDoc(dummyDoc.d.shader[">link"]);
        this[">link"] = this[">link"] || dummyDoc.d.shader[">link"];
        this[">link"].type = this[">link"].type || doc.t;
      } else doc = this.doc;

      //console.log(this['>link'])

      this.viewer = "edit";

      asset = this.inputs.get("_asset"); // { '>link': { id: this.doc._id } };
      this[">link"] = { id: asset._id };
      this.doc = cloud.getDoc(asset);

      document.addEventListener(
        "keyup",
        (e) => e.code === "Space" && this.stepGeom()
      );

      // Preview Canvas
    } else {
      this.viewer = "preview";

      this.previewDoc = doc;
      this[">link"] = doc[">link"];
      this.dummyLink = this[">link"];

      this.doc = doc;
      asset = { id: doc._id, [">link"]: { type: doc.t } };

      this.me.camera.position.set(0, 0, 10);
    }

    this.stepType = "geometry";

    this.light = new THREE.DirectionalLight(0xffffff, 0.9);
    this.light.position.set(10, 10, 10);
    this.cont3D.add(this.light);
    this.rotSpeed = 0.002;

    if (!doc) {
      this.me.ctrl.setActive(false);
    }

    console.log("Preview asset >>>", asset, cloud.getDoc(asset));

    this.material = materialManager.build({
      base: new THREE.MeshBasicMaterial(),
      asset,
    });
    this.geometry = new THREE.PlaneGeometry();
    this.mesh = new THREE.Mesh(this.geometry, this.material);
    this.cont3D.add(this.mesh);

    const dimZ = this.renderer.screenDimZ(this.me.camera.position.z);
    this.z = this.me.camera.position.z;
    const d = Math.min(dimZ.x, dimZ.y);
    this.mesh.scale.setScalar(d);

    this.renderer.renderer.domElement.addEventListener("click", this.stepGeom);

    const typeDef = typeMan.getTypeDef(this.doc.t);
    this.inputs.typeDef = typeDef;
    this.material.side = THREE.DoubleSide;

    const texture = this.material.map;

    for (let propName in typeDef.properties) {
      const t = typeDef.properties[propName];

      this.inputs.listeners.add(propName, (_) => {
        const val = this.inputs.get(propName);
        texture.inputs.set(propName, val);
      });
    }

    // Preview options - step through geometries
    this.steps = [
      {
        geom: new THREE.PlaneGeometry(1, 1, 10, 10),
        matOptions: {
          wireframe: false,
        },
      },
      {
        geom: new THREE.BoxGeometry(1, 1, 1, 10, 10, 10),
        matOptions: {
          wireframe: false,
        },
      },
      {
        geom: new THREE.SphereGeometry(0.6, 16, 16),
        matOptions: {
          wireframe: false,
        },
      },
      {
        geom: new THREE.PlaneGeometry(1, 1, 10, 10),
        matOptions: {
          wireframe: true,
        },
      },
      {
        geom: new THREE.BoxGeometry(1, 1, 1, 10, 10, 10),
        matOptions: {
          wireframe: true,
        },
      },
      {
        geom: new THREE.SphereGeometry(0.6, 16, 16),
        matOptions: {
          wireframe: true,
        },
      },
    ];

    this.stepInd = 0;

    this.update(0);

    if (!this?.scene?.app?.isIframePreview) return;
  }

  // changes geometry when preview canvas is clicked
  stepGeom() {
    if (this.stepInd > 0) {
      if (this.me.ctrl) this.me.ctrl.setActive(true);
      this.mesh.scale.setScalar(1);
    } else {
      if (this.me.ctrl) this.me.ctrl.setActive(false);

      this.me.camera.rotation.set(0, 0, 0);

      this.me.camera.position.set(0, 0, this.z);
      const dimZ = this.renderer.screenDimZ(this.me.camera.position.z);

      const d = Math.min(dimZ.x, dimZ.y);
      this.mesh.scale.setScalar(d);
    }

    this.stepInd++;
    this.stepInd = this.stepInd % this.steps.length;

    this.mesh.geometry = this.steps[this.stepInd].geom;
    for (let key in this.steps[this.stepInd].matOptions)
      this.mesh.material[key] = this.steps[this.stepInd].matOptions[key];
    this.mesh.material.needsUpdate = true;
    this.mesh.rotation.set(0, 0, 0);
  }

  //called every frame, dt: time passed between frames
  update(dt) {
    super.update(dt);
    if (!dt) return;

    if (this.stepInd !== 0 && this.stepInd !== 3)
      this.mesh.rotation.y += this.rotSpeed;
  }

  dispose() {
    this.material.dispose();
    if (this.geometry) this.geometry.dispose();
    for (let s in this.steps) if (s.geom) s.geom.dispose();
    this.cont3D.remove(this.mesh);
  }
}

// DO NOT DELETE THIS SHIT

typeManager.registerClass(
  typeNameToId("Preview.CanvasTexture"),
  CanvasTexturePreview
);

export default CanvasTexturePreview;
