export default function isIOS() {
	const regExp = {
		ios          : /(iPad|iPhone|iPod)/g,
		mobileChrome : /(CriOS)/g,
		mobile       : /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/g
	  }
	var
	  userAgent      = navigator.userAgent,
	  isIOS          = userAgent.match(regExp.ios)
	;
	if(isIOS ) {

	  return true;
	}
	else {
	  return false;
	}
  }