import merge from "lodash/merge";
import cloud from "@cloud/VJYCloudClient";
import typeMan from "@cloud/TypeManager";
import objMan from "@cloud/ObjectManager";

import factoryMat from "@three-extra/asset/MaterialManager";
import VRStarter from "@ui/VRStarter";
import AppBase from "./AppBase";
import SceneSingle from "./SceneSingle";
import Context from "@rt/Context";
import geometryManager from "./asset/GeometryManager";
import input from "@input/InputManager";
import music from "@audio/MusicMeta";
import extAssetCache from "@three-extra/asset/ExtAssetCache";
import { cloneDeep } from "lodash";
const { typeNameToId } = typeMan;

const cloudClient = cloud;
class AppSingle extends AppBase {
  constructor(appDecl) {
    super();
    this.globalContext = new Context();
    this.scene = new SceneSingle();
    this.appDecl = appDecl;
    this.update = this.update.bind(this);
    this.projectLoaded = this.projectLoaded.bind(this);
    this.compLoaded = this.compLoaded.bind(this);
    this.scrollable = false;
    this.factoryMat = factoryMat;
    this.enableScreenshot = false;
  }

  async start() {
    this.scene.enableScreenshot = this.enableScreenshot;

    // Subscribe preloader to ExtAssetCache events
    if (this.preloader && this.preloader.onAssetLoaded) {
      extAssetCache.listeners.add("assetLoaded", this.preloader.onAssetLoaded);
      extAssetCache.hasPreloader = true;
    }
    if (this?.preloader?.doneLoading)
      extAssetCache.listeners.add("allLoaded", this.preloader.doneLoading);

    // Load Project
    let projectQuery = { t: typeNameToId("Project") };
    if (this.appDecl.project.id != null)
      projectQuery.id = this.appDecl.project.id;
    else if (this.appDecl.project.n != null)
      projectQuery.n = this.appDecl.project.n;
    else {
      console.log("!!!!! NO PROJECT !!!!!");
      return;
    }

    //	console.log('** App Decl', this.appDecl)
    const opts = {
      deps: true,
      include: {
        m:
          this?.appDecl?.settings?.cloud?.includeMeta !== undefined
            ? this.appDecl.settings.cloud.includeMeta
            : true,
      },
    };
    const localOpts = {
      cacheResults: true,
      cacheTypeDefs: true,
    };

    opts.include.m = true;
    // determine asset type

    const assetLink = this.appDecl.asset;

    // No asset, fetch the project and start the scene
    if (!assetLink) {
      const res = await cloud.find(projectQuery, opts, localOpts);
      return this.projectLoaded(res);
    }

    // Fetch asset
    const res = await cloud.find(assetLink, opts, localOpts);

    const assetDoc = res.docs[0];
    let assetType = typeMan.getTypeDef(assetDoc.t);

    const user = await cloudClient.getCurrentUser();

    // CHeck if asset is AV and update project query accordinal
    if (assetDoc?.m?.tags?.length && assetDoc?.m?.tags.indexOf("AV") > -1) {
      this.appDecl.project.n = "View.AV";
      this.appDecl.project.id = user.settings.viewAV[">link"].id;
      projectQuery.id = this.appDecl.project.id;
      // Else use default view project
    } else {
      this.appDecl.project.id = user.settings.view[">link"].id;
    }

    // If the asset is a VisComp, we don't need a visualizer - fetch the project and start
    if (typeMan.isCompatible(assetDoc.t, typeNameToId("VisComp"))) {
      const res = await cloud.find(projectQuery, opts, localOpts);
      return this.projectLoaded(res);
    }

    // Check the asset type for a visualizer
    // if none is there, go up the inheritance chain until we find one
    let { visualizer } = assetType;
    let counter = 0;

    while (!visualizer || !visualizer.comp) {
      if (counter > 10 || !assetType.parent) break;
      const parentDoc = cloud.getDoc(assetType.parent);
      const parentType = typeMan.getTypeDef(parentDoc.d.name);
      visualizer = parentType.visualizer;
      //	console.log('parentype', parentType)
      counter++;
    }

    const { project, comp } = visualizer;

    // Exit and throw error if no visualizer present
    if (!project && !comp) {
      throw new Error("No visualizer found for asset", assetDoc);
    }

    if (project) projectQuery.id = project[">link"].id;

    let query = comp;
    // Fetch visualizer docs
    let visRes = await cloud.find(query, opts, localOpts);

    const { appDecl } = this;

    if (project) {
      query = { t: typeNameToId("Project"), id: project[">link"].id };
      visRes = await cloud.find(query, opts, localOpts);
    } else {
      visRes = await cloud.find(projectQuery, opts, localOpts);
    }

    // VisComp used to preview the asset
    const previewDoc = cloud.getDoc(comp);
    let previewType = typeMan.getTypeDef(previewDoc.t);
    if (!previewType) {
      previewType = await typeMan.findTypeDef(previewDoc.t);
    }

    appDecl.project.override = {
      scene: {
        startComp: { ">link": { by: "id", id: previewDoc._id } },
      },
    };

    // Preview VisComps have VisComp properties + _asset
    // Add in the asset's properties to the Preview VisComp's typedef
    previewType.properties = merge(
      previewType.properties,
      cloneDeep(assetType.properties)
    );
    // then set values for these properties
    for (let key in assetDoc.d) {
      previewDoc.d[key] = cloneDeep(assetDoc.d[key]);
    }
    // link asset to preview comp
    previewDoc.d._asset = {
      ">link": {
        id: assetDoc._id,
      },
    };

    // 3 preloading project
    // 4  Sort out VisComp type spec logic
    await this.projectLoaded(visRes);
  }
  allowScroll() {
    if (!this.scrollable) {
      this.contScroll = document.scrollingElement; //document.documentElement;//document.body;
      window.onscroll = this.onScroll.bind(this);
    }

    //

    this.scrollable = true;
  }

  onScroll(ev) {
    //console.log("Scrollll",this.contScroll.scrollTop,ev);
    //this.contScroll.offsetHeight
    input.setScrollPosition(
      Math.max(this.contScroll.scrollTop, 0),
      window.innerHeight,
      this.contScroll.scrollHeight
    );
    //this.scroll0=this.divContent.scrollTop;
  }
  addHTML(content, link) {
    if (!this.appDecl.project.doAddHTML)
      return document.body.classList.toggle("hasHTML", false);

    document.body.classList.toggle("hasHTML", true);

    let { id } = link;
    if (!id && link[">link"]) {
      id = link[">link"].id;
    }

    this.divContent = document.createElement("div");

    for (let ii = 0; ii < content.length; ii++) {
      const html = cloud.getDoc(content[ii]).d.code;
      // console.log(html);
      this.divContent.innerHTML += html;
    }

    if (this.projectDecl?.settings?.inputMan?.mkb?.customScroll) {
      input.enableCustomScroll();
    } else {
      this.allowScroll();
    }
    for (let child of [...this.divContent.children]) {
      document.body.appendChild(child);
      child.dataset.comp_id = id;
    }

    // if there is no content to scroll, then canvas should stay at height 100%
    // on mobile, if the body is scrollable, we need the canvas to be 100vh tall so that it fully
    // covers the screen after the user has scrolled and the bottom bar is hidden. before the user scrolls,
    // the canvas's bottom will be clipped but that's fine
    const { scrollHeight } = document.body;
    if (scrollHeight > window.innerHeight) {
      if (!this.canvas) this.canvas = document.getElementById("canvas");
      this.canvas.style.height = "100vh";
    }
  }
  addCSS(content) {
    if (
      window.location.href.match("VisJet%20MicroSite") &&
      window.location.href.match("localhost")
    )
      return;
    if (!this.appDecl.project.doAddCSS) return;

    const head = document.getElementsByTagName("head")[0];
    for (let ii = 0; ii < content.length; ii++) {
      const css = cloud.getDoc(content[ii]).d.code;
      const style = document.createElement("style");
      style.innerText = css;
      head.appendChild(style);
    }
  }
  async projectLoaded(res) {
    const doc = res.docs[0];
    //	console.log('** Project docs', res)
    //console.log("** Cloud", cloud._cache);
    //console.log("** Types", typeMan.defs);
    //console.log("** Project", doc);

    this.projectDecl = doc.d;
    merge(this.projectDecl, this.appDecl.project.override);

    console.log(
      "** Project decl",
      this.projectDecl,
      this.appDecl.project.override,
      doc.d
    );

    //HTML & CSS Content
    if (navigator != undefined && navigator.userAgent != undefined) {
      var user_agent = navigator.userAgent.toLowerCase();
      if (user_agent.indexOf("android") > -1) {
        // Is Android.
        document.body.classList.add("Android");
      }
    }
    if (
      this.projectDecl.contentHtml != null &&
      this.projectDecl.contentHtml.length > 0
    ) {
      this.addHTML(
        this.projectDecl.contentHtml,
        this.projectDecl.scene.startComp ||
          (this.projectDecl?.resources?.composition &&
            this.projectDecl.resources.composition[0])
      );
    }
    if (
      this.projectDecl.contentCss != null &&
      this.projectDecl.contentCss.length > 0
    ) {
      this.addCSS(this.projectDecl.contentCss);
    }

    if (this.appDecl.project.doScroll) this.allowScroll();

    //Music Meta

    if (this.projectDecl?.settings?.musicMeta) {
      this.projectDecl.settings.audioMan.musicMeta =
        this.projectDecl.settings.musicMeta;
    }
    // console.log("Project Decl",this.projectDecl);
    // Merge App & Project settings
    merge(this.appDecl.settings, this.projectDecl.settings);
    if (this.projectDecl.ui) this.appDecl.ui.main = this.projectDecl.ui;
    this.setParams(this.appDecl);
    this.startServices();

    //If there is an active MusicSetup, wait to get init
    if (music.enabled && music.setup != null && !music.setup.ready) {
      music.setup.listeners.add("Ready", this.startScene.bind(this));
    } else {
      await this.startScene();
    }
  }

  async startScene() {
    //Check whether start scene is here:

    this.startCompLink = this.projectDecl.scene.startComp;
    if (this.startCompLink == null)
      this.startCompLink = this.projectDecl.resources.composition[0];
    //console.log('Start scene')
    if (cloud.getDoc(this.startCompLink)) return this.compLoaded();

    await cloud.find(
      this.startCompLink,
      {
        deps: true,
      },
      {
        cacheResults: true,
        cacheTypeDefs: true,
      }
    );
    await this.compLoaded();
  }

  async compLoaded() {
    // console.log("Comp Loaded",cloud.getDoc(this.startCompLink));
    /* MERGE COMP - Experinetal */
    if (this.projectDecl.scene.startCompOver) {
      const comp = cloud.getDoc(this.startCompLink);
      merge(comp.d, this.projectDecl.scene.startCompOver);
    }
    const { settings, resources = {}, scene } = this.projectDecl;

    // Global Context
    if (resources.sceneContext)
      this.globalContext.setObj(resources.sceneContext);

    // Scene
    this.scene.globalContext = this.globalContext;
    this.scene.dominantContext = this.appDecl.dominantContext || "composition";
    this.scene.renderComp = this.renderComp;
    this.scene.me = this.me;
    this.scene.app = this;
    this.scene.cont3D = this.cont3D;
    if (window.location.hash.match("vr"))
      this.vrstarter = new VRStarter(this.renderComp.renderer);
    /*
		this.scene.listeners.add('after-set-composition', event => {
			if (event.data.comp.vr) {
				if (!this.vrstarter) 
			} else if (this.vrstarter) {
				this.vrstarter.destroy();
				this.vrstarter = null;
			}
		});*/
    this.scene.start();
    this.renderComp.scene.add(this.scene.cont3D);

    await this.scene.setComposition(
      this.startCompLink,
      this.appDecl.compOverrideSettings
    );

    // Main UI
    if (this.appDecl.ui.main) {
      this.mainUI = objMan.instantiateObj(this.appDecl.ui.main);

      this.mainUI.composition = this.scene.compScene;
      this.mainUI.scene = this.scene;
      this.mainUI.renderer = this.renderComp;
      this.mainUI.projectDecl = this.projectDecl;
      this.mainUI.props = this.appDecl.ui.main.d;
      if (this.mainUI.start) this.mainUI.start();
      else this.mainUI = null; // necessary for Edit app compatibility
    }
    if (this.onRunning) this.onRunning();

    // Update Loop
    this.scene.renderComp.renderer.setAnimationLoop(this.update);

    // 2DO - custom fade out screens
    if (this.preloader && !this.preloader.doneLoading) {
      this.update(0); // prerender
      // alert("pref")
      this.preloader.fadeOut();
    }

    if (this.onCompLoaded) this.onCompLoaded();
  }

  // function to be called once this has loaded
  setLoadCallback(cb) {
    this.onCompLoaded = cb;
  }
  // function to be called at eachupdate
  setUpdateCallback(cb) {
    this.onUpdate = cb;
  }

  stop() {
    this.scene.renderComp.renderer.setAnimationLoop(null);
    this.clock.stop();
  }
  resume() {
    this.clock.start();
    this.scene.renderComp.renderer.setAnimationLoop(this.update);
  }

  update() {
    const dt = super.update();
    factoryMat.update(dt);
    geometryManager.update(dt);
    this.scene.update(dt);
    if (this.mainUI) this.mainUI.update();
    if (this.onUpdate) this.onUpdate(dt);
    this.renderComp.update(dt);
  }
}

export default AppSingle;
