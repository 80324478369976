export function vertShader( infiniteLife, isMoving  ) {

  //  console.log( "VERYTES", infiniteLife, isMoving )
    return  `
    precision highp float;

    const vec4 bitSh = vec4(256. * 256. * 256., 256. * 256., 256., 1.);
    const vec4 bitMsk = vec4(0.,vec3(1./256.0));
    const vec4 bitShifts = vec4(1.) / bitSh;

    #define FLOAT_MAX  1.70141184e38
    #define FLOAT_MIN  1.17549435e-38

    lowp vec4 encode_float(highp float v) {
        highp float av = abs(v);
        //Handle special cases
        if(av < FLOAT_MIN) {
        return vec4(0.0, 0.0, 0.0, 0.0);
        } else if(v > FLOAT_MAX) {
        return vec4(127.0, 128.0, 0.0, 0.0) / 255.0;
        } else if(v < -FLOAT_MAX) {
        return vec4(255.0, 128.0, 0.0, 0.0) / 255.0;
        }
        highp vec4 c = vec4(0,0,0,0);
        //Compute exponent and mantissa
        highp float e = floor(log2(av));
        highp float m = av * pow(2.0, -e) - 1.0;
        c[1] = floor(128.0 * m);
        m -= c[1] / 128.0;
        c[2] = floor(32768.0 * m);
        m -= c[2] / 32768.0;
        c[3] = floor(8388608.0 * m);
        //Unpack exponent
        highp float ebias = e + 127.0;
        c[0] = floor(ebias / 2.0);
        ebias -= c[0] * 2.0;
        c[1] += floor(ebias) * 128.0;
        //Unpack sign bit
        c[0] += 128.0 * step(0.0, -v);
        //Scale back to range
        return c / 255.0;
    }

  
    uniform float uTime;
    uniform float uScale;
    uniform sampler2D tNoise;

    attribute vec4 particlePositionsStartTime;
    attribute vec4 particleVelColSizeLife;

    varying vec4 vColor;
    varying float lifeLeft;
    
    void main() {

        // unpack things from our attributes
        vColor = encode_float( particleVelColSizeLife.y );

        // convert our velocity back into a value we can use
        vec4 velTurb = encode_float( particleVelColSizeLife.x );
        vec3 velocity = vec3( velTurb.xyz );

        velocity.x = ( velocity.x - .5 ) * 3.;
        velocity.y = ( velocity.y - .5 ) * 3.;
        velocity.z = ( velocity.z - .5 ) * 3.;
        float turbulence = velTurb.w;
      

        float timeElapsed = uTime - particlePositionsStartTime.a;
        lifeLeft = 1. - (timeElapsed / particleVelColSizeLife.w);



        vec3 pos = particlePositionsStartTime.xyz ;
     

        vec3  newPosition = pos  + ( velocity * 10. ) * timeElapsed ;
      //  newPosition = pos; 

        // vec3 noise = texture2D( tNoise, vec2( newPosition.x  + (uTime * .05), newPosition.y + (uTime * .015) )).rgb;
       // vec3 noiseVel = ( noise.rgb - .5 ) ;

     //  newPosition = mix(newPosition, newPosition + vec3(noiseVel * ( turbulence  ) ), (timeElapsed / particleVelColSizeLife.a) );
        
      
     
        ${ infiniteLife? "lifeLeft = 0.95;" : ""}
        ${ isMoving? "": "newPosition = pos  ;" }

        gl_PointSize = ( uScale * particleVelColSizeLife.z ) * lifeLeft;
        
        if( timeElapsed > 0. ) {
            gl_Position = projectionMatrix * modelViewMatrix * vec4( newPosition, 1.0 );
        } else {
            gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
            lifeLeft = 0.;
            gl_PointSize = 0.;
        }
    }
    `
}